import * as React from 'react';
import { Textarea } from '@/components/ui/textarea';

interface TaskInternalNotesProps {
  notes: string;
  onNotesChange: (notes: string) => void;
}

export const TaskInternalNotes: React.FC<TaskInternalNotesProps> = ({
  notes,
  onNotesChange,
}) => {
  return (
    <div>
      <h3 className="text-lg font-semibold mb-2 pt-2">Internal Notes</h3>
      <Textarea
        id="internal-notes"
        aria-label="Internal notes"
        placeholder="Type internal Finch notes here..."
        className="h-48 border border-gray-300 rounded-lg px-4 py-2 shadow-none"
        value={notes}
        onChange={(e) => onNotesChange(e.target.value)}
      />
    </div>
  );
};

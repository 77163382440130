import { TemplateVariable, VariableCategory } from '../types';

// Define variables by category
export const variablesByCategory: Record<VariableCategory, TemplateVariable[]> =
  {
    firm: ['firmName', 'firmFullName'],
    matter: [
      'matterName',
      'matterStatus',
      'matterMatterType',
      'matterDateOfLoss',
      'matterCity',
      'matterState',
      'matterStatuteOfLimitationsDate',
    ],
    client: [
      'clientName',
      'clientEmail',
      'clientDateOfBirth',
      'clientAddress',
      'clientPhone',
      'clientLastFourOfSsn',
    ],
    police: [
      'policeName',
      'policeAddress',
      'policePhone',
      'policeEmail',
      'policePoliceReportNumber',
    ],
    medical: [
      'medicalName',
      'medicalDatesOfService',
      'medicalTreatment',
      'medicalAddress',
      'medicalPhone',
      'medicalEmail',
      'medicalStillTreating',
      'medicalDateOfRecordsRequest',
    ],
    insurance: [
      'insuranceDateFiled',
      'insurancePolicyType',
      'insuranceHolder',
      'insuranceCarrier',
      'insurancePolicyNumber',
      'insurancePolicyLimitations',
      'insuranceVehicleDescription',
      'insuranceClaimNumber',
      'insuranceAdjusterName',
      'insuranceAdjusterPhone',
      'insuranceAdjusterEmail',
      'insuranceMemberId',
      'insuranceGroupId',
    ],
    incident: [
      'incidentDescription',
      'incidentDateOfLoss',
      'incidentTimeOfLoss',
      'incidentLocation',
      'incidentInjuriesDescribed',
      'incidentType',
      'incidentHospital',
      'incidentAmbulance',
    ],
  };

// Flatten all variables into a single array
export const templateVariables: TemplateVariable[] =
  Object.values(variablesByCategory).flat();

// Export the categorized variables too for components that need them
export const categorizedTemplateVariables = variablesByCategory;

import { MessageRole, MessageStatus } from '../api/matterAssistant';
import { Blob, MatterType } from './client-intake-types';

// Base types matching the Python models
export interface CreateDemand {
  name: string;
}

export interface CreateRevision {
  revision_id: string;
  name?: string | null;
  creator_id?: string | null;
  authors_ids?: string[] | null;
  diff_data?: string | null;
  attributes?: string | null;
  from_version?: number | null;
  to_version?: number | null;
  is_empty_current?: boolean | null;
  data?: string | null;
}

export interface PutRevision {
  revision: CreateRevision;
}

export enum DemandGenerationStatus {
  PENDING = 'PENDING',
  GENERATING = 'GENERATING',
  GENERATED = 'GENERATED',
  FAILED = 'FAILED',
}

// Types matching the Django serializers
export interface Demand {
  id: string;
  name: string;
  matter: string;
  generation_status: DemandGenerationStatus;
  generated_document: string | null;
  created_at: string;
  modified_at: string;
}

export interface DemandRevision {
  id: string;
  created_at: string;
  modified_at: string;
  revision_id: string;
  name: string | null;
  creator_id: string | null;
  attributes: string | null;
  from_version: number | null;
  to_version: number | null;
  is_empty_current: boolean | null;
  author_ids: string[] | null;
}

// Full demand revision type (based on DemandRevisionSerializer with all fields)
export interface DemandRevisionFull extends DemandRevision {
  // Additional fields from "__all__"
  diff_data?: string;
  data?: string;
  demand: string; // Foreign key to Demand
}

// Thread types
export interface DemandEditorThread {
  id: string;
  title: string;
  demand: string;
  created_at: string;
  modified_at: string;
}

export interface CreateDemandEditorThread {
  title: string;
}

export interface UpdateDemandEditorThread {
  title: string;
}

// Message content types
interface BaseMessageContent {
  id: string;
  created_at: string;
  modified_at: string;
}

interface TextMessageContent extends BaseMessageContent {
  type: 'text';
  text: string;
}

interface HTMLMessageContent extends BaseMessageContent {
  type: 'html';
  html: string;
}

interface ImageMessageContent extends BaseMessageContent {
  type: 'image';
  image_id: string;
}

export type DemandEditorMessageContent =
  | TextMessageContent
  | HTMLMessageContent
  | ImageMessageContent;

// Message types
export interface DemandEditorMessage {
  id: string;
  parent: string | null;
  created_at: string;
  modified_at: string;
  thread: string;
  role: MessageRole;
  status: MessageStatus;
  openai_response: Record<string, any> | null;
  content: DemandEditorMessageContent[];
}

export interface CreateDemandEditorMessageRequest {
  message: {
    id: string;
    created_at: string;
    modified_at: string;
    role: string;
    parent_id: string | null;
    status: string;
    openai_response: Record<string, any> | null;
    content: DemandEditorMessageContent[];
  };
  context: string;
}

// File types
export enum DemandEditorFileType {
  IMAGE = 'image',
  FILE = 'file',
}

export interface DemandEditorFile {
  id: string;
  thread: string;
  file_type: DemandEditorFileType;
  file_name: string;
  url: string;
  created_at?: string;
  modified_at?: string;
}

export interface CreateDemandEditorFile {
  filename: string;
  file_type: DemandEditorFileType;
  file: File; // Using browser's File type instead of bytes for frontend
}

// Enum for document sections
export enum DemandDocumentSectionType {
  // Introduction
  INTRODUCTION = 'introduction',

  // Facts & Liability
  FACTS_LIABILITY = 'facts_liability',
  DEFENDANT_NEGLIGENCE_AND_BREACH_OF_DUTY = 'defendant_negligence_and_breach_of_duty',
  PROXIMATE_CAUSE_OF_HARM = 'proximate_cause_of_harm',

  // Injuries & Treatment
  INJURIES_AND_TREATMENT = 'injuries_and_treatment',
  SUMMARY_INJURIES = 'summary_injuries',
  TREATMENT = 'treatment',

  // Damages
  DAMAGES = 'damages',
  TOTAL_PROJECTED_CLAIM_VALUE = 'total_projected_claim_value',
  PAST_MEDICAL_EXPENSES = 'past_medical_expenses',
  FUTURE_MEDICAL_EXPENSES = 'future_medical_expenses',
  LOST_WAGES = 'lost_wages',
  PROPERTY_DAMAGE = 'property_damage',
  LOSS_HOUSEHOLD_SERVICES = 'loss_household_services',
  PAIN_SUFFERING = 'pain_suffering',

  VERDICT_ANALYSIS = 'verdict_analysis',
  PER_DIEM_ANALYSIS = 'per_diem_analysis',

  // Demand to Settle
  DEMAND_TO_SETTLE = 'demand_to_settle',
}

// Section configuration interface
export interface DemandSectionConfiguration {
  is_enabled: boolean;
  title: string;
  title_only: boolean;
  content_prompt: string | null;
  content_example: string | null;
  formatting_prompt: string | null;
  formatting_example: string | null;
}

// Create configuration request
export interface CreateDemandConfiguration {
  firm_id: string;
  case_type: MatterType;
  name: string;
  description?: string;
  configuration: Partial<
    Record<DemandDocumentSectionType, DemandSectionConfiguration>
  >;
}

// Update configuration request
export interface UpdateDemandConfiguration {
  name?: string;
  description?: string;
  configuration: Partial<
    Record<DemandDocumentSectionType, DemandSectionConfiguration>
  >;
}

// Full configuration model from API
export interface DemandConfiguration {
  id: string;
  name: string;
  description: string | null;
  case_type: MatterType;
  firm: string;
  configuration: Record<DemandDocumentSectionType, DemandSectionConfiguration>;
  created_at: string;
  modified_at: string;
}

export interface DemandConfigurationList {
  id: string;
  name: string;
  description: string | null;
  case_type: MatterType;
  firm: string;
  created_at: string;
  modified_at: string;
}

export interface DemandCitation {
  id: string;
  created_at: string;
  modified_at: string;
  artifact: Artifact;
  citation_id: string;
  demand: string;
  text_index_start: number;
  text_index_end: number;
  page_number_start: number;
  page_number_end: number;
}

interface BaseArtifact {
  id: string;
  created_at: string;
  modified_at: string;
  name: string;
  matter: string;
  blob: Blob | null;
}

interface BlobArtifact extends BaseArtifact {
  artifact_type: ArtifactType.BLOB;
  blob: Blob;
}

interface OtherArtifact extends BaseArtifact {
  artifact_type: Exclude<ArtifactType, ArtifactType.BLOB>;
}

type Artifact = BlobArtifact | OtherArtifact;

export enum ArtifactType {
  TRANSCRIPT = 'TRANSCRIPT',
  AUDIO_CONVERSATION = 'AUDIO_CONVERSATION',
  DOCUMENT = 'DOCUMENT',
  EMAIL = 'EMAIL',
  EMAIL_ATTACHMENT = 'EMAIL_ATTACHMENT',
  INTAKE_NOTE = 'INTAKE_NOTE',
  MESSAGE = 'MESSAGE',
  MESSAGE_ATTACHMENT = 'MESSAGE_ATTACHMENT',
  CONVERSATION = 'CONVERSATION',
  BLOB = 'BLOB',
}
export interface DemandTemplate {
  id: string;
  created_at: string;
  modified_at: string;
  firm: string;
  stylesheet: string;
  docx_template: string;
  demand_preamble: string;
}

export interface UpdateDemandTemplate {
  docx_template?: File;
  stylesheet?: File;
  demand_preamble?: string;
}

export interface HTMLToDocxRequest {
  html: string;
  css?: string;
  config?: Record<string, unknown>;
}

import * as React from 'react';
import { Article } from '@mui/icons-material';
import { ExternalLink, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useUpdateTaskMutation } from '@/services/api';
import { Blob, Task } from '@/services/types/client-intake-types';
import { DocumentSelectionModal } from './DocumentSelectionModal';

interface TaskAttachmentsProps {
  task: Task;
  onDeleteAttachment: (attachmentId: string) => Promise<void>;
}

export const TaskAttachments: React.FC<TaskAttachmentsProps> = ({
  task,
  onDeleteAttachment,
}) => {
  const [attachmentToDelete, setAttachmentToDelete] =
    React.useState<Blob | null>(null);
  const [documentSelectionOpen, setDocumentSelectionOpen] =
    React.useState(false);
  const [updateTask] = useUpdateTaskMutation();

  const handleSelectDocuments = async (blobIds: string[]) => {
    // Update task with all new attachment IDs
    await updateTask({
      matterId: task.matter,
      taskId: task.id,
      task: {
        attachments: Array.from(blobIds),
      },
    });
  };

  return (
    <div className="py-3">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-semibold">Attachments</h3>
        <div className="flex gap-2">
          <Button
            variant="outline"
            className="border-gray-300 hover:bg-gray-100"
            size="sm"
            onClick={() => setDocumentSelectionOpen(true)}
          >
            <span className="font-bold text-xs">ADD</span>
          </Button>
        </div>
      </div>
      <div className="border border-dashed rounded-lg transition-colors border-gray-400">
        {task.attachments.length === 0 ? (
          <div className="text-center text-sm text-gray-500 p-4">
            <p>No attachments yet</p>
          </div>
        ) : (
          <div className="space-y-2">
            {task.attachments.map((attachment) => (
              <div
                key={attachment.id}
                className="flex items-center justify-between p-2 rounded-lg hover:bg-gray-50"
              >
                <div className="flex items-center gap-2">
                  <Article className="h-4 w-4 text-gray-500" />
                  <a
                    href={attachment.download_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-medium hover:text-primary"
                  >
                    {attachment.name}
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-8 w-8 p-0 hover:bg-gray-100"
                    onClick={() =>
                      window.open(attachment.download_url, '_blank')
                    }
                  >
                    <ExternalLink className="h-4 w-4 p-1" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-8 w-8 p-0 hover:bg-gray-100 hover:text-red-600"
                    onClick={() => setAttachmentToDelete(attachment)}
                  >
                    <Trash2 className="h-4 w-4 p-1" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <DocumentSelectionModal
        open={!!documentSelectionOpen}
        onClose={() => {
          setDocumentSelectionOpen(false);
        }}
        matterId={task.matter}
        onSelectDocuments={handleSelectDocuments}
        task={task}
      />

      <AlertDialog
        open={!!attachmentToDelete}
        onOpenChange={() => setAttachmentToDelete(null)}
      >
        <AlertDialogContent className="bg-white">
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Attachment</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to remove &ldquo;
              {attachmentToDelete?.name}&rdquo;?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              className="bg-red-600 text-white hover:bg-red-700"
              onClick={async () => {
                if (attachmentToDelete) {
                  await onDeleteAttachment(attachmentToDelete.id);
                  setAttachmentToDelete(null);
                }
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

import { Header } from '@/layout/Header';

import { Firms } from '../firms/firms';

export const FirmPage = () => {
  return (
    <div className="w-full">
      <Header />
      <Firms />
    </div>
  );
};

import { Download, Eye, FileText, Loader2, X } from 'lucide-react';
import React from 'react';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { DemandCitation } from '@/services/types/demand-types';

import { useGetDemandCitationQuery } from '../../../services/api/demandService';

const mimeToHumanReadable: Record<string, string> = {
  'text/plain': 'Text File',
  'text/html': 'HTML Document',
  'text/csv': 'CSV File',
  'application/json': 'JSON File',
  'application/pdf': 'PDF Document',
  'application/msword': 'Word Document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Word Document',
  'application/vnd.ms-excel': 'Excel Spreadsheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'Excel Spreadsheet',
  'image/jpeg': 'JPEG Image',
  'image/png': 'PNG Image',
  'image/gif': 'GIF Image',
  'image/webp': 'WebP Image',
  'audio/mpeg': 'MP3 Audio',
  'audio/wav': 'WAV Audio',
  'video/mp4': 'MP4 Video',
  'video/webm': 'WebM Video',
  'application/zip': 'ZIP Archive',
  'application/x-tar': 'TAR Archive',
  'application/x-rar-compressed': 'RAR Archive',
  'application/octet-stream': 'Binary File',
};

/**
 * Generic Citation Layout component that accepts heading, subheading, and action nodes
 */
interface CitationLayoutProps {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  action?: React.ReactNode;
  className?: string;
}

const CitationLayout: React.FC<CitationLayoutProps> = ({
  heading,
  subheading,
  action,
  className = '',
}) => {
  return (
    <div
      className={cn(
        'flex flex-row items-center justify-between w-full gap-2',
        className,
      )}
    >
      <div className="flex flex-col items-center">
        <div>
          <div className="text-sm">{heading}</div>
          {subheading && (
            <div className="text-xs text-muted-foreground">{subheading}</div>
          )}
        </div>
      </div>
      {action && <div>{action}</div>}
    </div>
  );
};

/**
 * Unified blob citation component that handles both PDF and other file types
 */
const BlobCitation: React.FC<{ citation: DemandCitation }> = ({ citation }) => {
  if (!citation.artifact.blob) return null;

  const isPdf = citation.artifact.blob.file_mime_type === 'application/pdf';
  const heading = citation.artifact.blob.name;

  const buildSubheading = (items: string[]) => {
    return items.join(' · ');
  };

  // Determine the subheading based on file type
  const subheadingItems = [];
  if (
    citation.artifact.blob.file_mime_type &&
    citation.artifact.blob.file_mime_type in mimeToHumanReadable
  ) {
    subheadingItems.push(
      mimeToHumanReadable[citation.artifact.blob.file_mime_type],
    );
  }
  if (isPdf && citation.page_number_start) {
    subheadingItems.push(
      `Pages: ${citation.page_number_start} - ${citation.page_number_end}`,
    );
  }
  const subheading = buildSubheading(subheadingItems);

  // Determine the download URL and action icon based on file type
  let downloadUrl = citation.artifact.blob.download_url;
  if (isPdf && citation.page_number_start) {
    downloadUrl = `${downloadUrl}#page=${citation.page_number_start}`;
  }

  const action = (
    <Button
      size="sm"
      variant="ghost"
      onClick={() => window.open(downloadUrl, '_blank')}
    >
      {isPdf ? <Eye className="h-3 w-3" /> : <Download className="h-3 w-3" />}
    </Button>
  );

  return (
    <CitationLayout heading={heading} subheading={subheading} action={action} />
  );
};

/**
 * Non-blob artifact citation component
 */
const ArtifactCitation: React.FC<{
  citation: DemandCitation;
  onClose?: () => void;
}> = ({ citation, onClose }) => {
  const heading = citation.artifact.name;
  const subheading = citation.artifact.artifact_type || 'Unknown artifact type';
  const action = onClose && (
    <Button size="sm" variant="ghost" onClick={onClose}>
      <X className="h-4 w-4" />
      <span className="ml-1">Close</span>
    </Button>
  );

  return (
    <div className="rounded-md border border-muted bg-card">
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center gap-4">
          <FileText className="h-6 w-6 text-muted-foreground" />
          <div>
            <h4 className="text-sm">{heading}</h4>
            <p className="text-xs text-muted-foreground">{subheading}</p>
          </div>
        </div>
        {action}
      </div>
    </div>
  );
};

interface CitationItemProps {
  matterId: string;
  demandId: string;
  citationId: string;
  onClose?: () => void;
}

/**
 * Main Citation component that handles data fetching and displays the appropriate citation type
 */
export const CitationItem: React.FC<CitationItemProps> = ({
  matterId,
  demandId,
  citationId,
  onClose,
}) => {
  // Fetch the citation data
  const {
    data: citation,
    isLoading,
    isError,
  } = useGetDemandCitationQuery({
    matterId,
    demandId,
    citationId,
  });

  // Loading state
  if (isLoading) {
    return (
      <div className="p-3 rounded-md border border-muted bg-card">
        <CitationLayout
          heading={
            <div className="flex items-center gap-2">
              <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
              <span className="text-muted-foreground">Loading citation...</span>
            </div>
          }
        />
      </div>
    );
  }

  // Error state
  if (isError || !citation) {
    return (
      <div className="p-3 rounded-md border border-destructive/20 bg-destructive/5">
        <span className="text-sm text-destructive">
          Failed to load citation
        </span>
      </div>
    );
  }

  // If not a blob, show a non-blob artifact citation
  if (!citation.artifact.blob) {
    return <ArtifactCitation citation={citation} onClose={onClose} />;
  }

  // For blob artifacts, use the unified BlobCitation component
  return (
    <div className="flex flex-row items-center gap-2 w-full">
      <BlobCitation citation={citation} />
    </div>
  );
};

import { format } from 'date-fns';
import {
  AlertCircle,
  AlertTriangle,
  CheckCircle2,
  Clock,
  HelpCircle,
} from 'lucide-react';
import React from 'react';

import { Card, CardContent } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';

import {
  ProofreadingJob,
  ProofreadingJobStatus,
  ProofreadingRuleStatus,
} from '../../../services/types/proofreading-types';
import { useRuleResults } from '../hooks/useRuleResults';
import { JobStatusBadge } from './JobStatusBadge';

interface JobCardProps {
  job: ProofreadingJob;
  isSelected?: boolean;
  onClick: () => void;
}

export const JobCard: React.FC<JobCardProps> = ({
  job,
  isSelected = false,
  onClick,
}) => {
  const isInProgress =
    job.status === ProofreadingJobStatus.PENDING ||
    job.status === ProofreadingJobStatus.PROCESSING;

  const isError =
    job.status === ProofreadingJobStatus.FAILED ||
    job.status === ProofreadingJobStatus.CANCELLED;

  const isCompleted = job.status === ProofreadingJobStatus.COMPLETED;

  // Calculate completion percentage based on job status
  let completionPercentage = null;
  if (job.completion_percentage !== undefined) {
    completionPercentage = job.completion_percentage;
  } else if (job.status === ProofreadingJobStatus.COMPLETED) {
    completionPercentage = 100;
  } else if (isError) {
    completionPercentage = 0;
  }

  const formattedDate = job.created_at
    ? format(new Date(job.created_at), 'MMM d, h:mm a')
    : 'Unknown';

  const formattedCompletionDate = job.completed_at
    ? format(new Date(job.completed_at), 'MMM d, h:mm a')
    : null;

  // Statistics for completed jobs
  const { statistics } = useRuleResults(job);
  const resultSummary = isCompleted
    ? {
        noIssues: statistics.byStatus[ProofreadingRuleStatus.NO_ISSUES] || 0,
        minorIssues:
          statistics.byStatus[ProofreadingRuleStatus.MINOR_ISSUES_FOUND] || 0,
        moderateIssues:
          statistics.byStatus[ProofreadingRuleStatus.MODERATE_ISSUES_FOUND] ||
          0,
        criticalIssues:
          statistics.byStatus[ProofreadingRuleStatus.CRITICAL_ISSUES_FOUND] ||
          0,
        unableToAssess:
          statistics.byStatus[ProofreadingRuleStatus.UNABLE_TO_ASSESS] || 0,
      }
    : null;

  return (
    <Card
      className={cn(
        'cursor-pointer hover:border-blue-200 transition-all',
        isSelected ? 'border-blue-500 shadow-sm' : '',
      )}
      onClick={onClick}
    >
      <CardContent className="p-3">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xs text-gray-500 flex items-center gap-1">
            <Clock className="h-2.5 w-2.5" />
            <span>{formattedDate}</span>
            {formattedCompletionDate && (
              <span className="ml-1 text-gray-400">
                → {formattedCompletionDate}
              </span>
            )}
          </div>
          <JobStatusBadge status={job.status} />
        </div>

        {job.error_message && (
          <div className="mt-1.5 text-xs text-red-500 flex items-center gap-1">
            <AlertCircle className="h-3 w-3 flex-shrink-0" />
            <span className="line-clamp-1">{job.error_message}</span>
          </div>
        )}

        {isInProgress && completionPercentage !== null && (
          <div className="mt-2">
            <Progress value={completionPercentage} className="h-1.5" />
            <div className="flex justify-end text-xs mt-0.5 text-gray-500">
              <span>{completionPercentage}%</span>
            </div>
          </div>
        )}

        {/* Display statistics for completed jobs */}
        {isCompleted && resultSummary && (
          <div className="mt-2 flex flex-wrap gap-1">
            {resultSummary.noIssues > 0 && (
              <div className="inline-flex items-center gap-1 px-1.5 py-0.5 rounded text-xs bg-green-50 text-green-700 border border-green-200">
                <CheckCircle2 className="h-2.5 w-2.5" />
                <span>{resultSummary.noIssues}</span>
              </div>
            )}
            {resultSummary.minorIssues > 0 && (
              <div className="inline-flex items-center gap-1 px-1.5 py-0.5 rounded text-xs bg-yellow-50 text-yellow-700 border border-yellow-200">
                <AlertTriangle className="h-2.5 w-2.5" />
                <span>{resultSummary.minorIssues}</span>
              </div>
            )}
            {resultSummary.moderateIssues > 0 && (
              <div className="inline-flex items-center gap-1 px-1.5 py-0.5 rounded text-xs bg-orange-50 text-orange-700 border border-orange-200">
                <AlertTriangle className="h-2.5 w-2.5" />
                <span>{resultSummary.moderateIssues}</span>
              </div>
            )}
            {resultSummary.criticalIssues > 0 && (
              <div className="inline-flex items-center gap-1 px-1.5 py-0.5 rounded text-xs bg-red-50 text-red-700 border border-red-200">
                <AlertCircle className="h-2.5 w-2.5" />
                <span>{resultSummary.criticalIssues}</span>
              </div>
            )}
            {resultSummary.unableToAssess > 0 && (
              <div className="inline-flex items-center gap-1 px-1.5 py-0.5 rounded text-xs bg-gray-50 text-gray-700 border border-gray-300">
                <HelpCircle className="h-2.5 w-2.5" />
                <span>{resultSummary.unableToAssess}</span>
              </div>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

import { CanonicalTaskType } from '@/services/types/task-types';
import { sampleTemplateValues } from '../data/SampleTemplateContext';
import { variablesByCategory } from '../data/template-variables';
import { getAvailableCategoriesForTaskType } from '../data/variable-categories';

export const getSampleTemplateValues = (taskType: CanonicalTaskType) => {
  // Get available categories for this task type using our centralized helper
  const allowedCategories = getAvailableCategoriesForTaskType(taskType);

  // Filter the template values to only include allowed categories
  const filteredValues: Partial<typeof sampleTemplateValues> = {};

  allowedCategories.forEach((category) => {
    variablesByCategory[category].forEach((key) => {
      filteredValues[key as keyof typeof sampleTemplateValues] =
        sampleTemplateValues[key as keyof typeof sampleTemplateValues];
    });
  });

  return filteredValues;
};

import { useCallback, useEffect, useState } from 'react';

import {
  useCreateProofreadingJobMutation,
  useGetProofreadingJobResultsQuery,
  useListProofreadingJobsQuery,
} from '../../../services/api/proofreadingService';
import {
  ProofreadingJob,
  ProofreadingJobStartResponse,
  ProofreadingJobStatus,
} from '../../../services/types/proofreading-types';

export function useProofreadingJob(demandId: string, jobId?: string) {
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    undefined,
  );

  // Fetch jobs list for the demand
  const {
    data: jobsList,
    isLoading: isJobsLoading,
    error: jobsError,
    refetch: refetchJobs,
  } = useListProofreadingJobsQuery(
    { demandId },
    { refetchOnMountOrArgChange: true },
  );

  // Create job mutation
  const [createJobMutation, { isLoading: isCreating }] =
    useCreateProofreadingJobMutation();

  // Fetch specific job results if jobId is provided
  const {
    data: jobResult,
    isLoading: isResultLoading,
    error: resultError,
    refetch: refetchResult,
  } = useGetProofreadingJobResultsQuery(
    { demandId, jobId: jobId || '' },
    {
      skip: !jobId,
      pollingInterval,
    },
  );

  // Find the current job from the jobs list
  const job =
    jobId && jobsList ? jobsList.find((j) => j.job_id === jobId) : undefined;

  // Auto-polling for pending or processing jobs
  useEffect(() => {
    if (job) {
      const shouldPoll =
        job.status === ProofreadingJobStatus.PENDING ||
        job.status === ProofreadingJobStatus.PROCESSING;

      setPollingInterval(shouldPoll ? 5000 : undefined);
    } else {
      setPollingInterval(undefined);
    }
  }, [job]);

  // Create a new job
  const createJob =
    useCallback(async (): Promise<ProofreadingJobStartResponse> => {
      const response = await createJobMutation({ demandId }).unwrap();
      refetchJobs();
      return response;
    }, [createJobMutation, demandId, refetchJobs]);

  // Refresh current job data
  const refreshJob = useCallback(() => {
    refetchJobs();
    if (jobId) {
      refetchResult();
    }
  }, [refetchJobs, refetchResult, jobId]);

  return {
    job,
    jobResult,
    isLoading: isJobsLoading || isResultLoading || isCreating,
    error: jobsError || resultError,
    refreshJob,
    createJob,
    jobsList,
  };
}

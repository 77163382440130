import { useEffect, useCallback, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';

import { OperatorTemplate } from '../../../services/types/operator-templates-types';
import {
  templateEditorConfig,
  DEFAULT_CONTENT_JSON,
} from '../config/editor-config';
import { useTemplateVariables } from '../context/TemplateContext';
import { deepClone } from '../../../utils/comparison';

interface OperatorTemplateEditorProps {
  template: OperatorTemplate;
  onContentChange: (content: {
    text: string;
    html: string;
    json: string;
    renderedText: string;
    [key: string]: any;
  }) => void;
}

export const OperatorTemplateEditor = ({
  template,
  onContentChange,
}: OperatorTemplateEditorProps) => {
  const { setUsedVariables, values } = useTemplateVariables();
  const [initialContentSet, setInitialContentSet] = useState(false);

  // Prevent editor re-initialization with initial template content
  // after content has been edited
  const templateIdRef = useCallback((id: string) => {
    return `template-${id}`;
  }, []);

  const currentTemplateId = templateIdRef(template.id);

  const updateContent = useCallback(
    (editorInstance: any) => {
      // Extract template variables from the editor content
      const variables = new Set<string>();
      editorInstance.state.doc.descendants((node: any) => {
        if (node.type.name === 'template' && node.attrs.id) {
          variables.add(node.attrs.id);
        }
      });

      // Update the context with the used variables
      setUsedVariables(variables);

      // Call the original onContentChange with the updated content
      const text = editorInstance.getText();
      const html = editorInstance.getHTML();
      const json = JSON.stringify(editorInstance.getJSON());
      const renderedText = editorInstance.getText({
        textSerializers: {
          template: ({ node }: any) => {
            return values[node.attrs.id];
          },
        },
      });

      onContentChange({
        text,
        html,
        json,
        renderedText,
      });
    },
    [onContentChange, setUsedVariables, values],
  );

  // Parse JSON content safely
  const parseJsonContent = useCallback((jsonContent: any) => {
    if (!jsonContent) {
      return deepClone(DEFAULT_CONTENT_JSON);
    }

    try {
      if (typeof jsonContent === 'string') {
        return JSON.parse(jsonContent);
      }
      return jsonContent;
    } catch (error) {
      console.error('Error parsing JSON content:', error);
      return deepClone(DEFAULT_CONTENT_JSON);
    }
  }, []);

  // Get the initial content for the editor
  const getInitialContent = useCallback(() => {
    return parseJsonContent(template.prompt_json);
  }, [template.prompt_json, parseJsonContent]);

  const editor = useEditor(
    {
      ...templateEditorConfig,
      content: getInitialContent(),
      editable: true,
      onCreate: ({ editor: editorInstance }) => {
        // Only update content on creation
        updateContent(editorInstance);
        setInitialContentSet(true);
      },
      onUpdate: ({ editor: editorInstance }) => {
        // This is called on every keystroke
        updateContent(editorInstance);
      },
    },
    [currentTemplateId], // Only recreate editor when template ID changes
  );

  // Update editor content when template changes and it's a different template
  useEffect(() => {
    if (editor && template && !initialContentSet) {
      const parsedContent = parseJsonContent(template.prompt_json);

      editor.commands.setContent(parsedContent);
      setTimeout(() => updateContent(editor), 0);
      setInitialContentSet(true);
    }
  }, [editor, template, updateContent, initialContentSet, parseJsonContent]);

  // Reset initialContentSet when template ID changes
  useEffect(() => {
    setInitialContentSet(false);
  }, [currentTemplateId]);

  return (
    <div>
      {editor && <EditorContent editor={editor} className="prose max-w-none" />}
    </div>
  );
};

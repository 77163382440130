import React from 'react';
import {
  useGetNotesQuery,
  useGetOneToOneNoteQuery,
} from '@/services/api/matterNotesService';
import { MatterNoteConfig, NoteCardinality } from '../types';
import { isOneToOneNoteType } from '@/services/types/matter-notes-types';
import { SingleNoteSection } from './components/SingleNoteSection';
import { MultipleNotesSection } from './components/MultipleNotesSection';

interface MatterNoteSectionProps {
  config: MatterNoteConfig;
  matterId: string;
}

export const MatterNoteSection: React.FC<MatterNoteSectionProps> = ({
  config,
  matterId,
}) => {
  const isSingleNote = config.cardinality === NoteCardinality.SINGLE;

  // For single note (one-to-one relationship)
  const {
    data: singleNote,
    isLoading: isLoadingSingleNote,
    refetch: refetchSingleNote,
  } = useGetOneToOneNoteQuery(
    { matterId, noteType: config.type },
    {
      skip: !isSingleNote,
      refetchOnMountOrArgChange: true,
    },
  );

  // For multiple notes (one-to-many relationship)
  const {
    data: multipleNotes,
    isLoading: isLoadingMultipleNotes,
    refetch: refetchMultipleNotes,
  } = useGetNotesQuery(
    { matterId, noteType: config.type },
    {
      skip: isSingleNote,
      refetchOnMountOrArgChange: true,
    },
  );

  if (isSingleNote) {
    return (
      <SingleNoteSection
        config={config}
        matterId={matterId}
        note={singleNote || null}
        isLoading={isLoadingSingleNote}
        onSaved={() => refetchSingleNote()}
      />
    );
  }

  return (
    <MultipleNotesSection
      config={config}
      matterId={matterId}
      notes={multipleNotes || []}
      isLoading={isLoadingMultipleNotes}
      onNotesChange={() => refetchMultipleNotes()}
    />
  );
};

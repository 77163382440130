import React from 'react';

import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';

import { CitationItem } from './CitationItem';

interface CitationViewProps {
  matterId: string;
  demandId: string;
  citationIds: string[];
  onClose: () => void;
}

/**
 * Citation view component that displays a list of citations
 */
export const CitationView: React.FC<CitationViewProps> = ({
  matterId,
  demandId,
  citationIds,
  onClose,
}) => {
  return (
    <div
      className="rounded-md border ck-reset_all-excluded min-w-[300px]"
      tabIndex={-1}
    >
      <div className="p-2">
        <ScrollArea className="pr-3">
          {citationIds.length === 0 ? (
            <div className="text-muted-foreground py-2">
              No citation data available
            </div>
          ) : (
            <div className="space-y-1">
              {citationIds.map((citationId, index) => (
                <>
                  <CitationItem
                    key={citationId}
                    matterId={matterId}
                    demandId={demandId}
                    citationId={citationId}
                  />
                  {index !== citationIds.length - 1 && (
                    <Separator
                      key={`${citationId}-separator`}
                      orientation="horizontal"
                      className="w-full"
                    />
                  )}
                </>
              ))}
            </div>
          )}
        </ScrollArea>
      </div>
    </div>
  );
};

import { useState, useCallback, useRef } from 'react';
import { BlandCallTemplate } from '../../../services/types/bland-templates.types';

interface UseTemplateStateProps {
  initialTemplate?: BlandCallTemplate | null;
  onTemplateChange?: (template: BlandCallTemplate | null) => void;
}

export const useTemplateState = ({
  initialTemplate = null,
  onTemplateChange,
}: UseTemplateStateProps = {}) => {
  const [template, setTemplate] = useState<BlandCallTemplate | null>(
    initialTemplate,
  );
  const [renderedTemplate, setRenderedTemplate] = useState<string>('');

  const originalTemplateRef = useRef<BlandCallTemplate | null>(
    initialTemplate ? { ...initialTemplate } : null,
  );

  const updateTemplate = useCallback(
    (updater: (prev: BlandCallTemplate | null) => BlandCallTemplate | null) => {
      setTemplate((prev) => {
        const newTemplate = updater(prev);
        if (onTemplateChange) {
          onTemplateChange(newTemplate);
        }
        return newTemplate;
      });
    },
    [onTemplateChange],
  );

  const handleContentChange = useCallback(
    ({
      text,
      html,
      json,
      renderedText,
    }: {
      text: string;
      html: string;
      json: string;
      renderedText: string;
    }) => {
      updateTemplate((prev) => {
        setRenderedTemplate(renderedText);
        if (!prev) return null;
        return {
          ...prev,
          prompt: text,
          prompt_html: html,
          prompt_json: json,
        };
      });
    },
    [updateTemplate],
  );

  const handleSettingsChange = useCallback(
    (settings: Partial<BlandCallTemplate>) => {
      updateTemplate((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          ...settings,
        };
      });
    },
    [updateTemplate],
  );
  const getRenderedTemplate = useCallback(() => {
    return renderedTemplate;
  }, [renderedTemplate]);

  const getOriginalTemplate = useCallback(() => {
    return originalTemplateRef.current;
  }, []);

  const setTemplateAndOriginal = useCallback(
    (newTemplate: BlandCallTemplate | null) => {
      // Update both the template and original template
      setTemplate(newTemplate);
      originalTemplateRef.current = newTemplate ? { ...newTemplate } : null;
      if (onTemplateChange) {
        onTemplateChange(newTemplate);
      }
    },
    [onTemplateChange],
  );

  return {
    template,
    setTemplate: setTemplateAndOriginal,
    updateTemplate,
    handleContentChange,
    handleSettingsChange,
    getOriginalTemplate,
    getRenderedTemplate,
  };
};

import React from 'react';

import { LoadingStateProps } from '../../types';

export const LoadingState: React.FC<LoadingStateProps> = () => (
  <div className="flex justify-center py-6">
    <div className="animate-pulse text-muted-foreground">
      Loading messages...
    </div>
  </div>
);

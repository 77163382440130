import { PlayIcon } from 'lucide-react';
import React, { useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

import { ProofreadingJobStartResponse } from '../../../services/types/proofreading-types';
import { useProofreadingJob } from '../hooks/useProofreadingJob';

interface CreateJobButtonProps {
  demandId: string;
  onJobCreated?: (response: ProofreadingJobStartResponse) => void;
}

export const CreateJobButton: React.FC<CreateJobButtonProps> = ({
  demandId,
  onJobCreated,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { createJob, isLoading } = useProofreadingJob(demandId);

  const handleCreateJob = async () => {
    try {
      const response = await createJob();
      setIsPopoverOpen(false);
      if (onJobCreated) {
        onJobCreated(response);
      }
    } catch (error) {
      console.error('Failed to create proofreading job:', error);
    }
  };

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="gap-1 h-7"
          aria-label="Start Proofreading"
        >
          <PlayIcon className="h-3 w-3" />
          <span className="text-xs">Start</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-64">
        <div className="space-y-3">
          <h4 className="font-medium">Start Proofreading</h4>
          <p className="text-xs text-muted-foreground">
            This will analyze the current document revision against all
            available rules.
          </p>
          <div className="flex justify-end gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsPopoverOpen(false)}
              disabled={isLoading}
              className="text-xs h-7"
            >
              Cancel
            </Button>
            <Button
              size="sm"
              onClick={handleCreateJob}
              disabled={isLoading}
              className="text-xs h-7"
            >
              {isLoading ? 'Starting...' : 'Start'}
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

/* eslint-disable max-classes-per-file */
/* eslint-disable no-restricted-syntax */
/**
 
Goals for the editor

- Add a chat plugin that allows us to "pull state" from the editor and bring it into the chat.
- Save state of the editor into the database 
- add revision history to the editor
- figure out why pagination is not working in the editor (removing it fixes the weird width issues)

- Citation POC ?? (If Time Permits)

 */

/**
 * Chat Plugin
 *
 * The user can select a section of the document and ask the AI to edit it.
 * This means that that we need to emit an event (/ store state) that captures the current state of the selection
 *
 * When the selection is active and the AI chat has the selection in its context the content that is in the chat context should be highlighted purple
 *
 * When there is not a chat selection active there should be an item in the balloon menu that allows you to start the chat
 * If you press META+K you should start the chat with the current selection (if there is a selection active)
 *
 */

// TODO: There should be better support for the UI / UX of the plugin
//  - there should be a shortcut to update the selection
//  - there should be an option to update or clear the selection
//  - scroll to the selection via a command
//  - You should be able to recapture the selection via a command

import { loadCKEditorCloud } from '@ckeditor/ckeditor5-react';

const {
  CKEditor: { ButtonView, Command, Plugin, Editor: _Editor, Range: _Range },
} = await loadCKEditorCloud({
  version: '44.3.0',
  premium: true,
  translations: ['en'],
});

type Editor = InstanceType<typeof _Editor>;
type Range = InstanceType<typeof _Range>;

// Define our custom events using CKEditor's event system pattern - more explicit naming
export const CHAT_EVENTS = {
  // Chat selection events
  SELECTION_CAPTURED: 'chat:selectionCaptured', // Selection was created
  SELECTION_CLEARED: 'chat:selectionCleared', // Selection was removed
  CONTENT_CHANGED: 'chat:contentChanged', // Selection content changed

  // Plugin state events - explicit "chat" in the name
  ENABLED: 'chat:enabled', // Chat was enabled
  DISABLED: 'chat:disabled', // Chat was disabled
};

// Define commands (to be used with editor.execute())
export const CHAT_COMMANDS = {
  CAPTURE_SELECTION: 'captureChatSelection', // Capture selection
  CLEAR_SELECTION: 'clearChatSelection', // Clear selection
  INSERT_HTML: 'insertHtmlInChat', // Insert HTML at current selection
  ENABLE_CHAT: 'enableChat', // Enable chat functionality
  DISABLE_CHAT: 'disableChat', // Disable chat functionality
};

// Define event data interfaces
export interface ChatSelectionEventData {
  html: string;
  isActive: boolean;
  range?: Range;
}

export interface ChatStateEventData {
  isEnabled: boolean;
}

// Command to insert HTML at the captured range
export class InsertHtmlInChatCommand extends Command {
  private chatPlugin: ChatPlugin;

  constructor(editor: Editor, chatPlugin: ChatPlugin) {
    super(editor);
    this.chatPlugin = chatPlugin;

    // Update isEnabled state based on chat state
    this.listenTo(chatPlugin, CHAT_EVENTS.SELECTION_CAPTURED, () => {
      this.isEnabled = chatPlugin.isSelectionActive && chatPlugin.isEnabled;
    });

    this.listenTo(chatPlugin, CHAT_EVENTS.SELECTION_CLEARED, () => {
      this.isEnabled = false;
    });

    this.listenTo(chatPlugin, CHAT_EVENTS.ENABLED, () => {
      this.isEnabled = chatPlugin.isSelectionActive;
    });

    this.listenTo(chatPlugin, CHAT_EVENTS.DISABLED, () => {
      this.isEnabled = false;
    });

    // Set initial state
    this.isEnabled = chatPlugin.isSelectionActive && chatPlugin.isEnabled;
  }

  execute(html: string): boolean {
    return this.chatPlugin.insertHtml(html);
  }
}

// Command to capture selection
export class CaptureChatSelectionCommand extends Command {
  private chatPlugin: ChatPlugin;

  constructor(editor: Editor, chatPlugin: ChatPlugin) {
    super(editor);
    this.chatPlugin = chatPlugin;

    // Update isEnabled state based on chat state
    this.listenTo(chatPlugin, CHAT_EVENTS.ENABLED, () => {
      this.refresh();
    });

    this.listenTo(chatPlugin, CHAT_EVENTS.DISABLED, () => {
      this.isEnabled = false;
    });

    // Set initial state
    this.refresh();
  }

  refresh(): void {
    this.isEnabled =
      this.chatPlugin.isEnabled && !this.chatPlugin.isSelectionActive;
  }

  execute(): boolean {
    return this.chatPlugin.captureSelection();
  }
}

// Command to clear selection
export class ClearChatSelectionCommand extends Command {
  private chatPlugin: ChatPlugin;

  constructor(editor: Editor, chatPlugin: ChatPlugin) {
    super(editor);
    this.chatPlugin = chatPlugin;

    // Update isEnabled state based on chat state
    this.listenTo(chatPlugin, CHAT_EVENTS.SELECTION_CAPTURED, () => {
      this.isEnabled = this.chatPlugin.isEnabled;
    });

    this.listenTo(chatPlugin, CHAT_EVENTS.SELECTION_CLEARED, () => {
      this.isEnabled = false;
    });

    this.listenTo(chatPlugin, CHAT_EVENTS.ENABLED, () => {
      this.isEnabled = this.chatPlugin.isSelectionActive;
    });

    this.listenTo(chatPlugin, CHAT_EVENTS.DISABLED, () => {
      this.isEnabled = false;
    });

    // Set initial state
    this.isEnabled = chatPlugin.isSelectionActive && chatPlugin.isEnabled;
  }

  execute(): void {
    this.chatPlugin.clearSelection();
  }
}

// Command to enable chat
export class EnableChatCommand extends Command {
  private chatPlugin: ChatPlugin;

  constructor(editor: Editor, chatPlugin: ChatPlugin) {
    super(editor);
    this.chatPlugin = chatPlugin;

    // This command is always enabled
    this.isEnabled = true;
  }

  execute(): void {
    this.chatPlugin.enableChat();
  }
}

// Command to disable chat
export class DisableChatCommand extends Command {
  private chatPlugin: ChatPlugin;

  constructor(editor: Editor, chatPlugin: ChatPlugin) {
    super(editor);
    this.chatPlugin = chatPlugin;

    // This command is always enabled
    this.isEnabled = true;
  }

  execute(): void {
    this.chatPlugin.disableChat();
  }
}

export class ChatPlugin extends Plugin {
  private capturedRange: Range | null = null;

  private capturedHtml = '';

  private isHighlighted = false;

  private highlightMarker: string | null = null;

  private _isDisabled = false;

  static get pluginName() {
    return 'ChatPlugin';
  }

  /**
   * Get whether the chat plugin is currently enabled
   */
  public get isDisabled(): boolean {
    return this._isDisabled;
  }

  /**
   * Get whether a selection is currently active
   */
  public get isSelectionActive(): boolean {
    return this.isHighlighted && !!this.capturedRange;
  }

  /**
   * Get the HTML content of the current selection
   */
  public get selectedHtml(): string {
    return this.capturedHtml;
  }

  init() {
    this.registerCommands();
    this.registerChatButton();
    this.setupDocumentChangeListener();
    this.setupHighlightConversion();
  }

  /**
   * Register all commands provided by this plugin
   */
  private registerCommands() {
    this.editor.commands.add(
      CHAT_COMMANDS.INSERT_HTML,
      new InsertHtmlInChatCommand(this.editor, this),
    );

    this.editor.commands.add(
      CHAT_COMMANDS.CAPTURE_SELECTION,
      new CaptureChatSelectionCommand(this.editor, this),
    );

    this.editor.commands.add(
      CHAT_COMMANDS.CLEAR_SELECTION,
      new ClearChatSelectionCommand(this.editor, this),
    );

    this.editor.commands.add(
      CHAT_COMMANDS.ENABLE_CHAT,
      new EnableChatCommand(this.editor, this),
    );

    this.editor.commands.add(
      CHAT_COMMANDS.DISABLE_CHAT,
      new DisableChatCommand(this.editor, this),
    );
  }

  /**
   * Registers the chat button in the editor toolbar
   */
  private registerChatButton() {
    this.editor.ui.componentFactory.add('chat', () => {
      const button = new ButtonView();

      button.set({
        label: 'Chat',
        withText: true,
        tooltip: 'Capture text for chat',
        isOn: this.isHighlighted,
        isEnabled: !this._isDisabled,
      });

      button.on('execute', () => {
        if (this.isSelectionActive) {
          this.clearSelection();
        } else {
          this.captureSelection();
        }
      });

      // Update button state based on plugin state
      this.on(CHAT_EVENTS.SELECTION_CAPTURED, () => {
        button.set({ isOn: true });
      });

      this.on(CHAT_EVENTS.SELECTION_CLEARED, () => {
        button.set({ isOn: false });
      });

      this.on(CHAT_EVENTS.ENABLED, () => {
        button.set({ isEnabled: true });
      });

      this.on(CHAT_EVENTS.DISABLED, () => {
        button.set({ isEnabled: false });
      });

      return button;
    });
  }

  /**
   * Enable chat functionality (idempotent)
   */
  public enableChat(): void {
    if (!this._isDisabled) return; // No-op if already enabled

    this._isDisabled = false;

    // Fire event that chat was enabled
    this.fire(CHAT_EVENTS.ENABLED, {
      isEnabled: true,
    });
  }

  /**
   * Disable chat functionality (idempotent)
   */
  public disableChat(): void {
    if (this._isDisabled) return; // No-op if already disabled

    this._isDisabled = true;

    // Fire event that chat was disabled
    this.fire(CHAT_EVENTS.DISABLED, {
      isEnabled: false,
    });
  }

  /**
   * Captures the current editor selection (idempotent)
   * @returns True if selection was captured successfully
   */
  public captureSelection(): boolean {
    // No-op if disabled or already has selection
    if (!!this._isDisabled || this.isSelectionActive) {
      return this.isSelectionActive;
    }

    const { selection } = this.editor.model.document;

    if (selection.isCollapsed) {
      return false; // No selection to capture
    }

    this.capturedRange = selection.getFirstRange();
    if (!this.capturedRange) {
      return false;
    }

    this.capturedHtml = this.getSelectionHtml(selection);
    this.addHighlight(this.capturedRange);
    this.isHighlighted = true;

    // Fire event for new selection
    this.fire(CHAT_EVENTS.SELECTION_CAPTURED, {
      html: this.capturedHtml,
      isActive: true,
      range: this.capturedRange,
    });

    return true;
  }

  /**
   * Clears the currently captured selection (idempotent)
   */
  public clearSelection(): void {
    // No-op if no selection is active
    if (!this.isSelectionActive) {
      return;
    }

    this.removeHighlight();
    this.isHighlighted = false;
    this.capturedRange = null;
    this.capturedHtml = '';

    // Fire event for clearing the selection
    this.fire(CHAT_EVENTS.SELECTION_CLEARED, {
      html: '',
      isActive: false,
    });
  }

  /**
   * Inserts HTML at the captured selection
   * @param html HTML to insert
   * @returns True if insertion was successful
   */
  public insertHtml(html: string): boolean {
    if (!!this._isDisabled || !this.isSelectionActive) {
      return false;
    }

    const range = this.capturedRange;
    if (!range) {
      return false;
    }

    // First remove the highlight to avoid marker errors
    this.removeHighlight();

    // Replace the content in the highlighted range
    const newRange = this.replaceRangeWithHtml(range, html);

    console.log('newRange', newRange);

    // // Only continue if we got a valid range back
    // if (newRange) {
    //   // Update the captured range to the new range
    //   this.capturedRange = newRange;

    //   // Update the captured HTML
    //   this.capturedHtml = html;

    //   // Add highlight to the new range
    //   this.addHighlight(this.capturedRange);

    //   // Fire HTML change event
    //   this.fire(CHAT_EVENTS.CONTENT_CHANGED, {
    //     html: this.capturedHtml,
    //     isActive: true,
    //     range: this.capturedRange,
    //   });

    //   return true;
    // }

    // If something went wrong, clear the selection
    this.clearSelection();
    return false;
  }

  /**
   * Replaces content in the range with HTML content
   * @param range The range to replace
   * @param html The HTML string to insert
   * @returns The new range covering the inserted content, or null if insertion failed
   */
  private replaceRangeWithHtml(range: Range, html: string): Range | null {
    let newRange: Range | null = null;

    try {
      this.editor.model.change((writer) => {
        // Store the position where we'll insert new content
        const position = range.start.clone();

        // Remove the content in the range
        writer.remove(range);

        // Create a new selection at the insertion point
        const newSelection = writer.createSelection(position);
        writer.setSelection(newSelection);

        // Convert HTML to view fragment
        const viewFragment = this.editor.data.processor.toView(html);

        // Convert view fragment to model fragment
        const modelFragment = this.editor.data.toModel(viewFragment);

        // Insert content at the selection
        this.editor.model.insertContent(modelFragment);

        // Get the resulting selection after insertion
        newRange =
          this.editor.model.document.selection.getFirstRange()?.clone() || null;
      });
    } catch (e) {
      console.error('Error replacing content with HTML:', e);
    }

    return newRange;
  }

  /**
   * Sets up a listener for document changes to update captured HTML
   */
  private setupDocumentChangeListener() {
    this.editor.model.document.on('change:data', () => {
      if (!!this._isDisabled || !this.isSelectionActive) {
        return;
      }

      try {
        // Check if the range is still valid before trying to use it
        if (ChatPlugin.isRangeValid(this.capturedRange!)) {
          const previousHtml = this.capturedHtml;
          this.capturedHtml = this.getHtmlFromRange(this.capturedRange!);

          // Only fire event if HTML actually changed
          if (previousHtml !== this.capturedHtml) {
            this.fire(CHAT_EVENTS.CONTENT_CHANGED, {
              html: this.capturedHtml,
              isActive: true,
              range: this.capturedRange,
            });
          }
        } else {
          console.log('Captured range is no longer valid');
          this.clearSelection();
        }
      } catch (e) {
        console.error('Error updating HTML from range:', e);
        this.clearSelection();
      }
    });
  }

  /**
   * Sets up the highlight conversion for the editor
   */
  private setupHighlightConversion() {
    const { editor } = this;

    // Define how markers should be rendered in the view
    editor.conversion.for('editingDowncast').markerToHighlight({
      model: 'chat-highlight',
      view: {
        classes: 'chat-highlight',
      },
    });

    // Define also the representation of the marker in data
    editor.conversion.for('dataDowncast').markerToData({
      model: 'chat-highlight',
      view: (markerName) => ({
        group: 'chat-highlight',
        name: markerName.slice(8),
      }),
    });
  }

  /**
   * Checks if a range is still valid in the current document state
   */
  private static isRangeValid(range: Range): boolean {
    try {
      // Check if the range's root is still in the document
      const isRootInDocument = !!range.root.document;
      return isRootInDocument;
    } catch (e) {
      return false;
    }
  }

  /**
   * Converts a range to HTML
   */
  private getHtmlFromRange(range: Range): string {
    try {
      // Create a fresh selection from the range to avoid stale references
      const rangeSelection = this.editor.model.createSelection(range);
      const selectedContent =
        this.editor.model.getSelectedContent(rangeSelection);
      const viewFragment = this.editor.data.toView(selectedContent);
      return this.editor.data.processor.toData(viewFragment);
    } catch (e) {
      console.error('Error converting range to HTML:', e);
      throw e; // Re-throw to handle the error in the caller
    }
  }

  /**
   * Converts a selection to HTML string
   */
  private getSelectionHtml(selection: any): string {
    try {
      const selectedContent = this.editor.model.getSelectedContent(selection);
      const viewFragment = this.editor.data.toView(selectedContent);
      return this.editor.data.processor.toData(viewFragment);
    } catch (e) {
      console.error('Error converting selection to HTML:', e);
      return '';
    }
  }

  /**
   * Adds highlight to the specified range
   */
  private addHighlight(range: Range): void {
    this.editor.model.change((writer) => {
      // Remove any existing highlight
      if (this.highlightMarker) {
        writer.removeMarker(this.highlightMarker);
      }

      // Create a new marker
      this.highlightMarker = `chat-highlight:${Date.now()}`;
      writer.addMarker(this.highlightMarker, {
        range,
        usingOperation: false,
        affectsData: false,
      });
    });
  }

  /**
   * Removes highlight from the document
   */
  private removeHighlight(): void {
    if (this.highlightMarker) {
      this.editor.model.change((writer) => {
        writer.removeMarker(this.highlightMarker!);
        this.highlightMarker = null;
      });
    }
  }
}

import { useEditor } from '../../editor/editor-provider';
import { EditorContextType } from '../types';

// Safe hook to use editor context without throwing errors if not available
export const useSafeEditor = (): EditorContextType => {
  try {
    // This will throw if no provider is available
    return { ...useEditor(), available: true };
  } catch (error) {
    // Return default values when no provider is available
    return {
      editor: null,
      setEditor: () => {},
      isEnabled: false,
      isSelectionActive: false,
      selectedHtml: '',
      isReady: false,
      enableChat: () => false,
      disableChat: () => false,
      captureSelection: () => false,
      clearSelection: () => false,
      insertHtml: () => false,
      getChatPlugin: () => null,
      available: false,
    };
  }
};

import {
  Box,
  CircularProgress,
  Container,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useLoginUserMutation } from '../../services/api/userService';
import { userLoggedIn } from '../../state/reducers/root';
import theme from '../../theme/theme';
import { isEmailValid } from '../../utils/validation';
import { PrimaryButton } from '../base/Buttons';
import FinchLogo from '../base/FinchLogo';

interface LoginFormState {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const [formState, setFormState] = useState<LoginFormState>({
    email: '',
    password: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [loginUser] = useLoginUserMutation();
  const [searchParams] = useSearchParams();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isEmailValid(formState.email)) {
      setError('Please enter a valid email address');
      return;
    }

    setLoading(true);
    try {
      const response = await loginUser({
        username: formState.email,
        password: formState.password,
        auth_secret: searchParams.get('auth_secret') || undefined,
      }).unwrap();

      dispatch(userLoggedIn(response.user));
    } catch (e) {
      Sentry.captureException(e);
      // TODO refine this error and pass it to the user if it is specfic from backend
      setError('Invalid email or password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange =
    (prop: keyof LoginFormState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({ ...formState, [prop]: event.target.value });
    };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <FinchLogo width="160px" />
        {error && (
          <Typography
            variant="body1"
            sx={{ mt: '36px', color: 'red', width: '100%' }}
          >
            {error}
          </Typography>
        )}
        <Stack
          component="form"
          onSubmit={handleSubmit}
          spacing={4}
          sx={{ mt: error ? '48px' : '0px', width: '100%' }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            placeholder="Email Address"
            name="email"
            autoComplete="email"
            variant="outlined"
            autoFocus
            value={formState.email}
            onChange={handleChange('email')}
            error={!!error && error.includes('email')}
            inputProps={{
              pattern: '[^\\s@]+@[^\\s@]+\\.[^\\s@]+',
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                border: '1.5px solid theme.palette.border.main',
                paddingX: '8px',
                fontWeight: '600',
                height: '54px',
                marginY: '0px',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.border.dark,
                  borderWidth: '1.5px',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.main,
                  borderWidth: '2px',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.border.main,
                borderWidth: '1.5px',
              },
              '& .MuiOutlinedInput-root .MuiInputBase-input::placeholder': {
                color: theme.palette.info.light, // Replace with your desired color
                opacity: 1, // Override default opacity if needed
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            placeholder="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            variant="outlined"
            value={formState.password}
            onChange={handleChange('password')}
            inputProps={{
              minLength: 8,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                border: '1.5px solid theme.palette.border.main',
                paddingX: '8px',
                fontWeight: '600',
                height: '54px',
                marginY: '0px',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.border.dark,
                  borderWidth: '1.5px',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.main,
                  borderWidth: '2px',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.border.main,
                borderWidth: '1.5px',
              },
              '& .MuiOutlinedInput-root .MuiInputBase-input::placeholder': {
                color: theme.palette.info.light, // Replace with your desired color
                opacity: 1, // Override default opacity if needed
              },
            }}
          />
          <Link color="primary" href="/forgot-password">
            Forgot password?
          </Link>
          <PrimaryButton
            type="submit"
            fullWidth
            disableRipple
            variant="contained"
            sx={{ height: '54px !important' }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Log In'}
          </PrimaryButton>
        </Stack>
      </Box>
    </Container>
  );
};

export default LoginForm;

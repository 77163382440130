export enum CanonicalTaskGroup {
  INTAKE = 'Intake',
  POLICE_REPORT_INVESTIGATION = 'Police Report & Investigation',
  FIRST_PARTY_INSURANCE = '1P Insurance',
  THIRD_PARTY_INSURANCE = '3P Insurance',
  INITIAL_MEDICAL_RECORDS = 'Initial Medical Records',
  MEDICAL_RECORDS = 'Medical Records',
  TREATMENT = 'Treatment',
  DEMAND_LETTER = 'Demand Letter',
  LIENS = 'Liens',
  MISCELLANEOUS = 'Miscellaneous',
  ADDITIONAL_INSURANCE = 'Additional Insurance',
}

export enum CanonicalTaskType {
  MEDICAL_RECORDS = 'Medical Records',
  LOR_ACKNOWLEDGEMENT = 'LOR Acknowledgement',
  SEND_LOR = 'Send LOR',
  OPEN_CLAIM = 'Open Claim',
  FINAL_LIEN = 'Final Lien',
  POLICE_REPORT = 'Police Report',
  OPEN_FILE = 'Open File',
  LOCATE_REPORT_SOURCE = 'Locate Report Source',
  ASCERTAIN_SUBROGATOR = 'Ascertain Subrogator',
  FINALIZE_DEMAND = 'Finalize Demand',
  DRAFT_DEMAND = 'Draft Demand',
  DECLARATION_PAGE = 'Declaration Page',
  HEALTH_INSURANCE_CARD = 'Health Insurance Card',
  TREATMENT_CHECK_IN = 'Treatment Check-In',
  HIPAA_DIRECTIVE = 'HIPAA Directive',
  RETAINER_AGREEMENT = 'Retainer Agreement',
  CONDUCT_INTAKE = 'Conduct Intake',
  OTHER = 'Other',
  ALL = 'All',
}

export enum CanonicalTaskStatus {
  PENDING = 'Pending',
  BLOCKED = 'Blocked',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  NOT_APPLICABLE = 'Not Applicable',
}

export const TaskStatusColors = {
  [CanonicalTaskStatus.PENDING]: 'bg-gray-100 text-gray-700',
  [CanonicalTaskStatus.BLOCKED]: 'bg-red-100 text-red-700',
  [CanonicalTaskStatus.IN_PROGRESS]: 'bg-blue-100 text-blue-700',
  [CanonicalTaskStatus.COMPLETED]: 'bg-green-100 text-green-700',
  [CanonicalTaskStatus.NOT_APPLICABLE]: 'bg-gray-100 text-gray-700',
};

export const TASKS_WITH_ATTACHED_MEDICAL_PROVIDER = [
  CanonicalTaskType.MEDICAL_RECORDS,
  CanonicalTaskType.POLICE_REPORT,
];

export const TASKS_WITH_ATTACHED_INSURANCE_POLICY = [
  CanonicalTaskType.LOR_ACKNOWLEDGEMENT,
  CanonicalTaskType.SEND_LOR,
  CanonicalTaskType.OPEN_CLAIM,
  CanonicalTaskType.DECLARATION_PAGE,
];

export const TASKS_WITH_VOICE_AI_ACTION = [
  ...TASKS_WITH_ATTACHED_INSURANCE_POLICY,
  ...TASKS_WITH_ATTACHED_MEDICAL_PROVIDER,
];

export const TASKS_WITH_CLIENT_CALL_ACTION = [
  CanonicalTaskType.TREATMENT_CHECK_IN,
  CanonicalTaskType.HIPAA_DIRECTIVE,
  CanonicalTaskType.RETAINER_AGREEMENT,
  CanonicalTaskType.CONDUCT_INTAKE,
  CanonicalTaskType.DRAFT_DEMAND,
  CanonicalTaskType.FINALIZE_DEMAND,
];

export interface Contact {
  id: string;
  name: string;
  phone: string;
  type: ContactType;
}
export enum ContactType {
  MEDICAL_PROVIDER = 'Medical Provider',
  INSURANCE_CARRIER = 'Insurance Carrier',
  LAW_ENFORCEMENT_AGENCY = 'Law Enforcement Agency',
  INSURANCE_ADJUSTER = 'Insurance Adjuster',
  OTHER = 'Other',
}

export const INSURANCE_CARRIER_CONTACTS: Contact[] = [
  {
    id: 'state-farm',
    name: 'State Farm',
    phone: '(800) 732-5246',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'progressive',
    name: 'Progressive',
    phone: '(800) 776-4737',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'cure',
    name: 'Cure',
    phone: '(800) 535-2873',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'usa-underwriters',
    name: 'USA Underwriters',
    phone: '(855) 230-1656',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'allstate',
    name: 'Allstate',
    phone: '(800) 255-7828',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'geico',
    name: 'Geico',
    phone: '(800) 841-3000',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'plymouth-rock',
    name: 'Plymouth Rock',
    phone: '(844) 346-1225',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'bristol-west',
    name: 'Bristol West',
    phone: '(800) 274-7865',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'farmers',
    name: 'Farmers Insurance',
    phone: '(800) 435-7764',
    type: ContactType.INSURANCE_CARRIER,
  },
];

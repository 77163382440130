import React from 'react';
import { CalendarDays } from 'lucide-react';
import { cn } from '@/lib/utils';
import { ScheduleDetailsProps } from './types';
import { formatDays } from './utils';

interface CalendarDaysComponentProps extends ScheduleDetailsProps {
  daysOfMonth: number[];
}

export const CalendarDaysComponent: React.FC<CalendarDaysComponentProps> = ({
  schedule,
  daysOfMonth,
}) => {
  return (
    <div className="bg-white rounded-lg p-4 shadow-sm">
      <div className="flex items-center mb-3">
        <CalendarDays className="h-4 w-4 mr-2 text-primary" />
        <h4 className="font-medium">Calendar Days</h4>
        <span className="ml-2 text-xs text-muted-foreground">
          ({formatDays(daysOfMonth)})
        </span>
      </div>
      <div className="grid grid-cols-7 gap-2">
        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => {
          const isSelected = daysOfMonth.includes(day);
          return (
            <div
              key={day}
              className={cn(
                'h-8 w-8 flex items-center justify-center rounded-full text-sm',
                isSelected
                  ? 'bg-primary text-primary-foreground font-medium'
                  : 'text-muted-foreground bg-slate-100/50 opacity-40',
              )}
            >
              {day}
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import { JSONTree } from 'react-json-tree';
import * as frontService from '@/services/frontService';
import { useFrontContext } from '@/providers/frontContext';
import { MessageAttachment } from '@/services/frontService';

// Chrome DevTools-like theme
const jsonTheme = {
  scheme: 'chrome',
  base00: '#ffffff',
  base01: '#f5f5f5',
  base02: '#e8e8e8',
  base03: '#b8b8b8',
  base04: '#585858',
  base05: '#383838',
  base06: '#282828',
  base07: '#181818',
  base08: '#9a3332', // string
  base09: '#e96800', // number
  base0A: '#c4a100', // label
  base0B: '#008001', // { }
  base0C: '#008080', // regex
  base0D: '#0073e6', // function
  base0E: '#881280', // arrow
};

interface DebugData {
  recipients: any[];
  attachments: MessageAttachment[];
  messages: any[];
  urls: string[];
  isLoading: boolean;
}

interface FrontDebugPanelProps {
  frontContext: ReturnType<typeof useFrontContext>;
}

export const FrontDebugPanel = ({ frontContext }: FrontDebugPanelProps) => {
  const [showDebugPanel, setShowDebugPanel] = useState(false);
  const [debugData, setDebugData] = useState<DebugData>({
    recipients: [],
    attachments: [],
    messages: [],
    urls: [],
    isLoading: false,
  });

  const loadDebugData = async () => {
    if (!frontContext) return;
    setDebugData((prev) => ({ ...prev, isLoading: true }));
    try {
      const [recipients, attachments, messages, urls] = await Promise.all([
        frontService.listConversationRecipients(frontContext),
        frontService.listConversationAttachments(frontContext),
        frontService.getConversationMessages(frontContext),
        frontService.extractUrlsFromConversation(frontContext),
      ]);
      setDebugData({
        recipients,
        attachments,
        messages,
        urls,
        isLoading: false,
      });
    } catch (error) {
      console.error('Error loading debug data:', error);
      setDebugData((prev) => ({ ...prev, isLoading: false }));
    }
  };

  useEffect(() => {
    if (frontContext && showDebugPanel) {
      loadDebugData();
    }
  }, [frontContext, showDebugPanel]);

  const debugObject = frontContext
    ? {
        type: frontContext.type,
        data: {
          recipients: debugData.recipients,
          attachments: debugData.attachments,
          messages: debugData.messages,
          urls: debugData.urls,
        },
      }
    : { type: 'no context' };

  return (
    <div className="font-mono h-full flex flex-col p-2 w-full">
      {/* Debug Panel Toggle */}
      <div className="flex items-center gap-1">
        <button
          type="button"
          onClick={() => setShowDebugPanel(!showDebugPanel)}
          className={`text-[10px] font-medium ${
            showDebugPanel ? 'text-gray-900' : 'text-gray-400'
          } hover:text-gray-900 transition-colors select-none`}
        >
          inspect
        </button>
        {showDebugPanel && (
          <button
            type="button"
            onClick={loadDebugData}
            disabled={debugData.isLoading}
            className="text-[10px] font-medium text-gray-400 hover:text-gray-900 transition-colors select-none disabled:opacity-50"
          >
            {debugData.isLoading ? (
              <span className="inline-flex items-center">
                <Loader2 className="h-2.5 w-2.5 animate-spin mr-0.5" />
                loading
              </span>
            ) : (
              'refresh'
            )}
          </button>
        )}
      </div>

      {/* Debug Panel */}
      {showDebugPanel && (
        <div className="flex-1 mt-1  h-screen [&_*]:!text-[10px] [&_*]:!leading-3 [&_ul]:!my-0">
          <JSONTree
            data={debugObject}
            theme={jsonTheme}
            invertTheme={false}
            shouldExpandNodeInitially={() => true}
          />
        </div>
      )}
    </div>
  );
};

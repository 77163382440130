import React, { useState } from 'react';
import { BaseMatterNote } from '@/services/types/matter-notes-types';
import { MatterNoteConfig } from '../../types';
import { MatterNoteForm } from '../../form/MatterNoteForm';
import { SectionHeader } from './SectionHeader';
import { Skeleton } from '@/components/ui/skeleton';

interface SingleNoteSectionProps {
  config: MatterNoteConfig;
  matterId: string;
  note: BaseMatterNote | null;
  isLoading: boolean;
  onSaved?: (note: BaseMatterNote) => void;
}

export const SingleNoteSection: React.FC<SingleNoteSectionProps> = ({
  config,
  matterId,
  note,
  isLoading,
  onSaved,
}) => {
  const [showForm, setShowForm] = useState(!!note);
  const isEmpty = !note && !showForm;

  const handleAddNew = () => {
    setShowForm(true);
  };

  const handleSaved = (savedNote: BaseMatterNote) => {
    onSaved?.(savedNote);
  };

  const handleCancelled = () => {
    if (!note) {
      setShowForm(false);
    }
  };

  const handleCleared = () => {
    setShowForm(false);
  };

  return (
    <div className="space-y-4">
      <SectionHeader
        title={config.title}
        matterId={matterId}
        config={config}
        onAddNew={!isLoading ? handleAddNew : undefined}
        showAddButton={!isLoading && isEmpty}
        isEmptyState={isEmpty}
        onCleared={handleCleared}
        isLoading={isLoading}
      />

      {isLoading ? (
        <div className="space-y-3 p-2">
          <Skeleton className="h-5 w-3/4" />
          <Skeleton className="h-4 w-1/2" />
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-4 w-2/3" />
        </div>
      ) : (
        !isEmpty && (
          <div className={!note ? 'border-l-4 border-primary pl-4' : ''}>
            <MatterNoteForm
              config={{
                ...config,
                title: note ? '' : 'New Entry',
              }}
              matterId={matterId}
              note={note}
              onSaved={handleSaved}
              onCancelled={handleCancelled}
            />
          </div>
        )
      )}
    </div>
  );
};

import { BlandSchedule } from '@/services/types/bland-schedules-types';

/**
 * Gets a time string (HH:MM) from a schedule's hour and minute
 */
export function getTimeStringFromSchedule(schedule: BlandSchedule): string {
  if (schedule.calendar_hour.length === 0) {
    return '00:00';
  }

  const hour = schedule.calendar_hour[0];
  const minute = schedule.calendar_minute[0] || 0;

  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
}

import { Eye } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetDemandConfigurationQuery } from '@/services/api/demandService';
import {
  DemandConfiguration,
  DemandDocumentSectionType,
} from '@/services/types/demand-types';

// Define section order - must match what's in demand-configuration-editor.tsx
const sectionOrder = [
  // Introduction
  DemandDocumentSectionType.INTRODUCTION,

  // Facts & Liability
  DemandDocumentSectionType.FACTS_LIABILITY,
  DemandDocumentSectionType.DEFENDANT_NEGLIGENCE_AND_BREACH_OF_DUTY,
  DemandDocumentSectionType.PROXIMATE_CAUSE_OF_HARM,

  // Injuries & Treatment
  DemandDocumentSectionType.INJURIES_AND_TREATMENT,
  DemandDocumentSectionType.SUMMARY_INJURIES,
  DemandDocumentSectionType.TREATMENT,

  // Damages
  DemandDocumentSectionType.DAMAGES,
  DemandDocumentSectionType.TOTAL_PROJECTED_CLAIM_VALUE,
  DemandDocumentSectionType.PAST_MEDICAL_EXPENSES,
  DemandDocumentSectionType.FUTURE_MEDICAL_EXPENSES,
  DemandDocumentSectionType.LOST_WAGES,
  DemandDocumentSectionType.PROPERTY_DAMAGE,
  DemandDocumentSectionType.LOSS_HOUSEHOLD_SERVICES,
  DemandDocumentSectionType.PAIN_SUFFERING,

  DemandDocumentSectionType.VERDICT_ANALYSIS,
  DemandDocumentSectionType.PER_DIEM_ANALYSIS,

  // Demand to Settle
  DemandDocumentSectionType.DEMAND_TO_SETTLE,
];

// Define section categories for better visual grouping in the preview
const sectionCategories = {
  Introduction: [DemandDocumentSectionType.INTRODUCTION],
  'Facts & Liability': [
    DemandDocumentSectionType.FACTS_LIABILITY,
    DemandDocumentSectionType.DEFENDANT_NEGLIGENCE_AND_BREACH_OF_DUTY,
    DemandDocumentSectionType.PROXIMATE_CAUSE_OF_HARM,
  ],
  'Injuries & Treatment': [
    DemandDocumentSectionType.INJURIES_AND_TREATMENT,
    DemandDocumentSectionType.SUMMARY_INJURIES,
    DemandDocumentSectionType.TREATMENT,
  ],
  Damages: [
    DemandDocumentSectionType.DAMAGES,
    DemandDocumentSectionType.TOTAL_PROJECTED_CLAIM_VALUE,
    DemandDocumentSectionType.PAST_MEDICAL_EXPENSES,
    DemandDocumentSectionType.FUTURE_MEDICAL_EXPENSES,
    DemandDocumentSectionType.LOST_WAGES,
    DemandDocumentSectionType.PROPERTY_DAMAGE,
    DemandDocumentSectionType.LOSS_HOUSEHOLD_SERVICES,
    DemandDocumentSectionType.PAIN_SUFFERING,
  ],
  Analysis: [
    DemandDocumentSectionType.VERDICT_ANALYSIS,
    DemandDocumentSectionType.PER_DIEM_ANALYSIS,
  ],
  Conclusion: [DemandDocumentSectionType.DEMAND_TO_SETTLE],
};

interface PreviewProps {
  configuration: DemandConfiguration;
  isLoading?: boolean;
}
export const DemandConfigurationPreview = ({
  configuration,
  isLoading = false,
}: PreviewProps) => {
  const [previewContent, setPreviewContent] = useState<string>('');

  // This would ideally call an API to generate a preview
  // For now, we'll just create a simple representation
  useEffect(() => {
    if (isLoading || !configuration) return;

    // Generate preview content following the predefined section order
    const contentSections = sectionOrder
      .filter(
        (sectionKey) =>
          configuration.configuration[sectionKey] &&
          configuration.configuration[sectionKey].is_enabled,
      )
      .map((sectionKey) => {
        const section = configuration.configuration[sectionKey];
        if (!section) return null;

        // Find which category this section belongs to for styling
        const categoryName = Object.entries(sectionCategories).find(
          ([_, sectionKeys]) => sectionKeys.includes(sectionKey),
        )?.[0];

        const categoryClass = categoryName
          ? `category-${categoryName.toLowerCase().replace(/[^a-z0-9]/g, '-')}`
          : '';

        const sectionContent = section.title_only
          ? ''
          : section.content_example ||
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';

        return `
            <div class="mb-6 ${categoryClass}">
              <h2 class="text-lg font-bold mb-2">${section.title}</h2>
              ${sectionContent ? `<p class="text-sm">${sectionContent}</p>` : ''}
            </div>
          `;
      })
      .filter(Boolean)
      .join('');

    // Add category styling
    const styles = `
      <style>
        .category-introduction { border-left: 4px solid #e5e7eb; padding-left: 12px; }
        .category-facts-liability { border-left: 4px solid #bfdbfe; padding-left: 12px; }
        .category-injuries-treatment { border-left: 4px solid #bbf7d0; padding-left: 12px; }
        .category-damages { border-left: 4px solid #fecaca; padding-left: 12px; }
        .category-analysis { border-left: 4px solid #ddd6fe; padding-left: 12px; }
        .category-conclusion { border-left: 4px solid #fed7aa; padding-left: 12px; }
      </style>
    `;

    // Check if there are any sections enabled
    const hasSections = contentSections.trim() !== '';

    const emptyMessage = !hasSections
      ? `
      <div class="p-6 border-2 border-dashed border-gray-300 rounded-md text-center text-gray-500">
        <p>No sections are currently enabled.</p>
        <p class="text-sm mt-2">Enable sections to see them in the preview.</p>
      </div>
    `
      : '';

    setPreviewContent(`
        <div class="font-serif">
          ${styles}
          <div class="text-center mb-8">
            <h1 class="text-2xl font-bold mb-4">DEMAND LETTER</h1>
            <p class="text-sm">[Law Firm Name]</p>
            <p class="text-sm">[Date]</p>
          </div>
          
          <div class="mb-4">
            <p>[Recipient Name]</p>
            <p>[Insurance Company]</p>
            <p>[Address]</p>
            <p>[City, State ZIP]</p>
          </div>
          
          <div class="mb-4">
            <p>Re: [Client Name] v. [Defendant Name]</p>
            <p>Claim No.: [Claim Number]</p>
            <p>Date of Loss: [Date of Incident]</p>
          </div>
          
          ${emptyMessage || contentSections}
          
          ${
            hasSections
              ? `
          <div class="mt-8">
            <p>Sincerely,</p>
            <p class="mt-4">[Attorney Name]</p>
          </div>
          `
              : ''
          }
        </div>
      `);
  }, [configuration, isLoading]);

  if (isLoading) {
    return (
      <div className="border rounded-md p-6 h-full w-full flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <Skeleton className="h-8 w-40" />
          <div className="flex gap-2">
            <Skeleton className="h-9 w-9" />
            <Skeleton className="h-9 w-9" />
          </div>
        </div>
        <div className="flex-1 space-y-4">
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-24 w-full" />
          <Skeleton className="h-24 w-full" />
          <Skeleton className="h-24 w-full" />
          <Skeleton className="h-24 w-full" />
        </div>
      </div>
    );
  }

  return (
    <div className="border rounded-md h-full w-full flex flex-col">
      <ScrollArea className="flex-1 p-6 bg-white">
        <div
          className="max-w-[700px] mx-auto p-8 bg-white shadow-sm border"
          dangerouslySetInnerHTML={{ __html: previewContent }}
        />
      </ScrollArea>

      <div className="p-3 border-t bg-muted/30 text-center text-sm text-muted-foreground">
        <Eye className="h-4 w-4 inline mr-1" />
        This is a simplified preview. The actual document will be professionally
        formatted.
      </div>
    </div>
  );
};

// Standalone wrapper for use with the router
export const DemandConfigurationPreviewPage = ({
  configId,
}: {
  configId: string;
}) => {
  const navigate = useNavigate();
  const {
    data: configuration,
    isLoading,
    error,
  } = useGetDemandConfigurationQuery({ configurationId: configId });

  if (error) {
    return (
      <div className="p-6">
        <div className="p-4 border border-red-300 bg-red-50 rounded-md text-red-700">
          <h2 className="font-bold">Error loading configuration</h2>
          <p>Please try again later or contact support.</p>
          <Button
            className="mt-4"
            onClick={() => navigate('/firms/demand-configurations')}
          >
            Go Back
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Configuration Preview</h1>
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={() => navigate('/firms/demand-configurations')}
          >
            Back to List
          </Button>
          <Button
            onClick={() =>
              navigate(`/firms/demand-configurations/${configId}/edit`)
            }
          >
            Edit Configuration
          </Button>
        </div>
      </div>

      {isLoading && (
        <DemandConfigurationPreview
          configuration={{} as DemandConfiguration}
          isLoading
        />
      )}
      {!isLoading && configuration && (
        <DemandConfigurationPreview
          configuration={configuration}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

import React from 'react';
import { format } from 'date-fns';
import { MatterNoteFieldConfig, MatterNoteFieldType } from '../../types';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface FormFieldProps {
  note_id: string;
  field: MatterNoteFieldConfig;
  value: any;
  onChange: (value: any) => void;
  disabled?: boolean;
}

export const FormField: React.FC<FormFieldProps> = ({
  note_id,
  field,
  value,
  onChange,
  disabled = false,
}) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange(e.target.value);
  };

  const handleCheckboxChange = (checked: boolean) => {
    onChange(checked);
  };

  const handleSelectChange = (selectedValue: string) => {
    onChange(selectedValue);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const fieldType = field.type || MatterNoteFieldType.TEXT;

  const renderField = () => {
    switch (fieldType) {
      case MatterNoteFieldType.TEXT:
        if (field.multiline) {
          return (
            <Textarea
              id={`${note_id}-${field.name}`}
              value={value || ''}
              onChange={handleChange}
              placeholder={field.label}
              disabled={disabled}
              className="min-h-[80px]"
            />
          );
        }
        return (
          <Input
            id={`${note_id}-${field.name}`}
            type="text"
            value={value || ''}
            onChange={handleChange}
            placeholder={field.label}
            disabled={disabled}
          />
        );
      case MatterNoteFieldType.DATE:
        return (
          <Input
            id={`${note_id}-${field.name}`}
            type="date"
            value={value || ''}
            onChange={handleDateChange}
            disabled={disabled}
          />
        );
      case MatterNoteFieldType.CHECKBOX:
        return (
          <Checkbox
            id={`${note_id}-${field.name}`}
            checked={!!value}
            onCheckedChange={handleCheckboxChange}
            disabled={disabled}
          />
        );
      case MatterNoteFieldType.SELECT:
        return (
          <Select
            value={value || ''}
            onValueChange={handleSelectChange}
            disabled={disabled}
          >
            <SelectTrigger id={`${note_id}-${field.name}`}>
              <SelectValue placeholder={field.label} />
            </SelectTrigger>
            <SelectContent>
              {field.options?.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );
      default:
        return (
          <Input
            id={`${note_id}-${field.name}`}
            type="text"
            value={value || ''}
            onChange={handleChange}
            placeholder={field.label}
            disabled={disabled}
          />
        );
    }
  };

  return renderField();
};

import { z } from 'zod';
import {
  BlandSchedule,
  CreateBlandScheduleRequest,
  SendCallRequest,
} from '@/services/types/bland-schedules-types';

// Define common timezone options
export const TIMEZONE_OPTIONS = [
  { value: 'America/New_York', label: 'Eastern Time (ET)' },
  { value: 'America/Chicago', label: 'Central Time (CT)' },
  { value: 'America/Denver', label: 'Mountain Time (MT)' },
  { value: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
  { value: 'America/Anchorage', label: 'Alaska Time (AKT)' },
  { value: 'America/Honolulu', label: 'Hawaii Time (HT)' },
];

// Define days of week options
export const DAYS_OF_WEEK = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];

// Schema for simplified form validation
export const scheduleFormSchema = z
  .object({
    time: z.string().min(1, 'Time is required'),
    selectedDates: z
      .array(z.date())
      .min(1, 'Select at least one date')
      .optional(),
    selectedDaysOfWeek: z
      .array(z.number())
      .min(1, 'Select at least one day of the week')
      .optional(),
    selectionMode: z.enum(['dates', 'daysOfWeek']).default('daysOfWeek'),
    timezone: z.string().default('America/New_York'),
    retryEnabled: z.boolean().default(false),
    maxRetries: z.number().min(1).default(3),
    retryDelay: z.number().min(60).default(3600),
    endDate: z.date().optional(),
  })
  .refine(
    (data) => {
      if (data.selectionMode === 'dates') {
        return data.selectedDates && data.selectedDates.length > 0;
      }
      return data.selectedDaysOfWeek && data.selectedDaysOfWeek.length > 0;
    },
    {
      message: 'You must select either specific dates or days of the week',
      path: ['selectionMode'],
    },
  )
  .refine(
    (data) => {
      if (data.selectionMode === 'daysOfWeek') {
        return !!data.endDate;
      }
      return true;
    },
    {
      message: 'End date is required when selecting days of the week',
      path: ['endDate'],
    },
  );

export type ScheduleFormValues = z.infer<typeof scheduleFormSchema>;

export interface ScheduleFormProps {
  taskId: string;
  template: SendCallRequest;
  initialSchedule?: BlandSchedule;
  onSubmit: (schedule: CreateBlandScheduleRequest & { id?: string }) => void;
  onCancel: () => void;
}

import { ApplicationMessage } from '@frontapp/plugin-sdk';
import getUrls from 'get-urls';

/**
 * Validates if a URL is a Google Drive URL
 */
export function isGoogleDriveUrl(url: string): boolean {
  try {
    const { hostname } = new URL(url);
    return hostname === 'drive.google.com';
  } catch {
    return false;
  }
}

/**
 * Cleans and normalizes a URL string
 */
export function normalizeUrl(rawUrl: string): string | null {
  try {
    // Remove HTML tags and decode entities
    const decoded = rawUrl
      .replace(/<[^>]*>/g, '')
      .replace(/&amp;/g, '&')
      .trim();

    // Validate and normalize URL
    const url = new URL(
      decoded.startsWith('http') ? decoded : `https://${decoded}`,
    );

    // Remove unnecessary query parameters that don't affect the resource identity
    url.searchParams.delete('usp');
    url.searchParams.delete('usp_drive_link');
    url.searchParams.delete('usp_drive_copy');

    return url.toString();
  } catch {
    return null;
  }
}

/**
 * Normalizes a Google Drive URL to a canonical form
 * This handles various Google Drive URL formats and returns a consistent format
 */
function normalizeGoogleDriveUrl(url: string): string | null {
  try {
    const urlObj = new URL(url);

    // Validate it's a Google Drive domain
    if (!isGoogleDriveUrl(url)) {
      return null;
    }

    // Extract the file/folder ID which is the most stable identifier
    const fileId: string | null = null;

    // Handle /file/d/ format
    const [, extractedFileId] =
      urlObj.pathname.match(/\/file\/d\/([a-zA-Z0-9_-]+)/) || [];
    if (extractedFileId) {
      return `https://drive.google.com/file/d/${extractedFileId}`;
    }

    // Handle /open?id= format
    const idParam = urlObj.searchParams.get('id');
    if (idParam) {
      return `https://drive.google.com/file/d/${idParam}`;
    }

    // Handle docs/sheets/slides formats
    const [, type, docId] =
      urlObj.pathname.match(
        /\/(document|spreadsheets|presentation)\/d\/([a-zA-Z0-9_-]+)/,
      ) || [];
    if (type && docId) {
      const serviceMap: Record<
        'document' | 'spreadsheets' | 'presentation',
        string
      > = {
        document: 'docs',
        spreadsheets: 'sheets',
        presentation: 'slides',
      };
      return `https://${serviceMap[type as keyof typeof serviceMap]}.google.com/${type}/d/${docId}`;
    }

    return null;
  } catch {
    return null;
  }
}

/**
 * Extracts URLs from a string of text
 */
export function extractUrlsFromText(text: string): string[] {
  const urls = Array.from(getUrls(text));
  const normalizedUrls = new Set<string>();

  urls.forEach((url) => {
    // First try to normalize as a Google Drive URL
    const normalizedGDriveUrl = normalizeGoogleDriveUrl(url);
    if (normalizedGDriveUrl) {
      normalizedUrls.add(normalizedGDriveUrl);
      return;
    }

    // If not a Google Drive URL, normalize as regular URL
    const normalizedUrl = normalizeUrl(url);
    if (normalizedUrl) {
      normalizedUrls.add(normalizedUrl);
    }
  });

  return Array.from(normalizedUrls);
}

/**
 * Extracts URLs from Front message content
 */
export function extractUrlsFromMessage(message: ApplicationMessage): string[] {
  if (!message.content?.body) return [];
  return extractUrlsFromText(message.content.body);
}

/**
 * Extracts unique URLs from an array of Front messages
 */
export function extractUrlsFromMessages(
  messages: ApplicationMessage[],
): string[] {
  const urlSet = new Set<string>();

  messages.forEach((message) => {
    const urls = extractUrlsFromMessage(message);
    urls.forEach((url) => urlSet.add(url));
  });

  return Array.from(urlSet);
}

import {
  AlertCircle,
  AlertTriangle,
  CheckCircle2,
  Clock,
  HelpCircle,
  List,
  Loader2,
  ScrollText,
} from 'lucide-react';
import React from 'react';

import { Card, CardContent } from '@/components/ui/card';
import { cn } from '@/lib/utils';

import {
  ProofreadingJob,
  ProofreadingRuleStatus,
} from '../../../services/types/proofreading-types';
import { useRuleResults } from '../hooks/useRuleResults';

interface ResultsOverviewProps {
  jobResult: ProofreadingJob;
  activeFilter: string;
  onFilterChange: (filter: string) => void;
}

type FilterOption = {
  key: string;
  icon: React.ReactNode;
  label: string;
  count: number;
  color: string;
};

export const ResultsOverview: React.FC<ResultsOverviewProps> = ({
  jobResult,
  activeFilter,
  onFilterChange,
}) => {
  const { statistics } = useRuleResults(jobResult);
  const isJobInProgress = jobResult.completion_percentage < 100;

  // Prepare data for filters
  const resultSummary = {
    noIssues: statistics.byStatus[ProofreadingRuleStatus.NO_ISSUES] || 0,
    minorIssues:
      statistics.byStatus[ProofreadingRuleStatus.MINOR_ISSUES_FOUND] || 0,
    moderateIssues:
      statistics.byStatus[ProofreadingRuleStatus.MODERATE_ISSUES_FOUND] || 0,
    criticalIssues:
      statistics.byStatus[ProofreadingRuleStatus.CRITICAL_ISSUES_FOUND] || 0,
    unableToAssess:
      statistics.byStatus[ProofreadingRuleStatus.UNABLE_TO_ASSESS] || 0,
  };

  const filterOptions: FilterOption[] = [
    {
      key: 'all',
      icon: <List className="h-3 w-3" />,
      label: 'All',
      count: jobResult.rule_results.length,
      color: 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200',
    },
    {
      key: 'passed',
      icon: <CheckCircle2 className="h-3 w-3" />,
      label: 'Passed',
      count: resultSummary.noIssues,
      color: 'bg-green-50 text-green-700 border-green-200 hover:bg-green-100',
    },
    {
      key: 'minor',
      icon: <AlertTriangle className="h-3 w-3" />,
      label: 'Minor',
      count: resultSummary.minorIssues,
      color:
        'bg-yellow-50 text-yellow-700 border-yellow-200 hover:bg-yellow-100',
    },
    {
      key: 'moderate',
      icon: <AlertTriangle className="h-3 w-3" />,
      label: 'Moderate',
      count: resultSummary.moderateIssues,
      color:
        'bg-orange-50 text-orange-700 border-orange-200 hover:bg-orange-100',
    },
    {
      key: 'critical',
      icon: <AlertCircle className="h-3 w-3" />,
      label: 'Critical',
      count: resultSummary.criticalIssues,
      color: 'bg-red-50 text-red-700 border-red-200 hover:bg-red-100',
    },
    {
      key: 'unableToAssess',
      icon: <HelpCircle className="h-3 w-3" />,
      label: 'N/A',
      count: resultSummary.unableToAssess,
      color: 'bg-gray-50 text-gray-700 border-gray-300 hover:bg-gray-100',
    },
  ].filter((option) => option.count > 0);

  // Add a visual indicator of overall completion
  const completionBar = (
    <div className="h-1 bg-gray-100 rounded-full overflow-hidden flex mb-2">
      <div
        className="bg-blue-500 h-full transition-all duration-500 ease-out"
        style={{ width: `${jobResult.completion_percentage}%` }}
      />
    </div>
  );

  // Calculate issue stats
  const totalIssues =
    resultSummary.minorIssues +
    resultSummary.moderateIssues +
    resultSummary.criticalIssues;
  const issuesText = totalIssues === 1 ? '1 issue' : `${totalIssues} issues`;

  return (
    <Card className="relative mb-3">
      <CardContent className="p-3">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-1.5">
            <ScrollText className="h-3.5 w-3.5 text-gray-500" />
            <h3 className="text-xs font-medium text-gray-700">
              Proofreading Analysis
            </h3>
          </div>
        </div>
        {completionBar}
        <div className="flex flex-wrap gap-1.5">
          {filterOptions.map((option) => (
            <button
              type="button"
              key={option.key}
              onClick={() => onFilterChange(option.key)}
              className={cn(
                'inline-flex items-center gap-1.5 px-2 py-1 rounded-md text-xs border transition-colors',
                option.color,
                activeFilter === option.key &&
                  'ring-1 ring-offset-1 ring-gray-400',
              )}
            >
              {option.icon}
              <span>
                {option.label}{' '}
                <span className="font-medium">{option.count}</span>
              </span>
            </button>
          ))}

          {isJobInProgress && (
            <span className="text-xs text-blue-600 ml-1 flex items-center gap-1">
              <Loader2 className="h-3 w-3 animate-spin" />
              {Math.round(jobResult.completion_percentage)}% complete
            </span>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

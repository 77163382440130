import { ArrowDownToLine, Check } from 'lucide-react';
import React, { useState } from 'react';

import { Button } from '../../../ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../ui/tooltip';
import { useSafeEditor } from '../hooks/useSafeEditor';
import { MessageProps } from '../types';
import { MessageContent } from './message/MessageContent';

// Function to filter out patterns like 【4:0†source】 from text
const filterTextContent = (text: string): string => {
  return text.replace(/【\d+:\d+†[^】]+】/g, '');
};

export const Message: React.FC<MessageProps> = ({
  message,
  matterId,
  demandId,
  threadId,
}) => {
  const isAssistant = message.role === 'assistant';
  const { isSelectionActive, insertHtml } = useSafeEditor();
  const [insertSuccess, setInsertSuccess] = useState(false);

  // Filter message content
  const filteredContent = message.content.map((content) => {
    if (content.type === 'text' && content.text) {
      return { ...content, text: filterTextContent(content.text) };
    }
    if (content.type === 'html' && content.html) {
      return { ...content, html: filterTextContent(content.html) };
    }
    return content;
  });

  // Check if the message has HTML content that can be inserted
  const hasHtmlContent = filteredContent.some(
    (content) => content.type === 'html' && content.html,
  );

  // Get the HTML content to insert (if present)
  const getHtmlToInsert = () => {
    const htmlContent = filteredContent.find(
      (content) => content.type === 'html',
    );
    return htmlContent ? htmlContent.html : '';
  };

  // Function to replace the selected text with HTML content
  const handleReplaceSelection = () => {
    const html = getHtmlToInsert();
    if (html) {
      const success = insertHtml(html);
      if (success) {
        // Show success indicator briefly
        setInsertSuccess(true);
        setTimeout(() => setInsertSuccess(false), 2000);
      }
    }
  };

  // Determine if this message should have the HTML glow effect
  const shouldHaveHtmlGlow =
    isAssistant && hasHtmlContent && !isSelectionActive;

  return (
    <div
      className={`p-4 rounded-lg transition-colors ${
        isAssistant
          ? 'bg-primary/5 ml-4 border border-primary/10'
          : 'bg-accent/50 mr-4 border border-accent/20'
      } ${shouldHaveHtmlGlow ? 'html-glow' : ''}`}
    >
      <div className="font-semibold mb-1 text-sm flex items-center justify-between">
        <span className={isAssistant ? 'text-primary' : ''}>
          {isAssistant ? 'Finch' : 'You'}
        </span>

        {/* Show replace button only for assistant messages with HTML when selection is active */}
        {isAssistant && hasHtmlContent && isSelectionActive && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleReplaceSelection}
                  className={`h-6 py-0 px-2 text-xs ${insertSuccess ? 'text-green-600 bg-green-50' : 'text-primary hover:bg-primary/10'} ml-auto transition-colors`}
                >
                  {insertSuccess ? (
                    <>
                      <Check className="h-3 w-3 mr-1" />
                      Inserted
                    </>
                  ) : (
                    <>
                      <ArrowDownToLine className="h-3 w-3 mr-1" />
                      Replace Selection
                    </>
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Replace your selected text with this content</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <div className="text-sm">
        {filteredContent.map((contentItem, idx) => (
          <MessageContent
            key={idx}
            content={contentItem}
            matterId={matterId}
            demandId={demandId}
            threadId={threadId}
          />
        ))}
      </div>
    </div>
  );
};

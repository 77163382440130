import React from 'react';
import { MatterNoteFieldConfig, MatterNoteFieldType } from '../../types';
import { formatDate } from '@/lib/utils';

interface DisplayFieldProps {
  field: MatterNoteFieldConfig;
  value: any;
}

export const DisplayField: React.FC<DisplayFieldProps> = ({ field, value }) => {
  const fieldType = field.type || MatterNoteFieldType.TEXT;

  if (value === null || value === undefined || value === '') {
    return <span className="text-muted-foreground italic">Not provided</span>;
  }

  switch (fieldType) {
    case MatterNoteFieldType.CHECKBOX:
      return value ? 'Yes' : 'No';
    case MatterNoteFieldType.DATE: {
      return value ? formatDate(value) : '';
    }
    case MatterNoteFieldType.SELECT: {
      const option = field.options?.find((opt) => opt.value === value);
      return option ? option.label : value;
    }
    default:
      return value;
  }
};

/* eslint-disable react/no-danger */
import { ArrowDownToLine, Code } from 'lucide-react';
import React from 'react';

import { useSafeEditor } from '../../hooks/useSafeEditor';
import { HtmlContentProps } from '../../types';

export const HtmlContent: React.FC<HtmlContentProps> = ({ html }) => {
  const { isSelectionActive } = useSafeEditor();

  return (
    <div
      className={`relative prose prose-sm max-w-none rounded-md ${
        isSelectionActive
          ? 'border-l-2 border-primary pl-3 py-2 -ml-2 bg-primary/5 rounded-r-sm'
          : 'p-3'
      }`}
    >
      {isSelectionActive ? (
        <div className="text-xs text-primary mb-1 font-medium flex items-center">
          <ArrowDownToLine className="h-3 w-3 mr-1 inline-block" />
          Insertable content
        </div>
      ) : (
        <div className="html-badge text-xs text-primary/70 mb-2">
          Suggestion
        </div>
      )}
      <div
        className={`${isSelectionActive ? 'pb-1' : 'html-content'}`}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </div>
  );
};

import { AlertCircle, ClipboardList, FilePlus2, FileText } from 'lucide-react';
import React from 'react';

import { Button } from '@/components/ui/button';

interface ProofreadingEmptyStateProps {
  type: 'jobs' | 'results' | 'rules';
  onAction?: () => void;
}

export const ProofreadingEmptyState: React.FC<ProofreadingEmptyStateProps> = ({
  type,
  onAction,
}) => {
  const config = {
    jobs: {
      icon: <ClipboardList className="h-12 w-12 text-gray-400" />,
      title: 'No proofreading jobs',
      description:
        'Start a new proofreading job to check this document for issues.',
      buttonText: 'Create Job',
    },
    results: {
      icon: <FileText className="h-12 w-12 text-gray-400" />,
      title: 'No results available',
      description: 'Select a job from the list to view its results.',
      buttonText: 'Back to Jobs',
    },
    rules: {
      icon: <FilePlus2 className="h-12 w-12 text-gray-400" />,
      title: 'No custom rules',
      description: 'Create firm-specific rules to enhance your proofreading.',
      buttonText: 'Create Rule',
    },
  };

  const { icon, title, description, buttonText } = config[type];

  return (
    <div className="flex flex-col items-center justify-center p-8 text-center bg-gray-50 rounded-lg border border-dashed border-gray-300">
      {icon}
      <h3 className="mt-4 text-lg font-semibold text-gray-900">{title}</h3>
      <p className="mt-2 text-sm text-gray-500 max-w-md">{description}</p>
      {onAction && (
        <Button onClick={onAction} className="mt-4" size="sm">
          {buttonText}
        </Button>
      )}
    </div>
  );
};

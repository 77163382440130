import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Label } from '@/components/ui/label';

export type ComboboxOption = {
  id: string;
  [key: string]: any;
};

export type ComboboxOptionGroup<T extends ComboboxOption> = {
  label: string;
  options: T[];
};

export type GroupedComboboxProps<T extends ComboboxOption> = {
  label: string | React.ReactNode;
  groups: ComboboxOptionGroup<T>[];
  value: T | null;
  onChange: (value: T | null) => void;
  placeholder: string;
  emptyText?: string;
  className?: string;
  displayValue?: (item: T) => string;
  searchKeys?: string[];
  renderItem?: (props: {
    item: T;
    isSelected: boolean;
    displayValue: string;
  }) => React.ReactNode;
  renderSelection?: (props: {
    item: T;
    displayValue: string;
  }) => React.ReactNode;
};

export const GroupedCombobox = <T extends ComboboxOption>({
  label,
  groups,
  value,
  onChange,
  placeholder,
  emptyText = 'No results found.',
  className,
  displayValue = (item: T) => item.name || item.title || item.id,
  searchKeys = ['name'],
  renderItem,
  renderSelection,
}: GroupedComboboxProps<T>) => {
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');

  // Store the initial width of the trigger
  const [triggerWidth, setTriggerWidth] = React.useState<number>(0);
  const triggerRef = React.useRef<HTMLButtonElement>(null);

  // Update width when popover opens
  React.useEffect(() => {
    if (open && triggerRef.current) {
      setTriggerWidth(triggerRef.current.offsetWidth);
    }
  }, [open]);

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {label && <Label className="font-bold">{label}</Label>}
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          <Button
            ref={triggerRef}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between h-auto min-h-10 py-2 px-3"
          >
            <div className="flex-1 flex items-start">
              {(() => {
                if (!value) return placeholder;
                if (renderSelection) {
                  return renderSelection({
                    item: value,
                    displayValue: displayValue(value),
                  });
                }
                return displayValue(value);
              })()}
            </div>
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="p-0 bg-white"
          align="start"
          style={{
            width: triggerWidth
              ? `${triggerWidth}px`
              : 'var(--radix-popover-trigger-width)',
          }}
        >
          <Command>
            <CommandInput
              placeholder="Search..."
              value={searchQuery}
              onValueChange={setSearchQuery}
              className="border-0"
            />
            <CommandList>
              <CommandEmpty className="py-2 px-4 text-sm text-gray-500">
                {emptyText}
              </CommandEmpty>
              {groups.map((group, groupIndex) => (
                <React.Fragment key={group.label}>
                  {groupIndex > 0 && <CommandSeparator />}
                  <CommandGroup
                    heading={group.label}
                    className="px-2 py-1.5 text-xs font-medium text-gray-500"
                  >
                    {group.options.map((option) => (
                      <CommandItem
                        key={option.id}
                        value={displayValue(option)}
                        onSelect={() => {
                          onChange(option);
                          setOpen(false);
                        }}
                        className="flex items-center px-2 py-1 text-sm rounded-sm cursor-pointer hover:bg-gray-100 aria-selected:bg-gray-100 data-[selected=true]:bg-gray-100"
                      >
                        <Check
                          className={cn(
                            'mr-2 h-4 w-4 shrink-0',
                            value?.id === option.id
                              ? 'opacity-100'
                              : 'opacity-0',
                          )}
                        />
                        {renderItem
                          ? renderItem({
                              item: option,
                              isSelected: value?.id === option.id,
                              displayValue: displayValue(option),
                            })
                          : displayValue(option)}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </React.Fragment>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

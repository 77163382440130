import { loadCKEditorCloud } from '@ckeditor/ckeditor5-react';

import {
  DemandRevision,
  DemandRevisionFull,
  PutRevision,
} from '@/services/types/demand-types';
import { store } from '@/state/state';

import { demandsApiClient } from '../../../services/api/demandService';

const {
  CKEditor: { Plugin, Editor: _Editor },
} = await loadCKEditorCloud({
  version: '44.3.0',
  premium: true,
  translations: ['en'],
});

interface RevisionHistoryConfig {
  matterId: string;
  demandId: string;
}

type Editor = InstanceType<typeof _Editor>;

type RevisionData = any;

const convertToRevision = (revision: DemandRevisionFull): RevisionData => {
  return {
    id: revision.revision_id,
    name: revision.name,
    creatorId: revision.creator_id,
    authorsIds: revision.author_ids || [],
    diffData: JSON.parse(revision.diff_data || '{}'),
    data: JSON.parse(revision.data || '{}'),
    attributes: JSON.parse(revision.attributes || '{}'),
    createdAt: new Date(revision.created_at),
    fromVersion: revision.from_version || undefined,
    toVersion: revision.to_version || undefined,
    isEmptyCurrent: revision.is_empty_current || undefined,
  };
};

const convertToRevisionJSON = (revision: DemandRevision): RevisionData => {
  return {
    id: revision.revision_id,
    name: revision.name,
    creatorId: revision.creator_id,
    authorsIds: revision.author_ids || [],
    attributes: JSON.parse(revision.attributes || '{}'),
    createdAt: new Date(revision.created_at),
    fromVersion: revision.from_version || undefined,
    toVersion: revision.to_version || undefined,
    isEmptyCurrent: revision.is_empty_current || undefined,
  };
};

const exportToDemandRevision = (revision: RevisionData): PutRevision => {
  if (!revision.id) {
    throw new Error('Revision ID is required');
  }

  return {
    revision: {
      revision_id: revision.id,
      name: revision.name,
      creator_id: revision.creatorId,
      authors_ids: revision.authorsIds,
      from_version: revision.fromVersion,
      to_version: revision.toVersion,
      diff_data: revision.diffData
        ? JSON.stringify(revision.diffData)
        : undefined,
      attributes: revision.attributes
        ? JSON.stringify(revision.attributes)
        : undefined,
      data: revision.data ? JSON.stringify(revision.data) : undefined,
    },
  };
};

class RevisionHistoryIntegration extends Plugin {
  private matterId: string;

  private demandId: string;

  private api: typeof demandsApiClient;

  static get pluginName() {
    return 'RevisionHistoryIntegration';
  }

  static get requires() {
    return ['RevisionHistory'];
  }

  constructor(editor: Editor) {
    super(editor);
    const config = editor.config.get(
      'revisionHistoryItem',
    ) as RevisionHistoryConfig;
    this.matterId = config.matterId;
    this.demandId = config.demandId;
    this.api = demandsApiClient;
  }

  async init() {
    const revisionHistory = this.editor.plugins.get('RevisionHistory');

    revisionHistory.adapter = {
      getRevision: async ({ revisionId }) => {
        const result = await store.dispatch(
          this.api.endpoints.getRevision.initiate(
            {
              matterId: this.matterId,
              demandId: this.demandId,
              revisionId,
            },
            { forceRefetch: true },
          ),
        );

        if ('error' in result || !result.data) {
          throw new Error('Failed to fetch revision');
        }

        return convertToRevision(result.data);
      },

      updateRevisions: async (revisionsData) => {
        // Get existing revisions to compare
        const documentData = this.editor.getData();
        const revisionPromises = revisionsData.map((revision) => {
          return this.saveRevision({
            ...revision,
            data: documentData,
          });
        });

        return Promise.all(revisionPromises);
      },
    };

    // Add the revisions data for existing revisions.
    const revisionsData = await this._fetchRevisionsData();

    revisionsData.forEach((revisionData) => {
      revisionHistory.addRevisionData(revisionData as any);
    });
  }

  async saveRevision(revision: RevisionData): Promise<RevisionData> {
    if (!revision.id) {
      throw new Error('Revision ID is required');
    }

    const putRevision = exportToDemandRevision(revision);
    const result = await store.dispatch(
      this.api.endpoints.updateRevision.initiate({
        matterId: this.matterId,
        demandId: this.demandId,
        revisionId: revision.id,
        data: putRevision,
      }),
    );

    if ('error' in result || !result.data) {
      throw new Error('Failed to save revision');
    }

    return convertToRevision(result.data);
  }

  private async _fetchRevisionsData(): Promise<RevisionData[]> {
    const result = await store.dispatch(
      this.api.endpoints.listRevisions.initiate(
        {
          matterId: this.matterId,
          demandId: this.demandId,
        },
        { forceRefetch: true },
      ),
    );

    if ('error' in result || !result.data) {
      throw new Error('Failed to fetch revisions');
    }

    return result.data.map(convertToRevisionJSON);
  }
}

export { RevisionHistoryIntegration };

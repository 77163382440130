// Enums matching backend definitions
export enum ProofreadingRuleType {
  GRAMMAR = 'grammar',
  LEGAL = 'legal',
  STYLE = 'style',
  CONTENT = 'content',
  FORMATTING = 'formatting',
}

export enum ProofreadingJobStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export enum ProofreadingRuleStatus {
  NO_ISSUES = 'no_issues_found',
  MINOR_ISSUES_FOUND = 'minor_issues_found',
  MODERATE_ISSUES_FOUND = 'moderate_issues_found',
  CRITICAL_ISSUES_FOUND = 'critical_issues_found',
  UNABLE_TO_ASSESS = 'unable_to_assess',
}

// Interface definitions matching backend models
export interface ProofreadingRule {
  id: string;
  title: string;
  description: string;
  prompt: string;
  rule_type: ProofreadingRuleType;
  enabled: boolean;
  is_global: boolean;
  firm_id: string | null;
  created_at: string;
  modified_at: string;
}

export interface FindingEntry {
  excerpt: string;
  rationale: string;
  suggested_fix: string;
}

export interface RuleResult {
  status: ProofreadingRuleStatus;
  reason: string;
  specific_issues?: FindingEntry[] | null;
  recommendations?: string[] | null;
}

export interface ProofreadingRuleResult {
  rule: ProofreadingRule;
  result: RuleResult;
}

export interface ProofreadingJob {
  job_id: string;
  demand_revision_id: string;
  status: ProofreadingJobStatus;
  created_at: string;
  completed_at?: string;
  error_message?: string | null;
  rule_results: ProofreadingRuleResult[];
  completion_percentage: number;
  total_rules_count: number;
  processed_rules_count: number;
}

// DTO for creating a proofreading job
export interface CreateProofreadingJob {
  demand_revision_id: string;
}

// DTO for creating a proofreading rule
export interface CreateProofreadingRule {
  title: string;
  description: string;
  prompt: string;
  rule_type: ProofreadingRuleType;
  enabled: boolean;
  firm: string;
}

// Response from starting a new proofreading job
export interface ProofreadingJobStartResponse {
  workflow_id: string;
  demand_id: string;
  revision_id: string;
  status: string;
  message: string;
}

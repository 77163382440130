import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';

import { FirmDemandTemplate } from './demands/export/firm-demand-template';
import {
  DemandConfigurationCreate,
  DemandConfigurationEditor,
  DemandConfigurationListView,
  DemandConfigurationPreviewPage,
} from './index';

export const Firms = () => {
  const [activeTab, setActiveTab] = useState('demandLetter');
  const { pathname } = useLocation();
  const { configId, firm_id } = useParams();
  const navigate = useNavigate();

  // Determine which component to render based on the current route
  const renderDemandConfigComponent = () => {
    if (pathname.includes('demand-template') && firm_id) {
      return <FirmDemandTemplate firmId={firm_id} />;
    }
    if (pathname.includes('/create')) {
      return <DemandConfigurationCreate />;
    }
    if (pathname.includes('/edit') && configId) {
      return <DemandConfigurationEditor configId={configId} />;
    }
    if (configId && !pathname.includes('/edit')) {
      return <DemandConfigurationPreviewPage configId={configId} />;
    }
    return <DemandConfigurationListView />;
  };

  return (
    <div className="p-6 w-full mx-auto">
      <div className="flex gap-6">
        {/* Sidebar */}
        <div className="w-1/8">
          <div className="space-y-1">
            <Button
              variant={activeTab === 'demandLetter' ? 'default' : 'ghost'}
              className="w-full justify-start"
              onClick={() => {
                setActiveTab('demandLetter');
                navigate('/firms');
              }}
            >
              {/* The overall goal for what we would want to change here is:
                - Moving the demand letter configuration into a more hidden place as the firms section
                seems like it should be more user friendly and more focused on what ops will consume on a day-to-day basis.
               */}
              Demand Letters (TODO: Move)
            </Button>
            {firm_id && (
              <Button
                variant={activeTab === 'demandConfig' ? 'default' : 'ghost'}
                className="w-full justify-start"
                onClick={() => {
                  setActiveTab('demandConfig');
                  navigate(`/firms/${firm_id}/demand-configuration`);
                }}
              >
                Demand Configuration
              </Button>
            )}
          </div>
        </div>

        {/* Main content */}
        <div className="w-5/6">
          {activeTab === 'demandLetter' && renderDemandConfigComponent()}
        </div>
      </div>
    </div>
  );
};

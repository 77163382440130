// eslint-disable-next-line max-classes-per-file
import { loadCKEditorCloud } from '@ckeditor/ckeditor5-react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from '@/state/state';

import { CitationView } from './CitationView';

const {
  CKEditor: { Plugin, ContextualBalloon, View, clickOutsideHandler },
} = await loadCKEditorCloud({
  version: '44.3.0',
  premium: true,
  translations: ['en'],
});

type TView = InstanceType<typeof View>;

// Define a custom interface for our wrapper view
interface CustomView extends TView {
  reactRoot?: Root;
  reactContainer?: HTMLElement;
}

interface CitationPluginConfig {
  matterId: string;
  demandId: string;
}

export class CitationPlugin extends Plugin {
  private _balloon: any;

  private citationView!: CustomView;

  private matterId!: string;

  private demandId!: string;

  static get requires() {
    return [ContextualBalloon];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this._balloon = this.editor.plugins.get(ContextualBalloon);
    this._createCitationView();
    this._enableClickListener();
    const config = this.editor.config.get(
      'citationPlugin',
    ) as CitationPluginConfig;
    this.matterId = config.matterId;
    this.demandId = config.demandId;
  }

  private defineSchema() {
    const { schema } = this.editor.model;

    schema.extend('$text', {
      allowAttributes: ['citations'],
    });
  }

  private defineConverters() {
    const { conversion } = this.editor;
    // Downcast: Model to View (for editing and display)
    conversion.for('downcast').attributeToElement({
      model: 'citations',
      view: (citations: string, { writer }: any) => {
        const span = writer.createAttributeElement('span', {
          class: 'citation-highlight',
          citations,
        });

        return span;
      },
    });

    // Upcast: HTML to Model (for loading content)
    conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        attributes: {
          citations: true,
        },
      },
      model: {
        key: 'citations',
        value: (viewElement: any) => viewElement.getAttribute('citations'),
      },
    });
  }

  /**
   * Creates the citation view that will be displayed in the balloon.
   */
  private _createCitationView() {
    const { editor } = this;

    // Create a simple view
    this.citationView = new View(editor.locale) as CustomView;

    // Set up the view template with a container for React
    this.citationView.setTemplate({
      tag: 'div',
      attributes: {
        class: ['ck', 'ck-citation-balloon'],
        tabindex: '-1',
      },
      children: [
        {
          tag: 'div',
          attributes: {
            class: 'react-container',
          },
        },
      ],
    });

    // After the view is rendered, set up React
    this.citationView.on('render', () => {
      if (!this.citationView.element) return;

      // Store a reference to the container element
      this.citationView.reactContainer =
        this.citationView.element.querySelector(
          '.react-container',
        ) as HTMLElement;

      if (this.citationView.reactContainer) {
        // Create a React root once
        this.citationView.reactRoot = createRoot(
          this.citationView.reactContainer,
        );

        // Initial render with empty citations
        this._renderReactComponent([]);
      }
    });

    // Clean up React when the view is destroyed
    this.citationView.on('destroy', () => {
      if (this.citationView.reactRoot) {
        this.citationView.reactRoot.unmount();
      }
    });

    // Set up click outside handler
    clickOutsideHandler({
      emitter: this.citationView,
      activator: () => this._balloon.visibleView === this.citationView,
      contextElements: [this._balloon.view.element],
      callback: () => this._hideUI(),
    });
  }

  /**
   * Helper method to render the React component
   */
  private _renderReactComponent(citations: string[]) {
    if (this.citationView.reactRoot) {
      this.citationView.reactRoot.render(
        <Provider store={store}>
          <CitationView
            matterId={this.matterId}
            demandId={this.demandId}
            citationIds={citations}
            onClose={() => this._hideUI()}
          />
        </Provider>,
      );
    }
  }

  /**
   * Adds a click listener to the view document to detect clicks on citation elements.
   */
  private _enableClickListener() {
    const { editor } = this;
    const viewDocument = editor.editing.view.document;

    this.listenTo(viewDocument, 'click', (evt: any, data: any) => {
      const domTarget = data.target;

      if (domTarget.hasClass && domTarget.hasClass('citation-highlight')) {
        evt.stop();

        const citations = domTarget.getAttribute('citations');
        this._showUI(domTarget, citations);
      }
    });
  }

  /**
   * Shows the citation UI with citation content.
   */
  private _showUI(targetElement: any, citations: string) {
    const citationList = CitationPlugin.parseCitations(citations);

    // Add the view to the balloon
    this._balloon.add({
      view: this.citationView,
      position: this._getBalloonPositionData(targetElement),
    });

    // Update the React component
    this._renderReactComponent(citationList);

    // Focus the balloon container instead of calling focus on the view
    if (this.citationView.element) {
      this.citationView.element.focus();
    }
  }

  /**
   * Hides the citation UI.
   */
  private _hideUI() {
    if (this._balloon.hasView(this.citationView)) {
      this._balloon.remove(this.citationView);
      this.editor.editing.view.focus();
    }
  }

  /**
   * Returns the position data for the balloon based on the target element.
   */
  private _getBalloonPositionData(targetElement: any) {
    const { editor } = this;
    const { view } = editor.editing;

    return {
      target: view.domConverter.mapViewToDom(targetElement),
    };
  }

  /**
   * Parses the citation string into an array of formatted citations.
   */
  private static parseCitations(citations: string): string[] {
    if (!citations) {
      return [];
    }

    return citations.split(' ');
  }
}

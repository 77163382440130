import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Calendar,
  Pause,
  Play,
  Trash2,
  Edit,
  ChevronDown,
  ChevronUp,
  AlertTriangle,
  CheckCircle,
  Clock,
  X,
} from 'lucide-react';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import {
  useGetBlandSchedulesByTaskIdQuery,
  usePauseBlandScheduleMutation,
  useResumeBlandScheduleMutation,
  useDeleteBlandScheduleMutation,
  useGetBlandSchedulesQuery,
} from '@/services/api/blandScheduleService';
import {
  BlandSchedule,
  BlandScheduleStatus,
} from '@/services/types/bland-schedules-types';
import { cn } from '@/lib/utils';
import { DeleteConfirmationDialog } from '@/components/ui/delete-confirmation-dialog';
import { ScheduleDetails } from './schedule-parts/ScheduleDetails';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Skeleton } from '@/components/ui/skeleton';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';

interface TaskSchedulesListProps {
  taskId: string;
  onEmpty?: () => void;
}

// Helper function for formatting days of month - moved outside component to avoid recreation
const getDayOfMonthText = (days: number[] = []) => {
  if (!days || days.length === 0) return 'Custom schedule';
  return `Once daily for ${days.length} day${days.length > 1 ? 's' : ''}`;
};

// Format time helper - moved outside component to avoid recreation
const formatTimeString = (hour: number, minute: number) => {
  try {
    const date = new Date();
    date.setHours(hour || 0);
    date.setMinutes(minute || 0);
    return format(date, 'h:mm a');
  } catch (error) {
    return `${hour || 0}:${(minute || 0).toString().padStart(2, '0')}`;
  }
};

export const TaskSchedulesList = ({
  taskId,
  onEmpty,
}: TaskSchedulesListProps) => {
  const {
    data: schedules = [],
    isLoading,
    refetch,
  } = useGetBlandSchedulesByTaskIdQuery(taskId);
  const [deleteSchedule] = useDeleteBlandScheduleMutation();
  const [pauseSchedule] = usePauseBlandScheduleMutation();
  const [resumeSchedule] = useResumeBlandScheduleMutation();
  const [actionInProgress, setActionInProgress] = useState<string | null>(null);

  const [expandedScheduleId, setExpandedScheduleId] = useState<string | null>(
    null,
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [scheduleToDelete, setScheduleToDelete] = useState<string | null>(null);

  useEffect(() => {
    if (!isLoading && schedules.length === 0 && onEmpty) {
      onEmpty();
    }
  }, [schedules, isLoading, onEmpty]);

  if (isLoading) {
    return (
      <div className="space-y-4">
        <h3 className="text-lg font-medium">Scheduled Calls</h3>
        <div className="space-y-3">
          {[1, 2].map((i) => (
            <div key={i} className="border rounded-lg overflow-hidden">
              <div className="p-4 bg-gray-50">
                <div className="flex justify-between items-center">
                  <div className="space-y-2">
                    <Skeleton className="h-5 w-40" />
                    <Skeleton className="h-4 w-60" />
                  </div>
                  <div className="flex space-x-2">
                    <Skeleton className="h-8 w-8 rounded-md" />
                    <Skeleton className="h-8 w-8 rounded-md" />
                    <Skeleton className="h-8 w-8 rounded-md" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (!schedules || schedules.length === 0) {
    return (
      <div className="rounded-lg border p-8 text-center">
        <Calendar className="h-12 w-12 mx-auto text-muted-foreground mb-3" />
        <h3 className="text-lg font-medium mb-2">No Scheduled Calls</h3>
        <p className="text-sm text-muted-foreground max-w-sm mx-auto mb-4">
          You haven&apos;t set up any scheduled calls for this task yet. Create
          a schedule to automatically run this task at specific times.
        </p>
      </div>
    );
  }

  const getStatusBadge = (status: BlandScheduleStatus) => {
    const statusConfig = {
      [BlandScheduleStatus.ACTIVE]: {
        label: 'Active',
        className: 'bg-green-100 text-green-800 border-green-200',
        icon: <CheckCircle className="h-3 w-3 mr-1" />,
      },
      [BlandScheduleStatus.PAUSED]: {
        label: 'Paused',
        className: 'bg-yellow-100 text-yellow-800 border-yellow-200',
        icon: <Pause className="h-3 w-3 mr-1" />,
      },
      [BlandScheduleStatus.COMPLETED]: {
        label: 'Completed',
        className: 'bg-blue-100 text-blue-800 border-blue-200',
        icon: <CheckCircle className="h-3 w-3 mr-1" />,
      },
      [BlandScheduleStatus.ERROR]: {
        label: 'Error',
        className: 'bg-red-100 text-red-800 border-red-200',
        icon: <AlertTriangle className="h-3 w-3 mr-1" />,
      },
      [BlandScheduleStatus.CANCELLED]: {
        label: 'Cancelled',
        className: 'bg-gray-100 text-gray-800 border-gray-200',
        icon: <X className="h-3 w-3 mr-1" />,
      },
    };

    const config = statusConfig[status] || {
      label: status || 'Unknown',
      className: 'bg-gray-100 text-gray-800 border-gray-200',
      icon: null,
    };

    return (
      <Badge
        variant="outline"
        className={cn(
          'flex items-center px-2 py-1 text-xs font-medium',
          config.className,
        )}
      >
        {config.icon}
        {config.label}
      </Badge>
    );
  };

  const formatSchedule = (schedule: BlandSchedule) => {
    // Format based on interval or calendar settings
    if (!schedule) {
      return {
        primary: 'Unknown schedule',
        secondary: '',
      };
    }

    if (schedule.interval_period) {
      return {
        primary: `Every ${schedule.interval_period}`,
        secondary: '',
      };
    }

    // Format days of week
    const weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayText =
      schedule.calendar_day_of_week && schedule.calendar_day_of_week.length > 0
        ? schedule.calendar_day_of_week
            .map((d) => weekdayNames[d || 0])
            .join(', ')
        : getDayOfMonthText(schedule.calendar_day_of_month || []);

    // Format time
    const timeText =
      schedule.calendar_hour && schedule.calendar_hour.length > 0
        ? `at ${schedule.calendar_hour
            .map((h) =>
              formatTimeString(
                h || 0,
                (schedule.calendar_minute && schedule.calendar_minute[0]) || 0,
              ),
            )
            .join(', ')}`
        : '';

    return {
      primary: dayText || 'Custom schedule',
      secondary: timeText,
    };
  };

  const handlePauseResume = async (schedule: BlandSchedule) => {
    if (!schedule || !schedule.id) {
      toast.error('Invalid schedule');
      return;
    }

    try {
      setActionInProgress(schedule.id);
      if (schedule.status === BlandScheduleStatus.ACTIVE) {
        await pauseSchedule({ id: schedule.id }).unwrap();
        toast.success('Schedule paused successfully');
      } else if (schedule.status === BlandScheduleStatus.PAUSED) {
        await resumeSchedule({ id: schedule.id }).unwrap();
        toast.success('Schedule resumed successfully');
      }
      refetch();
    } catch (error) {
      toast.error('Failed to update schedule status');
    } finally {
      setActionInProgress(null);
    }
  };

  const confirmDelete = (scheduleId: string) => {
    if (!scheduleId) return;
    setScheduleToDelete(scheduleId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!scheduleToDelete) return;

    try {
      setActionInProgress(scheduleToDelete);
      await deleteSchedule(scheduleToDelete).unwrap();
      toast.success('Schedule deleted successfully');
      refetch();
    } catch (error) {
      toast.error('Failed to delete schedule');
    } finally {
      setActionInProgress(null);
      setScheduleToDelete(null);
      setDeleteDialogOpen(false);
    }
  };

  const safeFormatDate = (dateString: string, formatStr = 'MMM d, yyyy') => {
    try {
      if (!dateString) return '';
      return format(new Date(dateString), formatStr);
    } catch (error) {
      return 'Invalid date';
    }
  };

  return (
    <TooltipProvider>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <Badge variant="outline" className="px-2 py-1">
            {schedules.length}{' '}
            {schedules.length === 1 ? 'Schedule' : 'Schedules'}
          </Badge>
        </div>

        <div className="space-y-3">
          {schedules.map((schedule) => {
            if (!schedule || !schedule.id) return null;

            const scheduleInfo = formatSchedule(schedule);
            const isExpanded = expandedScheduleId === schedule.id;
            const isActionInProgress = actionInProgress === schedule.id;
            const hasError = schedule.status === BlandScheduleStatus.ERROR;

            return (
              <div
                key={schedule.id}
                className={cn(
                  'border rounded-lg overflow-hidden transition-all duration-200',
                  hasError ? 'border-red-300' : '',
                  isExpanded ? 'shadow-md' : '',
                )}
              >
                <div
                  className={cn(
                    'p-4 bg-gray-50 flex items-center justify-between',
                    hasError ? 'bg-red-50' : '',
                  )}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center gap-2">
                      {getStatusBadge(schedule.status)}
                      <span className="font-semibold">
                        {scheduleInfo.primary}
                      </span>
                    </div>
                    <div className="flex flex-wrap items-center text-sm text-gray-500 space-x-3">
                      {scheduleInfo.secondary && (
                        <span className="flex items-center">
                          <Clock className="h-3 w-3 mr-1 inline" />
                          {scheduleInfo.secondary}
                        </span>
                      )}
                      <span>
                        {safeFormatDate(schedule.schedule_start_at)}
                        {schedule.schedule_end_at
                          ? ` to ${safeFormatDate(schedule.schedule_end_at)}`
                          : ' (No end date)'}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handlePauseResume(schedule)}
                          disabled={isActionInProgress}
                          className={
                            schedule.status === BlandScheduleStatus.ACTIVE
                              ? 'text-amber-600 hover:text-amber-800 hover:bg-amber-50'
                              : 'text-green-600 hover:text-green-800 hover:bg-green-50'
                          }
                        >
                          {schedule.status === BlandScheduleStatus.ACTIVE ? (
                            <Pause className="h-4 w-4" />
                          ) : (
                            <Play className="h-4 w-4" />
                          )}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          {schedule.status === BlandScheduleStatus.ACTIVE
                            ? 'Pause schedule'
                            : 'Resume schedule'}
                        </p>
                      </TooltipContent>
                    </Tooltip>

                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => confirmDelete(schedule.id)}
                          disabled={isActionInProgress}
                          className="text-red-600 hover:text-red-800 hover:bg-red-50"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Delete schedule</p>
                      </TooltipContent>
                    </Tooltip>

                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() =>
                            setExpandedScheduleId(
                              isExpanded ? null : schedule.id,
                            )
                          }
                          className={isExpanded ? 'bg-gray-100' : ''}
                        >
                          {isExpanded ? (
                            <ChevronUp className="h-4 w-4" />
                          ) : (
                            <ChevronDown className="h-4 w-4" />
                          )}
                          <span className="ml-1">
                            {isExpanded ? 'Less' : 'More'}
                          </span>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Show {isExpanded ? 'less' : 'more'} details</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </div>

                {isExpanded && <ScheduleDetails schedule={schedule} />}
              </div>
            );
          })}
        </div>
      </div>

      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        onConfirm={handleDeleteConfirm}
        title="Delete Schedule"
        description="Are you sure you want to delete this schedule? This action cannot be undone."
      />
    </TooltipProvider>
  );
};

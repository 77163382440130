import React, { useEffect, useState } from 'react';
import { PlusCircle, Sparkles, Eraser } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { useNotification } from '@/components/contexts/NotificationContext';
import { MatterNoteConfig } from '../../types';
import {
  matterNotesApi,
  useCheckGenerationStatusQuery,
  useGenerateNotesMutation,
  useClearNotesMutation,
} from '@/services/api/matterNotesService';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Button } from '@/components/ui/button';

export const GenerateMatterNoteButton = ({
  matterId,
  config,
  isEmptyState = false,
}: {
  matterId: string;
  config: MatterNoteConfig;
  isEmptyState?: boolean;
}) => {
  const [generateNotes] = useGenerateNotesMutation();
  const [taskId, setTaskId] = useState<string | null>(null);
  const notify = useNotification();
  const dispatch = useDispatch();
  const { type, title } = config;
  const { data: generationStatus } = useCheckGenerationStatusQuery(
    { matterId, noteType: type, taskId: taskId! },
    { skip: !taskId, pollingInterval: 2000 },
  );

  useEffect(() => {
    if (generationStatus && taskId) {
      if (generationStatus.status === 'SUCCESS') {
        notify(`Successfully generated ${title}`, 'success', 3000);
        dispatch(
          matterNotesApi.util.invalidateTags([
            { type: 'Notes', id: `${matterId}-${type}` },
          ]),
        );
        setTaskId(null);
      } else if (generationStatus.status === 'FAILURE') {
        notify(
          `Failed to generate ${title}: ${generationStatus.error}`,
          'error',
          3000,
        );
        setTaskId(null);
      }
    }
  }, [generationStatus, title, taskId, notify, dispatch, matterId, type]);

  const handleGenerate = async () => {
    try {
      const result = await generateNotes({
        matterId,
        noteType: type,
      }).unwrap();
      setTaskId(result.task_id);
      notify(`Generating ${title}...`, 'info', 3000);
    } catch (error) {
      notify(`Failed to start generation for ${title}`, 'error', 3000);
      console.error('Failed to start generation:', error);
    }
  };

  let buttonClassName =
    'group inline-flex items-center gap-1.5 text-xs font-medium transition-all duration-300 ';
  if (taskId) {
    buttonClassName += 'text-muted-foreground/40 cursor-not-allowed';
  } else if (isEmptyState) {
    buttonClassName += 'text-muted-foreground/50 hover:text-primary/70';
  } else {
    buttonClassName += 'text-muted-foreground/60 hover:text-primary/80';
  }

  let iconClassName = 'h-3 w-3 transition-all duration-300 ';
  if (taskId) {
    iconClassName += 'animate-pulse text-muted-foreground/40';
  } else if (isEmptyState) {
    iconClassName +=
      'text-muted-foreground/50 group-hover:text-primary/70 group-hover:scale-110';
  } else {
    iconClassName +=
      'text-muted-foreground/60 group-hover:text-primary/80 group-hover:scale-110';
  }

  return (
    <button
      onClick={handleGenerate}
      disabled={!!taskId}
      type="button"
      className={buttonClassName}
      aria-label={taskId ? 'Generating...' : `Generate ${title}`}
    >
      <Sparkles className={iconClassName} />
      <span className="hidden sm:inline-block tracking-tight">
        {taskId ? 'generating...' : 'Generate'}
      </span>
    </button>
  );
};

export const ClearNotesButton = ({
  matterId,
  config,
  isEmptyState = false,
  onCleared,
}: {
  matterId: string;
  config: MatterNoteConfig;
  isEmptyState?: boolean;
  onCleared?: () => void;
}) => {
  const [clearNotes] = useClearNotesMutation();
  const notify = useNotification();
  const dispatch = useDispatch();
  const { type, title } = config;
  const [isClearing, setIsClearing] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleConfirm = async () => {
    setIsClearing(true);
    setIsPopoverOpen(false);
    try {
      await clearNotes({ matterId, noteType: type }).unwrap();
      notify(`Successfully cleared all ${title}`, 'success', 3000);
      dispatch(
        matterNotesApi.util.invalidateTags([
          { type: 'Notes', id: `${matterId}-${type}` },
        ]),
      );
      onCleared?.();
    } catch (error) {
      notify(`Failed to clear ${title}`, 'error', 3000);
      console.error('Failed to clear notes:', error);
    } finally {
      setIsClearing(false);
    }
  };

  let buttonClassName =
    'group inline-flex items-center gap-1.5 text-xs font-medium transition-all duration-300 ';
  if (isClearing) {
    buttonClassName += 'text-muted-foreground/40 cursor-not-allowed';
  } else if (isEmptyState) {
    buttonClassName += 'text-muted-foreground/30 cursor-not-allowed';
  } else {
    buttonClassName += 'text-muted-foreground/60 hover:text-error/80';
  }

  let iconClassName = 'h-3 w-3 transition-all duration-300 ';
  if (isClearing) {
    iconClassName += 'animate-pulse text-muted-foreground/40';
  } else if (isEmptyState) {
    iconClassName += 'text-muted-foreground/30';
  } else {
    iconClassName +=
      'text-muted-foreground/60 group-hover:text-error/80 group-hover:scale-110';
  }

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <button
          disabled={isClearing || isEmptyState}
          type="button"
          className={buttonClassName}
          aria-label={isClearing ? 'Clearing...' : `Clear ${title}`}
        >
          <Eraser className={iconClassName} />
          <span className="hidden sm:inline-block tracking-tight">
            {isClearing ? 'clearing...' : 'Clear'}
          </span>
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-3" align="end">
        <div className="space-y-2">
          <p className="text-sm font-medium text-gray-900">
            Clear all {title}?
          </p>
          <p className="text-xs text-gray-500">This action cannot be undone.</p>
          <div className="flex justify-end gap-2 pt-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsPopoverOpen(false)}
              className="h-8 px-3"
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              size="sm"
              onClick={handleConfirm}
              disabled={isClearing}
              className="h-8 px-3 text-white"
            >
              Clear
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export const AddNoteButton = ({
  onAddNew,
  isEmptyState = false,
}: {
  onAddNew: (() => void) | undefined;
  isEmptyState?: boolean;
}) => {
  const buttonClassName = `group inline-flex items-center gap-1.5 text-xs font-medium transition-all duration-300 ${
    isEmptyState
      ? 'text-primary/70 hover:text-primary'
      : 'text-muted-foreground/60 hover:text-primary/80'
  }`;

  const iconClassName = `h-3.5 w-3.5 transition-all duration-300 ${
    isEmptyState
      ? 'text-primary/70 group-hover:text-primary group-hover:scale-110 animate-pulse'
      : 'text-muted-foreground/60 group-hover:text-primary/80 group-hover:scale-110'
  }`;

  return (
    <button
      onClick={onAddNew}
      type="button"
      className={buttonClassName}
      aria-label="Add New"
      disabled={!onAddNew}
    >
      <PlusCircle className={iconClassName} />
      <span className="hidden sm:inline-block tracking-tight">Add</span>
    </button>
  );
};

interface SectionActionsProps {
  matterId: string;
  config: MatterNoteConfig;
  onAddNew?: () => void;
  showAddButton?: boolean;
  isEmptyState?: boolean;
  onCleared?: () => void;
  disabled?: boolean;
}

export const SectionActions: React.FC<SectionActionsProps> = ({
  matterId,
  config,
  onAddNew,
  showAddButton = false,
  isEmptyState = false,
  onCleared,
  disabled = false,
}) => {
  // Use either provided isEmptyState or force it when disabled
  const effectiveIsEmptyState = disabled || isEmptyState;

  return (
    <div
      className={`flex items-center gap-3 pl-1 ${disabled ? 'opacity-50' : ''}`}
    >
      <GenerateMatterNoteButton
        matterId={matterId}
        config={config}
        isEmptyState={effectiveIsEmptyState}
      />
      <ClearNotesButton
        matterId={matterId}
        config={config}
        isEmptyState={effectiveIsEmptyState}
        onCleared={onCleared}
      />

      {showAddButton && onAddNew && (
        <AddNoteButton
          onAddNew={disabled ? undefined : onAddNew}
          isEmptyState={effectiveIsEmptyState}
        />
      )}
    </div>
  );
};

import { Check } from 'lucide-react';
import React from 'react';

import { cn } from '@/lib/utils';

interface RecommendationListProps {
  recommendations: string[];
  compact?: boolean;
}

export const RecommendationList: React.FC<RecommendationListProps> = ({
  recommendations,
  compact = false,
}) => {
  if (!recommendations || recommendations.length === 0) {
    return null;
  }

  return (
    <ul className="space-y-2.5 pt-2">
      {recommendations.map((recommendation, index) => (
        <li key={index} className="flex gap-2">
          <Check
            className={cn(
              'text-green-500 flex-shrink-0 mt-0.5',
              compact ? 'h-3.5 w-3.5' : 'h-4 w-4',
            )}
          />
          <span
            className={cn(
              'text-gray-800 leading-tight',
              compact ? 'text-xs' : 'text-sm',
            )}
          >
            {recommendation}
          </span>
        </li>
      ))}
    </ul>
  );
};

import { useEffect, useState, useRef } from 'react';
import { Loader2, RotateCcw } from 'lucide-react';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  useSeedMatterSummaryMutation,
  useGetSeedMatterSummaryStatusQuery,
} from '../../../services/api/matterSummaryService';

interface SeedSummaryButtonProps {
  matterId: string;
  /**
   * Callback function that will be called when the summary generation process
   * completes successfully. This is triggered after the seeding task finishes.
   */
  onSeedingComplete?: () => void;
}

const SeedSummaryButton = ({
  matterId,
  onSeedingComplete,
}: SeedSummaryButtonProps): JSX.Element => {
  const [seedSummary, { isLoading: isSeeding }] =
    useSeedMatterSummaryMutation();
  const [taskId, setTaskId] = useState<string | null>(null);
  const [isConfirming, setIsConfirming] = useState(false);

  // Use a ref to store the latest onSeedingComplete callback
  const onSeedingCompleteRef = useRef(onSeedingComplete);

  // Update the ref when the callback changes
  useEffect(() => {
    onSeedingCompleteRef.current = onSeedingComplete;
  }, [onSeedingComplete]);

  const { data: statusData, isLoading: isCheckingStatus } =
    useGetSeedMatterSummaryStatusQuery(
      { matterId, taskId: taskId || '' },
      { skip: !taskId, pollingInterval: 2000 },
    );

  useEffect(() => {
    if (statusData?.status === 'SUCCESS' && statusData.completed) {
      setTaskId(null);
      // Use the ref to call the latest callback
      onSeedingCompleteRef.current?.();
      toast.success('Summary generated successfully');
    } else if (statusData?.status === 'FAILURE') {
      console.error('Summary seeding failed:', statusData.error);
      setTaskId(null);
      toast.error(statusData.error || 'Failed to generate summary');
    }
  }, [statusData]); // Remove onSeedingComplete from the dependency array

  const handleSeedSummary = async () => {
    try {
      const response = await seedSummary({ matterId }).unwrap();
      setTaskId(response.task_id);
      setIsConfirming(false);
      toast.info('Starting summary generation...');
    } catch (error: any) {
      console.error('Failed to seed summary:', error);
      toast.error(error?.data?.message || 'Failed to start summary generation');
    }
  };

  const isLoading = isSeeding || isCheckingStatus || !!taskId;

  const buttonContent = isLoading ? (
    <>
      <Loader2 className="h-3 w-3 animate-spin" />
      <span className="ml-2 text-xs">Generating...</span>
    </>
  ) : (
    <RotateCcw className="h-3 w-3" />
  );

  const button = (
    <Button
      variant="ghost"
      size="sm"
      onClick={() => !isLoading && setIsConfirming(true)}
      disabled={isLoading}
      className="text-muted-foreground/50 hover:text-primary/90 transition-colors"
    >
      {buttonContent}
    </Button>
  );

  if (isLoading) {
    return button;
  }

  return (
    <TooltipProvider>
      <Popover open={isConfirming} onOpenChange={setIsConfirming}>
        <Tooltip>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>{button}</PopoverTrigger>
          </TooltipTrigger>
          <TooltipContent side="bottom" className="text-xs">
            Regenerate summary and key considerations
          </TooltipContent>
        </Tooltip>
        <PopoverContent
          className="w-80 p-0 overflow-hidden shadow-lg border-neutral-200"
          align="end"
          sideOffset={8}
        >
          <div className="flex flex-col">
            <div className="p-4 border-b border-neutral-100">
              <h4 className="text-base text-foreground flex items-center gap-2 font-semibold">
                Regenerate Summary?
              </h4>
              <div className="mt-2 bg-muted/20 rounded-md p-1 prose prose-sm">
                <p className="text-muted-foreground text-sm ">
                  This will clear all existing summaries and key considerations.
                </p>
                <p className="text-muted-foreground text-sm">
                  A new summary will be generated from the intake call. This
                  process may take a minute.
                </p>
              </div>
            </div>
            <div className="flex items-center justify-end gap-2 p-3 bg-neutral-50">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsConfirming(false)}
                className="border-neutral-200 text-muted-foreground hover:bg-neutral-100"
              >
                Cancel
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={handleSeedSummary}
                className="border-red-500 text-red-500 hover:bg-red-50 hover:text-red-600"
              >
                Confirm
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </TooltipProvider>
  );
};

export default SeedSummaryButton;

export const sampleTemplateValues = {
  // Firm Information
  firmName: 'Johnson & Associates',
  firmFullName: 'Johnson & Associates Legal Services, LLC',

  // Matter Details
  matterName: 'Smith v. ABC Corporation',
  matterStatus: 'Active',
  matterMatterType: 'Personal Injury',
  matterDateOfLoss: '2023-06-15',
  matterCity: 'Phoenix',
  matterState: 'Arizona',
  matterStatuteOfLimitationsDate: '2025-06-15',

  // Client Information
  clientName: 'Robert Smith',
  clientEmail: 'robert.smith@email.com',
  clientDateOfBirth: '1985-03-22',
  clientAddress: '123 Main Street, Phoenix, AZ 85001',
  clientPhone: '(602) 555-0123',
  clientLastFourOfSsn: '4321',

  // Police Information
  policeName: 'NYPD',
  policeAddress: '321 Police Plaza, Phoenix, AZ 85003',
  policePhone: '(602) 555-7890',
  policeEmail: 'jwilson@phoenixpd.gov',
  policePoliceReportNumber: 'PHX-2023-456789',

  // Medical Information
  medicalName: 'Phoenix General Hospital',
  medicalDatesOfService: '2023-06-15 to 2023-07-30',
  medicalTreatment: 'Physical Therapy, Surgery',
  medicalAddress: '789 Hospital Drive, Phoenix, AZ 85008',
  medicalPhone: '(602) 555-4567',
  medicalEmail: 'records@phoenixgeneral.com',
  medicalStillTreating: 'Yes',
  medicalDateOfRecordsRequest: '2023-06-15',
  // Incident Information
  incidentDescription:
    'Robert Smith was involved in a rear-end collision with another vehicle',
  incidentDateOfLoss: '2023-06-15',
  incidentTimeOfLoss: '14:30',
  incidentLocation: 'Intersection of Main St and 1st Ave, Phoenix, AZ',
  incidentInjuriesDescribed: 'Whiplash, back pain, and shoulder injury',
  incidentType: 'Motor Vehicle Accident, Rear End',
  incidentHospital: 'Phoenix General Hospital',
  incidentAmbulance: 'Phoenix Fire Department Ambulance Service',

  // Insurance Details
  insuranceDateFiled: '2023-06-20',
  insurancePolicyType: 'Auto Insurance',
  insuranceHolder: 'Robert Smith',
  insuranceCarrier: 'Safe Auto Insurance Co.',
  insurancePolicyNumber: 'POL-987654321',
  insurancePolicyLimitations: '100000/300000',
  insuranceVehicleDescription: '2020 Toyota Camry',
  insuranceClaimNumber: 'CLM-123456789',
  insuranceAdjusterName: 'Sarah Johnson',
  insuranceAdjusterPhone: '(800) 555-9876',
  insuranceAdjusterEmail: 'sjohnson@safeauto.com',
  insuranceMemberId: 'MEM123456',
  insuranceGroupId: 'GRP789012',
};

import { Box } from '@mui/material';
import { InsuranceNote } from '../../../../services/types/matter-notes-types';
import ReadOnlyNoteSection from './ReadOnlyNoteSection';
import ReadOnlyInsuranceNoteItem from './ReadOnlyInsuranceNoteItem';
import { hasPopulatedFields } from '../../../../utils/noteUtils';

interface ReadOnlyInsuranceNoteSectionProps {
  notes: InsuranceNote[];
}

const ReadOnlyInsuranceNoteSection = ({
  notes,
}: ReadOnlyInsuranceNoteSectionProps) => {
  if (!notes?.length || !notes.some(hasPopulatedFields)) return null;

  return (
    <ReadOnlyNoteSection title="Insurance Information">
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 4,
        }}
      >
        {notes.map((note) => (
          <Box
            key={note.id}
            sx={{
              minWidth: '300px',
              flex: '1 1 auto',
            }}
          >
            <ReadOnlyInsuranceNoteItem note={note} />
          </Box>
        ))}
      </Box>
    </ReadOnlyNoteSection>
  );
};

export default ReadOnlyInsuranceNoteSection;

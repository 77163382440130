// Base model that matches Django's MatterNote
export interface BaseMatterNote {
  id: string;
  matter: string;
  description: string | null;
  created_at: string;
  updated_at: string;
}

// Constants matching Django's note types
export const NOTE_TYPES = {
  INCIDENT: 'incident',
  CLIENT_EMPLOYMENT: 'client-employment',
  INVOLVED_PERSON: 'involved-person',
  LAW_ENFORCEMENT: 'law-enforcement',
  MEDICAL: 'medical',
  PRIOR_MEDICAL: 'prior-medical',
  PRIOR_LAWSUIT: 'prior-lawsuit',
  INSURANCE: 'insurance',
  DAMAGE: 'damage',
  EMERGENCY_CONTACT: 'emergency-contact',
} as const;

export type NoteType = (typeof NOTE_TYPES)[keyof typeof NOTE_TYPES];

// Models with OneToOne relationship to Matter
export const ONE_TO_ONE_NOTE_TYPES = [
  NOTE_TYPES.INCIDENT,
  NOTE_TYPES.CLIENT_EMPLOYMENT,
  NOTE_TYPES.EMERGENCY_CONTACT,
] as const;

export type OneToOneNoteType = (typeof ONE_TO_ONE_NOTE_TYPES)[number];

// Models with regular ForeignKey to Matter
export const ONE_TO_MANY_NOTE_TYPES = [
  NOTE_TYPES.INVOLVED_PERSON,
  NOTE_TYPES.LAW_ENFORCEMENT,
  NOTE_TYPES.MEDICAL,
  NOTE_TYPES.PRIOR_MEDICAL,
  NOTE_TYPES.PRIOR_LAWSUIT,
  NOTE_TYPES.INSURANCE,
  NOTE_TYPES.DAMAGE,
] as const;

export type OneToManyNoteType = (typeof ONE_TO_MANY_NOTE_TYPES)[number];

// Note models matching Django models exactly
export interface IncidentNote extends BaseMatterNote {
  date_of_loss: string | null;
  time_of_loss: string | null;
  location: string | null;
  weather_conditions: string | null;
  injuries_described: string | null;
  incident_type: string | null;
  hospital: string | null;
  ambulance: string | null;
  police_report: string | null;
  current_treatment: string | null;
  witnesses: string | null;
  statements: string | null;
  photos: string | null;
  representation: string | null;
}

export interface ClientEmploymentNote extends BaseMatterNote {
  employer: string | null;
  role: string | null;
  wage: number | null;
  lost_time_from_work: boolean;
  length_of_employment: string | null;
}

export interface InvolvedPersonNote extends BaseMatterNote {
  name: string | null;
  phone: string | null;
  email: string | null;
}

export interface LawEnforcementNote extends BaseMatterNote {
  name: string | null;
  address: string | null;
  phone: string | null;
  email: string | null;
  police_report_number: string | null;
}

export interface MedicalNote extends BaseMatterNote {
  name: string | null;
  address: string | null;
  phone: string | null;
  treatment: string | null;
  fax: string | null;
  email: string | null;
  dates_of_service: string | null;
  still_treating: string | null;
}

export interface PriorMedicalNote extends BaseMatterNote {
  name: string | null;
  treatment: string | null;
  dates_of_service: string | null;
  still_treating: string | null;
  is_pcp: boolean;
}

export interface PriorLawsuitNote extends BaseMatterNote {
  attorney: string | null;
  year: string | null;
  settlement: string | null;
}

export interface InsuranceNote extends BaseMatterNote {
  holder: string | null;
  carrier: string | null;
  policy_number: string | null;
  policy_limitations: string | null;
  vehicle_description: string | null;
  claim_number: string | null;
  date_filed: string | null;
  adjuster_name: string | null;
  adjuster_phone: string | null;
  adjuster_email: string | null;
  policy_type: string | null;
  member_id: string | null;
  group_id: string | null;
}

export interface DamageNote extends BaseMatterNote {
  amount: number | null;
}

export interface EmergencyContactNote extends BaseMatterNote {
  name: string | null;
  phone: string | null;
  email: string | null;
  address: string | null;
  date_of_birth: string | null;
  last_four_of_ssn: string | null;
}

// Simple mapping of note types to their models
export type NoteTypeToModel = {
  [NOTE_TYPES.INCIDENT]: IncidentNote;
  [NOTE_TYPES.CLIENT_EMPLOYMENT]: ClientEmploymentNote;
  [NOTE_TYPES.INVOLVED_PERSON]: InvolvedPersonNote;
  [NOTE_TYPES.LAW_ENFORCEMENT]: LawEnforcementNote;
  [NOTE_TYPES.MEDICAL]: MedicalNote;
  [NOTE_TYPES.PRIOR_MEDICAL]: PriorMedicalNote;
  [NOTE_TYPES.PRIOR_LAWSUIT]: PriorLawsuitNote;
  [NOTE_TYPES.INSURANCE]: InsuranceNote;
  [NOTE_TYPES.DAMAGE]: DamageNote;
  [NOTE_TYPES.EMERGENCY_CONTACT]: EmergencyContactNote;
};

// API types
export type NoteUpdateRequest<T extends BaseMatterNote> = Partial<
  Omit<T, 'id' | 'matter' | 'created_at' | 'updated_at'>
>;

export type TagTypes = `${Capitalize<NoteType>}Notes`;
export type NoteTag = { type: 'Notes'; id: string };

export type NoteUpdateArg<T extends NoteType> = {
  matterId: string;
  noteId: string;
  noteType: T;
  note: NoteUpdateRequest<NoteTypeToModel[T]>;
};

export type NoteCreateArg<T extends NoteType> = {
  matterId: string;
  noteType: T;
  note: NoteUpdateRequest<NoteTypeToModel[T]>;
};

export type NoteDeleteArg = {
  matterId: string;
  noteId: string;
};

// Add this helper type
export function isOneToOneNoteType(
  noteType: NoteType,
): noteType is OneToOneNoteType {
  return ONE_TO_ONE_NOTE_TYPES.includes(noteType as OneToOneNoteType);
}

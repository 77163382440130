import { ReactRenderer } from '@tiptap/react';
import { MentionOptions } from '@tiptap/extension-mention';
import tippy from 'tippy.js';
import type { Instance as TippyInstance } from 'tippy.js';
import Fuse from 'fuse.js';

import { MentionList } from '../components/MentionList';
import { templateVariables } from '../data/template-variables';
import { SuggestionProps } from '../types';

const LIMIT = 10;
const fuse = new Fuse(templateVariables, {
  threshold: 0.3,
  includeScore: true,
});

const suggestion: Partial<MentionOptions['suggestion']> = {
  char: '[',
  items: ({ query }) => {
    if (!query) {
      return templateVariables.slice(0, LIMIT).map((variable) => ({
        id: variable,
        label: variable,
      }));
    }

    return fuse
      .search(query)
      .slice(0, LIMIT)
      .map(({ item }) => ({
        id: item,
        label: item,
      }));
  },

  render: () => {
    let component: ReactRenderer<any>;
    let popup: TippyInstance[];

    return {
      onStart: (props) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as any,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate: (props) => {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect as any,
        });
      },

      onKeyDown: (props) => {
        if (props.event.key === 'Escape') {
          popup[0].hide();
          return true;
        }
        return component.ref?.onKeyDown(props);
      },

      onExit: () => {
        popup[0].destroy();
        component.destroy();
      },
    };
  },

  command: ({ editor, range, props }) => {
    const { nodeAfter } = editor.view.state.selection.$to;
    const overrideSpace = nodeAfter?.text?.startsWith(' ');

    if (overrideSpace) {
      range.to += 1;
    }

    editor
      .chain()
      .focus()
      .insertContentAt(range, [
        {
          type: 'template',
          attrs: props,
        },
        {
          type: 'text',
          text: ' ',
        },
      ])
      .run();

    editor.view.dom.ownerDocument.defaultView?.getSelection()?.collapseToEnd();
  },
};

export default suggestion;

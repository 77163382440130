import { Box, ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { Toaster } from 'sonner';

import { NotificationProvider } from './components/contexts/NotificationContext';
import { FrontPlugin } from './components/front-plugin/front-plugin';
import { Call } from './components/pages/Call';
import { FirmPage } from './components/pages/FirmPage';
import { ForgotPasswordForm } from './components/pages/ForgotPasswordForm';
import LoginForm from './components/pages/LoginForm';
import MatterLayout from './components/pages/MatterLayout';
import { MattersPage } from './components/pages/MattersPage';
import { ResetPasswordForm } from './components/pages/ResetPasswordForm';
import SignUpForm from './components/pages/SignUpForm';
import { TasksPage } from './components/pages/TasksPage';
import FrontPluginRoute from './components/routing/FrontPluginRoute';
import ProtectedRoute from './components/routing/ProtectedRoute';
import { PublicRoute } from './components/routing/PublicRoute';
import SessionManager from './components/routing/SessionManager';
import { NotFound } from './components/shared-matter/NotFound';
import { SharedMatter } from './components/shared-matter/SharedMatter';
import { OperatorTemplateManagerPage } from './components/template-editor/OperatorTemplateManagerPage';
import { TemplateManagerPage } from './components/template-editor/TemplateManagerPage';
import { store } from './state/state';
import theme from './theme/theme';

const App: React.FC = () => {
  return (
    <Box className="App" sx={{ display: 'flex', backgroundColor: '#fff' }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <SessionManager>
              <Router>
                <Toaster position="bottom-right" />
                <Routes>
                  <Route element={<FrontPluginRoute />}>
                    <Route path="/front-plugin" element={<FrontPlugin />} />
                  </Route>
                  <Route
                    path="/shared-matter/:token"
                    element={<SharedMatter />}
                  />
                  <Route element={<PublicRoute />}>
                    <Route path="/signup" element={<SignUpForm />} />
                    <Route path="/login" element={<LoginForm />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordForm />}
                    />
                    <Route
                      path="/reset-password"
                      element={<ResetPasswordForm />}
                    />
                    <Route path="/share/not_found" element={<NotFound />} />
                  </Route>
                  <Route element={<ProtectedRoute />}>
                    <Route path="/matters" element={<MattersPage />} />
                    <Route
                      path="/matters/:matter_id?"
                      element={<MatterLayout />}
                    />
                    <Route
                      path="/matters/:matter_id/demands"
                      element={<MatterLayout />}
                    />
                    <Route
                      path="/matters/:matter_id?/tasks/:task_id?"
                      element={<MatterLayout />}
                    />
                    <Route path="/tasks" element={<TasksPage />} />
                    <Route path="/tasks/:task_id?" element={<TasksPage />} />
                    <Route path="/call/:id" element={<Call />} />
                    <Route
                      path="/template-editor"
                      element={<TemplateManagerPage />}
                    />
                    <Route path="/firms" element={<FirmPage />} />
                    <Route path="/firms/:firm_id" element={<FirmPage />} />
                    <Route
                      path="/firms/:firm_id/demand-configuration"
                      element={<FirmPage />}
                    />
                    <Route
                      path="/firms/demand-configurations/create"
                      element={<FirmPage />}
                    />
                    <Route
                      path="/firms/demand-configurations/:configId"
                      element={<FirmPage />}
                    />
                    <Route
                      path="/firms/demand-configurations/:configId/edit"
                      element={<FirmPage />}
                    />
                    <Route
                      path="/firms/:firm_id/demand-template"
                      element={<FirmPage />}
                    />
                    <Route
                      path="/firms/:firm_id/proofreading-rules"
                      element={<FirmPage />}
                    />
                    <Route
                      path="/operator-template-editor"
                      element={<OperatorTemplateManagerPage />}
                    />
                    <Route path="*" element={<Navigate to="/matters" />} />
                  </Route>
                </Routes>
              </Router>
            </SessionManager>
          </NotificationProvider>
        </ThemeProvider>
      </Provider>
    </Box>
  );
};

export default App;

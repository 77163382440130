import { ChevronDown, X } from 'lucide-react';
import { useState } from 'react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Slider } from '@/components/ui/slider';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import {
  BlandCallTemplate,
  BlandVoicePreset,
  BlandModel,
  BlandBackgroundTrack,
} from '../../../services/types/bland-templates.types';

interface TemplateSettingsProps {
  template: BlandCallTemplate;
  onSettingsChange: (settings: Partial<BlandCallTemplate>) => void;
}

interface SettingsSection {
  id: string;
  title: string;
  fields: {
    key: keyof BlandCallTemplate;
    label: string;
    type: 'text' | 'number' | 'boolean' | 'select';
    tooltip?: string;
    options?: { value: string; label: string }[];
    min?: number;
    max?: number;
    step?: number;
  }[];
}

const SETTINGS_SECTIONS: SettingsSection[] = [
  {
    id: 'voice',
    title: 'Voice Settings',
    fields: [
      {
        key: 'voice',
        label: 'Voice Preset',
        type: 'select',
        options: Object.values(BlandVoicePreset).map((value) => ({
          value: value as string,
          label:
            (value as string).charAt(0).toUpperCase() +
            (value as string).slice(1),
        })),
      },
      {
        key: 'model',
        label: 'AI Model',
        type: 'select',
        tooltip:
          'Enhanced model is recommended for most cases - better latency and full feature support.',
        options: Object.values(BlandModel).map((value) => ({
          value: value as string,
          label:
            (value as string).charAt(0).toUpperCase() +
            (value as string).slice(1),
        })),
      },
    ],
  },
  {
    id: 'behavior',
    title: 'Call Behavior',
    fields: [
      {
        key: 'wait_for_greeting',
        label: 'Wait for Greeting',
        type: 'boolean',
        tooltip:
          'When enabled, the agent will wait for the call recipient to speak first instead of starting immediately.',
      },
      {
        key: 'block_interruptions',
        label: 'Block Interruptions',
        type: 'boolean',
        tooltip:
          'When enabled, the agent will continue speaking even if interrupted.',
      },
      {
        key: 'interruption_threshold',
        label: 'Interruption Threshold',
        type: 'number',
        tooltip:
          'The minimum duration (in milliseconds) of speech required to be considered an interruption.',
        min: 50,
        max: 200,
        step: 1,
      },
    ],
  },
  {
    id: 'audio',
    title: 'Audio Settings',
    fields: [
      {
        key: 'background_track',
        label: 'Background Track',
        type: 'select',
        tooltip:
          'Select an audio track to play in the background during the call. Creates a more natural experience by minimizing silence between speech.',
        options: Object.values(BlandBackgroundTrack).map((value) => ({
          value: value as string,
          label:
            (value as string).charAt(0).toUpperCase() +
            (value as string).slice(1),
        })),
      },
      {
        key: 'noise_cancellation',
        label: 'Noise Cancellation',
        type: 'boolean',
        tooltip:
          'Enable noise cancellation to reduce background noise during the call.',
      },
    ],
  },
  {
    id: 'tags',
    title: 'Tags',
    fields: [
      {
        key: 'available_tags',
        label: 'Available Tags',
        type: 'text',
        tooltip: 'Tags that can be used to categorize call outcomes.',
      },
    ],
  },
];

const LabelWithTooltip = ({
  label,
  tooltip,
}: {
  label: string;
  tooltip?: string;
}) => {
  if (!tooltip) {
    return (
      <Label className="text-sm text-muted-foreground select-none">
        {label}
      </Label>
    );
  }

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Label className="text-sm text-muted-foreground select-none cursor-help underline decoration-dotted decoration-muted-foreground/30 hover:decoration-muted-foreground/50 transition-colors">
            {label}
          </Label>
        </TooltipTrigger>
        <TooltipContent
          side="left"
          align="center"
          className={cn(
            'max-w-[280px] text-xs bg-white px-3 py-1.5',
            'border border-border shadow-sm',
            'text-muted-foreground',
          )}
        >
          {tooltip}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const TagManager = ({
  tags,
  onChange,
  label,
  tooltip,
}: {
  tags: string[];
  onChange: (tags: string[]) => void;
  label: string;
  tooltip?: string;
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <div className="flex flex-col gap-2 py-2">
      <div className="flex items-center justify-between">
        <LabelWithTooltip label={label} tooltip={tooltip} />
      </div>
      <div className="flex flex-wrap gap-2 mb-2">
        {tags.map((tag) => (
          <div
            key={tag}
            className="flex items-center gap-1 px-2 py-1 bg-primary/10 rounded-md"
          >
            <span className="text-sm">{tag}</span>
            <button
              type="button"
              onClick={() => {
                const newTags = tags.filter((t) => t !== tag);
                onChange(newTags);
              }}
              className="text-muted-foreground hover:text-foreground"
            >
              <X className="h-3 w-3" />
            </button>
          </div>
        ))}
      </div>
      <div className="flex gap-2">
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Add a tag..."
          className="flex-1"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && inputValue.trim()) {
              e.preventDefault();
              if (!tags.includes(inputValue.trim())) {
                onChange([...tags, inputValue.trim()]);
              }
              setInputValue('');
            }
          }}
        />
        <Button
          type="button"
          variant="secondary"
          onClick={() => {
            if (inputValue.trim() && !tags.includes(inputValue.trim())) {
              onChange([...tags, inputValue.trim()]);
              setInputValue('');
            }
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const TemplateSettings = ({
  template,
  onSettingsChange,
}: TemplateSettingsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleFieldChange = (key: keyof BlandCallTemplate, value: any) => {
    onSettingsChange({ [key]: value });
  };

  const renderField = (field: SettingsSection['fields'][0]) => {
    const value = template[field.key];

    switch (field.type) {
      case 'boolean':
        return (
          <div
            className="flex items-center justify-between py-1"
            key={field.key}
          >
            <LabelWithTooltip label={field.label} tooltip={field.tooltip} />
            <Switch
              id={field.key}
              checked={value as boolean}
              onCheckedChange={(checked) =>
                handleFieldChange(field.key, checked)
              }
              className="cursor-pointer"
            />
          </div>
        );

      case 'select':
        return (
          <div
            className="flex items-center justify-between gap-4 py-1"
            key={field.key}
          >
            <LabelWithTooltip label={field.label} tooltip={field.tooltip} />
            <Select
              value={String(value || '')}
              onValueChange={(newValue) =>
                handleFieldChange(field.key, newValue)
              }
            >
              <SelectTrigger
                className={cn(
                  'w-[180px] min-h-9 bg-white text-sm select-none',
                  'border border-input shadow-sm',
                  'focus:outline-none focus:ring-1 focus:ring-primary',
                  'data-[placeholder]:text-muted-foreground',
                )}
              >
                <SelectValue placeholder={`Select ${field.label}`} />
              </SelectTrigger>
              <SelectContent
                ref={(ref) => {
                  if (ref) {
                    ref.style.width = 'var(--radix-select-trigger-width)';
                  }
                }}
                className={cn(
                  'bg-white border rounded-md shadow-md',
                  'animate-in fade-in-0 zoom-in-95',
                )}
                position="popper"
                sideOffset={4}
              >
                {field.options?.map((option) => (
                  <SelectItem
                    key={option.value}
                    value={option.value}
                    className={cn(
                      'text-sm py-1.5 pl-2 pr-6 select-none',
                      'data-[highlighted]:bg-primary/5 data-[highlighted]:text-primary',
                      'data-[selected]:bg-primary/5 data-[selected]:text-primary',
                      'cursor-pointer',
                    )}
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        );

      case 'number':
        return (
          <div className="flex flex-col gap-2 py-2" key={field.key}>
            <div className="flex items-center justify-between">
              <LabelWithTooltip label={field.label} tooltip={field.tooltip} />
              <span className="text-sm tabular-nums font-medium">
                {value || 100}ms
              </span>
            </div>
            <Slider
              id={field.key}
              value={[(value as number) || 100]}
              onValueChange={([newValue]) =>
                handleFieldChange(field.key, newValue)
              }
              min={field.min}
              max={field.max}
              step={field.step}
              className="w-full"
            />
          </div>
        );

      case 'text':
        if (field.key === 'available_tags') {
          return (
            <TagManager
              key={field.key}
              tags={(value as string[]) || []}
              onChange={(newTags) => handleFieldChange(field.key, newTags)}
              label={field.label}
              tooltip={field.tooltip}
            />
          );
        }
        return (
          <div
            className="flex items-center justify-between gap-4 py-1"
            key={field.key}
          >
            <LabelWithTooltip label={field.label} tooltip={field.tooltip} />
            <Input
              id={field.key}
              type="text"
              value={value as string}
              onChange={(e) => handleFieldChange(field.key, e.target.value)}
              className="w-[180px] bg-white text-sm"
            />
          </div>
        );

      default:
        return (
          <div
            className="flex items-center justify-between gap-4 py-1"
            key={field.key}
          >
            <LabelWithTooltip label={field.label} tooltip={field.tooltip} />
            <Input
              id={field.key}
              type="text"
              value={value as string}
              onChange={(e) => handleFieldChange(field.key, e.target.value)}
              className="w-[180px] bg-white text-sm"
            />
          </div>
        );
    }
  };

  return (
    <div className="bg-muted/5 rounded transition-colors hover:bg-muted/10">
      <Button
        variant="ghost"
        className={cn(
          'w-full flex items-center justify-between p-2',
          'transition-all duration-200',
          'hover:bg-transparent',
          isExpanded && 'bg-muted/5',
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
        type="button"
      >
        <span className="text-sm text-muted-foreground">Advanced Settings</span>
        <ChevronDown
          className={cn(
            'h-4 w-4 text-muted-foreground',
            'transition-transform duration-200',
            isExpanded && 'transform rotate-180',
          )}
        />
      </Button>
      <div
        className={cn(
          'grid transition-all duration-200',
          isExpanded
            ? 'grid-rows-[1fr] opacity-100'
            : 'grid-rows-[0fr] opacity-0',
        )}
      >
        <div className="overflow-hidden">
          <div className="px-3 py-2 space-y-1">
            {SETTINGS_SECTIONS.flatMap((section) => section.fields).map(
              renderField,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useRef } from 'react';

import { TabsContent } from '@/components/ui/tabs';

import useMatterSelection from '../../hooks/useMatterSelection';
import { Demands } from '../demands/demands';
import { Matter } from '../matter/Matter';
import { ActionButton } from '../matter-assistant/action-button';
import { Documents } from '../matter-details/documents/Documents';
import { MatterNotesV2 } from '../matter-notes-v2/MatterNotesV2';
import WelcomeScreen from './WelcomeScreen';

interface CmsProps {
  activeTab: string;
}

const MatterPanel: React.FC<CmsProps> = ({ activeTab }) => {
  const { selectedMatterData } = useMatterSelection();
  const mainContentRef = useRef<HTMLDivElement>(null);

  // Scroll to top when selectedMatterId changes or tab changes
  useEffect(() => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [selectedMatterData?.id, activeTab]);

  if (!selectedMatterData) {
    return <WelcomeScreen />;
  }

  return (
    <div className="h-full flex flex-col relative pr-0.5">
      <div ref={mainContentRef} className="h-full overflow-y-auto scrollbar ">
        <TabsContent
          value="home"
          className="h-full data-[state=inactive]:hidden"
        >
          <div className="px-6 pb-4 pt-4 pr-5">
            {selectedMatterData && <Matter matter={selectedMatterData} />}
          </div>
        </TabsContent>

        <TabsContent
          value="documents"
          className="h-full data-[state=inactive]:hidden"
        >
          <div className="px-3 py-4 pr-5">
            <Documents />
          </div>
        </TabsContent>

        <TabsContent
          value="matter-details"
          className="h-full data-[state=inactive]:hidden"
        >
          {selectedMatterData?.id && (
            <MatterNotesV2 matterId={selectedMatterData.id} />
          )}
        </TabsContent>

        <TabsContent
          value="demands"
          className="h-full data-[state=inactive]:hidden"
        >
          <Demands
            matterId={selectedMatterData?.id}
            firmId={selectedMatterData?.firm.id}
          />
        </TabsContent>
      </div>
    </div>
  );
};

export default MatterPanel;

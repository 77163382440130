import deepEqual from 'fast-deep-equal';

/**
 * Compares two JSON values for deep equality
 * Uses fast-deep-equal for efficient deep comparison
 *
 * @param json1 First JSON value (string or object)
 * @param json2 Second JSON value (string or object)
 * @returns boolean indicating if the values are deeply equal
 */
export const isJsonEqual = (json1: any, json2: any): boolean => {
  try {
    // If both are strings, parse them
    const obj1 = typeof json1 === 'string' ? JSON.parse(json1) : json1;
    const obj2 = typeof json2 === 'string' ? JSON.parse(json2) : json2;

    // Use fast-deep-equal for comparison
    return deepEqual(obj1, obj2);
  } catch (error) {
    console.error('Error parsing JSON for comparison:', error);
    // Fall back to string comparison
    return JSON.stringify(json1) === JSON.stringify(json2);
  }
};

/**
 * Creates a deep clone of an object using JSON.parse/stringify
 * Note: This won't preserve functions, undefined values, or circular references
 *
 * @param obj Object to clone
 * @returns Deep clone of the object
 */
export const deepClone = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

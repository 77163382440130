import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import {
  isPhoneNumberValid,
  normalizePhoneNumber,
  sanitizePhoneNumber,
  isEmailValid,
} from '../../../utils/validation';
import { CountryFormField } from './CountryFormField';
import { DateFormField } from './DateFormField';
import { PhoneNumberFormField } from './PhoneFormField';
import { SliderFormField } from './SliderFormField';
import { TimeFormField } from './TimeFormField';
import { EmailFormField } from './EmailFormField';
/**
 * Controlled field components that integrate with React Hook Form.
 * Controlled fields are are used when fields dent adhere to the standard HTML form input interface.
 *
 * WHY: Reduces boilerplate and standardizes form field behavior across the app.
 * Instead of writing Controller logic repeatedly, import these components
 * whenever you need a form field that:
 * - Needs to be connected to React Hook Form
 * - Requires consistent data transformation (e.g., date formatting)
 * - Should follow the app's standard form validation patterns
 * -
 */

interface ControlledFieldProps<T extends FieldValues>
  extends UseControllerProps<T> {
  label: string;
}

export const ControlledDateField = <T extends FieldValues = FieldValues>({
  name,
  control,
  label,
  ...props
}: ControlledFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <DateFormField
        {...field}
        {...props}
        value={field.value ? dayjs(field.value, 'YYYY-MM-DD') : null}
        format="YYYY-MM-DD"
        onChange={(value) => field.onChange(value?.format('YYYY-MM-DD'))}
        label={label}
      />
    )}
  />
);

export const ControlledTimeField = <T extends FieldValues = FieldValues>({
  name,
  control,
  label,
  ...props
}: ControlledFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, onChange, ...field } }) => (
      <TimeFormField
        {...field}
        {...props}
        value={value ? dayjs(value, 'HH:mm:ss') : null}
        onChange={(v) => onChange(v?.format('HH:mm:ss'))}
        format="HH:mm:ss"
        label={label}
      />
    )}
  />
);

export const ControlledSliderField = <T extends FieldValues = FieldValues>({
  name,
  control,
  label,
  ...props
}: ControlledFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, onChange, ...field } }) => (
      <SliderFormField
        {...field}
        {...props}
        checked={value}
        onChange={onChange}
        label={label}
      />
    )}
  />
);

// @todo - debug this, its failing when the country is already selected
export const ControlledCountryField = <T extends FieldValues = FieldValues>({
  name,
  control,
  label,
  ...props
}: ControlledFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, onChange, ...field } }) => (
      <CountryFormField
        {...field}
        {...props}
        value={value}
        onChange={(e, v) => onChange(v)}
        countryCodesToDisplay={['US', 'CA', 'MX']}
      />
    )}
  />
);

interface ControlledPhoneFieldProps<T extends FieldValues>
  extends ControlledFieldProps<T> {
  onDelete?: () => void;
}

export const ControlledPhoneField = <T extends FieldValues = FieldValues>({
  name,
  control,
  label,
  onDelete,
  ...props
}: ControlledPhoneFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    rules={{
      validate: {
        validPhoneNumber: (value) => {
          const normalizedValue = normalizePhoneNumber(value);
          return (
            !normalizedValue ||
            isPhoneNumberValid(normalizedValue) ||
            'Please enter a valid phone number'
          );
        },
      },
    }}
    render={({ field, fieldState }) => (
      <PhoneNumberFormField
        {...field}
        {...props}
        label={label}
        error={!!fieldState.error}
        onChange={(e) => {
          const value = e.target.value.trim();
          field.onChange(value ? sanitizePhoneNumber(value) : '');
        }}
        helperText={fieldState.error?.message}
        value={normalizePhoneNumber(field.value)}
        slotProps={{
          input: {
            endAdornment: onDelete && (
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            ),
          },
        }}
      />
    )}
  />
);

export const ControlledEmailField = <T extends FieldValues = FieldValues>({
  name,
  control,
  label = '',
  ...props
}: ControlledFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    rules={{
      required: 'Email is required',
      validate: {
        validEmail: (value) =>
          isEmailValid(value) || 'Please enter a valid email address',
      },
    }}
    render={({ field, fieldState }) => (
      <EmailFormField
        {...field}
        {...props}
        value={field.value}
        label={label}
        error={!!fieldState.error}
        helperText={fieldState.error?.message}
      />
    )}
  />
);

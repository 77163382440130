import { NavLink, useLocation } from 'react-router-dom';

import { FinchLogoFlat } from '@/components/base/logos/FinchLogoFlat';
import { ProfileSettings } from '@/components/sidebar/ProfileSettings';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';

const TabItem = ({
  value,
  label,
  isActive,
}: {
  value: string;
  label: string;
  isActive: boolean;
}) => {
  return (
    <TabsTrigger
      value={value}
      className={cn(
        'font-sans font-bold px-3 mr-2 bg-none text-primary-light ',
        isActive && 'bg-white rounded-full text-header',
      )}
    >
      <span className="pb-0.5">{label}</span>
    </TabsTrigger>
  );
};

export const Header = () => {
  const { pathname } = useLocation();

  return (
    <div className="h-16 bg-header">
      <div className="h-16 bg-header">
        <div className="flex h-full w-full items-center justify-between gap-4 pr-3">
          <div className="flex items-center gap-4">
            <FinchLogoFlat style={{ marginLeft: '24px' }} />
            <Tabs
              defaultValue={pathname}
              value={pathname}
              className="bg-header"
            >
              <TabsList className="bg-header">
                <NavLink to="/matters">
                  <TabItem
                    value="matters"
                    label="Matters"
                    isActive={pathname === '/matters'}
                  />
                </NavLink>
                <NavLink to="/tasks">
                  <TabItem
                    value="tasks"
                    label="Tasks"
                    isActive={pathname === '/tasks'}
                  />
                </NavLink>
                <NavLink to="/firms">
                  <TabItem
                    value="firms"
                    label="Firms"
                    isActive={pathname === '/firms'}
                  />
                </NavLink>
              </TabsList>
            </Tabs>
          </div>
          <ProfileSettings />
        </div>
      </div>
    </div>
  );
};

import { useRef, useState } from 'react';

interface UseScrollToBottomOptions {
  /**
   * Threshold in pixels to consider the user at the bottom
   */
  threshold?: number;
}

/**
 * Custom hook to manage scroll-to-bottom behavior with user override detection
 */
export function useScrollToBottom(options: UseScrollToBottomOptions = {}) {
  const { threshold = 20 } = options;

  // Reference to the scrollable container
  const scrollRef = useRef<HTMLDivElement>(null);

  // Internal state to track if auto-scrolling is enabled
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  // Function to scroll to bottom
  const scrollToBottom = () => {
    if (scrollRef.current && autoScrollEnabled) {
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
      }, 100);
    }
  };

  // Handle scroll events
  const handleScroll = () => {
    if (!scrollRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < threshold;

    // Update auto-scroll state based on scroll position
    setAutoScrollEnabled(isAtBottom);
  };

  // Reset auto-scroll (call this when a new message is sent)
  const resetAutoScroll = () => {
    setAutoScrollEnabled(true);
  };

  return {
    scrollRef,
    scrollToBottom,
    handleScroll,
    resetAutoScroll,
    isAutoScrollEnabled: autoScrollEnabled,
  };
}

import React from 'react';

import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

import { ProofreadingJobStatus } from '../../../services/types/proofreading-types';

interface JobStatusBadgeProps {
  status: ProofreadingJobStatus | string;
}

interface StatusConfig {
  label: string;
  className: string;
}

// Define the status config with an index signature
const statusConfig: Record<string, StatusConfig> = {
  [ProofreadingJobStatus.PENDING]: {
    label: 'Pending',
    className: 'bg-blue-100 text-blue-800 hover:bg-blue-100',
  },
  [ProofreadingJobStatus.PROCESSING]: {
    label: 'Processing',
    className: 'bg-purple-100 text-purple-800 hover:bg-purple-100',
  },
  [ProofreadingJobStatus.COMPLETED]: {
    label: 'Completed',
    className: 'bg-green-100 text-green-800 hover:bg-green-100',
  },
  [ProofreadingJobStatus.FAILED]: {
    label: 'Failed',
    className: 'bg-red-100 text-red-800 hover:bg-red-100',
  },
  [ProofreadingJobStatus.CANCELLED]: {
    label: 'Cancelled',
    className: 'bg-gray-100 text-gray-800 hover:bg-gray-100',
  },
};

export const JobStatusBadge: React.FC<JobStatusBadgeProps> = ({ status }) => {
  // Get config for the status or use a default
  const config = statusConfig[status] || {
    label:
      typeof status === 'string'
        ? status.charAt(0).toUpperCase() + status.slice(1)
        : 'Unknown',
    className: 'bg-gray-100 text-gray-800 hover:bg-gray-100',
  };

  return (
    <Badge variant="outline" className={cn('font-medium', config.className)}>
      {config.label}
    </Badge>
  );
};

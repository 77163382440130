import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithErrorHandling } from './baseQuery';

// Define the request payload type
export interface SendExternalOpsMessagePayload {
  task_id: string;
  message: string;
}

// Create the API client
export const externalOpsMessageApiClient = createApi({
  reducerPath: 'externalOpsMessageApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['ExternalOpsMessages'],
  endpoints: (builder) => ({
    sendExternalOpsMessage: builder.mutation<
      { status: string },
      SendExternalOpsMessagePayload
    >({
      query: (payload) => ({
        url: 'api/integrations/slack/send-external-ops-message/',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

// Export the hooks
export const { useSendExternalOpsMessageMutation } =
  externalOpsMessageApiClient;

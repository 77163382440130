import { Contact, ContactType } from '@/services/types/task-types';

export const INSURANCE_CARRIER_CONTACTS: Contact[] = [
  {
    id: 'state-farm',
    name: 'State Farm',
    phone: '(800) 732-5246',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'progressive',
    name: 'Progressive',
    phone: '(800) 776-4737',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'cure',
    name: 'Cure',
    phone: '(800) 535-2873',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'usa-underwriters',
    name: 'USA Underwriters',
    phone: '(855) 230-1656',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'allstate',
    name: 'Allstate',
    phone: '(800) 255-7828',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'geico',
    name: 'Geico',
    phone: '(800) 841-3000',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'plymouth-rock',
    name: 'Plymouth Rock',
    phone: '(844) 346-1225',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'bristol-west',
    name: 'Bristol West',
    phone: '(800) 274-7865',
    type: ContactType.INSURANCE_CARRIER,
  },
  {
    id: 'farmers',
    name: 'Farmers Insurance',
    phone: '(800) 435-7764',
    type: ContactType.INSURANCE_CARRIER,
  },
];

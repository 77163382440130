import { FieldConfig } from '../components/SharedNoteComponents';
import {
  NOTE_TYPES,
  DamageNote,
  IncidentNote,
  PriorMedicalNote,
  InsuranceNote,
  MedicalNote,
  LawEnforcementNote,
  InvolvedPersonNote,
  ClientEmploymentNote,
  EmergencyContactNote,
  PriorLawsuitNote,
  NoteType,
} from '../../../services/types/matter-notes-types';

export interface NoteConfig<T> {
  type: NoteType;
  title: string;
  prompt: string;
  fields: FieldConfig[];
  layout: 'grid' | 'stack';
  gridCols?: number;
}

export const INSURANCE_POLICY_TYPE_OPTIONS = [
  { value: 'FIRST_PARTY_PIP', label: 'PIP / 1P Insurance' },
  { value: 'THIRD_PARTY_BI', label: 'BI / 3P Insurance' },
  { value: 'PERSONAL_HEALTH_INSURANCE', label: 'Personal Health Insurance' },
];

export const NOTE_CONFIGS = {
  [NOTE_TYPES.EMERGENCY_CONTACT]: {
    type: NOTE_TYPES.EMERGENCY_CONTACT,
    title: 'Emergency Contact',
    prompt: 'Emergency contact information for the client',
    layout: 'grid',
    gridCols: 12,
    fields: [
      // First row - core identity and primary contact
      {
        name: 'name',
        label: 'NAME',
        gridProps: { xs: 12, sm: 4 },
      },
      {
        name: 'phone',
        label: 'PHONE',
        gridProps: { xs: 12, sm: 4 },
      },
      {
        name: 'email',
        label: 'EMAIL',
        type: 'email',
        gridProps: { xs: 12, sm: 4 },
      },
      // Second row - additional details and description
      {
        name: 'date_of_birth',
        label: 'DATE OF BIRTH',
        type: 'date',
        gridProps: { xs: 12, sm: 2 },
      },
      {
        name: 'last_four_of_ssn',
        label: 'LAST 4 OF SSN',
        gridProps: { xs: 12, sm: 2 },
      },
      {
        name: 'address',
        label: 'ADDRESS',
        gridProps: { xs: 12, sm: 3 },
      },
      {
        name: 'description',
        label: 'DESCRIPTION',
        gridProps: { xs: 12, sm: 5 },
        muiProps: {
          minRows: 2,
          maxRows: 4,
        },
      },
    ],
  } satisfies NoteConfig<EmergencyContactNote>,
  [NOTE_TYPES.INCIDENT]: {
    type: NOTE_TYPES.INCIDENT,
    title: 'Accident Details',
    prompt: 'A narrative description of the accident',
    layout: 'grid',
    gridCols: 12,
    fields: [
      // Primary description at top
      {
        name: 'description',
        label: 'DESCRIPTION',
        multiline: true,
        gridProps: { sm: 12 },
        muiProps: {
          minRows: 3,
          maxRows: 6,
        },
      },
      // Core incident details
      {
        name: 'date_of_loss',
        label: 'DATE OF LOSS',
        type: 'date',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'time_of_loss',
        label: 'TIME OF LOSS',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'incident_type',
        label: 'INCIDENT TYPE',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'location',
        label: 'LOCATION',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      // Scene details
      {
        name: 'weather_conditions',
        label: 'WEATHER CONDITIONS',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'police_report',
        label: 'POLICE REPORT #',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'photos',
        label: 'PHOTOS/EVIDENCE',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      // Witness information
      {
        name: 'witnesses',
        label: 'WITNESSES',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'statements',
        label: 'STATEMENTS',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      // Medical and emergency response
      {
        name: 'injuries_described',
        label: 'INJURIES',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'current_treatment',
        label: 'TREATMENT',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'ambulance',
        label: 'AMBULANCE',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'hospital',
        label: 'HOSPITAL',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      // Legal status
      {
        name: 'representation',
        label: 'REPRESENTATION',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
    ],
  } satisfies NoteConfig<IncidentNote>,

  [NOTE_TYPES.MEDICAL]: {
    type: NOTE_TYPES.MEDICAL,
    title: 'Medical Information',
    prompt: 'A medical provider seen for treatment by the client',
    layout: 'grid',
    gridCols: 12,
    fields: [
      // Provider info
      {
        name: 'name',
        label: 'PROVIDER NAME',
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: 'treatment',
        label: 'TREATMENT',
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: 'dates_of_service',
        label: 'DATES OF SERVICE',
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: 'phone',
        label: 'PHONE',
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: 'address',
        label: 'ADDRESS',
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: 'date_of_records_request',
        label: 'DATE OF RECORDS REQUEST',
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: 'description',
        label: 'DESCRIPTION',
        multiline: true,
        gridProps: { xs: 12 },
        muiProps: {
          minRows: 2,
          maxRows: 6,
        },
      },
    ],
  } satisfies NoteConfig<MedicalNote>,

  [NOTE_TYPES.DAMAGE]: {
    type: NOTE_TYPES.DAMAGE,
    title: 'Damages',
    prompt: 'Notable damages related to the matter',
    layout: 'grid',
    gridCols: 12,
    fields: [
      {
        name: 'description',
        label: 'DESCRIPTION',

        gridProps: { sm: 8 },
        muiProps: {
          minRows: 2,
          maxRows: 4,
        },
      },
      {
        name: 'amount',
        label: 'AMOUNT',
        type: 'number',
        gridProps: { sm: 4 },
      },
    ],
  } satisfies NoteConfig<DamageNote>,

  [NOTE_TYPES.PRIOR_MEDICAL]: {
    type: NOTE_TYPES.PRIOR_MEDICAL,
    title: 'Prior Medical History',
    prompt: 'A medical provider seen by the client prior to the incident',
    layout: 'grid',
    gridCols: 12,
    fields: [
      {
        name: 'name',
        label: 'PROVIDER NAME',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'treatment',
        label: 'TREATMENT',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'dates_of_service',
        label: 'DATES OF SERVICE',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'is_pcp',
        label: 'PRIMARY CARE PROVIDER',
        isCheckbox: true,
        checkboxLabel: 'Primary Care Provider',
        gridProps: { xs: 12, sm: 6, md: 6 },
      },
      {
        name: 'still_treating',
        label: 'STILL TREATING',
        gridProps: { xs: 12, sm: 6, md: 6 },
      },
      {
        name: 'description',
        label: 'DESCRIPTION',
        multiline: true,
        gridProps: { xs: 12 },
        muiProps: {
          minRows: 2,
          maxRows: 4,
        },
      },
    ],
  } satisfies NoteConfig<PriorMedicalNote>,

  [NOTE_TYPES.INSURANCE]: {
    type: NOTE_TYPES.INSURANCE,
    title: 'Insurance',
    prompt: 'An insurance policy involved in the matter',
    layout: 'grid',
    gridCols: 12,
    fields: [
      {
        name: 'policy_type',
        label: 'POLICY TYPE',
        type: 'select',
        options: INSURANCE_POLICY_TYPE_OPTIONS,
        freeSolo: true,
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'holder',
        label: 'HOLDER',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'carrier',
        label: 'CARRIER',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'policy_number',
        label: 'POLICY NUMBER',
        gridProps: { xs: 12, sm: 6, md: 3 },
      },
      {
        name: 'claim_number',
        label: 'CLAIM NUMBER',
        gridProps: { xs: 12, sm: 6, md: 3 },
      },
      {
        name: 'member_id',
        label: 'MEMBER ID',
        gridProps: { xs: 12, sm: 6, md: 3 },
      },
      {
        name: 'group_id',
        label: 'GROUP ID',
        gridProps: { xs: 12, sm: 6, md: 3 },
      },
      {
        name: 'date_filed',
        label: 'DATE FILED',
        type: 'date',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'vehicle_description',
        label: 'VEHICLE',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'policy_limitations',
        label: 'POLICY LIMITATIONS',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'adjuster_name',
        label: 'ADJUSTER NAME',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'adjuster_phone',
        label: 'ADJUSTER PHONE',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'adjuster_email',
        label: 'ADJUSTER EMAIL',
        type: 'email',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },

      {
        name: 'description',
        label: 'DESCRIPTION',
        multiline: true,
        gridProps: { xs: 12 },
        muiProps: {
          minRows: 2,
          maxRows: 4,
        },
      },
    ],
  } satisfies NoteConfig<InsuranceNote>,

  [NOTE_TYPES.LAW_ENFORCEMENT]: {
    type: NOTE_TYPES.LAW_ENFORCEMENT,
    title: 'Police',
    prompt: 'A law enforcement agency or officer involved in the matter',
    layout: 'grid',
    gridCols: 12,
    fields: [
      {
        name: 'name',
        label: 'NAME',
        gridProps: { xs: 12, sm: 4 },
      },
      {
        name: 'police_report_number',
        label: 'REPORT NUMBER',
        gridProps: { xs: 12, sm: 4 },
      },
      {
        name: 'phone',
        label: 'PHONE',
        gridProps: { xs: 12, sm: 4 },
      },
      {
        name: 'email',
        label: 'EMAIL',
        type: 'email',
        gridProps: { xs: 12, sm: 4 },
      },
      {
        name: 'address',
        label: 'ADDRESS',
        gridProps: { xs: 12, sm: 4 },
      },
      {
        name: 'description',
        label: 'DESCRIPTION',
        // multiline: true,
        gridProps: { xs: 12, sm: 4 },
      },
    ],
  } satisfies NoteConfig<LawEnforcementNote>,

  [NOTE_TYPES.INVOLVED_PERSON]: {
    type: NOTE_TYPES.INVOLVED_PERSON,
    title: 'Additional Parties',
    prompt: 'A person involved in the matter',
    layout: 'grid',
    gridCols: 12,
    fields: [
      {
        name: 'name',
        label: 'NAME',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'phone',
        label: 'PHONE',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'email',
        label: 'EMAIL',
        type: 'email',
        gridProps: { xs: 12, sm: 6, md: 4 },
      },
      {
        name: 'description',
        label: 'DESCRIPTION',
        multiline: true,
        gridProps: { xs: 12, sm: 12, md: 12 },
        muiProps: {
          minRows: 2,
          maxRows: 4,
        },
      },
    ],
  } satisfies NoteConfig<InvolvedPersonNote>,

  [NOTE_TYPES.CLIENT_EMPLOYMENT]: {
    type: NOTE_TYPES.CLIENT_EMPLOYMENT,
    title: 'Employment',
    prompt: 'Employment information for the client',
    layout: 'grid',
    gridCols: 12,
    fields: [
      {
        name: 'description',
        label: 'DESCRIPTION',
        multiline: true,
        gridProps: { sm: 12 },
        muiProps: {
          minRows: 2,
          maxRows: 4,
        },
      },
      {
        name: 'employer',
        label: 'EMPLOYER',
        gridProps: { sm: 6 },
      },
      {
        name: 'role',
        label: 'ROLE',
        gridProps: { sm: 6 },
      },
      {
        name: 'wage',
        label: 'WAGE',
        type: 'number',
        gridProps: { sm: 6 },
      },
      {
        name: 'length_of_employment',
        label: 'LENGTH OF EMPLOYMENT',
        gridProps: { sm: 6 },
      },
      {
        name: 'lost_time_from_work',
        label: 'LOST TIME FROM WORK',
        isCheckbox: true,
        checkboxLabel: 'Lost Time From Work',
        gridProps: { sm: 12 },
      },
    ],
  } satisfies NoteConfig<ClientEmploymentNote>,

  [NOTE_TYPES.PRIOR_LAWSUIT]: {
    type: NOTE_TYPES.PRIOR_LAWSUIT,
    title: 'Prior Lawsuits',
    prompt: 'A prior lawsuit involving the client',
    layout: 'grid',
    gridCols: 12,
    fields: [
      {
        name: 'description',
        label: 'DESCRIPTION',
        multiline: true,
        gridProps: { sm: 12 },
        muiProps: {
          minRows: 2,
          maxRows: 4,
        },
      },
      {
        name: 'attorney',
        label: 'ATTORNEY',
        gridProps: { sm: 6, md: 4 },
      },
      {
        name: 'year',
        label: 'YEAR',
        gridProps: { sm: 6, md: 4 },
      },
      {
        name: 'settlement',
        label: 'SETTLEMENT',
        gridProps: { sm: 12, md: 4 },
      },
    ],
  } satisfies NoteConfig<PriorLawsuitNote>,
} as const;

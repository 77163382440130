/* eslint-disable no-console */
import Cookies from 'js-cookie';
import { useState } from 'react';

import {
  demandEditorMessageAdapter,
  demandsApiClient,
} from '@/services/api/demandService';
import { MessageRole, MessageStatus } from '@/services/api/matterAssistant';
import {
  CreateDemandEditorMessageRequest,
  DemandEditorMessage,
} from '@/services/types/demand-types';
import { store } from '@/state/state';

import { SSEParser } from '../utils/sse-parser';

const addMessage = (
  matterId: string,
  demandId: string,
  threadId: string,
  message: DemandEditorMessage,
) => {
  store.dispatch(
    demandsApiClient.util.updateQueryData(
      'listEditorMessages',
      { matterId, demandId, threadId },
      (draft) => {
        return demandEditorMessageAdapter.addOne(draft, message);
      },
    ),
  );
};

const updateMessage = (
  matterId: string,
  demandId: string,
  threadId: string,
  message: DemandEditorMessage,
) => {
  store.dispatch(
    demandsApiClient.util.updateQueryData(
      'listEditorMessages',
      { matterId, demandId, threadId },
      (draft) => {
        return demandEditorMessageAdapter.updateOne(draft, {
          id: message.id,
          changes: {
            ...draft.entities[message.id],
            ...message,
          },
        });
      },
    ),
  );
};

const convertRequestToMessage = (
  threadId: string,
  request: CreateDemandEditorMessageRequest,
): DemandEditorMessage => {
  return {
    id: request.message.id,
    parent: request.message.parent_id,
    created_at: request.message.created_at,
    modified_at: request.message.modified_at,
    thread: threadId,
    role: request.message.role as MessageRole,
    status: request.message.status as MessageStatus,
    openai_response: request.message.openai_response,
    content: request.message.content,
  };
};

export const useSendMessage = (
  matterId: string,
  demandId: string,
  threadId: string,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const sendMessage = async (message: CreateDemandEditorMessageRequest) => {
    // Optimistically add the message to the UI
    addMessage(
      matterId,
      demandId,
      threadId,
      convertRequestToMessage(threadId, message),
    );

    // Send the message to the API
    // const newMessageRequest = messageToRequest(newMessage);

    try {
      setIsLoading(true);
      const response = await fetch(
        `${import.meta.env.VITE_APP_DJANGO_BACKEND_HOST}/api/matters/${matterId}/demands/${demandId}/editor/threads/${threadId}/messages/run/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken') || '',
          },
          credentials: 'include',
          body: JSON.stringify(message),
        },
      );

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      // Get a reader for the response body stream
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Failed to get reader from response');
      }

      // Create SSE parser with the reader and subscribe to events

      const parser = new SSEParser(reader);

      parser.subscribe((event) => {
        if (event.type === 'message.created') {
          addMessage(matterId, demandId, threadId, event.data);
        }

        if (event.type === 'message.delta') {
          updateMessage(matterId, demandId, threadId, event.data);
        }

        if (event.type === 'message.completed') {
          updateMessage(matterId, demandId, threadId, event.data);
        }
      });

      await parser.start();
    } catch (error) {
      console.error('Error sending message:', error);
      // Here you might want to handle the error, e.g., update the message status
    } finally {
      setIsLoading(false);
    }
  };

  return { sendMessage, isLoading };
};

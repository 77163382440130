import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { ArrowUp, Loader2 } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { Markdown } from 'tiptap-markdown';
import { useSendMessage } from '../../hooks/useMatterAssistantStream';
import { useScrollToBottom } from '../../hooks/useScrollToBottom';
import { cn } from '../../lib/utils';
import {
  MatterMessage,
  MatterThread,
  MessageRole,
  useGetMatterMessagesQuery,
} from '../../services/api/matterAssistant';
import { AutosizeTextarea } from '../ui/autosize-textarea';
import { Button } from '../ui/button';
import './tiptap-editor.css';

// Individual message component
const Message = ({ message }: { message: MatterMessage }) => {
  const isUser = message.role === MessageRole.USER;

  // Filter out content that looks like file references with pattern 【...】
  const textContent = message.content
    .filter((content) => content.text_content)
    .map((content) => content.text_content)
    .map((content) => {
      // Remove file references like 【18:2†S..pdf】
      return content?.replace(/【[^】]*】/g, '') || '';
    })
    .join('\n');

  // Initialize Tiptap editor with markdown content and table support
  const editor = useEditor({
    extensions: [
      StarterKit,
      Markdown,
      Table.configure({
        resizable: false,
      }),
      TableRow,
      TableCell,
      TableHeader,
      // Add task list support
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
    ],
    content: textContent,
    editable: false,
  });

  // Update editor content when message changes
  useEffect(() => {
    if (editor && textContent) {
      // Set content as markdown
      editor.commands.setContent(textContent);
    }
  }, [editor, textContent]);

  return (
    <div
      className={cn(
        'flex w-full mb-4',
        isUser ? 'justify-end' : 'justify-start',
      )}
    >
      <div
        className={cn(
          'max-w-[80%] overflow-hidden',
          isUser
            ? 'bg-gray-200 rounded-lg px-4 py-2 text-right'
            : 'bg-white rounded-lg px-4 py-2 prose prose-sm max-w-none',
        )}
      >
        <EditorContent
          editor={editor}
          className={cn(
            'tiptap-editor',
            'prose-table:border-collapse prose-table:w-full',
            'prose-td:border prose-td:border-gray-300 prose-td:p-2 prose-td:text-inherit prose-td:text-base',
            'prose-th:border prose-th:border-gray-300 prose-th:p-2 prose-th:bg-gray-100 prose-th:font-semibold prose-th:text-inherit prose-th:text-base',
            'prose-tr:even:bg-gray-50',
            'prose-pre:bg-gray-800 prose-pre:text-white prose-pre:rounded-md prose-pre:p-4',
            'prose-code:text-gray-800 prose-code:bg-gray-100 prose-code:px-1 prose-code:py-0.5 prose-code:rounded',
          )}
        />
      </div>
    </div>
  );
};

// Message input component
const MessageInput = ({
  onSendMessage,
  isLoading,
}: {
  onSendMessage: (message: string) => void;
  isLoading: boolean;
}) => {
  const [messageText, setMessageText] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (messageText.trim()) {
      onSendMessage(messageText);
      setMessageText('');
    }
  };

  // Handle cmd+enter keyboard shortcut
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.preventDefault();
      if (messageText.trim() && !isLoading) {
        onSendMessage(messageText);
        setMessageText('');
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="border rounded-lg shadow-sm my-3 overflow-hidden"
    >
      <div className="w-full">
        <AutosizeTextarea
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
          className="border-none w-full rounded-lg scrollbar resize-none p-3 shadow-none focus-visible:ring-0"
          rows={2}
          maxHeight={120}
          disabled={isLoading}
        />
      </div>
      <div className="flex justify-end items-center px-3 py-2">
        <Button
          type="submit"
          className="flex justify-center items-center p-2 h-8 w-8 rounded-full"
          disabled={isLoading || !messageText.trim()}
          variant="outline"
        >
          {isLoading ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <ArrowUp className="h-4 w-4" />
          )}
        </Button>
      </div>
    </form>
  );
};

// Messages container
const MessagesContainer = ({
  messages,
  isLoading,
}: {
  messages: MatterMessage[] | undefined;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
        <p className="text-gray-500 mt-2">Loading messages...</p>
      </div>
    );
  }

  if (!messages || messages.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-gray-500">
        No messages in this thread. Start a conversation!
      </div>
    );
  }

  // Find the path from newest message to root
  const getMessagePath = () => {
    // Create a map for quick lookup
    const messagesMap = new Map<string, MatterMessage>();
    messages.forEach((message) => {
      messagesMap.set(message.id, message);
    });

    // Find the newest message (assuming messages are sorted by creation date)
    const newestMessage = [...messages.concat()].sort((a, b) => {
      // Compare creation dates (assuming created_at exists in BaseEntity)
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    })[0];

    // Build the path from newest to root
    const path: MatterMessage[] = [];
    let currentMessage: MatterMessage | undefined = newestMessage;

    // Follow parent references until we reach the root
    while (currentMessage) {
      path.push(currentMessage);

      // Move to parent or stop if we've reached the root
      currentMessage = currentMessage.parent_id
        ? messagesMap.get(currentMessage.parent_id)
        : undefined;
    }

    // Reverse to get root-to-newest order
    return path.reverse();
  };

  const messagePath = getMessagePath();

  return (
    <div className="flex flex-col overflow-y-auto p-4">
      {messagePath.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </div>
  );
};

// Main ThreadContent component
export const ThreadContent = ({
  matterId,
  thread,
}: {
  matterId: string;
  thread: MatterThread;
}) => {
  const { data: messages, isLoading: isLoadingMessages } =
    useGetMatterMessagesQuery({
      matterId,
      threadId: thread.id,
    });

  const [isSending, setIsSending] = useState(false);
  const { sendMessage } = useSendMessage(matterId, thread.id);
  const messageCount = Object.keys(messages?.entities ?? {}).length;

  // Use our custom scroll hook
  const { scrollRef, scrollToBottom, handleScroll, resetAutoScroll } =
    useScrollToBottom();

  // Track the latest message content for changes (for streaming responses)
  const latestMessageRef = useRef<string>('');
  const messagesArray = Object.values(messages?.entities ?? {});
  const latestMessage =
    messagesArray.length > 0
      ? messagesArray.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        )[0]
      : null;

  const latestMessageContent = latestMessage
    ? latestMessage.content
        .filter((content) => content.text_content)
        .map((content) => content.text_content)
        .join('\n')
    : '';

  // Scroll to bottom when messages change or when sending a message
  useEffect(() => {
    scrollToBottom();
  }, [messageCount, isSending, scrollToBottom]);

  // Also scroll to bottom when loading completes
  useEffect(() => {
    if (!isLoadingMessages) {
      scrollToBottom();
    }
  }, [isLoadingMessages, scrollToBottom]);

  // Check for content changes in the latest message (for streaming responses)
  useEffect(() => {
    if (
      latestMessageContent &&
      latestMessageContent !== latestMessageRef.current
    ) {
      latestMessageRef.current = latestMessageContent;
      scrollToBottom();
    }
  }, [latestMessageContent, scrollToBottom]);

  const handleSendMessage = async (message: string) => {
    try {
      setIsSending(true);
      // Reset scroll behavior when sending a new message
      resetAutoScroll();

      // Find the latest message to use as parent
      const sortedMessages = Object.values(messages?.entities ?? {}).sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );

      const parentId = sortedMessages.length > 0 ? sortedMessages[0].id : null;

      await sendMessage(parentId, message);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };

  if (!thread.id) {
    return (
      <div className="flex items-center justify-center h-full text-gray-500">
        Select a thread to view messages
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div
        className="flex-1 overflow-scroll scrollbar"
        ref={scrollRef}
        onScroll={handleScroll}
      >
        <MessagesContainer
          messages={Object.values(messages?.entities ?? {})}
          isLoading={isLoadingMessages}
        />
      </div>

      <div className="px-4 pb-4">
        <MessageInput
          onSendMessage={handleSendMessage}
          isLoading={isLoadingMessages || isSending}
        />
      </div>
    </div>
  );
};

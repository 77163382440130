import * as React from 'react';
import { useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from '@/components/ui/button';
import { TaskStatusBadge } from '../TaskStatusBadge';
import { Task, TaskStatus } from '@/services/types/client-intake-types';
import { useDebounce } from './hooks';

interface TaskHeaderProps {
  task: Task;
  onTitleChange: (title: string) => void;
  onStatusChange: (status: TaskStatus) => void;
}

export const TaskHeader: React.FC<TaskHeaderProps> = ({
  task,
  onTitleChange,
  onStatusChange,
}) => {
  const [editingTitle, setEditingTitle] = React.useState(false);
  const [titleValue, setTitleValue] = React.useState(task.name);
  const [hasUserEdited, setHasUserEdited] = React.useState(false);
  const { matter_id } = useParams();

  const debouncedTitle = useDebounce(titleValue, 500);

  // Update local title when task name changes (but not when we're editing)
  React.useEffect(() => {
    if (!editingTitle && !hasUserEdited) {
      setTitleValue(task.name);
    }
  }, [task.name, editingTitle, hasUserEdited]);

  // Only trigger update when user has made changes and debounced value is different
  React.useEffect(() => {
    if (hasUserEdited && debouncedTitle !== task.name) {
      onTitleChange(debouncedTitle);
      setHasUserEdited(false);
    }
  }, [debouncedTitle, task.name, onTitleChange, hasUserEdited]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value);
    setHasUserEdited(true);
  };

  const handleTitleBlur = () => {
    setEditingTitle(false);
    if (titleValue !== task.name) {
      onTitleChange(titleValue);
    }
  };

  const titleInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div className="flex justify-between items-start mb-2">
      <div className="flex flex-col gap-3 w-full">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between gap-2 w-full">
            {editingTitle ? (
              <input
                ref={titleInputRef}
                type="text"
                value={titleValue}
                onChange={handleTitleChange}
                onBlur={handleTitleBlur}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleTitleBlur();
                  }
                }}
                className="text-xl font-bold w-full border ring-0 border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            ) : (
              <Button
                variant="ghost"
                className="truncate mb-1 w-full focus-visible:ring-0 focus-visible:outline-none justify-start pl-0 text-xl font-bold cursor-text hover:text-primary text-left focus:ring-0"
                onClick={() => {
                  setEditingTitle(true);
                  setTimeout(() => {
                    titleInputRef.current?.focus();
                  }, 0);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setEditingTitle(true);
                  }
                }}
              >
                {task.name}
              </Button>
            )}
            <TaskStatusBadge
              status={task.status}
              onStatusChange={(status) => {
                onStatusChange(status);
              }}
            />
          </div>
        </div>
        {!matter_id && (
          <div className="flex items-center gap-2">
            <a
              href={`/matters/${task.matter}`}
              className="text-primary hover:underline font-bold"
            >
              {task.matter_name}
              <OpenInNewIcon className="ml-2 inline-block" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

import React from 'react';
import { Repeat } from 'lucide-react';
import { cn } from '@/lib/utils';
import { ScheduleDetailsProps, DAYS_OF_WEEK } from './types';
import { formatDaysOfWeek } from './utils';

interface WeeklyScheduleProps extends ScheduleDetailsProps {
  daysOfWeek: number[];
}

export const WeeklySchedule: React.FC<WeeklyScheduleProps> = ({
  schedule,
  daysOfWeek,
}) => {
  return (
    <div className="bg-white rounded-lg p-4 shadow-sm">
      <div className="flex items-center mb-3">
        <Repeat className="h-4 w-4 mr-2 text-primary" />
        <h4 className="font-medium">Weekly Schedule</h4>
      </div>
      <div className="grid grid-cols-7 gap-2">
        {DAYS_OF_WEEK.map((day) => {
          const isSelected = daysOfWeek.includes(day.value);
          return (
            <div
              key={day.value}
              className={cn(
                'h-10 flex flex-col items-center justify-center rounded-md text-sm',
                isSelected
                  ? 'bg-primary text-primary-foreground font-medium'
                  : 'text-muted-foreground bg-slate-100/50 opacity-40',
              )}
            >
              <span className="text-xs">{day.label.substring(0, 3)}</span>
            </div>
          );
        })}
      </div>
      <div className="mt-2 text-sm text-muted-foreground">
        <span>Calls will be made on {formatDaysOfWeek(daysOfWeek)}</span>
      </div>
    </div>
  );
};

import { ChevronLeft } from 'lucide-react';
import React, { useState } from 'react';

import { Button } from '@/components/ui/button';

import { ProofreadingEmptyState } from '../common/ProofreadingEmptyState';
import { ProofreadingSkeleton } from '../common/ProofreadingSkeleton';
import { useProofreadingJob } from '../hooks/useProofreadingJob';
import { JobList } from '../jobs/JobList';
import { ResultsOverview } from '../results/ResultsOverview';
import { RuleResultsList } from '../results/RuleResultsList';

interface ProofreadingDashboardProps {
  demandId: string;
  revisionId?: string;
}

export const ProofreadingDashboard: React.FC<ProofreadingDashboardProps> = ({
  demandId,
  revisionId,
}) => {
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const { jobResult, isLoading, refreshJob } = useProofreadingJob(
    demandId,
    selectedJobId || undefined,
  );

  const handleJobSelect = (jobId: string) => {
    setSelectedJobId(jobId);
  };

  const handleBackToJobs = () => {
    setSelectedJobId(null);
  };

  if (isLoading && !jobResult) {
    return <ProofreadingSkeleton type="job-list" />;
  }

  if (selectedJobId && jobResult) {
    return (
      <div className="space-y-2">
        <div className="flex items-center">
          <Button
            variant="ghost"
            size="sm"
            onClick={handleBackToJobs}
            className="gap-1"
          >
            <ChevronLeft className="h-4 w-4" />
            Back
          </Button>
        </div>

        <RuleResultsList jobId={selectedJobId} demandId={demandId} />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <JobList
        demandId={demandId}
        selectedJobId={selectedJobId || undefined}
        onSelectJob={handleJobSelect}
        onJobCreated={refreshJob}
      />
    </div>
  );
};

import { NoteType } from '@/services/types/matter-notes-types';
/**
 * MatterNoteFieldType is the type of field in a matter note.
 * Specifies the component to render for the field.
 *
 * By Default, all fields are rendered as a text input.
 */
export enum MatterNoteFieldType {
  TEXT = 'text',
  DATE = 'date',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
}

/**
 * The cardinality of the note, i.e single or many. Some data entry should
 * happen one time for a given matter, while other data may be entered multiple times.
 *
 * Examples:
 * Single: Law Enforcement Information
 * Multiple: Medical Providers
 */
export enum NoteCardinality {
  SINGLE = 'single',
  MANY = 'many',
}

/**
 * SelectOption is the configuration for a select option in a matter note.
 * It is used to generate the options for the select field.
 */
export interface SelectOption {
  value: string;
  label: string;
}

/**
 * MatterNoteFieldConfig is the configuration for a field in a matter note.
 * It is used to generate the form fields for the note.
 */
export interface MatterNoteFieldConfig {
  name: string;
  label: string;
  type?: MatterNoteFieldType;
  multiline?: boolean;
  options?: SelectOption[];
}

/**
 * MatterNotes are a collection of basic data entry models for a given matter.
 * Because they are presented so similarly, we can use a single component to render them,
 * and define the fields in a configuration.
 */
export interface MatterNoteConfig {
  /**
   * The type of note, i.e Incident, ClientEmployment, etc.
   * Directly maps to a Django model.
   */
  type: NoteType;
  /**
   * A title to be displayed as a section header above the form/ collection of forms.
   */
  title: string;
  /**
   * The fields of the model to be exposed in each individual form.
   */
  fields: MatterNoteFieldConfig[];
  /**
   * The cardinality of the note, i.e single or many. In the case of many,
   * the form will be rendered as a collection of forms.
   */
  cardinality: NoteCardinality;
}

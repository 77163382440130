import { css as cssLang } from '@codemirror/lang-css';
import { EditorState } from '@codemirror/state';
import { oneDark } from '@codemirror/theme-one-dark';
import { basicSetup, EditorView } from 'codemirror';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';

import { UpdateDemandTemplate } from '@/services/types/demand-types';

import {
  useGetDemandTemplateQuery,
  useUpdateDemandTemplateMutation,
} from '../../../../services/api/demandService';

interface FirmDemandTemplateProps {
  firmId: string;
}

// Example document content for preview
const EXAMPLE_DOCUMENT_CONTENT = `
<div class="ck-content" dir="ltr"><h1 id="ec06c7d6a5fbf43d66a9b677a7730a787">Heading 1</h1><h2 id="ec11daf63e409584328cd7fd8fba73606">Heading 2</h2><h3 id="ed691f2a0fa479f235ccb07528c137344">Heading 3</h3><h4 id="e81f7a341456781e5f116a8ee3bc19276">Heading 4</h4><p>Text</p><p>&nbsp;</p></div>
`;

/**
 * Fetches default CSS for the CKEditor styling
 */
const fetchDefaultCSS = async (): Promise<string> => {
  const premiumCss = await fetch(
    'https://cdn.ckeditor.com/ckeditor5-premium-features/44.3.0/ckeditor5-premium-features.css',
  );
  const editorCss = await fetch(
    'https://cdn.ckeditor.com/ckeditor5/44.3.0/ckeditor5.css',
  );
  const premiumCssText = await premiumCss.text();
  const editorCssText = await editorCss.text();
  return `${premiumCssText}\n\n${editorCssText}`;
};

/**
 * Component for configuring firm-specific demand letter templates
 */
export const FirmDemandTemplate = ({ firmId }: FirmDemandTemplateProps) => {
  const [isExporting, setIsExporting] = useState(false);
  const [templateFile, setTemplateFile] = useState<File | null>(null);
  const [stylesheetContent, setStylesheetContent] = useState('');
  const [preamble, setPreamble] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [defaultCss, setDefaultCss] = useState('');
  const editorRef = useRef<HTMLDivElement>(null);
  const editorViewRef = useRef<EditorView | null>(null);

  const {
    data: template,
    isLoading,
    error,
  } = useGetDemandTemplateQuery({ firmId });

  const [updateTemplate, { isLoading: isUpdating }] =
    useUpdateDemandTemplateMutation();

  // Load template data when available
  useEffect(() => {
    if (template) {
      setPreamble(template.demand_preamble || '');

      // If there's a stylesheet URL, fetch and load its content
      if (template.stylesheet) {
        fetch(template.stylesheet)
          .then((response) => response.text())
          .then((text) => setStylesheetContent(text))
          .catch((err) =>
            console.error('Failed to load stylesheet content:', err),
          );
      }
    }
  }, [template]);

  // Load default CSS on component mount
  useEffect(() => {
    fetchDefaultCSS().then((cssText) => setDefaultCss(cssText));
  }, []);

  // Initialize CodeMirror editor
  useEffect(() => {
    if (editorRef.current && isEditing) {
      // Clean up previous editor instance if it exists
      if (editorViewRef.current) {
        editorViewRef.current.destroy();
      }

      const startState = EditorState.create({
        doc: stylesheetContent,
        extensions: [
          basicSetup,
          cssLang(),
          oneDark,
          EditorView.updateListener.of((update) => {
            if (update.docChanged) {
              setStylesheetContent(update.state.doc.toString());
            }
          }),
          EditorView.theme({
            '&': {
              height: '100%',
              maxHeight: '100%',
            },
            '.cm-scroller': {
              overflow: 'auto',
              height: '100%',
            },
            '.cm-content, .cm-gutter': {
              minHeight: '100%',
            },
          }),
        ],
      });

      const view = new EditorView({
        state: startState,
        parent: editorRef.current,
      });

      editorViewRef.current = view;
    }

    return () => {
      if (editorViewRef.current) {
        editorViewRef.current.destroy();
        editorViewRef.current = null;
      }
    };
  }, [isEditing]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();

    if (templateFile) {
      formData.append('docx_template', templateFile);
    }

    // Convert stylesheet content to a file if it exists
    if (stylesheetContent.trim()) {
      const stylesheetBlob = new Blob([stylesheetContent], {
        type: 'text/css',
      });
      formData.append('stylesheet', stylesheetBlob, 'stylesheet.css');
    }

    formData.append('demand_preamble', preamble);

    try {
      await updateTemplate({
        firmId,
        data: formData as unknown as UpdateDemandTemplate,
      }).unwrap();
      setIsEditing(false);
      setTemplateFile(null);
    } catch (err) {
      console.error('Failed to update template:', err);
    }
  };

  const handleTestTemplate = async () => {
    try {
      const baseCss = await fetchDefaultCSS();
      const requestBody = {
        html: EXAMPLE_DOCUMENT_CONTENT,
        css: `${baseCss}\n\n${stylesheetContent}`,
      };
      setIsExporting(true);
      const response = await fetch(
        `${import.meta.env.VITE_APP_DJANGO_BACKEND_HOST}/api/demands/template/${firmId}/export/`,
        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken') || '',
          },
        },
      );

      // Create a blob from the response data
      const blob = await response.blob();

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'demand.docx'; // This is a fallback in case the server doesn't set the filename
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Failed to test template:', err);
    } finally {
      setIsExporting(false);
    }
  };

  if (isLoading) {
    return <div>Loading template...</div>;
  }

  if (error) {
    return <div>Error loading template</div>;
  }

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Demand Template Configuration</h2>

      <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded text-sm">
        <h3 className="font-semibold text-blue-800 mb-2">
          How to use this configuration
        </h3>
        <ul className="list-disc pl-5 space-y-1 text-blue-700">
          <li>
            The <strong>Document Template</strong> is a DOCX file that defines
            the headers and footers for your demand letters.
          </li>
          <li>
            The <strong>Stylesheet</strong> controls the appearance of content
            in the exported document.
          </li>
          <li>
            The <strong>Demand Preamble</strong> is text that will appear at the
            beginning of all demand letters.
          </li>
          <li>
            Use the <strong>Test Template</strong> button to preview how your
            configuration will look in a real document.
          </li>
        </ul>
      </div>

      {!isEditing ? (
        <div className="mb-6">
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <h3 className="font-medium">Document Template</h3>
              {template?.docx_template ? (
                <div className="flex items-center mt-2">
                  <a
                    href={template.docx_template}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    View current template
                  </a>
                </div>
              ) : (
                <p className="text-gray-500">No template uploaded</p>
              )}
            </div>

            <div>
              <h3 className="font-medium">Stylesheet</h3>
              {template?.stylesheet ? (
                <div className="flex items-center mt-2">
                  <a
                    href={template.stylesheet}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    View current stylesheet
                  </a>
                </div>
              ) : (
                <p className="text-gray-500">No stylesheet uploaded</p>
              )}
            </div>
          </div>

          <div className="mb-4">
            <h3 className="font-medium">Demand Preamble</h3>
            <div className="mt-2 p-2 border rounded bg-gray-50">
              {template?.demand_preamble ? (
                <p className="whitespace-pre-wrap">
                  {template.demand_preamble}
                </p>
              ) : (
                <p className="text-gray-500">No preamble set</p>
              )}
            </div>
          </div>

          <div className="flex space-x-2">
            <button
              type="button"
              onClick={() => setIsEditing(true)}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Edit Template
            </button>
            <button
              type="button"
              onClick={handleTestTemplate}
              disabled={isExporting}
              className="ml-2 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 disabled:bg-green-300"
            >
              {isExporting ? 'Testing...' : 'Test Template'}
            </button>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <h2 className="text-lg font-medium mb-2">Document Template</h2>
            <p className="text-sm text-gray-500 mb-2">
              Upload a DOCX template that defines the headers and footers for
              your demand letters. The main content will be inserted between
              them.
            </p>
            <input
              id="docx-template"
              name="docx-template"
              type="file"
              accept=".docx"
              onChange={(e) => setTemplateFile(e.target.files?.[0] || null)}
              className="w-full border p-2 rounded"
              aria-labelledby="docx-template-label"
            />
            {template?.docx_template && (
              <p className="mt-1 text-sm text-gray-500">
                Current template:{' '}
                <a
                  href={template.docx_template}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  View
                </a>
              </p>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h2 className="text-lg font-medium mb-2">Stylesheet</h2>
              <p className="text-sm text-gray-500 mb-2">
                Customize the appearance of your documents with CSS styles.
                Common selectors include <code>.ck-content h1</code>,{' '}
                <code>.ck-content p</code>, etc.
              </p>
              <div
                id="stylesheet-editor-container"
                className="border rounded h-[400px] overflow-hidden"
                style={{
                  fontSize: '14px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  ref={editorRef}
                  className="h-full"
                  aria-labelledby="stylesheet-label"
                  role="textbox"
                  aria-multiline="true"
                  tabIndex={0}
                />
              </div>
              {template?.stylesheet && (
                <p className="mt-1 text-sm text-gray-500">
                  Original stylesheet:{' '}
                  <a
                    href={template.stylesheet}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    View
                  </a>
                </p>
              )}
            </div>

            <div>
              <h2 className="text-lg font-medium mb-2">Live Preview</h2>
              <p className="text-sm text-gray-500 mb-2">
                See how your CSS changes affect the document appearance in
                real-time.
              </p>
              <div className="border rounded h-[400px] overflow-auto">
                <iframe
                  id="preview-frame"
                  srcDoc={`
                    <!DOCTYPE html>
                    <html>
                      <head>
                        <style>${defaultCss}\n\n${stylesheetContent}</style>
                      </head>
                      <body>
                        ${EXAMPLE_DOCUMENT_CONTENT}
                      </body>
                    </html>
                  `}
                  title="Preview"
                  className="w-full h-full border-none"
                  aria-labelledby="preview-label"
                />
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-lg font-medium mb-2">Demand Preamble</h2>
            <p className="text-sm text-gray-500 mb-2">
              Enter text that will appear at the beginning of all demand
              letters. This is typically a formal introduction.
            </p>
            <textarea
              id="demand-preamble"
              name="demand-preamble"
              value={preamble}
              onChange={(e) => setPreamble(e.target.value)}
              rows={6}
              className="w-full border p-2 rounded"
              placeholder="Enter demand preamble text..."
              aria-labelledby="preamble-label"
            />
          </div>

          <div className="flex space-x-2">
            <button
              type="submit"
              disabled={isUpdating}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:bg-blue-300"
            >
              {isUpdating ? 'Saving...' : 'Save Template'}
            </button>
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              disabled={isUpdating}
              className="px-4 py-2 border rounded hover:bg-gray-100"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

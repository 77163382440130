import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  ReactNode,
} from 'react';
import { ConversationContext } from '@frontapp/plugin-sdk';
import { useFrontContext } from '@/providers/frontContext';
import * as frontService from '@/services/frontService';
import {
  BaseMatter,
  DetailedMatter,
} from '@/services/types/client-intake-types';

import {
  useGetMattersQuery,
  useGetMatterDetailsQuery,
} from '@/services/api/mattersService';

interface FrontMatterLinksContextType {
  // State
  selectedMatter: BaseMatter | null;
  error: string | null;
  searchQuery: string;
  isLoading: boolean;
  isSearchMode: boolean;
  linkedMatterId: string | null;
  // Matter data
  matters: BaseMatter[];
  mattersLoading: boolean;
  filteredMatters: BaseMatter[];
  matterDetails: DetailedMatter | null; // TODO: Type this properly based on your API response
  matterDetailsLoading: boolean;
  matterDetailsError: any;

  // Actions
  selectMatter: (matter: BaseMatter) => void;
  setSelectedMatter: (matter: BaseMatter | null) => void;
  linkMatter: (
    matter: BaseMatter,
  ) => Promise<{ success: boolean; error?: string }>;
  unlinkMatter: () => Promise<{ success: boolean; error?: string }>;
  updateSearchQuery: (query: string) => void;
  clearError: () => void;
  refetchMatters: () => Promise<void>;
  // Utility
  hasMatterLink: boolean;
}

const FrontMatterLinksContext =
  createContext<FrontMatterLinksContextType | null>(null);

export const FrontMatterLinksProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const frontContext: ConversationContext = useFrontContext();

  const linkedMatterId = frontService.getMatterId(frontContext);
  const [selectedMatter, setSelectedMatter] = useState<BaseMatter | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // API queries
  const {
    data: matters = [],
    isLoading: mattersLoading,
    refetch: refetchMattersApi,
  } = useGetMattersQuery();

  const mattersById = useMemo(() => {
    return matters.reduce(
      (acc, matter) => {
        acc[matter.id] = matter;
        return acc;
      },
      {} as Record<string, BaseMatter>,
    );
  }, [matters]);

  const {
    data: matterDetails,
    isLoading: matterDetailsLoading,
    error: detailsError,
  } = useGetMatterDetailsQuery(selectedMatter?.id || '', {
    skip: !selectedMatter?.id,
  });

  // Initialize state based on current conversation - only runs on mount and when frontContext changes
  useEffect(() => {
    if (linkedMatterId) {
      setSelectedMatter(mattersById[linkedMatterId]);
    }
  }, [frontContext, mattersById, linkedMatterId]);

  // Derive search mode from conditions
  const isSearchMode = !selectedMatter;

  // Matter selection and linking
  const selectMatter = useCallback((matter: BaseMatter) => {
    setSelectedMatter(matter);
    setSearchQuery('');
  }, []);

  const linkMatter = useCallback(
    async (matterToLink: BaseMatter) => {
      // If already linked to this matter, just return
      if (linkedMatterId === matterToLink.id) {
        return { success: true };
      }

      setError(null);
      setIsLoading(true);

      try {
        const result = await frontService.addMatterLink(
          frontContext,
          matterToLink,
        );

        if (result.success) {
          setSelectedMatter(matterToLink);
          return { success: true };
        }

        setError(result.error || 'Unknown error linking matter');
        return { success: false, error: result.error };
      } catch (err) {
        const errorMsg =
          err instanceof Error ? err.message : 'Failed to link matter';
        setError(errorMsg);
        return { success: false, error: errorMsg };
      } finally {
        setIsLoading(false);
      }
    },
    [frontContext, linkedMatterId],
  );

  const unlinkMatter = useCallback(async () => {
    setError(null);
    setIsLoading(true);

    try {
      const result = await frontService.removeMatterLinks(frontContext);

      if (result.success) {
        return { success: true };
      }

      setError(result.error || 'Unknown error unlinking matter');
      return { success: false, error: result.error };
    } catch (err) {
      const errorMsg =
        err instanceof Error ? err.message : 'Failed to unlink matter';
      setError(errorMsg);
      return { success: false, error: errorMsg };
    } finally {
      setIsLoading(false);
    }
  }, [frontContext]);

  // Filter matters based on search query
  const filteredMatters = useMemo(() => {
    if (!searchQuery.trim() || !matters.length) return matters;

    const query = searchQuery.toLowerCase();
    return matters.filter(
      (matter) =>
        matter.name?.toLowerCase().includes(query) ||
        matter.type?.toLowerCase().includes(query) ||
        matter.firm?.name?.toLowerCase().includes(query),
    );
  }, [searchQuery, matters]);

  // Enhanced refetchMatters that also updates the selected matter
  const refetchMatters = useCallback(async () => {
    setIsLoading(true);
    try {
      // Store the current selected matter ID if any
      const currentMatterId = selectedMatter?.id;

      // Refetch the matters list
      await refetchMattersApi();

      // If we had a selected matter, update it with the fresh data
      if (currentMatterId) {
        // Wait a bit for the Redux store to update with new data
        setTimeout(() => {
          const refreshedMatter = matters.find((m) => m.id === currentMatterId);
          if (refreshedMatter) {
            setSelectedMatter(refreshedMatter);
          }
        }, 100);
      }
    } finally {
      setIsLoading(false);
    }
  }, [refetchMattersApi, selectedMatter, matters, setSelectedMatter]);

  const value = useMemo(
    () => ({
      selectedMatter,
      error,
      searchQuery,
      isLoading,
      linkedMatterId,
      isSearchMode,
      matters,
      mattersLoading,
      filteredMatters,
      matterDetails: matterDetails || null,
      matterDetailsLoading,
      matterDetailsError: detailsError,
      selectMatter,
      setSelectedMatter,
      refetchMatters,
      linkMatter,
      unlinkMatter,
      updateSearchQuery: (query: string) => setSearchQuery(query),
      clearError: () => setError(null),
      hasMatterLink: frontService.conversationIsLinked(frontContext),
    }),
    [
      linkedMatterId,
      selectedMatter,
      error,
      searchQuery,
      isLoading,
      isSearchMode,
      matters,
      mattersLoading,
      filteredMatters,
      matterDetails,
      matterDetailsLoading,
      detailsError,
      selectMatter,
      setSelectedMatter,
      refetchMatters,
      linkMatter,
      unlinkMatter,
      frontContext,
    ],
  );

  return (
    <FrontMatterLinksContext.Provider value={value}>
      {children}
    </FrontMatterLinksContext.Provider>
  );
};

export const useFrontMatterLinks = () => {
  const context = useContext(FrontMatterLinksContext);
  if (!context) {
    throw new Error(
      'useFrontMatterLinks must be used within a FrontMatterLinksProvider',
    );
  }
  return context;
};

import { CanonicalTaskType } from './task-types';
// Enum for voice presets
export enum BlandVoicePreset {
  JOSH = 'josh',
  FLORIAN = 'florian',
  DEREK = 'derek',
  JUNE = 'june',
  NAT = 'nat',
  PAIGE = 'paige',
  MAYA = 'maya',
}

// Enum for background tracks
export enum BlandBackgroundTrack {
  NONE = 'none',
  OFFICE = 'office',
  CAFE = 'cafe',
  RESTAURANT = 'restaurant',
}

// Enum for voicemail actions
export enum BlandVoicemailAction {
  HANGUP = 'hangup',
  LEAVE_MESSAGE = 'leave_message',
  IGNORE = 'ignore',
}

// Enum for AI models
export enum BlandModel {
  BASE = 'base',
  TURBO = 'turbo',
  ENHANCED = 'enhanced',
}

// /**
//  * Guide for how the AI should pronounce specific words
//  */
// export interface PronunciationGuide {
//   /** The word to be pronounced */
//   word: string;
//   /** The phonetic pronunciation guide */
//   pronunciation: string;
//   /** Whether the pronunciation is case sensitive */
//   case_sensitive?: boolean;
//   /** Whether spaces should be preserved in pronunciation */
//   spaced?: boolean;
// }

/**
 * Base interface with common fields for all template types
 */
export interface BaseBlandCallTemplate {
  /** Title of the template */
  title: string;
  /** Associated Finch task type */
  finch_task_type: CanonicalTaskType | null;
  /** Raw text prompt */
  prompt: string | null;
  /** HTML formatted prompt */
  prompt_html: string | null;
  /** JSON structured prompt data */
  prompt_json: string | null;
  /** Voice preset to use */
  voice: BlandVoicePreset | null;
  /** AI model to use */
  model: BlandModel | null;
  /** Controls randomness of AI responses (0-1) */
  temperature: number | null;
  /** Primary language for speech and transcription */
  language: string | null;
  /** First sentence the AI will speak */
  first_sentence: string | null;
  /** Whether AI waits for callee to speak first */
  wait_for_greeting: boolean | null;
  /** Whether to ignore caller interruptions */
  block_interruptions: boolean | null;
  /** Balance of AI response timing (50-200) */
  interruption_threshold: number | null;
  /** Background audio track during speech */
  background_track: BlandBackgroundTrack | null;
  /** Whether to apply noise filtering */
  noise_cancellation: boolean | null;
  /** Maximum call duration in minutes */
  max_duration: number | null;
  /** Action to take when voicemail detected */
  voicemail_action: BlandVoicemailAction | null;
  /** Message to leave if voicemail detected */
  voicemail_message: string | null;
  /** Custom pronunciation guides */
  pronunciation_guide: null;
  /** Possible tags for call disposition */
  available_tags: string[] | null;
  /** Group identifier for related tasks */
  task_group: string | null;
}

// Full template type with all fields (includes system fields)
export interface BlandCallTemplate extends BaseBlandCallTemplate {
  id: string;
  created_at: string;
  modified_at: string;
}

// Create template type (matches CreateCallTemplateSerializer)
export type CreateBlandCallTemplate = BaseBlandCallTemplate;

// Update template type (all fields optional)
export type UpdateBlandCallTemplate = Partial<BaseBlandCallTemplate>;

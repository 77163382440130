import { DetailedMatter } from '../../services/types/client-intake-types';
import IntakeCallSection from '../intake-form/IntakeCallSection';
import KeyConsiderations from '../key_considerations/KeyConsiderations';
import { Letters } from '../letters/Letters';
import Summaries from '../Summaries';

export const Matter = ({ matter }: { matter: DetailedMatter }) => {
  const hasSummaries = matter.summaries && matter.summaries.length > 0;
  const hasKeyConsiderations =
    matter.key_considerations && matter.key_considerations.length > 0;

  return (
    <div className="flex flex-col ">
      <Summaries />
      {hasKeyConsiderations && <KeyConsiderations />}
      <IntakeCallSection />
      <Letters matter={matter} />
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { Sparkles } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { useNotification } from '@/components/contexts/NotificationContext';
import { matterNotesApi } from '@/services/api/matterNotesService';
import {
  useExtractMatterNotesMutation,
  useGetExtractMatterNotesStatusQuery,
} from '@/services/api/matterNotesExtractionService';

interface MatterNotesHeaderProps {
  matterId: string;
}

export const MatterNotesHeader: React.FC<MatterNotesHeaderProps> = ({
  matterId,
}) => {
  const [extractNotes] = useExtractMatterNotesMutation();
  const [groupId, setGroupId] = useState<string | null>(null);
  const notify = useNotification();
  const dispatch = useDispatch();

  const { data: statusData } = useGetExtractMatterNotesStatusQuery(
    { matterId, groupId: groupId || '' },
    { skip: !groupId, pollingInterval: 2000 },
  );

  useEffect(() => {
    if (statusData?.status === 'SUCCESS' && statusData.completed) {
      const allTasksSuccessful = statusData.results?.every(
        (task) => task?.result?.success ?? false,
      );

      if (allTasksSuccessful) {
        notify('Successfully generated all matter notes', 'success', 3000);
        dispatch(
          matterNotesApi.util.invalidateTags([{ type: 'Notes', id: matterId }]),
        );
        setGroupId(null);
      } else {
        notify('Some note generation tasks failed', 'error', 3000);
        console.error('Some extraction tasks failed:', statusData.results);
        setGroupId(null);
      }
    } else if (statusData?.status === 'FAILURE') {
      notify('Failed to generate notes', 'error', 3000);
      console.error('Extraction failed:', statusData);
      setGroupId(null);
    }
  }, [statusData, notify, dispatch, matterId]);

  const handleGenerate = async () => {
    try {
      const response = await extractNotes({ matterId }).unwrap();
      setGroupId(response.group_id);
      notify('Generating all matter notes...', 'info', 3000);
    } catch (error) {
      notify('Failed to start note generation', 'error', 3000);
      console.error('Failed to extract notes:', error);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between leading-none py-2 border-b border-gray-100/80 mb-1">
        <div className="flex items-baseline gap-4">
          <h2 className="text-xl font-semibold text-gray-800">
            Matter Details
          </h2>
        </div>
        {/* Generate All Notes Button */}
        <div className="flex items-center gap-3 pl-1">
          <button
            onClick={handleGenerate}
            disabled={!!groupId}
            type="button"
            className={`group inline-flex items-center gap-1.5 text-xs font-medium px-3 py-1.5 rounded-full transition-all duration-300 ${
              groupId
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-white/95 border border-indigo-200/70 text-indigo-600 hover:bg-indigo-50/90 hover:text-indigo-700 hover:border-indigo-300/80 hover:shadow-sm hover:shadow-indigo-100/50 hover:scale-[1.02] active:scale-[0.98]'
            }`}
            aria-label={groupId ? 'Generating...' : 'Generate All'}
          >
            <Sparkles
              className={`h-3.5 w-3.5 transition-all duration-300 ${
                groupId
                  ? 'opacity-60'
                  : 'text-indigo-500 group-hover:text-indigo-600 group-hover:animate-pulse'
              }`}
            />
            <span className="tracking-tight font-medium ml-1">
              {groupId ? 'Generating...' : 'Generate All'}
            </span>
          </button>
        </div>
        {/* End of Generate All Notes Button */}
      </div>
    </div>
  );
};

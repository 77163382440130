import * as React from 'react';
import { format } from 'date-fns';
import { toast } from 'sonner';
import { CalendarRange, Phone, Trash } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Task } from '@/services/types/client-intake-types';
import { BlandSchedule } from '@/services/types/bland-schedules-types';
import {
  useDeleteBlandScheduleMutation,
  useGetBlandSchedulesByTaskIdQuery,
} from '@/services/api/blandScheduleService';
import { TaskSchedulesList } from './TaskSchedulesList';

interface TaskSchedulesProps {
  task: Task;
  onCreateSchedule: () => void;
}

// Helper function for formatting phone numbers
const formatPhoneNumber = (phoneNumber: string): string => {
  // Return original if empty or not a 10-digit number
  if (!phoneNumber || phoneNumber.length < 10) return phoneNumber;

  // Strip non-numeric characters
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Format as (XXX) XXX-XXXX for US numbers
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // Return original format if not matching expected pattern
  return phoneNumber;
};

export const TaskSchedules: React.FC<TaskSchedulesProps> = ({
  task,
  onCreateSchedule,
}) => {
  const {
    data: schedules,
    isLoading,
    refetch,
  } = useGetBlandSchedulesByTaskIdQuery(task.id);
  const [deleteSchedule, { isLoading: isDeleting }] =
    useDeleteBlandScheduleMutation();
  const [scheduleToDelete, setScheduleToDelete] = React.useState<string | null>(
    null,
  );

  const handleDeleteSchedule = async () => {
    if (scheduleToDelete) {
      try {
        await deleteSchedule(scheduleToDelete).unwrap();
        toast.success('Schedule deleted successfully');
        refetch();
      } catch (error) {
        console.error('Error deleting schedule:', error);
        toast.error('Failed to delete schedule');
      } finally {
        setScheduleToDelete(null);
      }
    }
  };

  // Helper to add ordinal suffix (1st, 2nd, 3rd, etc.)
  const getOrdinalSuffix = (day: number): string => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const mod = day % 100;
    const suffix = suffixes[(mod - 20) % 10] || suffixes[mod] || suffixes[0];
    return `${day}${suffix}`;
  };

  // Get the timezone label from the timezone string
  const getTimezoneLabel = (timezone: string): string => {
    const timezoneMap: Record<string, string> = {
      'America/New_York': 'Eastern Time (ET)',
      'America/Chicago': 'Central Time (CT)',
      'America/Denver': 'Mountain Time (MT)',
      'America/Los_Angeles': 'Pacific Time (PT)',
      'America/Anchorage': 'Alaska Time (AKT)',
      'America/Honolulu': 'Hawaii Time (HT)',
    };
    return timezoneMap[timezone] || timezone;
  };

  // Format the schedule time
  const formatScheduleTime = (schedule: BlandSchedule): string => {
    const hour = schedule.calendar_hour[0];
    const minute = schedule.calendar_minute[0];
    return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
  };

  // Format dates for display
  const formatDateRange = (
    startDate: string,
    endDate: string | null,
  ): string => {
    if (!startDate) return 'No date range specified';
    if (!endDate)
      return `Starting ${format(new Date(startDate), 'MMM d, yyyy')}`;

    return `${format(new Date(startDate), 'MMM d, yyyy')} to ${format(new Date(endDate), 'MMM d, yyyy')}`;
  };

  // Format days of month for display
  const formatDaysOfMonth = (daysOfMonth: number[]): string => {
    if (!daysOfMonth.length) return 'No days specified';

    // Sort the days numerically
    const sortedDays = [...daysOfMonth].sort((a, b) => a - b);

    // For 5 or fewer days, show the actual days
    if (sortedDays.length <= 5) {
      return sortedDays.map((day) => getOrdinalSuffix(day)).join(', ');
    }

    // For more than 5 days, show the count
    return `${sortedDays.length} days each month`;
  };

  // Render loading state
  if (isLoading) {
    return (
      <div className="py-3 border-t border-gray-200">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Scheduled Calls</h3>
          <Button
            onClick={onCreateSchedule}
            className="bg-primary text-white font-semibold"
          >
            <CalendarRange className="mr-2 h-4 w-4" />
            Schedule New Call
          </Button>
        </div>
        <div className="text-center p-4">Loading schedules...</div>
      </div>
    );
  }

  // Render empty state
  if (!schedules || schedules.length === 0) {
    return (
      <div className="py-3 border-t border-gray-200">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Scheduled Calls</h3>
        </div>
        <div className="text-center p-6 border border-dashed rounded-lg">
          <CalendarRange className="mx-auto h-8 w-8 text-gray-400 mb-2" />
          <p className="text-gray-500">No scheduled calls yet</p>
          <Button onClick={onCreateSchedule} variant="outline" className="mt-4">
            Schedule a Voice AI Call
          </Button>
        </div>
      </div>
    );
  }

  // Render schedules list
  return (
    <div className="py-3 border-t border-gray-200">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Scheduled Calls</h3>
        <Button
          onClick={onCreateSchedule}
          className="bg-primary text-white font-semibold"
        >
          <CalendarRange className="mr-2 h-4 w-4" />
          Schedule New Call
        </Button>
      </div>

      <TaskSchedulesList taskId={task.id} onEmpty={onCreateSchedule} />

      {/* Confirmation dialog for delete */}
      <AlertDialog
        open={!!scheduleToDelete}
        onOpenChange={(open) => !open && setScheduleToDelete(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Schedule</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this schedule? This action cannot
              be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteSchedule}
              className="bg-red-600 text-white hover:bg-red-700"
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

import { ChevronRight } from 'lucide-react';
import { useState } from 'react';
import { cn } from '@/lib/utils';
import { CanonicalTaskType } from '@/services/types/task-types';
import { useTemplateVariables } from '../context/TemplateContext';
import { templateVariables } from '../data/template-variables';
import {
  isCategoryAvailable as checkCategoryAvailability,
  getCategoryByDisplayName,
  groupVariablesByCategory,
} from '../data/variable-categories';
import type { DisplayCategoryName } from '../types';

interface TemplateDebugPanelProps {
  taskType: CanonicalTaskType;
}

const isCategoryAvailable = (
  category: DisplayCategoryName,
  taskType: CanonicalTaskType,
): boolean => {
  try {
    const categoryDef = getCategoryByDisplayName(category);
    return checkCategoryAvailability(categoryDef.id, taskType);
  } catch (error) {
    return false;
  }
};

export const TemplateDebugPanel = ({ taskType }: TemplateDebugPanelProps) => {
  const {
    values,
    setValues,
    usedVariables,
    hasUnpopulatedVariables,
    getUnpopulatedVariables,
  } = useTemplateVariables();

  const [expandedCategories, setExpandedCategories] = useState<
    Record<string, boolean>
  >({});

  const unpopulatedVars = getUnpopulatedVariables();
  const groupedVariables = groupVariablesByCategory(templateVariables);

  const toggleCategory = (category: string) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <div className="text-xs">
      {hasUnpopulatedVariables && (
        <div className="my-4 p-3 border border-red-200 rounded-lg bg-red-50">
          <h4 className="font-medium text-red-700">Unpopulated Variables</h4>
          <p className="mt-1 text-red-600/80 text-[10px]">
            These variables are not available for the selected task type
          </p>
          <div className="mt-2 grid grid-cols-2 gap-1">
            {unpopulatedVars.map((variable) => (
              <div key={variable} className="text-red-600">
                {variable}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="space-y-2">
        <div>
          <h3 className="font-medium">Available Variables</h3>
          <p className="text-xs text-gray-500">Type [ to insert</p>
        </div>
        <div className="space-y-1">
          {Object.entries(groupedVariables).map(([category, vars]) => {
            const usedInCategory = vars.some((v) => usedVariables.has(v));
            const unpopulatedInCategory = vars.some((v) =>
              unpopulatedVars.includes(v),
            );
            const isAvailable = isCategoryAvailable(
              category as DisplayCategoryName,
              taskType,
            );

            return (
              <div key={category}>
                <button
                  type="button"
                  onClick={() => toggleCategory(category)}
                  className={cn(
                    'w-full flex items-center justify-between py-1 px-2 rounded hover:bg-gray-100 transition-colors',
                    usedInCategory && 'font-medium',
                    unpopulatedInCategory && 'text-red-600',
                    !isAvailable && 'opacity-50',
                  )}
                >
                  <span className="flex items-center gap-1">
                    <ChevronRight
                      className={cn(
                        'h-3 w-3 transition-transform',
                        expandedCategories[category] && 'transform rotate-90',
                      )}
                    />
                    {category}
                    {!isAvailable && (
                      <span className="text-xs text-gray-500 ml-1">
                        (unavailable)
                      </span>
                    )}
                  </span>
                  <div className="flex items-center gap-2 text-xs">
                    {usedInCategory && !unpopulatedInCategory && (
                      <div className="w-1.5 h-1.5 rounded-full bg-purple-500" />
                    )}
                    {unpopulatedInCategory && (
                      <span className="px-1.5 py-0.5 rounded-full bg-red-100 text-red-900">
                        !
                      </span>
                    )}
                  </div>
                </button>
                {expandedCategories[category] && (
                  <div className="ml-4 mt-1 space-y-0.5">
                    {vars.map((variable) => {
                      const isUsed = usedVariables.has(variable);
                      const isUnpopulated = unpopulatedVars.includes(variable);
                      const value = values[variable];

                      return (
                        <div
                          key={variable}
                          className={cn(
                            'group flex flex-col py-1 px-2 rounded',
                            isUsed ? 'bg-purple-50' : 'hover:bg-gray-100',
                            isUnpopulated && 'text-red-600',
                          )}
                        >
                          <span className="font-medium">{variable}</span>
                          <span
                            className={cn(
                              'text-[10px]',
                              isUsed ? 'text-gray-500' : 'text-gray-400',
                            )}
                          >
                            {value || '(no value)'}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

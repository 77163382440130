import Fuse from 'fuse.js';
import {
  Bot,
  Loader2,
  MoreHorizontal,
  PanelLeft,
  Pencil,
  Plus,
  Search,
  Trash,
  X,
} from 'lucide-react';

import React, { useEffect, useState } from 'react';
import { cn } from '../../lib/utils';
import {
  CreateMatterThreadRequest,
  MatterThread,
  useCreateMatterThreadMutation,
  useDeleteMatterThreadMutation,
  useGetMatterThreadsQuery,
  useUpdateMatterThreadMutation,
} from '../../services/api/matterAssistant';
import { useNotification } from '../contexts/NotificationContext';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Input } from '../ui/input';
import { Separator } from '../ui/separator';

// Time breakpoint definitions
type TimeBreakpoint = {
  id: string;
  title: string;
  daysAgo: number;
};

const TIME_BREAKPOINTS: TimeBreakpoint[] = [
  { id: 'recent', title: 'Today', daysAgo: 1 },
  { id: 'week', title: 'Last 7 days', daysAgo: 7 },
  { id: 'month', title: 'Last 30 days', daysAgo: 30 },
  { id: 'older', title: 'Older', daysAgo: Infinity },
];

// Hook to group threads by time breakpoints
const useThreadsWithTimeBreakpoints = (threads: MatterThread[] | undefined) => {
  const groupedThreads = React.useMemo(() => {
    if (!threads || threads.length === 0) {
      return {};
    }

    // Sort threads by modified_at date (newest first)
    const sortedThreads = [...threads].sort((a, b) => {
      return (
        new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime()
      );
    });

    // Initialize result object with empty arrays for each breakpoint
    const result: Record<string, MatterThread[]> = {};
    TIME_BREAKPOINTS.forEach((breakpoint) => {
      result[breakpoint.id] = [];
    });

    // Calculate date thresholds
    const now = new Date();
    const thresholds = TIME_BREAKPOINTS.map((breakpoint) => {
      const date = new Date(now);
      date.setDate(date.getDate() - breakpoint.daysAgo);
      return { id: breakpoint.id, date };
    });

    // Group threads by time breakpoints
    sortedThreads.forEach((thread) => {
      const threadDate = new Date(thread.modified_at);

      // Find the first threshold that this thread's date is after
      for (let i = 0; i < thresholds.length; i += 1) {
        if (i === thresholds.length - 1 || threadDate >= thresholds[i].date) {
          result[thresholds[i].id].push(thread);
          break;
        }
      }
    });

    return result;
  }, [threads]);

  return { groupedThreads, breakpoints: TIME_BREAKPOINTS };
};

const useThreads = ({ matterId }: { matterId: string }) => {
  const {
    data: threads,
    isLoading,
    error,
  } = useGetMatterThreadsQuery(matterId);
  return { threads, isLoading, error };
};

const ThreadButton = ({
  thread,
  openThreadId,
  handleThreadClick,
  matterId,
  onThreadDeleted,
}: {
  thread: MatterThread;
  openThreadId: string | null;
  handleThreadClick: (thread: MatterThread) => void;
  matterId: string;
  onThreadDeleted?: (threadId: string) => void;
}) => {
  const notification = useNotification();
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState(thread.title);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [updateThread, { isLoading: isUpdating }] =
    useUpdateMatterThreadMutation();
  const [deleteThread, { isLoading: isDeleting }] =
    useDeleteMatterThreadMutation();

  const getButtonClassName = (threadId: string) => {
    return cn('w-full flex flex-row justify-start', {
      'bg-gray-200 hover:bg-gray-200': openThreadId === threadId,
      'bg-gray-100': menuOpen && openThreadId !== threadId,
      'hover:bg-gray-100': !menuOpen && openThreadId !== threadId,
    });
  };

  const handleRenameClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setNewTitle(thread.title);
    setRenameDialogOpen(true);
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const handleRenameSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newTitle.trim() === '') {
      notification('Thread title cannot be empty', 'error', 3000);
      return;
    }

    if (newTitle.trim() === thread.title) {
      setRenameDialogOpen(false);
      return;
    }

    try {
      await updateThread({
        matterId,
        threadId: thread.id,
        data: { title: newTitle.trim() },
      }).unwrap();
      notification('Thread renamed successfully', 'success', 3000);
      setRenameDialogOpen(false);
    } catch (error) {
      notification('Failed to rename thread', 'error', 3000);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteThread({
        matterId,
        threadId: thread.id,
      }).unwrap();
      notification('Thread deleted successfully', 'success', 3000);
      setDeleteDialogOpen(false);

      // Call the onThreadDeleted callback if provided
      if (onThreadDeleted) {
        onThreadDeleted(thread.id);
      }
    } catch (error) {
      notification('Failed to delete thread', 'error', 3000);
    }
  };

  return (
    <div key={thread.id} className="relative group">
      <Button
        variant="ghost"
        className={getButtonClassName(thread.id)}
        onClick={() => handleThreadClick(thread)}
      >
        <span className="text-ellipsis overflow-hidden text-xs">
          {thread.title}
        </span>
      </Button>
      <div
        className={cn(
          'absolute right-1 top-1/2 -translate-y-1/2 transition-colors opacity-0 group-hover:opacity-100',
          menuOpen ? 'opacity-100' : 'opacity-0',
        )}
      >
        <DropdownMenu
          onOpenChange={(open) => {
            if (open) {
              setMenuOpen(true);
            } else if (menuOpen) {
              setMenuOpen(false);
            }
          }}
        >
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8 p-0"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">More options</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            data-side="left"
            align="start"
            className="bg-white"
          >
            <DropdownMenuItem
              className="hover:bg-gray-100 text-xs flex items-center gap-2"
              onClick={handleRenameClick}
            >
              <Pencil className="h-3 w-3" />
              Rename
            </DropdownMenuItem>
            <DropdownMenuItem
              className="text-red-600 hover:bg-red-100 text-xs flex items-center gap-2"
              onClick={handleDeleteClick}
            >
              <Trash className="h-3 w-3" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {/* Rename Dialog */}
      <Dialog open={renameDialogOpen} onOpenChange={setRenameDialogOpen}>
        <DialogContent className="sm:max-w-md bg-white">
          <DialogHeader>
            <DialogTitle>Rename Thread</DialogTitle>
            <DialogDescription>
              Enter a new name for this thread
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleRenameSubmit}>
            <div className="grid gap-4 py-4">
              <Input
                ref={inputRef}
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                placeholder="Thread title"
                className="col-span-3"
                autoFocus
              />
            </div>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setRenameDialogOpen(false)}
                disabled={isUpdating}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="outline"
                className="bg-primary text-white hover:bg-primary-dark"
                disabled={isUpdating}
              >
                {isUpdating ? 'Saving...' : 'Save Changes'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <DialogContent className="sm:max-w-md bg-white">
          <DialogHeader>
            <DialogTitle>Delete Thread</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this thread? This action cannot be
              undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              type="button"
              variant="outline"
              onClick={() => setDeleteDialogOpen(false)}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="outline"
              className="bg-red-600 text-white hover:bg-red-700"
              onClick={handleDeleteConfirm}
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export const ThreadSidebar = ({
  matterId,
  onThreadSelected,
  toggleCollapsed,
}: {
  matterId: string;
  onThreadSelected: (thread: MatterThread | null) => void;
  toggleCollapsed: () => void;
}) => {
  const notification = useNotification();
  const { threads, isLoading, error } = useThreads({ matterId });
  const { groupedThreads, breakpoints } =
    useThreadsWithTimeBreakpoints(threads);
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null);
  const [createThread, { isLoading: isCreating }] =
    useCreateMatterThreadMutation();
  const [searchQuery, setSearchQuery] = useState('');

  // Create a Fuse instance for fuzzy searching
  const fuse = React.useMemo(() => {
    if (!threads) return null;
    return new Fuse(threads, {
      keys: ['title'],
      threshold: 0.3,
      ignoreLocation: true,
    });
  }, [threads]);

  const handleThreadClick = (thread: MatterThread) => {
    setSelectedThreadId(thread.id);
    onThreadSelected(thread);
  };

  const handleThreadDeleted = (threadId: string) => {
    // If the deleted thread is the currently selected one, set selected thread to null
    if (selectedThreadId === threadId) {
      setSelectedThreadId(null);
      onThreadSelected(null); // Pass null to indicate no thread is selected
    }
  };

  const handleCreateThread = async () => {
    try {
      const newThreadData: CreateMatterThreadRequest = {
        title: 'New Thread',
      };

      const result = await createThread({
        matterId,
        data: newThreadData,
      }).unwrap();

      notification('Thread created successfully', 'success', 3000);

      // Select the newly created thread
      if (result && result.id) {
        handleThreadClick(result);
      }
    } catch (err) {
      notification('Failed to create thread', 'error', 3000);
    }
  };

  useEffect(() => {
    if (error) {
      notification('Error fetching threads', 'error', 5000);
    }
  }, [error, notification]);

  // Filter threads based on search query using Fuse.js
  const filteredThreads = React.useMemo(() => {
    if (!searchQuery.trim() || !fuse) {
      return threads || [];
    }

    return fuse.search(searchQuery).map((result) => result.item);
  }, [searchQuery, fuse, threads]);

  // Group filtered threads by time breakpoints when searching
  const filteredGroupedThreads = React.useMemo(() => {
    if (!searchQuery.trim()) {
      return groupedThreads;
    }

    // Initialize result object with empty arrays for each breakpoint
    const result: Record<string, MatterThread[]> = {};
    breakpoints.forEach((breakpoint) => {
      result[breakpoint.id] = [];
    });

    // Group filtered threads by time breakpoints
    filteredThreads.forEach((thread) => {
      // Find which breakpoint this thread belongs to in the original grouping
      let threadAdded = false;
      Object.keys(groupedThreads).forEach((breakpointId) => {
        if (threadAdded) return; // Skip if thread already added to a group

        const threadsInGroup = groupedThreads[breakpointId];
        if (threadsInGroup.some((t) => t.id === thread.id)) {
          result[breakpointId].push(thread);
          threadAdded = true;
        }
      });
    });

    return result;
  }, [searchQuery, filteredThreads, groupedThreads, breakpoints]);

  // Determine which grouped threads to display
  const displayGroupedThreads = searchQuery.trim()
    ? filteredGroupedThreads
    : groupedThreads;

  return (
    <div className={cn('flex flex-row h-full w-full')}>
      <div className={cn('w-full h-full flex flex-col gap-y-2')}>
        {/* Thread Sidebar Header */}
        <div className="@max-[8em]:hidden flex flex-col gap-y-2">
          <div className="w-full flex flex-row items-center gap-2 px-3 pt-3 justify-between ">
            <div className="flex flex-row items-center gap-2">
              <Bot className="w-4 h-4" />
              <span>FinchGPT</span>
            </div>

            <div className="flex flex-row items-center mr-5 md:mr-0">
              <Button
                variant="ghost"
                size="icon"
                onClick={handleCreateThread}
                disabled={isCreating}
              >
                <Plus className="w-4 h-4" />
              </Button>
              <Button variant="ghost" size="icon" onClick={toggleCollapsed}>
                <PanelLeft className="h-3 w-3" />
              </Button>
            </div>
          </div>
          <Separator orientation="horizontal" className="h-full" />
        </div>

        {/* Search Input */}
        <div className="px-3 pt-2 @max-[8em]:hidden">
          <div className="relative">
            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-3 w-3 text-gray-400" />
            <Input
              placeholder="Search threads..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-8 text-xs h-8"
            />
            {searchQuery && (
              <Button
                variant="ghost"
                size="icon"
                className="absolute right-1 top-1/2 transform -translate-y-1/2 h-6 w-6 p-0"
                onClick={() => setSearchQuery('')}
              >
                <X className="h-3 w-3" />
              </Button>
            )}
          </div>
        </div>

        {/* Thread Sidebar Content */}
        <div className="w-full h-full overflow-y-auto grow-1 overflow-x-hidden scrollbar px-3 gap-y-2 flex flex-col @max-[8em]:hidden">
          {(() => {
            if (isLoading) {
              return (
                <div className="w-full h-24 flex flex-col items-center justify-center">
                  <Loader2 className="h-5 w-5 animate-spin text-gray-400" />
                  <span className="text-xs text-gray-400 mt-2">
                    Loading threads...
                  </span>
                </div>
              );
            }

            if (threads && threads.length > 0) {
              if (filteredThreads.length === 0 && searchQuery) {
                return (
                  <div className="w-full h-24 flex flex-col items-center justify-center">
                    <span className="text-xs text-gray-400">
                      No threads match your search
                    </span>
                  </div>
                );
              }

              // Display threads grouped by time breakpoints
              return breakpoints.map((breakpoint) => {
                const threadsInGroup =
                  displayGroupedThreads[breakpoint.id] || [];

                // Skip empty groups
                if (threadsInGroup.length === 0) {
                  return null;
                }

                return (
                  <div key={breakpoint.id} className="mb-4">
                    <div className="text-xs font-medium text-gray-500 mb-1 mt-2">
                      {breakpoint.title}
                    </div>
                    {threadsInGroup.map((thread) => (
                      <ThreadButton
                        key={thread.id}
                        thread={thread}
                        openThreadId={selectedThreadId}
                        handleThreadClick={handleThreadClick}
                        matterId={matterId}
                        onThreadDeleted={handleThreadDeleted}
                      />
                    ))}
                  </div>
                );
              });
            }

            return (
              <div className="w-full h-full flex flex-col items-center justify-start pt-6">
                <span className="text-sm text-gray-500">
                  No threads created yet
                </span>
              </div>
            );
          })()}
        </div>
      </div>
      <Separator orientation="vertical" className="h-full @max-[8em]:hidden" />
    </div>
  );
};

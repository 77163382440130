import { ArrowDownToLine, Image, Loader2, X } from 'lucide-react';
import React from 'react';

import { useGetEditorFileQuery } from '../../../../../services/api/demandService';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../ui/tooltip';
import { ImageContentProps } from '../../types';

export const ImageContent: React.FC<ImageContentProps> = ({
  matterId,
  demandId,
  threadId,
  imageId,
}) => {
  const { data: file, isLoading: isFileLoading } = useGetEditorFileQuery({
    matterId,
    demandId,
    threadId,
    fileId: imageId,
  });

  if (isFileLoading) {
    return (
      <div className="inline-flex items-center gap-2 px-3 py-2 border rounded-md bg-muted/30">
        <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
        <span className="text-sm text-muted-foreground">Loading file...</span>
      </div>
    );
  }

  if (!file?.url) {
    return (
      <div className="inline-flex items-center gap-2 px-3 py-2 border rounded-md bg-destructive/10 text-destructive text-sm">
        <X className="h-4 w-4" />
        <span>Failed to load file</span>
      </div>
    );
  }

  return (
    <div className="inline-flex items-center gap-3 px-3 py-2 border rounded-md bg-muted/30 hover:bg-muted/50 transition-colors group">
      {/* File Icon */}
      <div className="text-primary/70">
        <Image className="h-4 w-4" />
      </div>

      {/* Filename */}
      <span className="text-sm text-foreground/90 truncate max-w-[200px]">
        {file.file_name || 'Unnamed file'}
      </span>

      {/* Download Button */}
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <a
              href={file.url}
              download={file.file_name}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex h-6 w-6 items-center justify-center rounded-full hover:bg-background/90 text-muted-foreground hover:text-primary transition-colors"
            >
              <ArrowDownToLine className="h-4 w-4" />
            </a>
          </TooltipTrigger>
          <TooltipContent side="top">
            <p>Download file</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

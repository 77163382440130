interface EditableTitleProps {
  title: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export const EditableTitle: React.FC<EditableTitleProps> = ({
  title,
  onChange,
  placeholder = 'Enter template title',
}) => {
  return (
    <div>
      <span className="text-xs text-gray-500 font-semibold uppercase mb-2 block">
        TEMPLATE TITLE <span className="text-red-500">*</span>
      </span>
      <input
        type="text"
        value={title}
        onChange={(e) => onChange(e.target.value)}
        className="text-xl font-bold w-full border ring-0 border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-primary"
        placeholder={placeholder}
      />
    </div>
  );
};

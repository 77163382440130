import { AlertCircle, ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import { DetailedMatter } from '@/services/types/client-intake-types';

// Types
interface Client {
  id: string;
  name: string;
  phone_numbers?: Array<{ phone_number: string }>;
  email?: string;
  date_of_birth?: string;
  last_four_of_ssn?: string;
  address?: {
    line1?: string;
    line2?: string;
    line3?: string;
    city?: string;
    state?: string;
    postcode?: string;
    country?: string;
  };
}

interface KeyConsideration {
  id: string;
  category: string;
  content: string;
}

interface MatterSummary {
  id: string;
  category: 'Accident' | 'Damages';
  content: string;
}

interface IncidentNote {
  id: string;
  description: string | null;
  date_of_loss: string | null;
  time_of_loss: string | null;
  location: string | null;
  weather_conditions: string | null;
  injuries_described: string | null;
  incident_type: string | null;
  hospital: string | null;
  ambulance: string | null;
  police_report: string | null;
  current_treatment: string | null;
  witnesses: string | null;
  statements: string | null;
  photos: string | null;
  representation: string | null;
}

interface EmergencyContactNote {
  id: string;
  name: string | null;
  phone: string | null;
  email: string | null;
  address: string | null;
  date_of_birth: string | null;
  last_four_of_ssn: string | null;
}

// Section Header Component
const SectionHeader = ({
  title,
  isOpen,
}: {
  title: string;
  isOpen: boolean;
}) => (
  <div className="mb-2.5 flex items-center space-x-2">
    <h4 className="text-primary flex items-center gap-2 text-xs font-medium tracking-wide uppercase">
      {title}
      <ChevronDown
        className={`text-muted-foreground h-4 w-4 transition-transform ${
          isOpen ? 'rotate-180' : ''
        }`}
      />
    </h4>
    <div className="h-[1px] flex-grow bg-gray-100" />
  </div>
);

// Client Card Component
const ClientCard = ({ client }: { client: Client }) => (
  <Card className="overflow-hidden bg-white shadow-sm transition-shadow duration-200 hover:shadow">
    <CardContent className="p-3">
      <div className="flex flex-col space-y-1.5">
        <div className="text-xs font-medium">{client.name}</div>
        {client.phone_numbers?.[0] && (
          <div className="text-xs flex items-center gap-1">
            <span className="text-[10px] text-muted-foreground">Phone:</span>
            {client.phone_numbers[0].phone_number}
          </div>
        )}
        {client.email && (
          <div className="text-xs flex items-center gap-1">
            <span className="text-[10px] text-muted-foreground">Email:</span>{' '}
            {client.email}
          </div>
        )}
      </div>
    </CardContent>
  </Card>
);

// Key Considerations Component
const KeyConsiderationCard = ({
  consideration,
}: {
  consideration: KeyConsideration;
}) => (
  <Card className="overflow-hidden bg-white shadow-sm transition-shadow duration-200 hover:shadow">
    <CardContent className="p-3">
      <div className="flex flex-col space-y-1.5">
        <Badge variant="outline" className="w-fit text-[10px] py-0 h-5">
          {consideration.category}
        </Badge>
        <div className="text-xs leading-relaxed">{consideration.content}</div>
      </div>
    </CardContent>
  </Card>
);

// Summary Card Component
const SummaryCard = ({ content }: { content: string }) => (
  <Card className="overflow-hidden bg-white shadow-sm transition-shadow duration-200 hover:shadow">
    <CardContent className="p-3">
      <div className="text-xs leading-relaxed">{content}</div>
    </CardContent>
  </Card>
);

// Field Component for detailed information
const DetailField = ({
  label,
  value,
}: {
  label: string;
  value: string | null | undefined;
}) => {
  if (!value) return null;

  return (
    <div className="mb-2.5">
      <div className="flex flex-col">
        <span className="text-[10px]  tracking-wide font-semibold text-muted-foreground">
          {label}
        </span>
        <span className="text-xs leading-relaxed">{value}</span>
      </div>
    </div>
  );
};

// Personal Information Card Component
const PersonalInfoCard = ({ client }: { client: Client }) => {
  const formatAddress = (address: Client['address']) => {
    if (!address) return undefined;
    const parts = [
      address.line1,
      address.line2,
      address.line3,
      address.city,
      address.state,
      address.postcode,
    ].filter(Boolean);
    return parts.length > 0 ? parts.join(', ') : undefined;
  };

  return (
    <Card className="overflow-hidden bg-white shadow-sm transition-shadow duration-200 hover:shadow">
      <CardContent className="p-3">
        <div className="flex flex-col space-y-2.5">
          <DetailField label="Name" value={client.name} />
          <DetailField label="Email" value={client.email} />
          <DetailField
            label="Phone"
            value={client.phone_numbers?.[0]?.phone_number}
          />
          <DetailField label="Address" value={formatAddress(client.address)} />
          <DetailField label="Date of Birth" value={client.date_of_birth} />
          <DetailField label="Last 4 of SSN" value={client.last_four_of_ssn} />
        </div>
      </CardContent>
    </Card>
  );
};

// Incident Details Card Component
const IncidentDetailsCard = ({ note }: { note: IncidentNote }) => {
  const getDayOfWeek = (dateString: string | null | undefined): string => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { weekday: 'long' });
    } catch (error) {
      return '';
    }
  };

  return (
    <Card className="overflow-hidden bg-white shadow-sm transition-shadow duration-200 hover:shadow">
      <CardContent className="p-3">
        <div className="flex flex-col space-y-2.5">
          <DetailField label="Date of Loss" value={note.date_of_loss} />
          <DetailField
            label="Day of Week"
            value={getDayOfWeek(note.date_of_loss)}
          />
          <DetailField label="Time of Loss" value={note.time_of_loss} />
          <DetailField label="Location" value={note.location} />
          <DetailField
            label="Weather Conditions"
            value={note.weather_conditions}
          />
          <DetailField label="Accident Type" value={note.incident_type} />
          <DetailField label="Description" value={note.description} />
          <DetailField
            label="Injuries Described"
            value={note.injuries_described}
          />
          <DetailField label="Hospital" value={note.hospital} />
          <DetailField label="Ambulance" value={note.ambulance} />
          <DetailField
            label="Current Treatment"
            value={note.current_treatment}
          />
          <DetailField label="Police Report" value={note.police_report} />
          <DetailField label="Witnesses" value={note.witnesses} />
          <DetailField label="Statements" value={note.statements} />
          <DetailField label="Photos" value={note.photos} />
          <DetailField label="Representation" value={note.representation} />
        </div>
      </CardContent>
    </Card>
  );
};

// Emergency Contact Card Component
const EmergencyContactCard = ({ note }: { note: EmergencyContactNote }) => {
  return (
    <Card className="overflow-hidden bg-white shadow-sm transition-shadow duration-200 hover:shadow">
      <CardContent className="p-3">
        <div className="flex flex-col space-y-2.5">
          <DetailField label="Name" value={note.name} />
          <DetailField label="Phone" value={note.phone} />
          <DetailField label="Email" value={note.email} />
          <DetailField label="Address" value={note.address} />
          <DetailField label="Date of Birth" value={note.date_of_birth} />
          <DetailField label="Last 4 of SSN" value={note.last_four_of_ssn} />
        </div>
      </CardContent>
    </Card>
  );
};

// Loading State Component
const LoadingState = () => (
  <div className="animate-in fade-in-0 space-y-4 px-1 duration-300">
    <div className="space-y-2">
      <Skeleton className="h-5 w-28 rounded-md" />
      <div className="mt-2 space-y-2">
        <div className="flex flex-col space-y-1.5 rounded-md border border-gray-100 p-3">
          <Skeleton className="h-3.5 w-1/3 rounded-md" />
          <Skeleton className="h-3 w-1/2 rounded-md" />
        </div>
        <div className="flex flex-col space-y-1.5 rounded-md border border-gray-100 p-3">
          <Skeleton className="h-3.5 w-1/3 rounded-md" />
          <Skeleton className="h-3 w-2/3 rounded-md" />
        </div>
      </div>
    </div>

    <div className="space-y-2">
      <Skeleton className="h-5 w-32 rounded-md" />
      <div className="mt-2 space-y-2">
        <div className="rounded-md border border-gray-100 p-3">
          <Skeleton className="h-3.5 w-full rounded-md" />
          <Skeleton className="mt-1.5 h-3.5 w-3/4 rounded-md" />
        </div>
      </div>
    </div>
  </div>
);

// Main MatterDetailsSection component
interface MatterDetailsSectionProps {
  detailsLoading: boolean;
  detailsError: Error | null;
  matterDetails: DetailedMatter | null;
}

export const MatterDetailsSection = ({
  detailsLoading,
  detailsError,
  matterDetails,
}: MatterDetailsSectionProps) => {
  const [clientsOpen, setClientsOpen] = useState(true);
  const [personalInfoOpen, setPersonalInfoOpen] = useState(true);
  const [considerationsOpen, setConsiderationsOpen] = useState(true);
  const [incidentDetailsOpen, setIncidentDetailsOpen] = useState(true);
  const [accidentSummaryOpen, setAccidentSummaryOpen] = useState(true);
  const [finchEvaluationOpen, setFinchEvaluationOpen] = useState(true);
  const [emergencyContactOpen, setEmergencyContactOpen] = useState(true);

  // Using a generic type parameter to handle any object type
  const hasPopulatedFields = <T extends object>(
    obj: T | null | undefined,
  ): boolean => {
    if (!obj) return false;
    return Object.values(obj).some((val): boolean => {
      if (val === null || val === undefined || val === '') return false;
      if (typeof val === 'object') return hasPopulatedFields(val as object);
      return true;
    });
  };

  return (
    <div className="animate-in fade-in-50 slide-in-from-top-2 p-3 duration-200">
      {detailsLoading && <LoadingState />}

      {detailsError && (
        <Alert variant="destructive" className="px-2 py-1.5 text-xs">
          <AlertCircle className="mr-1.5 h-3 w-3" />
          <AlertDescription>Error loading matter details</AlertDescription>
        </Alert>
      )}

      {!detailsLoading && !detailsError && matterDetails && (
        <div className="space-y-4">
          {matterDetails.clients && matterDetails.clients.length > 0 && (
            <div className="animate-in fade-in-50 delay-100 duration-200">
              <Collapsible open={clientsOpen} onOpenChange={setClientsOpen}>
                <CollapsibleTrigger asChild className="w-full cursor-pointer">
                  <div>
                    <SectionHeader
                      title="Client Information"
                      isOpen={clientsOpen}
                    />
                  </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <div className="mt-2 space-y-2">
                    {matterDetails.clients.map((client) => (
                      <ClientCard key={client.id} client={client} />
                    ))}
                  </div>
                </CollapsibleContent>
              </Collapsible>
            </div>
          )}

          {matterDetails.incident_note &&
            hasPopulatedFields(matterDetails.incident_note) && (
              <div className="animate-in fade-in-50 delay-175 duration-200">
                <Collapsible
                  open={incidentDetailsOpen}
                  onOpenChange={setIncidentDetailsOpen}
                >
                  <CollapsibleTrigger asChild className="w-full cursor-pointer">
                    <div>
                      <SectionHeader
                        title="Accident Details"
                        isOpen={incidentDetailsOpen}
                      />
                    </div>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div className="mt-2 space-y-2">
                      <IncidentDetailsCard note={matterDetails.incident_note} />
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              </div>
            )}

          {matterDetails.summaries &&
            matterDetails.summaries.some(
              (summary) => summary.category === 'Accident',
            ) && (
              <div className="animate-in fade-in-50 delay-200 duration-200">
                <Collapsible
                  open={accidentSummaryOpen}
                  onOpenChange={setAccidentSummaryOpen}
                >
                  <CollapsibleTrigger asChild className="w-full cursor-pointer">
                    <div>
                      <SectionHeader
                        title="Accident Summary"
                        isOpen={accidentSummaryOpen}
                      />
                    </div>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div className="mt-2 space-y-2">
                      <SummaryCard
                        content={
                          matterDetails.summaries.find(
                            (summary) => summary.category === 'Accident',
                          )?.content || ''
                        }
                      />
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              </div>
            )}

          {matterDetails.intake_note && (
            <div className="animate-in fade-in-50 delay-225 duration-200">
              <Collapsible
                open={finchEvaluationOpen}
                onOpenChange={setFinchEvaluationOpen}
              >
                <CollapsibleTrigger asChild className="w-full cursor-pointer">
                  <div>
                    <SectionHeader
                      title="Finch Evaluation"
                      isOpen={finchEvaluationOpen}
                    />
                  </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <div className="mt-2 space-y-2">
                    <SummaryCard content={matterDetails.intake_note} />
                  </div>
                </CollapsibleContent>
              </Collapsible>
            </div>
          )}

          {matterDetails.key_considerations &&
            matterDetails.key_considerations.length > 0 && (
              <div className="animate-in fade-in-50 delay-250 duration-200">
                <Collapsible
                  open={considerationsOpen}
                  onOpenChange={setConsiderationsOpen}
                >
                  <CollapsibleTrigger asChild className="w-full cursor-pointer">
                    <div>
                      <SectionHeader
                        title="Key Considerations"
                        isOpen={considerationsOpen}
                      />
                    </div>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div className="mt-2 space-y-2">
                      {matterDetails.key_considerations.map((consideration) => (
                        <KeyConsiderationCard
                          key={consideration.id}
                          consideration={consideration}
                        />
                      ))}
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

import React, { createContext, useContext } from 'react';

import { useSendMessage } from '../../../../hooks/useDemandEditorChat';
import { useListEditorMessagesQuery } from '../../../../services/api/demandService';
import { MessageContextType } from '../types';

const MessageContext = createContext<MessageContextType | undefined>(undefined);

export const useMessages = (threadId?: string) => {
  const context = useContext(MessageContext);
  if (context === undefined) {
    throw new Error('useMessages must be used within a MessageProvider');
  }
  return context;
};

export const MessageProvider: React.FC<{
  children: React.ReactNode;
  matterId: string;
  demandId: string;
  threadId: string;
}> = ({ children, matterId, demandId, threadId }) => {
  const { data: messages, isLoading: isLoadingMessages } =
    useListEditorMessagesQuery(
      { matterId, demandId, threadId },
      { skip: !threadId },
    );

  const { sendMessage, isLoading } = useSendMessage(
    matterId,
    demandId,
    threadId,
  );

  const contextValue = React.useMemo(
    () => ({
      messages,
      isLoadingMessages,
      sendMessage,
      isLoading,
    }),
    [messages, isLoadingMessages, sendMessage, isLoading],
  );

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  );
};

import React, { useEffect, useState } from 'react';

import { ProofreadingJobStartResponse } from '../../../services/types/proofreading-types';
import { ProofreadingEmptyState } from '../common/ProofreadingEmptyState';
import { ProofreadingSkeleton } from '../common/ProofreadingSkeleton';
import { useProofreadingJob } from '../hooks/useProofreadingJob';
import { CreateJobButton } from './CreateJobButton';
import { JobCard } from './JobCard';

interface JobListProps {
  demandId: string;
  selectedJobId?: string;
  onSelectJob: (jobId: string) => void;
  onJobCreated?: () => void;
}

export const JobList: React.FC<JobListProps> = ({
  demandId,
  selectedJobId,
  onSelectJob,
  onJobCreated,
}) => {
  const { jobsList, isLoading, error, refreshJob } =
    useProofreadingJob(demandId);

  // Forced refresh counter to trigger additional refreshes
  const [refreshCounter, setRefreshCounter] = useState(0);
  // Track if we're in a rapid refresh cycle after job creation
  const [isRapidRefreshing, setIsRapidRefreshing] = useState(false);

  // Effect to handle refreshing
  useEffect(() => {
    refreshJob();

    // Slower polling interval for normal state
    const pollingInterval = setInterval(
      () => {
        refreshJob();
      },
      isRapidRefreshing ? 500 : 3000,
    );

    return () => clearInterval(pollingInterval);
  }, [refreshJob, refreshCounter, isRapidRefreshing]);

  // Effect for short burst of rapid refreshes after job creation
  useEffect(() => {
    if (isRapidRefreshing) {
      // Refresh rapidly for a short period after job creation
      const rapidRefreshTimeout = setTimeout(() => {
        setIsRapidRefreshing(false);
      }, 5000); // Rapid refresh for 5 seconds

      return () => clearTimeout(rapidRefreshTimeout);
    }
    return undefined; // Add explicit return for when isRapidRefreshing is false
  }, [isRapidRefreshing]);

  const handleJobCreated = (response: ProofreadingJobStartResponse) => {
    // Trigger rapid refresh mode
    setIsRapidRefreshing(true);

    // Force immediate refresh and start polling
    setRefreshCounter((count) => count + 1);

    // Immediately refresh
    refreshJob();

    // Call parent callback if provided
    if (onJobCreated) {
      onJobCreated();
    }
  };

  if (isLoading && !jobsList?.length) {
    return <ProofreadingSkeleton type="job-list" count={3} />;
  }

  if (error && !jobsList?.length) {
    return (
      <div className="p-4 border border-red-200 rounded-md bg-red-50 text-red-800">
        <p className="text-sm">
          Failed to load proofreading jobs. Please try again.
        </p>
      </div>
    );
  }

  // Check if we have any jobs to display
  if (!jobsList || jobsList.length === 0) {
    return (
      <div className="space-y-4">
        <div className="flex justify-end">
          <CreateJobButton
            demandId={demandId}
            onJobCreated={handleJobCreated}
          />
        </div>
        <ProofreadingEmptyState
          type="jobs"
          onAction={() => {
            const button = document.querySelector(
              'button[aria-label="Start Proofreading"]',
            );
            if (button) {
              (button as HTMLButtonElement).click();
            }
          }}
        />
      </div>
    );
  }

  // Sort jobs by created_at, newest first
  const sortedJobs = [...jobsList].sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  return (
    <div className="space-y-4">
      <div>
        <div className="flex justify-between items-center pb-2">
          <h3 className="text-lg font-medium">Proofreading</h3>
          <CreateJobButton
            demandId={demandId}
            onJobCreated={handleJobCreated}
          />
        </div>
        <div className="h-px bg-border w-full mb-3" />
      </div>

      <div className="space-y-3">
        {sortedJobs.map((job) => (
          <JobCard
            key={job.job_id}
            job={job}
            isSelected={job.job_id === selectedJobId}
            onClick={() => onSelectJob(job.job_id)}
          />
        ))}
      </div>
    </div>
  );
};

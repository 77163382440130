import React from 'react';
import { ClientFormField } from './ClientFormField';
import { DisplayField } from './DisplayField';
import { PhoneNumberFields } from './PhoneNumberFields';

interface ClientFormFieldDefinition {
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  multiline?: boolean;
  pattern?: RegExp;
  maxLength?: number;
}

interface ClientFormProps {
  isEditing: boolean;
  clientData: Record<string, any>;
  onFieldChange: (name: string, value: string) => void;
}

// Define the form fields structure
const CLIENT_FIELDS: ClientFormFieldDefinition[] = [
  { name: 'name', label: 'Name', placeholder: 'Enter client name' },
  {
    name: 'email',
    label: 'Email',
    placeholder: 'Enter email address',
    type: 'email',
  },
  {
    name: 'date_of_birth',
    label: 'Date of Birth',
    placeholder: 'Select date of birth',
    type: 'date',
  },
  {
    name: 'last_four_of_ssn',
    label: 'Last 4 of SSN',
    placeholder: 'Enter last 4 digits',
    pattern: /[^0-9]/g,
    maxLength: 4,
  },
  {
    name: 'address.line1',
    label: 'Address Line 1',
    placeholder: 'Enter street address',
  },
  {
    name: 'address.line2',
    label: 'Address Line 2',
    placeholder: 'Enter address line 2',
  },
  {
    name: 'address.line3',
    label: 'Address Line 3',
    placeholder: 'Enter address line 3',
  },
  { name: 'address.city', label: 'City', placeholder: 'Enter city' },
  { name: 'address.state', label: 'State', placeholder: 'Enter state' },
  {
    name: 'address.postcode',
    label: 'Zip Code',
    placeholder: 'Enter zip code',
  },
];

// Shared layout component for both display and edit modes
interface ClientFieldsLayoutProps {
  fields: ClientFormFieldDefinition[];
  renderField: (
    field: ClientFormFieldDefinition,
    index: number,
  ) => React.ReactNode;
}

const ClientFieldsLayout: React.FC<ClientFieldsLayoutProps> = ({
  fields,
  renderField,
}) => {
  return (
    <dl className="divide-y divide-gray-100">
      {fields.map((field, index) => (
        <div key={field.name} className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-900">{field.label}</dt>
          <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
            {renderField(field, index)}
          </dd>
        </div>
      ))}
    </dl>
  );
};

export const ClientForm: React.FC<ClientFormProps> = ({
  isEditing,
  clientData,
  onFieldChange,
}) => {
  // Helper to get nested values safely
  const getValue = (obj: any, path: string): any => {
    const keys = path.split('.');
    return keys.reduce(
      (o, key) => (o && o[key] !== undefined ? o[key] : ''),
      obj,
    );
  };

  // Phone number specific handlers
  const handleAddPhone = () => {
    const phones = [
      ...(clientData.phone_numbers || []),
      { id: '', phone_number: '' },
    ];
    onFieldChange('phone_numbers', JSON.stringify(phones));
  };

  const handleRemovePhone = (index: number) => {
    const phones = [...(clientData.phone_numbers || [])];
    phones.splice(index, 1);
    onFieldChange('phone_numbers', JSON.stringify(phones));
  };

  const handlePhoneChange = (index: number, value: string) => {
    const phones = [...(clientData.phone_numbers || [])];
    phones[index].phone_number = value;
    onFieldChange('phone_numbers', JSON.stringify(phones));
  };

  // Get non-empty fields for display mode
  const nonEmptyFields = isEditing
    ? CLIENT_FIELDS
    : CLIENT_FIELDS.filter((field) => {
        const value = getValue(clientData, field.name);
        return value !== null && value !== undefined && value !== '';
      });

  const hiddenFieldsCount = CLIENT_FIELDS.length - nonEmptyFields.length;

  // Render main form fields based on edit mode and empty fields
  const renderFormFields = () => {
    if (isEditing) {
      return (
        <ClientFieldsLayout
          fields={CLIENT_FIELDS}
          renderField={(field) => (
            <ClientFormField
              label={field.label}
              name={field.name}
              value={getValue(clientData, field.name)}
              onChange={(value) => onFieldChange(field.name, value)}
              type={field.type}
              placeholder={field.placeholder}
              multiline={field.multiline}
              pattern={field.pattern}
              maxLength={field.maxLength}
            />
          )}
        />
      );
    }
    if (nonEmptyFields.length > 0) {
      return (
        <ClientFieldsLayout
          fields={nonEmptyFields}
          renderField={(field) => (
            <div className={field.multiline ? 'whitespace-pre-wrap' : ''}>
              <DisplayField
                label={field.label}
                value={getValue(clientData, field.name)}
                multiline={field.multiline}
              />
            </div>
          )}
        />
      );
    }
    return (
      <p className="text-sm text-gray-500 italic pb-2">Nothing here yet.</p>
    );
  };

  return (
    <div className="space-y-0">
      {renderFormFields()}

      <PhoneNumberFields
        phoneNumbers={clientData.phone_numbers || []}
        isEditing={isEditing}
        onAdd={handleAddPhone}
        onRemove={handleRemovePhone}
        onChange={handlePhoneChange}
      />

      {!isEditing && hiddenFieldsCount > 0 && (
        <div className="border-t border-gray-100 pt-2">
          <p className="text-xs text-gray-400 italic">
            {hiddenFieldsCount} empty{' '}
            {hiddenFieldsCount === 1 ? 'field' : 'fields'} not shown.
          </p>
        </div>
      )}
    </div>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';

import {
  CreateProofreadingRule,
  ProofreadingJob,
  ProofreadingJobStartResponse,
  ProofreadingRule,
} from '../types/proofreading-types';
import { baseQueryWithErrorHandling } from './baseQuery';

export const proofreadingApiClient = createApi({
  reducerPath: 'proofreadingApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['ProofreadingJobs', 'ProofreadingRules'] as const,
  endpoints: (builder) => ({
    // Get all proofreading jobs for a demand
    listProofreadingJobs: builder.query<
      ProofreadingJob[],
      { demandId: string }
    >({
      query: ({ demandId }) => `api/demands/${demandId}/proofreading-jobs/`,
      providesTags: (result, error, { demandId }) => [
        { type: 'ProofreadingJobs', id: demandId },
      ],
    }),

    // Create a new proofreading job for the latest revision of a demand
    createProofreadingJob: builder.mutation<
      ProofreadingJobStartResponse,
      { demandId: string }
    >({
      query: ({ demandId }) => ({
        url: `api/demands/${demandId}/proofreading-jobs/create_job/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { demandId }) => [
        { type: 'ProofreadingJobs', id: demandId },
      ],
    }),

    // Get the results of a specific proofreading job
    getProofreadingJobResults: builder.query<
      ProofreadingJob,
      { demandId: string; jobId: string }
    >({
      query: ({ demandId, jobId }) =>
        `api/demands/${demandId}/proofreading-jobs/${jobId}/results/`,
      providesTags: (result, error, { demandId, jobId }) => [
        { type: 'ProofreadingJobs', id: `${demandId}:${jobId}` },
      ],
    }),

    // Get all proofreading rules for a firm
    listProofreadingRules: builder.query<
      ProofreadingRule[],
      { firmId: string }
    >({
      query: ({ firmId }) => `api/firms/${firmId}/proofreading-rules/`,
      providesTags: (result, error, { firmId }) => [
        { type: 'ProofreadingRules', id: firmId },
      ],
    }),

    // Create a new firm-specific proofreading rule
    createProofreadingRule: builder.mutation<
      ProofreadingRule,
      { firmId: string; data: CreateProofreadingRule }
    >({
      query: ({ firmId, data }) => ({
        url: `api/firms/${firmId}/proofreading-rules/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { firmId }) => [
        { type: 'ProofreadingRules', id: firmId },
      ],
    }),

    // Update a proofreading rule
    updateProofreadingRule: builder.mutation<
      ProofreadingRule,
      { firmId: string; ruleId: string; data: Partial<CreateProofreadingRule> }
    >({
      query: ({ firmId, ruleId, data }) => ({
        url: `api/firms/${firmId}/proofreading-rules/${ruleId}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { firmId }) => [
        { type: 'ProofreadingRules', id: firmId },
      ],
    }),

    // Delete a proofreading rule
    deleteProofreadingRule: builder.mutation<
      void,
      { firmId: string; ruleId: string }
    >({
      query: ({ firmId, ruleId }) => ({
        url: `api/firms/${firmId}/proofreading-rules/${ruleId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { firmId }) => [
        { type: 'ProofreadingRules', id: firmId },
      ],
    }),
  }),
});

export const {
  useListProofreadingJobsQuery,
  useCreateProofreadingJobMutation,
  useGetProofreadingJobResultsQuery,
  useListProofreadingRulesQuery,
  useCreateProofreadingRuleMutation,
  useUpdateProofreadingRuleMutation,
  useDeleteProofreadingRuleMutation,
} = proofreadingApiClient;

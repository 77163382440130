import React from 'react';
import { Calendar } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Skeleton } from '@/components/ui/skeleton';
import { formatDateTime } from './utils';

interface UpcomingCallsProps {
  upcomingRunTimes: string[];
  isPaused: boolean;
  isLoading: boolean;
  timezone?: string;
}

export const UpcomingCalls: React.FC<UpcomingCallsProps> = ({
  upcomingRunTimes,
  isPaused,
  isLoading,
  timezone = 'UTC',
}) => {
  const renderUpcomingCalls = () => {
    if (isLoading) {
      return (
        <div className="space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-5/6" />
        </div>
      );
    }

    if (upcomingRunTimes.length === 0) {
      return (
        <div className="text-sm text-muted-foreground">
          <p>No upcoming calls scheduled</p>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
        {upcomingRunTimes.slice(0, 4).map((time, index) => (
          <div
            key={index}
            className="text-sm bg-slate-50 px-2 py-1 rounded border border-slate-200 truncate"
          >
            {formatDateTime(time)}
          </div>
        ))}
        {upcomingRunTimes.length > 4 && (
          <p className="text-xs text-muted-foreground mt-1 col-span-full">
            + {upcomingRunTimes.length - 4} more upcoming calls
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg p-3 shadow-sm">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <Calendar className="h-4 w-4 text-primary flex-shrink-0" />
          <h4 className="font-medium">Upcoming Calls</h4>
          <Badge
            variant="outline"
            className="text-xs px-2 bg-blue-50 text-blue-700 border-blue-200 flex-shrink-0"
          >
            Timezone: {timezone}
          </Badge>
        </div>
        {isPaused && (
          <Badge
            variant="outline"
            className="bg-amber-50 text-amber-700 border-amber-200 flex-shrink-0"
          >
            Paused
          </Badge>
        )}
      </div>

      {renderUpcomingCalls()}
    </div>
  );
};

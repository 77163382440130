import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'sonner';
import { addDays } from 'date-fns';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { getTimeStringFromSchedule } from '@/utils/schedule-utils';
import { BlandSchedule } from '@/services/types/bland-schedules-types';
import { TimeAndTimezoneSelector } from './TimeAndTimezoneSelector';
import { SelectionModeToggle } from './SelectionModeToggle';
import { DaysOfMonthSelector } from './DaysOfMonthSelector';
import { DaysOfWeekSelector } from './DaysOfWeekSelector';
import { RetrySettings } from './RetrySettings';
import { isPhoneNumberValid, validatePhoneNumber } from '@/utils/validation';
import {
  ScheduleFormProps,
  ScheduleFormValues,
  scheduleFormSchema,
} from './types';
import {
  calendarStyles,
  getInitialDates,
  getNextBusinessDays,
  buildScheduleConfig,
} from './utils';

export const ScheduleForm = ({
  taskId,
  template,
  initialSchedule,
  onSubmit,
  onCancel,
}: ScheduleFormProps) => {
  // State to track selection mode - determine initial mode
  let initialMode: 'dates' | 'daysOfWeek' = 'daysOfWeek'; // Default to daysOfWeek for new schedules

  if (initialSchedule) {
    // If we have an initial schedule, check if it has days of week
    initialMode =
      initialSchedule.calendar_day_of_week &&
      initialSchedule.calendar_day_of_week.length > 0
        ? 'daysOfWeek'
        : 'dates';
  }

  const [selectionMode, setSelectionMode] = useState<'dates' | 'daysOfWeek'>(
    initialMode,
  );

  // Initialize the form with default values or values from an existing schedule
  const form = useForm<ScheduleFormValues>({
    resolver: zodResolver(scheduleFormSchema),
    defaultValues: initialSchedule
      ? {
          time: getTimeStringFromSchedule(initialSchedule),
          selectedDates:
            initialSchedule.calendar_day_of_month &&
            initialSchedule.calendar_day_of_month.length > 0
              ? getInitialDates(initialSchedule.calendar_day_of_month)
              : undefined,
          selectedDaysOfWeek: initialSchedule.calendar_day_of_week || undefined,
          selectionMode:
            initialSchedule.calendar_day_of_week &&
            initialSchedule.calendar_day_of_week.length > 0
              ? 'daysOfWeek'
              : 'dates',
          timezone: initialSchedule.timezone,
          retryEnabled: initialSchedule.retry_enabled,
          maxRetries: initialSchedule.max_retries,
          retryDelay: initialSchedule.retry_delay_seconds,
          endDate: initialSchedule.schedule_end_at
            ? new Date(initialSchedule.schedule_end_at)
            : addDays(new Date(), 7),
        }
      : {
          time: '09:00', // Default to 9 AM
          selectedDates: getNextBusinessDays(5), // Default to next 5 business days
          selectedDaysOfWeek: [1, 2, 3, 4, 5], // Default to weekdays
          selectionMode: 'daysOfWeek', // Default to weekly schedule
          timezone: 'America/New_York',
          retryEnabled: false,
          maxRetries: 3,
          retryDelay: 3600, // Default to 1 hour
          endDate: addDays(new Date(), 7), // Default to today + 7 days
        },
  });

  const currentSelectionMode = form.watch('selectionMode');

  // Update form when selection mode changes
  const handleSelectionModeChange = (mode: 'dates' | 'daysOfWeek') => {
    setSelectionMode(mode);
    form.setValue('selectionMode', mode, { shouldValidate: true });

    // Validate the appropriate field based on the selected mode
    if (mode === 'dates') {
      const dates = form.getValues('selectedDates') || [];
      if (dates.length === 0) {
        form.setError('selectedDates', {
          type: 'manual',
          message: 'Select at least one date',
        });
      } else {
        form.clearErrors('selectedDates');
      }
      form.clearErrors('selectedDaysOfWeek');
      form.clearErrors('endDate'); // Clear end date errors when switching to dates mode
    } else {
      const daysOfWeek = form.getValues('selectedDaysOfWeek') || [];
      if (daysOfWeek.length === 0) {
        form.setError('selectedDaysOfWeek', {
          type: 'manual',
          message: 'Select at least one day of the week',
        });
      } else {
        form.clearErrors('selectedDaysOfWeek');
      }

      // Ensure end date is set when switching to days of week mode
      const endDate = form.getValues('endDate');
      if (!endDate) {
        const defaultEndDate = addDays(new Date(), 7);
        form.setValue('endDate', defaultEndDate, { shouldValidate: true });
      }

      form.clearErrors('selectedDates');
    }
  };

  const handleSubmit = (values: ScheduleFormValues) => {
    // Validate that at least one option is selected based on the mode
    if (
      values.selectionMode === 'dates' &&
      (!values.selectedDates || values.selectedDates.length === 0)
    ) {
      form.setError('selectedDates', {
        type: 'manual',
        message: 'Select at least one date',
      });
      return;
    }
    if (
      values.selectionMode === 'daysOfWeek' &&
      (!values.selectedDaysOfWeek || values.selectedDaysOfWeek.length === 0)
    ) {
      form.setError('selectedDaysOfWeek', {
        type: 'manual',
        message: 'Select at least one day of the week',
      });
      return;
    }

    // Validate end date is set for days of week mode
    if (values.selectionMode === 'daysOfWeek' && !values.endDate) {
      form.setError('endDate', {
        type: 'manual',
        message: 'End date is required',
      });
      return;
    }

    // Validate that end date is in the future for days of week mode
    if (values.selectionMode === 'daysOfWeek' && values.endDate) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (values.endDate < today) {
        form.setError('endDate', {
          type: 'manual',
          message: 'End date must be in the future',
        });
        return;
      }
    }

    // Validate phone number in the template with more specific error messages
    if (!template.phone_number) {
      toast.error('Phone number is required before scheduling calls');
      return;
    }

    const phoneValidation = validatePhoneNumber(template.phone_number);
    if (!phoneValidation.isValid) {
      toast.error(
        phoneValidation.errorMessage || 'Invalid phone number format',
      );
      return;
    }

    // Build the schedule configuration
    const scheduleConfig = buildScheduleConfig(
      values,
      taskId,
      template,
      initialSchedule?.id,
    );

    onSubmit(scheduleConfig);
  };

  return (
    <FormProvider {...form}>
      <Form {...form}>
        <div className="space-y-6">
          {/* Inject custom CSS for animations */}
          <style>{calendarStyles}</style>

          <div className="space-y-4">
            {/* Time and Timezone Selection */}
            <TimeAndTimezoneSelector />

            {/* Selection Mode Toggle */}
            <SelectionModeToggle onModeChange={handleSelectionModeChange} />

            {/* Days of Month Selection - shown when in dates mode */}
            {currentSelectionMode === 'dates' && <DaysOfMonthSelector />}

            {/* Days of Week Selection - shown when in daysOfWeek mode */}
            {currentSelectionMode === 'daysOfWeek' && <DaysOfWeekSelector />}

            {/* Retry Settings */}
            <RetrySettings />
          </div>

          {/* Form Actions */}
          <div className="flex justify-end space-x-2">
            <Button type="button" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => form.handleSubmit(handleSubmit)()}
            >
              {initialSchedule ? 'Update Schedule' : 'Schedule Calls'}
            </Button>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

// Export all components for easy imports
export * from './TimeAndTimezoneSelector';
export * from './SelectionModeToggle';
export * from './DaysOfMonthSelector';
export * from './DaysOfWeekSelector';
export * from './RetrySettings';
export * from './types';
export * from './utils';

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';

import { blandScheduleApi } from '@/services/api/blandScheduleService';
import { demandsApiClient } from '@/services/api/demandService';

import { blandCallApi } from '../services/api/blandCallService';
import { blandTemplateApi } from '../services/api/blandtemplateService';
import { callApiClient } from '../services/api/callService';
import { collectionsApiClient } from '../services/api/collectionService';
import { externalOpsMessageApiClient } from '../services/api/externalOpsMessageService';
import { firmsApiClient } from '../services/api/firmsService';
import { formsApiClient } from '../services/api/formsService';
import { lettersApiClient } from '../services/api/lettersService';
import { matterAssistantApi } from '../services/api/matterAssistant';
import { matterContextApi } from '../services/api/matterContextService';
import { matterNotesExtractionApi } from '../services/api/matterNotesExtractionService';
import { matterNotesApi } from '../services/api/matterNotesService';
import { mattersApiClient } from '../services/api/mattersService';
import { matterSummaryApi } from '../services/api/matterSummaryService';
import { operatorTemplateApi } from '../services/api/operatorTemplateService';
import { proofreadingApiClient } from '../services/api/proofreadingService';
import { sharedMatterApiClient } from '../services/api/sharedMatterService';
import { tasksApiClient } from '../services/api/tasksService';
import { userApiClient } from '../services/api/userService';
import matterReducer from './reducers/matter';
import rootReducer from './reducers/root';
// Create the Sentry Redux enhancer
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: {
    matters: matterReducer,
    root: rootReducer,
    [mattersApiClient.reducerPath]: mattersApiClient.reducer,
    [userApiClient.reducerPath]: userApiClient.reducer,
    [callApiClient.reducerPath]: callApiClient.reducer,
    [formsApiClient.reducerPath]: formsApiClient.reducer,
    [collectionsApiClient.reducerPath]: collectionsApiClient.reducer,
    [lettersApiClient.reducerPath]: lettersApiClient.reducer,
    [matterNotesApi.reducerPath]: matterNotesApi.reducer,
    [matterNotesExtractionApi.reducerPath]: matterNotesExtractionApi.reducer,
    [matterSummaryApi.reducerPath]: matterSummaryApi.reducer,
    [firmsApiClient.reducerPath]: firmsApiClient.reducer,
    [sharedMatterApiClient.reducerPath]: sharedMatterApiClient.reducer,
    [tasksApiClient.reducerPath]: tasksApiClient.reducer,
    [blandTemplateApi.reducerPath]: blandTemplateApi.reducer,
    [matterContextApi.reducerPath]: matterContextApi.reducer,
    [blandCallApi.reducerPath]: blandCallApi.reducer,
    [matterAssistantApi.reducerPath]: matterAssistantApi.reducer,
    [blandScheduleApi.reducerPath]: blandScheduleApi.reducer,
    [operatorTemplateApi.reducerPath]: operatorTemplateApi.reducer,
    [externalOpsMessageApiClient.reducerPath]:
      externalOpsMessageApiClient.reducer,
    [demandsApiClient.reducerPath]: demandsApiClient.reducer,
    [proofreadingApiClient.reducerPath]: proofreadingApiClient.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(mattersApiClient.middleware)
      .concat(userApiClient.middleware)
      .concat(callApiClient.middleware)
      .concat(formsApiClient.middleware)
      .concat(collectionsApiClient.middleware)
      .concat(lettersApiClient.middleware)
      .concat(matterNotesApi.middleware)
      .concat(matterNotesExtractionApi.middleware)
      .concat(matterSummaryApi.middleware)
      .concat(firmsApiClient.middleware)
      .concat(sharedMatterApiClient.middleware)
      .concat(tasksApiClient.middleware)
      .concat(blandTemplateApi.middleware)
      .concat(matterContextApi.middleware)
      .concat(blandCallApi.middleware)
      .concat(matterAssistantApi.middleware)
      .concat(blandScheduleApi.middleware)
      .concat(operatorTemplateApi.middleware)
      .concat(externalOpsMessageApiClient.middleware)
      .concat(demandsApiClient.middleware)
      .concat(proofreadingApiClient.middleware),
  devTools: import.meta.env.NODE_ENV !== 'production',
  // TODO security audit - determine what we need to redact before sending to sentry
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);

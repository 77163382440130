import { Check, ChevronsUpDown, Copy, ExternalLink } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import {
  useCreateDemandConfigurationMutation,
  useGetDemandConfigurationQuery,
} from '@/services/api/demandService';
import { useGetFirmsQuery } from '@/services/api/firmsService';
import { MatterType } from '@/services/types/client-intake-types';
import { CreateDemandConfiguration } from '@/services/types/demand-types';

const CaseTypeIconMap = {
  [MatterType.MOTOR_VEHICLE_ACCIDENT]: '🚗',
  [MatterType.MEDICAL_MALPRACTICE]: '⚕️',
  [MatterType.MISCELLANEOUS]: '❓',
  [MatterType.BREACH_OF_EMPLOYMENT_CONTRACT]: '📄',
  [MatterType.SELLER_NON_DISCLOSURE]: '🏠',
  [MatterType.PREMISES_LIABILITY]: '⚠️',
  [MatterType.BROKER_NEGLIGENCE]: '💼',
  [MatterType.UNKNOWN]: '❔',
};

// Format matter type for display
const formatMatterType = (type: string) => {
  return type
    .split('_')
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(' ');
};

// Create case type options for combobox
const caseTypeOptions = Object.values(MatterType).map((type) => ({
  value: type,
  label: formatMatterType(type),
  icon: CaseTypeIconMap[type],
}));

export const DemandConfigurationCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Check if we're copying an existing configuration
  const copyId = searchParams.get('copy');
  const copyName = searchParams.get('name');
  const copyFirmId = searchParams.get('firmId');
  const copyCaseType = searchParams.get('caseType') as MatterType | null;

  const isCopying = !!copyId;

  const [createConfiguration, { isLoading: isCreating }] =
    useCreateDemandConfigurationMutation();
  const { data: firms, isLoading: isLoadingFirms } = useGetFirmsQuery();

  // Fetch the configuration to copy if we have an ID
  const { data: sourceConfig, isLoading: isLoadingSourceConfig } =
    useGetDemandConfigurationQuery(
      { configurationId: copyId || '' },
      { skip: !copyId },
    );

  // State
  const [firmId, setFirmId] = useState<string>(copyFirmId || '');
  const [caseType, setCaseType] = useState<MatterType | null>(copyCaseType);
  const [configName, setConfigName] = useState(
    copyName ? `${copyName} (Copy)` : '',
  );
  const [configDescription, setConfigDescription] = useState('');
  const [caseTypeOpen, setCaseTypeOpen] = useState(false);

  // Update with source config data when it loads
  useEffect(() => {
    if (sourceConfig && isCopying) {
      setConfigDescription(sourceConfig.description || '');
    }
  }, [sourceConfig, isCopying]);

  // Form validation
  const isValid =
    firmId.trim().length > 0 &&
    configName.trim().length > 0 &&
    caseType !== null;

  // Submit handler
  const handleSubmit = async () => {
    if (!isValid || !caseType) return;

    try {
      const requestData: CreateDemandConfiguration = {
        firm_id: firmId,
        case_type: caseType,
        name: configName,
        description: configDescription || undefined,
        configuration: sourceConfig?.configuration || {}, // Use the source config if available
      };

      const result = await createConfiguration({ data: requestData }).unwrap();

      toast.success('Configuration created successfully');

      // Navigate to the editor
      navigate(`/firms/demand-configurations/${result.id}`);
    } catch (err) {
      toast.error('Failed to create configuration');
      console.error('Error creating configuration:', err);
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-6 py-8 space-y-8">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">
          {isCopying
            ? 'Duplicate Demand Configuration'
            : 'Create Demand Configuration'}
        </h1>
      </div>

      {isCopying && (
        <Card className="bg-muted/30 border-muted">
          <CardContent className="py-3">
            <div className="flex items-center gap-3">
              <Copy className="h-4 w-4 text-muted-foreground" />
              <div className="text-sm text-muted-foreground">
                Creating a copy from:{' '}
                <span className="font-medium text-foreground">{copyName}</span>
                {copyId && (
                  <Link
                    to={`/firms/demand-configurations/${copyId}`}
                    className="ml-3 inline-flex items-center text-primary hover:underline"
                  >
                    View original <ExternalLink className="h-3 w-3 ml-1" />
                  </Link>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      <Card className="shadow-sm">
        <CardContent className="pt-6 pb-6 space-y-6">
          <div className="space-y-1.5">
            <Label htmlFor="firm-id" className="text-sm font-medium">
              Firm <span className="text-destructive">*</span>
            </Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  className="w-full justify-between h-10"
                  id="firm-id"
                  disabled={isLoadingFirms}
                >
                  {firmId && firms
                    ? firms.find((firm) => firm.id === firmId)?.name ||
                      'Select a firm'
                    : 'Select a firm'}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className="w-[var(--radix-popover-trigger-width)] p-0"
                align="start"
              >
                <Command>
                  <CommandInput placeholder="Search firms..." />
                  <CommandList>
                    <CommandEmpty>No firms found.</CommandEmpty>
                    <CommandGroup>
                      {firms?.map((firm) => (
                        <CommandItem
                          key={firm.id}
                          value={firm.name}
                          onSelect={() => {
                            setFirmId(firm.id);
                          }}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              firmId === firm.id ? 'opacity-100' : 'opacity-0',
                            )}
                          />
                          {firm.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="config-name" className="text-sm font-medium">
              Configuration Title <span className="text-destructive">*</span>
            </Label>
            <Input
              id="config-name"
              value={configName}
              onChange={(e) => setConfigName(e.target.value)}
              placeholder="E.g., Standard Motor Vehicle Accident"
              className="h-10"
            />
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="config-description" className="text-sm font-medium">
              Description (Optional)
            </Label>
            <Textarea
              id="config-description"
              value={configDescription}
              onChange={(e) => setConfigDescription(e.target.value)}
              placeholder="Briefly describe this configuration's purpose"
              className="min-h-[80px] resize-y"
            />
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="case-type" className="text-sm font-medium">
              Case Type <span className="text-destructive">*</span>
            </Label>
            <Popover open={caseTypeOpen} onOpenChange={setCaseTypeOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={caseTypeOpen}
                  className="w-full justify-between h-10"
                  id="case-type"
                >
                  {caseType ? (
                    <div className="flex items-center gap-2">
                      <span>{CaseTypeIconMap[caseType]}</span>
                      <span>{formatMatterType(caseType)}</span>
                    </div>
                  ) : (
                    'Select case type...'
                  )}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className="w-[var(--radix-popover-trigger-width)] p-0"
                align="start"
              >
                <Command>
                  <CommandInput placeholder="Search case types..." />
                  <CommandList>
                    <CommandEmpty>No case type found.</CommandEmpty>
                    <CommandGroup>
                      {caseTypeOptions.map((option) => (
                        <CommandItem
                          key={option.value}
                          value={option.value}
                          onSelect={(currentValue) => {
                            setCaseType(currentValue as MatterType);
                            setCaseTypeOpen(false);
                          }}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              caseType === option.value
                                ? 'opacity-100'
                                : 'opacity-0',
                            )}
                          />
                          <div className="flex items-center gap-2">
                            <span>{option.icon}</span>
                            <span>{option.label}</span>
                          </div>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
        </CardContent>
      </Card>

      <div className="flex justify-end pt-2 gap-3">
        <Button
          variant="outline"
          onClick={() => navigate('/firms/demand-configurations')}
          disabled={isCreating}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={
            !isValid ||
            isCreating ||
            isLoadingFirms ||
            (isCopying && isLoadingSourceConfig)
          }
          className="min-w-[140px]"
        >
          {(() => {
            if (isCreating) return 'Creating...';
            if (isCopying) return 'Create Duplicate';
            return 'Create Configuration';
          })()}
        </Button>
      </div>
    </div>
  );
};

export default DemandConfigurationCreate;

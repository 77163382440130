import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { format } from 'date-fns';
import { Task } from '@/services/types/client-intake-types';
import { CanonicalTaskStatus } from '@/services/types/task-types';
import { User } from '@/services/types/user-types';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function taskIsPastDue(task: Task) {
  return !!(
    task.due_date &&
    new Date(task.due_date) < new Date() &&
    task.status.canonical_status !== CanonicalTaskStatus.COMPLETED &&
    task.status.canonical_status !== CanonicalTaskStatus.NOT_APPLICABLE
  );
}

export function taskIsUrgent(task: Task) {
  return !!(
    task.order &&
    task.order < 0 &&
    task.status.canonical_status !== CanonicalTaskStatus.COMPLETED &&
    task.status.canonical_status !== CanonicalTaskStatus.NOT_APPLICABLE
  );
}

const FINCH_FIRM_ID = '09ba25a6-9f6a-4db8-a8d6-252e269c620d';
const FINCH_ENGINEER_USER_IDS = new Set([
  // Dean
  'a08a2058-27bd-4125-a18d-0b20dd2db169',
  // Moyez
  '44f424a8-8e85-46dc-bc32-dcde99d3e319',
]);

export function filterStaffUsers(staffUsers: User[]) {
  return staffUsers.filter(
    (user) =>
      user.firm === FINCH_FIRM_ID && !FINCH_ENGINEER_USER_IDS.has(user.id),
  );
}

export function formatDate(dateString: string): string {
  if (!dateString) return '';
  try {
    return format(new Date(dateString), 'PP');
  } catch (error) {
    return dateString;
  }
}

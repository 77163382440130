import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'sonner';
import equal from 'fast-deep-equal';
import { usePatchMatterClientMutation } from '@/services/api/mattersService';
import { MatterClient } from '@/services/types/matter-client-types';
import { useNotification } from '@/components/contexts/NotificationContext';
import { ClientSectionHeader } from './components/ClientSectionHeader';
import { ClientFormActions } from './components/ClientFormActions';
import { ClientForm } from './components/ClientForm';

interface MatterClientNoteV2Props {
  client: MatterClient;
  matterId: string;
}

export const MatterClientNoteV2: React.FC<MatterClientNoteV2Props> = ({
  client,
  matterId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [clientData, setClientData] = useState<MatterClient>(client);
  const [hasChanges, setHasChanges] = useState(false);

  const notify = useNotification();
  const [patchMatterClient, { isLoading: isSaving, isError }] =
    usePatchMatterClientMutation();

  // Reset form when client changes
  useEffect(() => {
    setClientData(client);
  }, [client]);

  // Track if form has changes compared to original client
  useEffect(() => {
    setHasChanges(!equal(client, clientData));
  }, [clientData, client]);

  // Handle field changes
  const handleFieldChange = (name: string, value: any) => {
    setClientData((prev) => {
      // Create a deep copy of the previous state
      const result = JSON.parse(JSON.stringify(prev));

      // Handle nested properties like address.line1
      if (name.includes('.')) {
        const parts = name.split('.');
        const lastPart = parts.pop()!;
        const targetObj = parts.reduce(
          (obj, part) => {
            if (!obj[part]) obj[part] = {};
            return obj[part];
          },
          result as Record<string, any>,
        );

        targetObj[lastPart] = value;
      }
      // Handle special case for phone_numbers that comes as JSON string
      else if (name === 'phone_numbers' && typeof value === 'string') {
        result[name] = JSON.parse(value);
      }
      // Regular property
      else {
        result[name] = value;
      }

      return result;
    });
  };

  // Edit mode handlers
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setClientData(client);
    setIsEditing(false);
  };

  const handleSave = async () => {
    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    try {
      await patchMatterClient({
        matter_id: matterId,
        matter_client_id: client.id,
        patch: clientData,
      }).unwrap();

      toast.success('Client information updated successfully');
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update client:', error);
      toast.error('Failed to update client information');
    }
  };

  // Add keyboard shortcut handler
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // Check for Cmd+Enter (Mac) or Ctrl+Enter (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault();
        if (isEditing && hasChanges && !isSaving) {
          handleSave();
        }
      }
    },
    [isEditing, hasChanges, isSaving],
  );

  // Add and remove event listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div>
      <ClientSectionHeader title="Client Information" />

      <div className="rounded-lg border shadow-sm overflow-hidden group mt-4">
        <div
          className={`transition-all duration-200 ${
            !isEditing ? 'opacity-70 group-hover:opacity-100' : 'opacity-100'
          }`}
        >
          <ClientFormActions
            isEditing={isEditing}
            isNew={false}
            onEdit={handleEdit}
            onSave={handleSave}
            onCancel={handleCancel}
            isSaving={isSaving}
            isDeleting={false}
            title=""
          />
        </div>

        <div className="p-4 sm:p-6 pt-2 sm:pt-0">
          <ClientForm
            isEditing={isEditing}
            clientData={clientData}
            onFieldChange={handleFieldChange}
          />
        </div>
      </div>
    </div>
  );
};

import { Box, Typography } from '@mui/material';
import { PriorMedicalNote } from '../../../../services/types/matter-notes-types';
import ReadOnlyNoteSection from './ReadOnlyNoteSection';
import ReadOnlyNoteField from './ReadOnlyNoteField';
import { hasPopulatedFields } from '../../../../utils/noteUtils';

interface ReadOnlyPriorMedicalNoteSectionProps {
  notes: PriorMedicalNote[];
}

const ReadOnlyPriorMedicalNoteSection = ({
  notes,
}: ReadOnlyPriorMedicalNoteSectionProps) => {
  if (!notes?.length || !notes.some(hasPopulatedFields)) return null;

  return (
    <ReadOnlyNoteSection title="Prior Medical History">
      {notes.map((note, index) => (
        <Box
          key={note.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mb: index < notes.length - 1 ? 4 : 0,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '20px',
              letterSpacing: '0%',
            }}
          >
            {note.name}
            {note.is_pcp ? ' - PCP' : ''}
          </Typography>
          <ReadOnlyNoteField label="TREATMENT" value={note.treatment} />
          <ReadOnlyNoteField
            label="DATES OF SERVICE"
            value={note.dates_of_service}
          />
          <ReadOnlyNoteField
            label="STILL TREATING"
            value={note.still_treating ? 'Yes' : 'No'}
          />
          <ReadOnlyNoteField label="NOTES" value={note.description} />
        </Box>
      ))}
    </ReadOnlyNoteSection>
  );
};

export default ReadOnlyPriorMedicalNoteSection;

import React from 'react';

import { MessageContentProps } from '../../types';
import { HtmlContent } from './HtmlContent';
import { ImageContent } from './ImageContent';
import { TextContent } from './TextContent';

export const MessageContent: React.FC<MessageContentProps> = ({
  matterId,
  demandId,
  threadId,
  content,
}) => {
  switch (content.type) {
    case 'text':
      return <TextContent text={content.text} />;
    case 'html':
      return <HtmlContent html={content.html} />;
    case 'image':
      return (
        <ImageContent
          imageId={content.image_id}
          demandId={demandId}
          threadId={threadId}
          matterId={matterId}
        />
      );
    default:
      return null;
  }
};

import { loadCKEditorCloud } from '@ckeditor/ckeditor5-react';
import type { Editor } from 'ckeditor5';

const {
  CKEditor: { Plugin },
  CKEditorPremiumFeatures: { RevisionTracker },
} = await loadCKEditorCloud({
  version: '44.3.0',
  premium: true,
  translations: ['en'],
});

interface RevisionTrackerWithCurrent {
  currentRevision: {
    id: string;
    createdAt: Date;
    fromVersion: number;
    toVersion: number;
    diffData: {
      main: {
        insertions: string; // json array of insertions
        deletions: string; // json array of deletions
      };
    };
  } | null;
  saveRevision(): Promise<void>;
  update(): Promise<void>;
}

// Configuration type for revision settings
interface RevisionConfig {
  saveIntervalCount: number;
  minTimeBetweenRevisions: number;
  significantChangeThreshold: number;
}

// Revision state management type
interface RevisionState {
  autosaveCount: number;
  lastCreatedAt: Date | null;
  lastContentLength: number;
  isInitialLoad: boolean;
}

/**
 * Plugin that handles automatic revision creation and updates for CKEditor
 */
class RevisionHistoryAutosaveIntegration extends Plugin {
  // Configuration defaults
  private _config: RevisionConfig = {
    saveIntervalCount: 5, // Create a new revision after 5 saves
    minTimeBetweenRevisions: 5 * 60 * 1000, // Minimum 5 minutes between forced new revisions
    significantChangeThreshold: 0.1, // 10% content change threshold for new revision
  };

  // State tracking
  private _state: RevisionState = {
    autosaveCount: 1,
    lastCreatedAt: null,
    lastContentLength: 0,
    isInitialLoad: true,
  };

  /**
   * Get the RevisionTracker plugin instance with proper typing
   */
  private getRevisionTracker(): RevisionTrackerWithCurrent {
    return this.editor.plugins.get(
      RevisionTracker,
    ) as unknown as RevisionTrackerWithCurrent;
  }

  /**
   * Calculate metrics for determining revision behavior
   */
  private calculateRevisionMetrics(currentContentLength: number) {
    const revisionTracker = this.getRevisionTracker();
    const { currentRevision } = revisionTracker;
    const now = new Date();

    // Calculate time since last revision
    const timeSinceLastRevision = this._state.lastCreatedAt
      ? now.getTime() - this._state.lastCreatedAt.getTime()
      : Infinity;

    // Calculate content change metrics
    let contentChangeRatio = 0;
    if (this._state.lastContentLength > 0) {
      contentChangeRatio =
        Math.abs(currentContentLength - this._state.lastContentLength) /
        this._state.lastContentLength;
    } else if (!this._state.isInitialLoad) {
      contentChangeRatio = 1; // Default to 1 for non-initial situations with no previous content
    }

    const isSignificantChange =
      contentChangeRatio > this._config.significantChangeThreshold;
    const isTimeThresholdExceeded =
      timeSinceLastRevision > this._config.minTimeBetweenRevisions;

    return {
      currentRevision,
      timeSinceLastRevision,
      contentChangeRatio,
      isSignificantChange,
      isTimeThresholdExceeded,
      now,
    };
  }

  /**
   * Detect if a revision was created externally
   */
  private handleExternalRevisionChanges(
    currentRevision: RevisionTrackerWithCurrent['currentRevision'],
    currentContentLength: number,
  ): boolean {
    if (
      currentRevision &&
      this._state.lastCreatedAt &&
      currentRevision.createdAt > this._state.lastCreatedAt
    ) {
      console.debug('[RevisionAutosave] Detected external revision creation', {
        currentRevisionTime: currentRevision.createdAt,
        lastTrackedTime: this._state.lastCreatedAt,
      });

      // Reset state when external revision is detected
      this._state = {
        ...this._state,
        autosaveCount: 1,
        lastCreatedAt: currentRevision.createdAt,
        lastContentLength: currentContentLength,
        isInitialLoad: false,
      };

      return true;
    }

    return false;
  }

  /**
   * Create a new revision and update state
   */
  private async createNewRevision(
    revisionTracker: RevisionTrackerWithCurrent,
    currentRevision: RevisionTrackerWithCurrent['currentRevision'],
    currentContentLength: number,
    metrics: {
      contentChangeRatio: number;
      timeSinceLastRevision: number;
      now: Date;
    },
    reasons: string[],
  ) {
    console.debug(
      `[RevisionAutosave] Creating new revision (reason: ${reasons.join(', ')})`,
      {
        autosaveCount: this._state.autosaveCount,
        contentChangeRatio: metrics.contentChangeRatio.toFixed(2),
        timeSinceLastMs: metrics.timeSinceLastRevision,
      },
    );

    await revisionTracker.saveRevision();
    console.debug('[RevisionAutosave] New revision saved successfully');

    // Update state after successful save
    this._state = {
      ...this._state,
      autosaveCount: 1,
      lastCreatedAt: currentRevision?.createdAt ?? metrics.now,
      lastContentLength: currentContentLength,
      isInitialLoad: false,
    };
  }

  /**
   * Update the current revision and update state if necessary
   */
  private async updateCurrentRevision(
    revisionTracker: RevisionTrackerWithCurrent,
    currentRevision: RevisionTrackerWithCurrent['currentRevision'],
    currentContentLength: number,
  ) {
    console.debug('[RevisionAutosave] Updating current revision', {
      autosaveCount: this._state.autosaveCount,
    });

    await revisionTracker.update();

    // Only update state if revision was actually updated
    if (
      currentRevision?.createdAt &&
      (this._state.lastCreatedAt === null ||
        currentRevision.createdAt > this._state.lastCreatedAt)
    ) {
      console.debug(
        '[RevisionAutosave] Revision was updated, incrementing counter',
        {
          previousCount: this._state.autosaveCount,
          newCount: this._state.autosaveCount + 1,
        },
      );

      this._state = {
        ...this._state,
        autosaveCount: this._state.autosaveCount + 1,
        lastCreatedAt: currentRevision.createdAt,
        lastContentLength: currentContentLength,
        isInitialLoad: false,
      };
    } else {
      console.debug(
        '[RevisionAutosave] No changes detected, counter not incremented',
      );
    }
  }

  /**
   * Determine if a new revision should be created
   */
  private shouldCreateNewRevision(metrics: {
    isSignificantChange: boolean;
    isTimeThresholdExceeded: boolean;
    contentChangeRatio: number;
  }): [boolean, string[]] {
    const reasons: string[] = [];

    if (this._state.autosaveCount >= this._config.saveIntervalCount) {
      reasons.push('count threshold reached');
    }

    if (metrics.isSignificantChange) {
      reasons.push('significant content change');
    }

    if (metrics.isTimeThresholdExceeded && metrics.contentChangeRatio > 0) {
      reasons.push('time threshold exceeded with changes');
    }

    return [reasons.length > 0, reasons];
  }

  /**
   * Log current autosave status for debugging
   */
  private logAutosaveStatus(
    currentRevision: RevisionTrackerWithCurrent['currentRevision'],
    currentContentLength: number,
    metrics: {
      timeSinceLastRevision: number;
      contentChangeRatio: number;
      isSignificantChange: boolean;
      isTimeThresholdExceeded: boolean;
    },
  ) {
    console.log('Autosave status:', {
      currentRevision,
      lastCreatedAt: this._state.lastCreatedAt?.toISOString(),
      autosaveCount: this._state.autosaveCount,
      saveIntervalCount: this._config.saveIntervalCount,
      timeSinceLastRevision: `${Math.floor(metrics.timeSinceLastRevision / 1000)}s`,
      contentChangeRatio: metrics.contentChangeRatio.toFixed(2),
      contentLength: currentContentLength,
      isSignificantChange: metrics.isSignificantChange,
      isTimeThresholdExceeded: metrics.isTimeThresholdExceeded,
      isInitialLoad: this._state.isInitialLoad,
    });
  }

  /**
   * Main autosave method that handles revision logic
   */
  async autosave() {
    const revisionTracker = this.getRevisionTracker();
    const { currentRevision } = revisionTracker;
    const currentContent = this.editor.getData();
    const currentContentLength = currentContent.length;

    // Calculate metrics needed for decision making
    const metrics = this.calculateRevisionMetrics(currentContentLength);

    // Log current status
    this.logAutosaveStatus(currentRevision, currentContentLength, metrics);

    // Handle external revision creation
    if (
      this.handleExternalRevisionChanges(currentRevision, currentContentLength)
    ) {
      return true;
    }

    // Determine whether to create new revision or update existing one
    const [shouldCreate, reasons] = this.shouldCreateNewRevision(metrics);

    if (shouldCreate) {
      await this.createNewRevision(
        revisionTracker,
        currentRevision,
        currentContentLength,
        metrics,
        reasons,
      );
    } else {
      await this.updateCurrentRevision(
        revisionTracker,
        currentRevision,
        currentContentLength,
      );
    }

    return true;
  }
}

export { RevisionHistoryAutosaveIntegration };

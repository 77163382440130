import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryWithErrorHandling } from './baseQuery';
import {
  BlandCallTemplate,
  CreateBlandCallTemplate,
  UpdateBlandCallTemplate,
} from '../types/bland-templates.types';

export const blandTemplateApi = createApi({
  reducerPath: 'blandTemplateApi',
  tagTypes: ['BlandTemplate'],
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    // Get all templates
    getBlandTemplates: builder.query<BlandCallTemplate[], void>({
      query: () => 'api/bland/templates/',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'BlandTemplate' as const,
                id,
              })),
              { type: 'BlandTemplate', id: 'LIST' },
            ]
          : [{ type: 'BlandTemplate', id: 'LIST' }],
    }),

    // Get single template by ID
    getBlandTemplateById: builder.query<BlandCallTemplate, string>({
      query: (id) => `api/bland/templates/${id}/`,
      providesTags: (result, error, id) => [{ type: 'BlandTemplate', id }],
    }),

    // Create new template
    createBlandTemplate: builder.mutation<
      BlandCallTemplate,
      Partial<BlandCallTemplate>
    >({
      query: (body) => ({
        url: 'api/bland/templates/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'BlandTemplate', id: 'LIST' }],
    }),

    // Update template
    updateBlandTemplate: builder.mutation<
      BlandCallTemplate,
      { id: string; template: Partial<BlandCallTemplate> }
    >({
      query: ({ id, template }) => ({
        url: `api/bland/templates/${id}/`,
        method: 'PATCH',
        body: template,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'BlandTemplate', id },
        { type: 'BlandTemplate', id: 'LIST' },
      ],
    }),

    // Delete template
    deleteBlandTemplate: builder.mutation<void, string>({
      query: (id) => ({
        url: `api/bland/templates/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'BlandTemplate', id: 'LIST' }],
    }),

    // Create empty template
    createEmptyTemplate: builder.mutation<
      BlandCallTemplate,
      { title: string; taskType: string }
    >({
      query: (body) => ({
        url: 'api/bland/templates/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'BlandTemplate', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetBlandTemplatesQuery,
  useGetBlandTemplateByIdQuery,
  useCreateEmptyTemplateMutation,
  useCreateBlandTemplateMutation,
  useUpdateBlandTemplateMutation,
  useDeleteBlandTemplateMutation,
} = blandTemplateApi;

import React from 'react';

import { MessageListProps } from '../types';
import { Message } from './Message';
import { EmptyState } from './states/EmptyState';
import { LoadingState } from './states/LoadingState';

export const MessageList: React.FC<MessageListProps> = ({
  messages,
  isLoading,
  matterId,
  demandId,
  threadId,
}) => {
  if (isLoading) {
    return <LoadingState />;
  }

  // Show empty state if explicitly requested or if there are no messages
  if (!messages || messages.ids.length === 0) {
    return <EmptyState />;
  }

  return (
    <div className="space-y-4 py-1">
      {messages.ids.map((messageId) => {
        const message = messages.entities[messageId];
        return (
          <Message
            key={message.id}
            message={message}
            matterId={matterId}
            demandId={demandId}
            threadId={threadId}
          />
        );
      })}
    </div>
  );
};

import {
  CheckSquare,
  ChevronDown,
  Copy,
  Save,
  Search,
  Square,
  X,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Skeleton } from '@/components/ui/skeleton';
import { Textarea } from '@/components/ui/textarea';
import {
  useGetDemandConfigurationQuery,
  useUpdateDemandConfigurationMutation,
} from '@/services/api/demandService';
import { DemandDocumentSectionType } from '@/services/types/demand-types';

import { DemandConfigurationPreview } from './demand-configuration-preview';

// Order of sections according to the enum
const sectionOrder = [
  // Introduction
  DemandDocumentSectionType.INTRODUCTION,

  // Facts & Liability
  DemandDocumentSectionType.FACTS_LIABILITY,
  DemandDocumentSectionType.DEFENDANT_NEGLIGENCE_AND_BREACH_OF_DUTY,
  DemandDocumentSectionType.PROXIMATE_CAUSE_OF_HARM,

  // Injuries & Treatment
  DemandDocumentSectionType.INJURIES_AND_TREATMENT,
  DemandDocumentSectionType.SUMMARY_INJURIES,
  DemandDocumentSectionType.TREATMENT,

  // Damages
  DemandDocumentSectionType.DAMAGES,
  DemandDocumentSectionType.TOTAL_PROJECTED_CLAIM_VALUE,
  DemandDocumentSectionType.PAST_MEDICAL_EXPENSES,
  DemandDocumentSectionType.FUTURE_MEDICAL_EXPENSES,
  DemandDocumentSectionType.LOST_WAGES,
  DemandDocumentSectionType.PROPERTY_DAMAGE,
  DemandDocumentSectionType.LOSS_HOUSEHOLD_SERVICES,
  DemandDocumentSectionType.PAIN_SUFFERING,

  DemandDocumentSectionType.VERDICT_ANALYSIS,
  DemandDocumentSectionType.PER_DIEM_ANALYSIS,

  // Demand to Settle
  DemandDocumentSectionType.DEMAND_TO_SETTLE,
];

// Group sections by categories
const sectionCategories = {
  Introduction: [DemandDocumentSectionType.INTRODUCTION],
  'Facts & Liability': [
    DemandDocumentSectionType.FACTS_LIABILITY,
    DemandDocumentSectionType.DEFENDANT_NEGLIGENCE_AND_BREACH_OF_DUTY,
    DemandDocumentSectionType.PROXIMATE_CAUSE_OF_HARM,
  ],
  'Injuries & Treatment': [
    DemandDocumentSectionType.INJURIES_AND_TREATMENT,
    DemandDocumentSectionType.SUMMARY_INJURIES,
    DemandDocumentSectionType.TREATMENT,
  ],
  Damages: [
    DemandDocumentSectionType.DAMAGES,
    DemandDocumentSectionType.TOTAL_PROJECTED_CLAIM_VALUE,
    DemandDocumentSectionType.PAST_MEDICAL_EXPENSES,
    DemandDocumentSectionType.FUTURE_MEDICAL_EXPENSES,
    DemandDocumentSectionType.LOST_WAGES,
    DemandDocumentSectionType.PROPERTY_DAMAGE,
    DemandDocumentSectionType.LOSS_HOUSEHOLD_SERVICES,
    DemandDocumentSectionType.PAIN_SUFFERING,
  ],
  Analysis: [
    DemandDocumentSectionType.VERDICT_ANALYSIS,
    DemandDocumentSectionType.PER_DIEM_ANALYSIS,
  ],
  Conclusion: [DemandDocumentSectionType.DEMAND_TO_SETTLE],
};

// Helper function to format section key to default title
const formatSectionKeyToTitle = (sectionKey: string) => {
  return sectionKey
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Helper component for section editor form fields
const SectionFormFields = ({
  sectionKey,
  displaySection,
  handleSectionUpdate,
}: {
  sectionKey: string;
  displaySection: any;
  handleSectionUpdate: (key: string, field: string, value: any) => void;
}) => {
  // Determine if all form fields should be disabled
  const isDisabled = !displaySection.is_enabled;

  return (
    <div className="p-4 space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <Checkbox
            id={`title-only-${sectionKey}`}
            checked={displaySection.title_only}
            onCheckedChange={(checked) => {
              handleSectionUpdate(sectionKey, 'title_only', checked);
            }}
            className="data-[state=checked]:bg-white"
            disabled={isDisabled}
          />
          <Label
            htmlFor={`title-only-${sectionKey}`}
            className={`text-sm cursor-pointer ${isDisabled ? 'text-muted-foreground' : ''}`}
          >
            Title only (no content)
          </Label>
        </div>
      </div>

      <div className="space-y-2">
        <Label
          htmlFor={`title-${sectionKey}`}
          className={isDisabled ? 'text-muted-foreground' : ''}
        >
          Section Title
        </Label>
        <Input
          id={`title-${sectionKey}`}
          value={displaySection.title || formatSectionKeyToTitle(sectionKey)}
          onChange={(e) =>
            handleSectionUpdate(sectionKey, 'title', e.target.value)
          }
          disabled={isDisabled}
        />
      </div>

      {!displaySection.title_only && (
        <>
          <div className="space-y-2">
            <Label
              htmlFor={`content-prompt-${sectionKey}`}
              className={isDisabled ? 'text-muted-foreground' : ''}
            >
              Content Prompt
            </Label>
            <Textarea
              id={`content-prompt-${sectionKey}`}
              value={displaySection.content_prompt || ''}
              onChange={(e) =>
                handleSectionUpdate(
                  sectionKey,
                  'content_prompt',
                  e.target.value,
                )
              }
              placeholder="Instructions for AI to generate content for this section"
              className="min-h-[100px]"
              disabled={isDisabled}
            />
            <p className="text-xs text-muted-foreground">
              Instruct the AI how to generate content for this section
            </p>
          </div>

          <div className="space-y-2">
            <Label
              htmlFor={`content-example-${sectionKey}`}
              className={isDisabled ? 'text-muted-foreground' : ''}
            >
              Content Example
            </Label>
            <Textarea
              id={`content-example-${sectionKey}`}
              value={displaySection.content_example || ''}
              onChange={(e) =>
                handleSectionUpdate(
                  sectionKey,
                  'content_example',
                  e.target.value,
                )
              }
              placeholder="Example content for this section"
              className="min-h-[100px]"
              disabled={isDisabled}
            />
            <p className="text-xs text-muted-foreground">
              Provide an example of good content for this section
            </p>
          </div>

          <div className="space-y-2">
            <Label
              htmlFor={`formatting-prompt-${sectionKey}`}
              className={isDisabled ? 'text-muted-foreground' : ''}
            >
              Formatting Prompt
            </Label>
            <Textarea
              id={`formatting-prompt-${sectionKey}`}
              value={displaySection.formatting_prompt || ''}
              onChange={(e) =>
                handleSectionUpdate(
                  sectionKey,
                  'formatting_prompt',
                  e.target.value,
                )
              }
              placeholder="Instructions for AI on how to format this section"
              className="min-h-[100px]"
              disabled={isDisabled}
            />
            <p className="text-xs text-muted-foreground">
              Instruct the AI how to format this section (optional)
            </p>
          </div>

          <div className="space-y-2">
            <Label
              htmlFor={`formatting-example-${sectionKey}`}
              className={isDisabled ? 'text-muted-foreground' : ''}
            >
              Formatting Example
            </Label>
            <Textarea
              id={`formatting-example-${sectionKey}`}
              value={displaySection.formatting_example || ''}
              onChange={(e) =>
                handleSectionUpdate(
                  sectionKey,
                  'formatting_example',
                  e.target.value,
                )
              }
              placeholder="Example of formatting for this section"
              className="min-h-[100px]"
              disabled={isDisabled}
            />
            <p className="text-xs text-muted-foreground">
              Provide an example of good formatting for this section (optional)
            </p>
          </div>
        </>
      )}

      {isDisabled && (
        <div className="bg-muted/50 p-3 rounded-md text-center">
          <p className="text-sm text-muted-foreground">
            Enable this section to edit its properties
          </p>
        </div>
      )}
    </div>
  );
};

// Component for a single section item
const SectionItem = ({
  category,
  sectionKey,
  section,
  exists,
  expandedSections,
  initializeSection,
  toggleSection,
  handleToggleSection,
  handleSectionUpdate,
}: {
  category: string;
  sectionKey: string;
  section: any;
  exists: boolean;
  expandedSections: string[];
  initializeSection: (key: string) => void;
  toggleSection: (key: string) => void;
  handleToggleSection: (key: string, checked: boolean) => void;
  handleSectionUpdate: (key: string, field: string, value: any) => void;
}) => {
  // If section doesn't exist, use a placeholder for display
  const displaySection = section || {
    is_enabled: false,
    title: formatSectionKeyToTitle(sectionKey),
    title_only: false,
    content_prompt: null,
    content_example: null,
    formatting_prompt: null,
    formatting_example: null,
  };

  // Used to determine if we should prevent expansion
  const isDisabled = exists && !displaySection.is_enabled;

  // Determine class based on state
  let sectionClass = 'border rounded-md overflow-hidden';
  if (!exists) {
    sectionClass += ' border-dashed';
  } else if (isDisabled) {
    sectionClass += ' opacity-75';
  }

  return (
    <Collapsible
      key={sectionKey}
      open={expandedSections.includes(sectionKey)}
      onOpenChange={(isOpen) => {
        if (!exists) {
          // Initialize section when expanding unconfigured section
          initializeSection(sectionKey);
        }
        toggleSection(sectionKey);
      }}
      className={sectionClass}
    >
      <CollapsibleTrigger className="w-full">
        <div className="flex items-center justify-between p-3 hover:bg-muted/50 cursor-pointer">
          <div className="flex items-center gap-3">
            <div className="flex items-center h-full pt-[2px]">
              <Checkbox
                id={`enable-${sectionKey}`}
                checked={exists && displaySection.is_enabled}
                onCheckedChange={(checked) => {
                  if (!exists) {
                    initializeSection(sectionKey);
                  }
                  handleToggleSection(sectionKey, checked as boolean);
                  // Don't close the collapsible when clicking the checkbox
                  return false;
                }}
                className="data-[state=checked]:bg-white"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <div className="flex flex-col items-start">
              <label
                htmlFor={`enable-${sectionKey}`}
                className="font-medium cursor-pointer leading-5"
              >
                {displaySection.title}
              </label>
              {!exists && (
                <span className="text-xs text-orange-500 block mt-0.5">
                  Default - click to modify
                </span>
              )}
              {exists && !displaySection.is_enabled && (
                <span className="text-xs text-gray-500 block mt-0.5">
                  Disabled
                </span>
              )}
              {exists && displaySection.is_enabled && (
                <span className="text-xs text-green-600 block mt-0.5">
                  Enabled
                </span>
              )}
            </div>
          </div>
          <ChevronDown className="h-4 w-4 transition-transform duration-200 transform ui-open:rotate-180" />
        </div>
      </CollapsibleTrigger>

      {exists && (
        <CollapsibleContent className="border-t">
          <SectionFormFields
            sectionKey={sectionKey}
            displaySection={displaySection}
            handleSectionUpdate={handleSectionUpdate}
          />
        </CollapsibleContent>
      )}
    </Collapsible>
  );
};

// Component for a category of sections
const CategorySection = ({
  category,
  sectionKeys,
  localConfig,
  expandedSections,
  searchTerm,
  initializeSection,
  toggleSection,
  handleToggleSection,
  handleSectionUpdate,
}: {
  category: string;
  sectionKeys: DemandDocumentSectionType[];
  localConfig: any;
  expandedSections: string[];
  searchTerm: string;
  initializeSection: (key: string) => void;
  toggleSection: (key: string) => void;
  handleToggleSection: (key: string, checked: boolean) => void;
  handleSectionUpdate: (key: string, field: string, value: any) => void;
}) => {
  // Helper function to get section title
  const getSectionTitle = (key: string) => {
    if (localConfig.configuration[key as DemandDocumentSectionType]) {
      return (
        localConfig.configuration[key as DemandDocumentSectionType].title ||
        formatSectionKeyToTitle(key)
      );
    }

    // Default title formatting
    return formatSectionKeyToTitle(key);
  };

  const categorySections = sectionKeys
    .filter(
      (key) =>
        !searchTerm ||
        (localConfig.configuration[key]
          ? localConfig.configuration[key].title
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          : getSectionTitle(key)
              .toLowerCase()
              .includes(searchTerm.toLowerCase())),
    )
    .map((key) => ({
      key,
      section: localConfig.configuration[key],
      exists: !!localConfig.configuration[key],
    }));

  if (categorySections.length === 0) return null;

  return (
    <div key={category} className="space-y-2">
      <h3 className="text-lg font-semibold">{category}</h3>
      {categorySections.map(({ key, section, exists }) => (
        <SectionItem
          key={key}
          category={category}
          sectionKey={key}
          section={section}
          exists={exists}
          expandedSections={expandedSections}
          initializeSection={initializeSection}
          toggleSection={toggleSection}
          handleToggleSection={handleToggleSection}
          handleSectionUpdate={handleSectionUpdate}
        />
      ))}
    </div>
  );
};

// Header component with action buttons
const EditorHeader = ({
  localConfig,
  isUpdating,
  hasChanges,
  navigate,
  saveChanges,
  setLocalConfig,
  setHasChanges,
}: {
  localConfig: any;
  isUpdating: boolean;
  hasChanges: boolean;
  navigate: any;
  saveChanges: () => void;
  setLocalConfig: (config: any) => void;
  setHasChanges: (hasChanges: boolean) => void;
}) => (
  <div className="space-y-6">
    <div className="flex justify-between items-center sticky top-0 bg-background z-10 py-4 border-b">
      <h1 className="text-2xl font-bold">
        Edit Configuration: {localConfig.name}
      </h1>

      <div className="flex items-center gap-2">
        <Button
          variant="outline"
          onClick={() => navigate('/firms/demand-configurations')}
          disabled={isUpdating}
        >
          <X className="h-4 w-4 mr-2" />
          Cancel
        </Button>

        <Button
          variant="outline"
          onClick={() => {
            navigate(
              `/firms/demand-configurations/create?copy=${localConfig.id}&name=${encodeURIComponent(localConfig.name)}&firmId=${localConfig.firm}&caseType=${localConfig.case_type}`,
            );
          }}
          disabled={isUpdating}
        >
          <Copy className="h-4 w-4 mr-2" />
          Duplicate
        </Button>

        <Button onClick={saveChanges} disabled={!hasChanges || isUpdating}>
          <Save className="h-4 w-4 mr-2" />
          {isUpdating ? 'Saving...' : 'Save Changes'}
        </Button>
      </div>
    </div>

    <div className="border rounded-md p-6 space-y-4">
      <div className="space-y-2">
        <Label htmlFor="config-name">Configuration Name</Label>
        <Input
          id="config-name"
          value={localConfig.name}
          onChange={(e) => {
            setLocalConfig({
              ...localConfig,
              name: e.target.value,
            });
            setHasChanges(true);
          }}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="config-description">Description</Label>
        <Textarea
          id="config-description"
          value={localConfig.description || ''}
          onChange={(e) => {
            setLocalConfig({
              ...localConfig,
              description: e.target.value,
            });
            setHasChanges(true);
          }}
          placeholder="Description of this configuration"
        />
      </div>

      <div className="space-y-2">
        <Label>Case Type</Label>
        <p className="text-sm text-muted-foreground">{localConfig.case_type}</p>
        <p className="text-xs text-muted-foreground">
          Case type cannot be changed after creation
        </p>
      </div>
    </div>
  </div>
);

// Search and actions toolbar
const SectionsToolbar = ({
  searchTerm,
  setSearchTerm,
  enabledCount,
  configuredSections,
  totalAvailableSections,
  toggleAllSections,
}: {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  enabledCount: number;
  configuredSections: number;
  totalAvailableSections: number;
  toggleAllSections: (checked: boolean) => void;
}) => (
  <div className="flex justify-between items-center">
    <div className="relative w-72">
      <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        placeholder="Search sections..."
        className="pl-8"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>

    <div className="flex items-center gap-4">
      <p className="text-sm text-muted-foreground">
        {enabledCount} of {configuredSections} configured sections enabled
        <span className="ml-1 text-xs text-muted-foreground">
          ({totalAvailableSections} total available)
        </span>
      </p>
      <div className="space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => toggleAllSections(true)}
        >
          <CheckSquare className="h-4 w-4 mr-2" />
          Enable All
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => toggleAllSections(false)}
        >
          <Square className="h-4 w-4 mr-2" />
          Disable All
        </Button>
      </div>
    </div>
  </div>
);

// Loading skeleton component
const LoadingSkeleton = () => (
  <div className="p-6 space-y-6">
    <div className="flex justify-between items-center">
      <Skeleton className="h-8 w-72" />
      <div className="flex gap-2">
        <Skeleton className="h-9 w-32" />
        <Skeleton className="h-9 w-32" />
      </div>
    </div>
    <Skeleton className="h-10 w-96" />
    <div className="space-y-4">
      {Array.from({ length: 5 }).map((_, i) => (
        <Skeleton key={i} className="h-24 w-full" />
      ))}
    </div>
  </div>
);

// Error component
const ErrorDisplay = ({ navigate }: { navigate: any }) => (
  <div className="p-6">
    <div className="p-4 border border-red-300 bg-red-50 rounded-md text-red-700">
      <h2 className="font-bold">Error loading configuration</h2>
      <p>Please try again later or contact support.</p>
      <Button
        className="mt-4"
        onClick={() => navigate('/firms/demand-configurations')}
      >
        Go Back
      </Button>
    </div>
  </div>
);

// Main component
export const DemandConfigurationEditor = ({
  configId: propConfigId,
}: {
  configId: string;
}) => {
  const { configId: urlConfigId } = useParams<{ configId: string }>();
  const navigate = useNavigate();

  // Use prop configId if provided, otherwise use from URL
  const effectiveConfigId = propConfigId || urlConfigId || '';

  const {
    data: config,
    isLoading,
    error,
  } = useGetDemandConfigurationQuery(
    { configurationId: effectiveConfigId },
    { skip: !effectiveConfigId },
  );

  const [updateConfiguration, { isLoading: isUpdating }] =
    useUpdateDemandConfigurationMutation();

  // Local state
  const [localConfig, setLocalConfig] = useState(config);
  const [expandedSections, setExpandedSections] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasChanges, setHasChanges] = useState(false);

  // Reset local config when the API data changes
  useEffect(() => {
    if (config) {
      setLocalConfig(config);
      setHasChanges(false);
    }
  }, [config]);

  if (isLoading || !localConfig) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return <ErrorDisplay navigate={navigate} />;
  }

  // Toggle section expansion
  const toggleSection = (sectionKey: string) => {
    setExpandedSections((prev) =>
      prev.includes(sectionKey)
        ? prev.filter((key) => key !== sectionKey)
        : [...prev, sectionKey],
    );
  };

  // Toggle section enable/disable state
  const handleToggleSection = (sectionKey: string, checked: boolean) => {
    if (!localConfig) return;

    const updatedConfiguration = {
      ...localConfig.configuration,
      [sectionKey]: {
        ...localConfig.configuration[sectionKey as DemandDocumentSectionType],
        is_enabled: checked,
      },
    };

    setLocalConfig({
      ...localConfig,
      configuration: updatedConfiguration,
    });

    setHasChanges(true);
  };

  // Update section fields
  const handleSectionUpdate = (
    sectionKey: string,
    field: string,
    value: any,
  ) => {
    if (!localConfig) return;

    const updatedConfiguration = {
      ...localConfig.configuration,
      [sectionKey]: {
        ...localConfig.configuration[sectionKey as DemandDocumentSectionType],
        [field]: value,
      },
    };

    setLocalConfig({
      ...localConfig,
      configuration: updatedConfiguration,
    });

    setHasChanges(true);
  };

  // Toggle all sections
  const toggleAllSections = (checked: boolean) => {
    if (!localConfig) return;

    const updatedConfiguration = { ...localConfig.configuration };

    // First, handle existing configuration entries
    Object.keys(updatedConfiguration).forEach((key) => {
      updatedConfiguration[key as DemandDocumentSectionType] = {
        ...updatedConfiguration[key as DemandDocumentSectionType],
        is_enabled: checked,
      };
    });

    // Then, initialize and set state for any unconfigured sections
    sectionOrder.forEach((sectionKey) => {
      if (!updatedConfiguration[sectionKey]) {
        // Create a default configuration for this section
        updatedConfiguration[sectionKey] = {
          is_enabled: checked,
          title: formatSectionKeyToTitle(sectionKey),
          title_only: false,
          content_prompt: null,
          content_example: null,
          formatting_prompt: null,
          formatting_example: null,
        };
      }
    });

    setLocalConfig({
      ...localConfig,
      configuration: updatedConfiguration,
    });

    setHasChanges(true);
  };

  // Save changes
  const saveChanges = async () => {
    if (!localConfig || !hasChanges) return;

    try {
      await updateConfiguration({
        firmId: localConfig.firm,
        configurationId: localConfig.id,
        data: {
          name: localConfig.name,
          description: localConfig.description || undefined,
          configuration: localConfig.configuration,
        },
      }).unwrap();

      toast.success('Configuration saved successfully');
      setHasChanges(false);
    } catch (err) {
      toast.error('Failed to save configuration');
      console.error('Error saving configuration:', err);
    }
  };

  // Get section title (from config or default)
  const getSectionTitle = (key: string) => {
    if (localConfig.configuration[key as DemandDocumentSectionType]) {
      return (
        localConfig.configuration[key as DemandDocumentSectionType].title ||
        formatSectionKeyToTitle(key)
      );
    }

    // Default title formatting
    return formatSectionKeyToTitle(key);
  };

  // Create default section configuration
  const createDefaultSection = (key: string) => {
    return {
      is_enabled: false,
      title: formatSectionKeyToTitle(key),
      title_only: false,
      content_prompt: null,
      content_example: null,
      formatting_prompt: null,
      formatting_example: null,
    };
  };

  // Initialize a section that doesn't exist yet
  const initializeSection = (key: string) => {
    if (!localConfig.configuration[key as DemandDocumentSectionType]) {
      const updatedConfiguration = {
        ...localConfig.configuration,
        [key]: createDefaultSection(key),
      };

      setLocalConfig({
        ...localConfig,
        configuration: updatedConfiguration,
      });

      setHasChanges(true);

      // Auto-expand the newly initialized section
      if (!expandedSections.includes(key)) {
        setExpandedSections([...expandedSections, key]);
      }
    }
  };

  const configuredSections = Object.keys(localConfig.configuration).length;
  const enabledCount = Object.values(localConfig.configuration).filter(
    (section) => section.is_enabled,
  ).length;
  const totalAvailableSections = sectionOrder.length;

  return (
    <div className="p-6 space-y-6">
      <EditorHeader
        localConfig={localConfig}
        isUpdating={isUpdating}
        hasChanges={hasChanges}
        navigate={navigate}
        saveChanges={saveChanges}
        setLocalConfig={setLocalConfig}
        setHasChanges={setHasChanges}
      />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="space-y-6">
          <h2 className="text-xl font-semibold">Sections</h2>
          <SectionsToolbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            enabledCount={enabledCount}
            configuredSections={configuredSections}
            totalAvailableSections={totalAvailableSections}
            toggleAllSections={toggleAllSections}
          />

          <div className="space-y-4 overflow-y-auto pr-2 max-h-[calc(100vh-300px)]">
            {Object.entries(sectionCategories).map(
              ([category, sectionKeys]) => (
                <CategorySection
                  key={category}
                  category={category}
                  sectionKeys={sectionKeys}
                  localConfig={localConfig}
                  expandedSections={expandedSections}
                  searchTerm={searchTerm}
                  initializeSection={initializeSection}
                  toggleSection={toggleSection}
                  handleToggleSection={handleToggleSection}
                  handleSectionUpdate={handleSectionUpdate}
                />
              ),
            )}
          </div>
        </div>

        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Preview</h2>
          <div className="overflow-y-auto pr-2 max-h-[calc(100vh-200px)]">
            {localConfig && (
              <DemandConfigurationPreview configuration={localConfig} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandConfigurationEditor;

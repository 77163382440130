import React, { useMemo, useState } from 'react';

import { ProofreadingRuleStatus } from '../../../services/types/proofreading-types';
import { ProofreadingEmptyState } from '../common/ProofreadingEmptyState';
import { ProofreadingSkeleton } from '../common/ProofreadingSkeleton';
import { useProofreadingJob } from '../hooks/useProofreadingJob';
import { useRuleResults } from '../hooks/useRuleResults';
import { ResultsOverview } from './ResultsOverview';
import { RuleResultCard } from './RuleResultCard';

interface RuleResultsListProps {
  jobId: string;
  demandId: string;
}

export const RuleResultsList: React.FC<RuleResultsListProps> = ({
  jobId,
  demandId,
}) => {
  const [activeFilter, setActiveFilter] = useState('all');

  const { jobResult, isLoading, error } = useProofreadingJob(demandId, jobId);
  const { statistics } = useRuleResults(jobResult);

  // Filter the rule results
  const filteredResults = useMemo(() => {
    if (!jobResult?.rule_results) return [];

    let results = [...jobResult.rule_results];

    // Apply status filter
    switch (activeFilter) {
      case 'passed':
        results = results.filter(
          (r) => r.result.status === ProofreadingRuleStatus.NO_ISSUES,
        );
        break;
      case 'minor':
        results = results.filter(
          (r) => r.result.status === ProofreadingRuleStatus.MINOR_ISSUES_FOUND,
        );
        break;
      case 'moderate':
        results = results.filter(
          (r) =>
            r.result.status === ProofreadingRuleStatus.MODERATE_ISSUES_FOUND,
        );
        break;
      case 'critical':
        results = results.filter(
          (r) =>
            r.result.status === ProofreadingRuleStatus.CRITICAL_ISSUES_FOUND,
        );
        break;
      case 'unableToAssess':
        results = results.filter(
          (r) => r.result.status === ProofreadingRuleStatus.UNABLE_TO_ASSESS,
        );
        break;
      default:
        // 'all' - no filtering needed
        break;
    }

    // Sort by severity
    return results.sort((a, b) => {
      const severityOrder = {
        [ProofreadingRuleStatus.CRITICAL_ISSUES_FOUND]: 0,
        [ProofreadingRuleStatus.MODERATE_ISSUES_FOUND]: 1,
        [ProofreadingRuleStatus.MINOR_ISSUES_FOUND]: 2,
        [ProofreadingRuleStatus.UNABLE_TO_ASSESS]: 3,
        [ProofreadingRuleStatus.NO_ISSUES]: 4,
      };

      return severityOrder[a.result.status] - severityOrder[b.result.status];
    });
  }, [jobResult, activeFilter]);

  if (isLoading) {
    return <ProofreadingSkeleton type="results" />;
  }

  if (error) {
    return (
      <div className="p-3 border border-red-200 rounded-md bg-red-50 text-red-800 text-sm">
        Failed to load proofreading results. Please try again.
      </div>
    );
  }

  if (
    !jobResult ||
    !jobResult.rule_results ||
    jobResult.rule_results.length === 0
  ) {
    return (
      <ProofreadingEmptyState
        type="results"
        onAction={() => console.log('Navigate back to jobs')}
      />
    );
  }

  return (
    <div className="space-y-3">
      <ResultsOverview
        jobResult={jobResult}
        activeFilter={activeFilter}
        onFilterChange={setActiveFilter}
      />

      {filteredResults.length === 0 ? (
        <div className="bg-gray-50 rounded-lg p-6 text-center text-gray-500 text-sm">
          No rules match this filter
        </div>
      ) : (
        <div className="space-y-2">
          {filteredResults.map((result, index) => (
            <RuleResultCard
              key={`${result.rule.id}-${index}`}
              ruleResult={result}
              expandedByDefault={
                result.result.status ===
                ProofreadingRuleStatus.CRITICAL_ISSUES_FOUND
              }
              compact
            />
          ))}
        </div>
      )}
    </div>
  );
};

import { Box, Grid } from '@mui/material';
import { MatterClient } from '../../../../services/types/matter-client-types';
import ReadOnlyNoteSection from './ReadOnlyNoteSection';
import ReadOnlyNoteField from './ReadOnlyNoteField';
import { hasPopulatedFields } from '../../../../utils/noteUtils';

interface ReadOnlyMatterClientNoteSectionProps {
  client?: MatterClient;
}

const ReadOnlyMatterClientNoteSection = ({
  client,
}: ReadOnlyMatterClientNoteSectionProps) => {
  if (!client || !hasPopulatedFields(client)) return null;

  const formatAddress = (address: MatterClient['address']) => {
    if (!address) return '';
    const parts = [
      address.line1,
      address.line2,
      address.line3,
      address.city,
      address.state,
      address.postcode,
    ].filter(Boolean);
    return parts.length > 0 ? parts.join(', ') : '';
  };

  return (
    <ReadOnlyNoteSection title="Personal Information">
      <Grid container spacing={2} columns={12}>
        <Grid item xs={12} md={6}>
          <ReadOnlyNoteField label="NAME" value={client.name} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReadOnlyNoteField label="EMAIL" value={client.email} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReadOnlyNoteField
            label="PHONE"
            value={client.phone_numbers?.[0]?.phone_number}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReadOnlyNoteField
            label="ADDRESS"
            value={formatAddress(client.address)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReadOnlyNoteField
            label="DATE OF BIRTH"
            value={client.date_of_birth}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReadOnlyNoteField
            label="LAST 4 OF SSN"
            value={client.last_four_of_ssn}
          />
        </Grid>
      </Grid>
    </ReadOnlyNoteSection>
  );
};

export default ReadOnlyMatterClientNoteSection;

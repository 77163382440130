import { ReactNodeViewRenderer } from '@tiptap/react';
import Mention, { MentionOptions } from '@tiptap/extension-mention';
import { Editor } from '@tiptap/core';
import { TemplateNodeView } from '../components/TemplateNodeView';
import suggestion from '../config/template-node-suggestion';

export interface TemplateNodeOptions extends MentionOptions {
  templateValues?: Record<string, string>;
}

export const TemplateNodeExtension = Mention.extend<TemplateNodeOptions>({
  name: 'template',

  addStorage() {
    return {
      values: {} as Record<string, string>,
    };
  },

  addOptions() {
    return {
      ...this.parent?.(),
      HTMLAttributes: {
        class: 'template-node',
      },
      suggestion,
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({
          'data-id': attributes.id,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="template"]',
        getAttrs: (element) => {
          const id = element.getAttribute('data-id');
          return id ? { id } : false;
        },
      },
    ];
  },

  renderHTML({ node }) {
    return [
      'span',
      {
        'data-type': 'template',
        'data-id': node.attrs.id,
      },
    ];
  },

  renderText({ node }) {
    return `[${node.attrs.id}]`;
  },

  addNodeView() {
    return ReactNodeViewRenderer(TemplateNodeView);
  },
});

import { Box, Stack } from '@mui/material';
import { DetailedMatter } from '../../services/types/client-intake-types';
import ReadOnlyIntakeCallSection from './sections/ReadOnlyIntakeCallSection';
import ReadOnlyKeyConsiderations from './sections/ReadOnlyKeyConsiderations';
import ReadOnlyLetters from './sections/ReadOnlyLetters';
import ReadOnlySummaries from './sections/ReadOnlySummaries';
import ReadOnlyMatterNotes from './sections/ReadOnlyMatterNotes';

interface ReadOnlyMatterProps {
  matter: DetailedMatter;
}

export const ReadOnlyMatter = ({ matter }: ReadOnlyMatterProps) => {
  const hasSummaries = matter.summaries && matter.summaries.length > 0;
  const hasKeyConsiderations =
    matter.key_considerations && matter.key_considerations.length > 0;

  return (
    <Stack
      direction="column"
      spacing={6}
      sx={{
        p: 6,
        flexGrow: 1,
        overflow: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {hasSummaries && <ReadOnlySummaries matter={matter} />}
        {hasKeyConsiderations && <ReadOnlyKeyConsiderations matter={matter} />}
      </Box>
      <ReadOnlyIntakeCallSection matter={matter} />
      <ReadOnlyMatterNotes matter={matter} />
      {/* <ReadOnlyLetters /> */}
    </Stack>
  );
};

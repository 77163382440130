import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Briefcase, Calendar as CalendarIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import { Calendar } from '@/components/ui/calendar';
import { ScheduleFormValues } from './types';
import { getNextBusinessDays } from './utils';

// Memoized calendar class names to prevent re-renders
const calendarClassNames = {
  day: 'h-9 w-9 p-0 font-normal rounded-full focus:outline-none',
  day_selected: 'text-primary font-bold border-3 border-primary',
  caption: 'flex justify-center pt-1 relative items-center mb-4',
  caption_label: 'text-lg font-bold',
  nav_button: 'h-7 w-7 bg-transparent p-0 opacity-75 hover:opacity-100',
  table: 'w-full border-collapse',
  head_row: 'flex',
  head_cell: 'text-muted-foreground rounded-md w-9 font-medium text-[0.8rem]',
  row: 'flex w-full mt-2',
  cell: 'relative p-0 text-center text-sm',
};

export const DaysOfMonthSelector = () => {
  const form = useFormContext<ScheduleFormValues>();
  const selectedDates = form.watch('selectedDates') || [];

  // Function to handle selecting business days
  const handleSelectBusinessDays = useCallback(() => {
    const businessDays = getNextBusinessDays(5);
    form.setValue('selectedDates', businessDays, { shouldValidate: true });
  }, [form]);

  // Optimize date selection handler
  const handleDateSelect = useCallback(
    (dates: Date[] | undefined) => {
      if (dates) {
        form.setValue('selectedDates', dates, { shouldValidate: true });

        // Show validation error if no dates selected
        if (dates.length === 0) {
          form.setError('selectedDates', {
            type: 'manual',
            message: 'Select at least one date',
          });
        } else {
          form.clearErrors('selectedDates');
        }
      }
    },
    [form],
  );

  return (
    <FormField
      control={form.control}
      name="selectedDates"
      render={({ field }) => (
        <FormItem className="border rounded-lg p-4">
          <div className="flex items-center justify-between mb-2">
            <FormLabel className="text-lg block m-0">
              Which Days to Call
            </FormLabel>
            <div className="flex gap-2">
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={handleSelectBusinessDays}
                className="flex items-center gap-1.5"
              >
                <Briefcase className="h-3.5 w-3.5" />
                <span>Next 5 Business Days</span>
              </Button>
            </div>
          </div>

          <p className="mb-4 text-sm text-muted-foreground">
            Select dates on the calendar when you want calls to be made
          </p>

          <FormControl>
            <Calendar
              mode="multiple"
              selected={field.value}
              onSelect={handleDateSelect}
              className="mx-auto"
              numberOfMonths={1}
              fixedWeeks
              disableNavigation={false}
              fromMonth={new Date()} // Can navigate from current month
              // Remove toMonth constraint to enable forward navigation
              classNames={calendarClassNames}
            />
          </FormControl>

          {selectedDates.length > 0 && (
            <div className="mt-4 text-sm text-muted-foreground">
              <div className="flex items-center gap-1.5">
                <CalendarIcon className="h-4 w-4 opacity-70" />
                <span className="font-medium">
                  {selectedDates.length} date
                  {selectedDates.length !== 1 ? 's' : ''} selected
                </span>
              </div>
            </div>
          )}

          <FormMessage />
        </FormItem>
      )}
    />
  );
};

import { Box, Grid } from '@mui/material';
import { ClientEmploymentNote } from '../../../../services/types/matter-notes-types';
import ReadOnlyNoteSection from './ReadOnlyNoteSection';
import ReadOnlyNoteField from './ReadOnlyNoteField';
import { hasPopulatedFields } from '../../../../utils/noteUtils';

interface ReadOnlyClientEmploymentNoteSectionProps {
  note?: ClientEmploymentNote;
}

const ReadOnlyClientEmploymentNoteSection = ({
  note,
}: ReadOnlyClientEmploymentNoteSectionProps) => {
  if (!note || !hasPopulatedFields(note)) return null;

  return (
    <ReadOnlyNoteSection title="Lost Wages">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <ReadOnlyNoteField label="LOST WORK TIME" value={note.description} />
        <ReadOnlyNoteField label="EMPLOYER" value={note.employer} />
        <ReadOnlyNoteField label="ROLE" value={note.role} />
        <ReadOnlyNoteField label="WAGE" value={note.wage} />
      </Box>
    </ReadOnlyNoteSection>
  );
};

export default ReadOnlyClientEmploymentNoteSection;

import { formatDistanceToNow } from 'date-fns';
import { ChevronDown, ChevronRight, FileText, Plus } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  useGetDemandConfigurationQuery,
  useListDemandConfigurationsQuery,
} from '@/services/api/demandService';
import { DemandDocumentSectionType } from '@/services/types/demand-types';

// Export all the components for easy access
export { DemandConfigurationCreate } from './demand-configuration-create';
export { DemandConfigurationEditor } from './demand-configuration-editor';
export { DemandConfigurationPreview } from './demand-configuration-preview';

// Component for the expanded row details - now read-only and more densely laid out
const ExpandedConfigDetails = ({ configId }: { configId: string }) => {
  const {
    data: config,
    isLoading,
    error,
  } = useGetDemandConfigurationQuery({ configurationId: configId });

  // Group sections by categories (reusing the same structure from sectionOrder)
  const sectionCategories = {
    Introduction: [DemandDocumentSectionType.INTRODUCTION],
    'Facts & Liability': [
      DemandDocumentSectionType.FACTS_LIABILITY,
      DemandDocumentSectionType.DEFENDANT_NEGLIGENCE_AND_BREACH_OF_DUTY,
      DemandDocumentSectionType.PROXIMATE_CAUSE_OF_HARM,
    ],
    'Injuries & Treatment': [
      DemandDocumentSectionType.INJURIES_AND_TREATMENT,
      DemandDocumentSectionType.SUMMARY_INJURIES,
      DemandDocumentSectionType.TREATMENT,
    ],
    Damages: [
      DemandDocumentSectionType.DAMAGES,
      DemandDocumentSectionType.TOTAL_PROJECTED_CLAIM_VALUE,
      DemandDocumentSectionType.PAST_MEDICAL_EXPENSES,
      DemandDocumentSectionType.FUTURE_MEDICAL_EXPENSES,
      DemandDocumentSectionType.LOST_WAGES,
      DemandDocumentSectionType.PROPERTY_DAMAGE,
      DemandDocumentSectionType.LOSS_HOUSEHOLD_SERVICES,
      DemandDocumentSectionType.PAIN_SUFFERING,
    ],
    Analysis: [
      DemandDocumentSectionType.VERDICT_ANALYSIS,
      DemandDocumentSectionType.PER_DIEM_ANALYSIS,
    ],
    Conclusion: [DemandDocumentSectionType.DEMAND_TO_SETTLE],
  };

  if (isLoading || !config) {
    return (
      <div className="py-3 px-4">
        <Skeleton className="h-4 w-full mb-2" />
        <Skeleton className="h-4 w-3/4 mb-2" />
        <Skeleton className="h-4 w-1/2" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="py-3 px-4 text-red-500">
        Failed to load configuration details.
      </div>
    );
  }

  // Get all sections for this config
  const configuredSections = Object.entries(config.configuration)
    .filter(([_, section]) => section) // Filter out undefined sections
    .map(([key, section]) => ({
      key: key as DemandDocumentSectionType,
      section,
    }));

  const enabledCount = configuredSections.filter(
    ({ section }) => section.is_enabled,
  ).length;

  // Helper function to get status badge for a section
  const getSectionBadge = (sectionKey: DemandDocumentSectionType) => {
    const section = config.configuration[sectionKey];

    if (!section) {
      return (
        <Badge variant="outline" className="text-orange-500 bg-orange-50">
          Default
        </Badge>
      );
    }

    return section.is_enabled ? (
      <Badge variant="outline" className="text-green-600 bg-green-50">
        Enabled
      </Badge>
    ) : (
      <Badge variant="outline" className="text-gray-500 bg-gray-50">
        Disabled
      </Badge>
    );
  };

  // Helper to get section title from configuration or default
  const getSectionTitle = (sectionKey: DemandDocumentSectionType) => {
    const section = config.configuration[sectionKey];
    if (section) {
      return section.title;
    }

    // Default titles if not configured
    return sectionKey
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className="py-5 px-6 bg-gray-50">
      <div className="flex justify-between items-center mb-4">
        <div>
          <h3 className="text-lg font-semibold mb-1">Section Overview</h3>
          <p className="text-sm text-muted-foreground">
            {enabledCount} of {configuredSections.length} configured sections
            enabled
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.entries(sectionCategories).map(([category, sectionKeys]) => (
          <div key={category} className="border rounded-md p-3">
            <h4 className="font-medium text-sm mb-2 pb-1 border-b">
              {category}
            </h4>
            <div className="space-y-2">
              {sectionKeys.map((sectionKey) => (
                <div
                  key={`${configId}-${sectionKey}`}
                  className="flex justify-between items-center"
                >
                  <span className="text-sm">{getSectionTitle(sectionKey)}</span>
                  {getSectionBadge(sectionKey)}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const DemandConfigurationListView = () => {
  const {
    data: configurations,
    isLoading,
    error,
  } = useListDemandConfigurationsQuery();
  const [expandedRowId, setExpandedRowId] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleRowClick = (id: string) => {
    if (expandedRowId === id) {
      setExpandedRowId(null); // Close if already open
    } else {
      setExpandedRowId(id); // Open if closed
    }
  };

  const handleCreateNew = () => {
    navigate('/firms/demand-configurations/create');
  };

  const handleEditConfiguration = (id: string) => {
    navigate(`/firms/demand-configurations/${id}/edit`);
  };

  if (isLoading) {
    return (
      <div className="space-y-4">
        <h2 className="text-2xl font-bold">Demand Configurations</h2>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[300px]">Name</TableHead>
                <TableHead>Case Type</TableHead>
                <TableHead>Created</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {[1, 2, 3].map((i) => (
                <TableRow key={`skeleton-row-${i}`}>
                  <TableCell>
                    <Skeleton className="h-5 w-3/4" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-1/2" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-1/2" />
                  </TableCell>
                  <TableCell className="text-right">
                    <Skeleton className="h-9 w-32 ml-auto" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 border border-red-300 bg-red-50 rounded-md text-red-700">
        <h2 className="font-bold">Error loading configurations</h2>
        <p>Please try again later or contact support.</p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Demand Configurations</h2>
        <Button onClick={handleCreateNew}>
          <Plus className="h-4 w-4 mr-2" />
          Create New
        </Button>
      </div>

      {configurations && configurations.length > 0 ? (
        <div className="rounded-md border">
          <Table className="border-none overflow-hidden">
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Case Type</TableHead>
                <TableHead>Created</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {configurations.map((config) => (
                <React.Fragment key={config.id}>
                  <TableRow
                    key={`${config.id}-${config.name}`}
                    className="cursor-pointer hover:bg-muted/50"
                    onClick={() => handleRowClick(config.id)}
                  >
                    <TableCell>
                      <div className="flex items-center">
                        {expandedRowId === config.id ? (
                          <ChevronDown className="h-4 w-4 text-muted-foreground mr-2" />
                        ) : (
                          <ChevronRight className="h-4 w-4 text-muted-foreground mr-2" />
                        )}
                        <FileText className="h-5 w-5 text-muted-foreground mr-2" />
                        <div>
                          <div className="font-medium">{config.name}</div>
                          {config.description && (
                            <div className="text-sm text-muted-foreground">
                              {config.description}
                            </div>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{config.case_type}</TableCell>
                    <TableCell>
                      {formatDistanceToNow(new Date(config.created_at), {
                        addSuffix: true,
                      })}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click handler
                          handleEditConfiguration(config.id);
                        }}
                      >
                        Edit Configuration
                      </Button>
                    </TableCell>
                  </TableRow>
                  {expandedRowId === config.id && (
                    <TableRow>
                      <TableCell colSpan={4} className="p-0 border-t-0">
                        <ExpandedConfigDetails configId={config.id} />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className="text-center py-8 border border-dashed rounded-md">
          <p className="text-muted-foreground mb-4">
            No demand configurations found
          </p>
          <Button onClick={handleCreateNew}>
            <Plus className="h-4 w-4 mr-2" />
            Create Your First Configuration
          </Button>
        </div>
      )}
    </div>
  );
};

// Default export for the main component
export default DemandConfigurationListView;

import {
  CanonicalTaskType,
  TASKS_WITH_ATTACHED_INSURANCE_POLICY,
  TASKS_WITH_ATTACHED_MEDICAL_PROVIDER,
} from '@/services/types/task-types';
import {
  CategoryDefinition,
  DisplayCategoryName,
  VariableCategory,
} from '../types';

// Map category IDs to their display names
export const CATEGORY_DISPLAY_NAMES: Record<
  VariableCategory,
  DisplayCategoryName
> = {
  firm: 'Firm Information',
  matter: 'Matter Details',
  client: 'Client Information',
  police: 'Police Information',
  medical: 'Medical Information',
  insurance: 'Insurance Details',
  incident: 'Incident Information',
};

// Define base categories that are always available
export const BASE_CATEGORIES: VariableCategory[] = [
  'firm',
  'matter',
  'client',
  'police',
  'incident',
];

// Complete category definitions with validation rules
export const VARIABLE_CATEGORIES: CategoryDefinition[] = [
  {
    id: 'firm',
    displayName: 'Firm Information',
    prefix: 'firm',
    alwaysAvailable: true,
  },
  {
    id: 'matter',
    displayName: 'Matter Details',
    prefix: 'matter',
    alwaysAvailable: true,
  },
  {
    id: 'client',
    displayName: 'Client Information',
    prefix: 'client',
    alwaysAvailable: true,
  },
  {
    id: 'police',
    displayName: 'Police Information',
    prefix: 'police',
    alwaysAvailable: true,
  },
  {
    id: 'incident',
    displayName: 'Incident Information',
    prefix: 'incident',
    alwaysAvailable: true,
  },
  {
    id: 'medical',
    displayName: 'Medical Information',
    prefix: 'medical',
    alwaysAvailable: false,
    taskTypeCondition: (taskType: CanonicalTaskType) =>
      TASKS_WITH_ATTACHED_MEDICAL_PROVIDER.includes(taskType),
  },
  {
    id: 'insurance',
    displayName: 'Insurance Details',
    prefix: 'insurance',
    alwaysAvailable: false,
    taskTypeCondition: (taskType: CanonicalTaskType) =>
      TASKS_WITH_ATTACHED_INSURANCE_POLICY.includes(taskType),
  },
];

// Helper functions
export const getCategoryById = (id: VariableCategory): CategoryDefinition => {
  const category = VARIABLE_CATEGORIES.find((cat) => cat.id === id);
  if (!category) {
    throw new Error(`Category with ID ${id} not found`);
  }
  return category;
};

export const getCategoryByDisplayName = (
  name: DisplayCategoryName,
): CategoryDefinition => {
  const category = VARIABLE_CATEGORIES.find((cat) => cat.displayName === name);
  if (!category) {
    throw new Error(`Category with display name ${name} not found`);
  }
  return category;
};

export const isCategoryAvailable = (
  categoryId: VariableCategory,
  taskType: CanonicalTaskType,
): boolean => {
  const category = getCategoryById(categoryId);
  if (category.alwaysAvailable) return true;
  return category.taskTypeCondition
    ? category.taskTypeCondition(taskType)
    : false;
};

// Function to group variables by category
export const groupVariablesByCategory = (
  variables: string[],
): Record<DisplayCategoryName, string[]> => {
  const result = {} as Record<DisplayCategoryName, string[]>;

  VARIABLE_CATEGORIES.forEach((category) => {
    result[category.displayName] = variables.filter((v) =>
      v.startsWith(category.prefix),
    );
  });

  return result;
};

// Function to get available categories for a task type
export const getAvailableCategoriesForTaskType = (
  taskType: CanonicalTaskType,
): VariableCategory[] => {
  return VARIABLE_CATEGORIES.filter(
    (category) =>
      category.alwaysAvailable ||
      (category.taskTypeCondition && category.taskTypeCondition(taskType)),
  ).map((category) => category.id);
};

// Type definitions for schedule components

import { BlandSchedule } from '@/services/types/bland-schedules-types';

// Define types for the schedule description response
export interface ScheduleCall {
  id: string;
  status: string;
  created_at: string;
}

export interface DatabaseSchedule {
  id: string;
  task_id: string | null;
  phone_number: string;
  status: string;
  created_by_id: string | null;
  schedule_start_at: string | null;
  schedule_end_at: string | null;
  time_zone_name: string;
  error_message: string | null;
  calls: ScheduleCall[];
}

export interface TemporalScheduleInfo {
  action_count: number;
}

export interface TemporalScheduleState {
  note: string;
  paused: boolean;
}

export interface TemporalSchedule {
  id: string;
  start: string | null;
  end: string | null;
  state: TemporalScheduleState;
  info: TemporalScheduleInfo;
  upcoming_run_times: string[];
  recent_action_times: string[];
}

export interface ScheduleDescriptionResponse {
  database_schedule: DatabaseSchedule;
  temporal_schedule: TemporalSchedule | null;
}

export interface ScheduleDetailsProps {
  schedule: BlandSchedule;
}

// Day of week mapping for display
export const DAYS_OF_WEEK = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];

/* eslint-disable no-nested-ternary */
// Need to import the CSS
import '../chat-styles.css';

import { ChevronDown, File, History, Loader2, Plus } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';

import {
  useCreateEditorThreadMutation,
  useGetDemandStatusQuery,
  useListEditorMessagesQuery,
  useListEditorThreadsQuery,
} from '../../../../services/api/demandService';
import { Button } from '../../../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../../ui/dropdown-menu';
import { useEditor } from '../../editor/editor-provider';
import { ChatProvider, useChat } from '../context/ChatContext';
import { MessageProvider, useMessages } from '../context/MessageContext';
import { ChatProps } from '../types';
import { ChatInput } from './ChatInput';
import { MessageList } from './MessageList';
import { ThreadList } from './ThreadList';

const ChatContentWithMessages: React.FC<{
  matterId: string;
  demandId: string;
  threadId: string;
  hasSelectedThread: boolean;
  onStartDrafting: () => void;
}> = ({ matterId, demandId, threadId, hasSelectedThread, onStartDrafting }) => {
  const { messages, isLoadingMessages } = useMessages();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  // Check if user is scrolled to bottom to determine auto-scroll
  const handleScroll = () => {
    if (!scrollContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } =
      scrollContainerRef.current;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < 30; // 30px threshold
    setShouldAutoScroll(isAtBottom);
  };

  // Update container height when dimensions change
  useEffect(() => {
    if (scrollContainerRef.current) {
      setContainerHeight(scrollContainerRef.current.clientHeight);
    }
  }, [scrollContainerRef.current?.clientHeight]);

  // Auto-scroll to bottom when messages change (if user was already at bottom)
  useEffect(() => {
    if (shouldAutoScroll && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, shouldAutoScroll]);

  return (
    <>
      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        className="flex-1 overflow-y-auto px-1 pb-2 pt-4 scrollbar-thin smooth-scroll"
      >
        <div>
          {hasSelectedThread ? (
            <MessageList
              messages={messages}
              isLoading={isLoadingMessages}
              matterId={matterId}
              demandId={demandId}
              threadId={threadId}
            />
          ) : (
            <div className="flex items-center justify-center h-full mt-24">
              <div className="text-muted-foreground text-center p-6 rounded-lg bg-muted/10 border border-border/10 max-w-md">
                <p className="text-sm mb-2">
                  Start typing to begin a new conversation
                </p>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <ChatInput
        matterId={matterId}
        demandId={demandId}
        threadId={threadId}
        onBeforeSend={!hasSelectedThread ? onStartDrafting : undefined}
        containerHeight={containerHeight}
        disableSend={!hasSelectedThread}
      />
    </>
  );
};

const ChatInner: React.FC<ChatProps> = ({ matterId, demandId }) => {
  const {
    selectedThreadId,
    setSelectedThreadId,
    newThreadTitle,
    setNewThreadTitle,
    handleCreateThread,
    handleDeleteThread,
    handleEditThread,
  } = useChat();

  const [historyOpen, setHistoryOpen] = useState(false);
  const [isCreatingThread, setIsCreatingThread] = useState(false);
  const [createThread] = useCreateEditorThreadMutation();
  const { data: demand } = useGetDemandStatusQuery({ matterId });
  const { editor } = useEditor();
  const { data: threads, isLoading: isLoadingThreads } =
    useListEditorThreadsQuery({
      matterId,
      demandId,
    });

  // Query messages for the selected thread to check if it's empty
  // Only perform the query when we have a selectedThreadId
  const { data: currentThreadMessages } = useListEditorMessagesQuery(
    { matterId, demandId, threadId: selectedThreadId || '' },
    { skip: selectedThreadId === null },
  );

  // Check if selected thread has no messages
  const hasEmptySelectedThread =
    !!selectedThreadId && currentThreadMessages?.ids?.length === 0;

  const handleInsertDemand = async () => {
    if (!demand?.generated_document) {
      console.error('demand', demand);
      return;
    }

    editor?.setData(demand.generated_document);
  };

  const handleStartDrafting = async () => {
    if (isCreatingThread) return;

    setIsCreatingThread(true);
    try {
      // Set the thread title and create immediately without relying on state updates
      const threadTitle = 'New Thread';
      setNewThreadTitle(threadTitle);

      // Directly use the mutation instead of going through context
      const response = await createThread({
        matterId,
        demandId,
        data: { title: threadTitle },
      }).unwrap();

      // Auto-select the newly created thread
      if (response && response.id) {
        setSelectedThreadId(response.id);
      }
    } catch (error) {
      console.error('Failed to create thread:', error);
    } finally {
      setIsCreatingThread(false);
    }
  };

  if (isLoadingThreads) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="flex flex-col items-center gap-2">
          <Loader2 className="h-6 w-6 animate-spin text-primary" />
          <div className="text-muted-foreground">Loading chat...</div>
        </div>
      </div>
    );
  }

  const hasSelectedThread = !!selectedThreadId;
  const activeThreadId = selectedThreadId || 'placeholder';

  return (
    <div className="flex flex-col h-full relative">
      {/* History Dropdown */}
      <div className="flex justify-between items-center p-2 mb-2 border-b">
        <div className="text-lg font-semibold">Chat</div>
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            className="flex items-center gap-1 min-w-[36px]"
            onClick={handleInsertDemand}
            title="Insert Demand"
            disabled={!demand?.generated_document}
          >
            <File className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="sm"
            className="flex items-center gap-1 min-w-[36px]"
            onClick={handleStartDrafting}
            title="Start new thread"
            disabled={isCreatingThread || hasEmptySelectedThread}
          >
            {isCreatingThread ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <Plus className="h-4 w-4" />
            )}
            {isCreatingThread && (
              <span className="sr-only">Creating thread...</span>
            )}
          </Button>
          <DropdownMenu open={historyOpen} onOpenChange={setHistoryOpen}>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="flex items-center gap-1"
              >
                <History className="h-4 w-4" />
                Threads
                <ChevronDown className="h-3 w-3 opacity-70" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-[350px] max-h-[70vh] overflow-y-auto"
            >
              <div className="p-2">
                <ThreadList
                  threads={threads || []}
                  selectedThreadId={selectedThreadId}
                  onSelectThread={(id) => {
                    setSelectedThreadId(id);
                    setHistoryOpen(false);
                  }}
                  onDeleteThread={handleDeleteThread}
                  onEditThread={handleEditThread}
                  newThreadTitle={newThreadTitle}
                  setNewThreadTitle={setNewThreadTitle}
                  onCreateThread={handleCreateThread}
                  isCreatingThread={isCreatingThread}
                />
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {/* Chat Content Area */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <MessageProvider
          matterId={matterId}
          demandId={demandId}
          threadId={activeThreadId}
        >
          <ChatContentWithMessages
            matterId={matterId}
            demandId={demandId}
            threadId={activeThreadId}
            hasSelectedThread={hasSelectedThread}
            onStartDrafting={handleStartDrafting}
          />
        </MessageProvider>
      </div>
    </div>
  );
};

export const Chat: React.FC<ChatProps> = ({ matterId, demandId }) => (
  <ChatProvider matterId={matterId} demandId={demandId}>
    <ChatInner matterId={matterId} demandId={demandId} />
  </ChatProvider>
);

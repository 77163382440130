import React, { useState } from 'react';
import { BaseMatterNote } from '@/services/types/matter-notes-types';
import { MatterNoteConfig } from '../../types';
import { MatterNoteForm } from '../../form/MatterNoteForm';
import { SectionHeader } from './SectionHeader';
import { Skeleton } from '@/components/ui/skeleton';

interface MultipleNotesSectionProps {
  config: MatterNoteConfig;
  matterId: string;
  notes: BaseMatterNote[];
  isLoading: boolean;
  onNotesChange?: () => void;
}

export const MultipleNotesSection: React.FC<MultipleNotesSectionProps> = ({
  config,
  matterId,
  notes,
  isLoading,
  onNotesChange,
}) => {
  const [showNewForm, setShowNewForm] = useState(false);
  const isEmpty = notes.length === 0 && !showNewForm;

  const handleAddNew = () => {
    setShowNewForm(true);
  };

  const handleSaved = () => {
    setShowNewForm(false);
    onNotesChange?.();
  };

  const handleCancelled = () => {
    setShowNewForm(false);
  };

  const handleDeleted = () => {
    onNotesChange?.();
  };

  return (
    <div className="space-y-4">
      <SectionHeader
        title={config.title}
        onAddNew={!isLoading ? handleAddNew : undefined}
        showAddButton={!isLoading && !showNewForm}
        matterId={matterId}
        config={config}
        isEmptyState={isEmpty}
        isLoading={isLoading}
      />

      {isLoading ? (
        <div className="space-y-4">
          {[1, 2].map((i) => (
            <div key={i} className="space-y-3 p-2">
              <Skeleton className="h-5 w-3/4" />
              <Skeleton className="h-4 w-1/2" />
              <Skeleton className="h-10 w-full" />
              <Skeleton className="h-4 w-2/3" />
            </div>
          ))}
        </div>
      ) : (
        !isEmpty && (
          <div className="space-y-6">
            {showNewForm && (
              <div className="border-l-4 border-primary pl-4">
                <MatterNoteForm
                  config={{
                    ...config,
                    title: 'New Entry', // Different title for new forms
                  }}
                  matterId={matterId}
                  onSaved={handleSaved}
                  onCancelled={handleCancelled}
                />
              </div>
            )}

            {notes.map((note) => (
              <MatterNoteForm
                key={note.id}
                config={{
                  ...config,
                  title: '', // Remove title as it's shown in the header
                }}
                matterId={matterId}
                note={note}
                onSaved={onNotesChange}
                onDeleted={handleDeleted}
              />
            ))}
          </div>
        )
      )}
    </div>
  );
};

import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import {
  Dialog,
  DialogTitle,
  RightDialogContent,
} from '@/components/ui/dialog';
import {
  useCreateTaskUpdateMutation,
  useDeleteTaskMutation,
  useDeleteTaskUpdateMutation,
  useGetTaskQuery,
  useGetTaskUpdatesQuery,
  useUpdateTaskMutation,
  useUpdateTaskUpdateMutation,
} from '@/services/api';
import { useGetStaffUsersQuery } from '@/services/api/userService';
import {
  PhoneCallAction,
  Task,
  TaskActionResult,
  TaskUpdateVisibility,
} from '@/services/types/client-intake-types';
import { CanonicalTaskStatus } from '@/services/types/task-types';
import { filterStaffUsers } from '@/lib/utils';
import { TaskAssignee } from '../TaskAssignee';
import { TaskWarningDropdown } from '../TaskWarningDropdown';
import { VoiceAICallModal } from '../VoiceAICallModal';
import { TaskHeader } from './TaskHeader';
import { TaskDates } from './TaskDates';
import { TaskActions } from './TaskActions';
import { TaskUpdates } from './TaskUpdates';
import { TaskAttachments } from './TaskAttachments';
import { TaskInternalNotes } from './TaskInternalNotes';
import { TaskSchedules } from '../TaskSchedules';
import { useDebounce } from './hooks';

interface TaskDetailProps {
  taskId: string;
  matterId?: string;
  onClose: () => void;
  isModal?: boolean;
}

export const TaskDetail: React.FC<TaskDetailProps> = ({
  taskId,
  matterId: propMatterId,
  onClose,
  isModal = true,
}) => {
  const params = useParams();
  const urlMatterId = params.matter_id;
  // Use matterId from props if provided, otherwise use from URL params
  const matterId = propMatterId || urlMatterId;

  const navigate = useNavigate();
  const {
    data: task,
    isLoading: isTaskLoading,
    error: taskError,
  } = useGetTaskQuery({ taskId, matterId: matterId! }, { skip: !matterId });
  const [updateTask] = useUpdateTaskMutation();
  const [updateTaskUpdate] = useUpdateTaskUpdateMutation();
  const [deleteTaskUpdate] = useDeleteTaskUpdateMutation();
  const [createTaskUpdate] = useCreateTaskUpdateMutation();
  const [deleteTask, { isLoading: isDeleting }] = useDeleteTaskMutation();
  const [internalNotes, setInternalNotes] = React.useState('');
  const debouncedNotes = useDebounce(internalNotes, 500);
  const { data: taskUpdates, refetch: refetchTaskUpdates } =
    useGetTaskUpdatesQuery(
      { matterId: matterId!, taskId },
      { skip: !matterId },
    );
  const { data: staffUsers, isLoading: isStaffUsersLoading } =
    useGetStaffUsersQuery();
  const filteredStaffUsers = React.useMemo(() => {
    return filterStaffUsers(staffUsers || []);
  }, [staffUsers]);

  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = React.useState(false);
  const [showVoiceAIModal, setShowVoiceAIModal] = React.useState(false);

  // Set internal notes when task data is loaded
  React.useEffect(() => {
    if (task) {
      setInternalNotes(task.description || '');
    }
  }, [task]);

  // If there's an error or no matterId, navigate back
  React.useEffect(() => {
    if (taskError || !matterId) {
      toast.error('Failed to load task details');
      onClose();
      navigate(matterId ? `/matters/${matterId}` : '/tasks');
    }
  }, [taskError, matterId, navigate, onClose]);

  const performTaskAction = (action: PhoneCallAction) => {
    if (!task) return;

    const result: TaskActionResult = {
      url: `https://app.bland.ai/dashboard/call-logs/${action.bland_call_id}`,
      status: 'success',
    };

    createTaskUpdate({
      matterId: matterId!,
      taskId,
      update: {
        update: `Made a Voice AI call to ${action.provider} at ${action.phone_number}. You can view the call details here: ${result.url}`,
        visibility: TaskUpdateVisibility.PRIVATE,
      },
    });
  };

  const handleDeleteTask = () => {
    deleteTask({ matterId: matterId!, taskId })
      .unwrap()
      .then(() => {
        onClose();
        navigate(matterId ? `/matters/${matterId}` : '/tasks');
      });
  };

  React.useEffect(() => {
    if (task && debouncedNotes !== task.description) {
      updateTask({
        matterId: matterId!,
        taskId,
        task: { description: debouncedNotes },
      });
    }
  }, [debouncedNotes, matterId, taskId, task?.description, updateTask, task]);

  // Loading state
  if (isTaskLoading || !task) {
    const loadingContent = (
      <div className="flex items-center justify-center h-full py-10">
        <div className="animate-spin rounded-full h-8 w-8 border-4 border-primary border-t-transparent" />
      </div>
    );

    if (isModal) {
      return (
        <Dialog open onOpenChange={onClose} modal={false}>
          <RightDialogContent className="bg-white h-[95vh] flex items-center justify-center">
            {loadingContent}
          </RightDialogContent>
        </Dialog>
      );
    }

    return loadingContent;
  }

  const taskDetailContent = (
    <>
      <TaskHeader
        task={task}
        onTitleChange={(title) =>
          updateTask({
            matterId: matterId!,
            taskId,
            task: { name: title },
          })
        }
        onStatusChange={(status) =>
          updateTask({
            matterId: matterId!,
            taskId,
            task: {
              status_id: status.id,
              completed_at:
                status.canonical_status === CanonicalTaskStatus.COMPLETED
                  ? new Date().toISOString()
                  : null,
            },
          })
        }
      />

      <TaskDates
        task={task}
        onStartDateChange={(date) => {
          if (date) {
            updateTask({
              matterId: matterId!,
              taskId,
              task: { start_date: date.toISOString() },
            });
          }
        }}
        onDueDateChange={(date) => {
          if (date) {
            updateTask({
              matterId: matterId!,
              taskId,
              task: { due_date: date.toISOString() },
            });
          }
        }}
        onCompletedDateChange={(date) => {
          updateTask({
            matterId: matterId!,
            taskId,
            task: {
              completed_at: date ? date.toISOString() : null,
            },
          });
        }}
      />

      <div className="flex flex-row gap-6 mb-2">
        <div className="flex flex-col gap-2">
          <h3 className="text-lg font-semibold mb-2">Assignee</h3>
          <TaskAssignee
            assignee={task.assignee}
            staffUsers={filteredStaffUsers || []}
            isLoading={isStaffUsersLoading}
            onAssigneeChange={(userId) => {
              updateTask({
                matterId: matterId!,
                taskId,
                task: { assignee_id: userId },
              });
            }}
          />
        </div>
        <div className="w-[1px] h-full bg-gray-200" />
        <div className="flex flex-col gap-2">
          <h3 className="text-lg font-semibold mb-2">Priority</h3>
          <TaskWarningDropdown
            task={task}
            onUrgentToggle={(urgent) =>
              updateTask({
                matterId: matterId!,
                taskId,
                task: { order: urgent ? -1 : 0 },
              })
            }
          />
        </div>
      </div>

      <TaskActions
        task={task}
        handleSubmit={performTaskAction}
        createTaskUpdate={createTaskUpdate}
        refetchTaskUpdates={refetchTaskUpdates}
      />

      <TaskSchedules
        task={task}
        onCreateSchedule={() => setShowVoiceAIModal(true)}
      />

      <TaskUpdates
        updates={taskUpdates || []}
        onCreateUpdate={async (update, visibility) => {
          await createTaskUpdate({
            matterId: matterId!,
            taskId,
            update: {
              update,
              visibility,
            },
          });
        }}
        onUpdateUpdate={async (updateId, update, visibility) => {
          await updateTaskUpdate({
            matterId: matterId!,
            taskId,
            updateId,
            update: {
              update,
              visibility,
            },
          });
        }}
        onDeleteUpdate={async (updateId) => {
          await deleteTaskUpdate({
            matterId: matterId!,
            taskId,
            updateId,
          });
        }}
      />

      <TaskAttachments
        task={task}
        onDeleteAttachment={async (attachmentId) => {
          await updateTask({
            matterId: matterId!,
            taskId,
            task: {
              attachments: task.attachments
                .filter((a) => a.id !== attachmentId)
                .map((a) => a.id),
            },
          });
        }}
      />

      <TaskInternalNotes
        notes={internalNotes}
        onNotesChange={setInternalNotes}
      />

      <div className="mt-4 pb-6 border-t border-gray-200 pt-4">
        <Button
          variant="destructive"
          className="w-full flex items-center justify-center gap-2 text-white font-bold max-w-[150px]"
          onClick={() => setIsDeleteAlertOpen(true)}
          disabled={isDeleting}
        >
          <span>{isDeleting ? 'Deleting...' : 'Delete Task'}</span>
        </Button>
      </div>

      {showVoiceAIModal && (
        <VoiceAICallModal
          task={task}
          onClose={() => setShowVoiceAIModal(false)}
          handleSubmit={performTaskAction}
        />
      )}

      <AlertDialog open={isDeleteAlertOpen} onOpenChange={setIsDeleteAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the task &ldquo;{task.name}&rdquo;.
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteTask}
              disabled={isDeleting}
              className="font-bold bg-destructive text-white hover:bg-destructive/90"
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );

  if (isModal) {
    return (
      <Dialog
        open
        onOpenChange={() => {
          onClose();
          navigate(urlMatterId ? `/matters/${matterId}` : '/tasks');
        }}
        modal={false}
      >
        <RightDialogContent className="bg-white h-[95vh] overflow-y-scroll flex flex-col gap-4">
          <DialogTitle className="sr-only">Task Details</DialogTitle>
          {taskDetailContent}
        </RightDialogContent>
      </Dialog>
    );
  }

  // Non-modal view
  return (
    <div className=" rounded-lg border divide-y mt-2 mb-4 flex flex-col mx-1">
      <div className="p-4">{taskDetailContent}</div>
    </div>
  );
};

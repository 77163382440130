import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithErrorHandling } from './baseQuery';

export type MatterContext = Record<string, string | null>;

export interface GetMatterContextParams {
  matterId: string;
  medicalNoteId?: string;
  insuranceNoteId?: string;
}

export const matterContextApi = createApi({
  reducerPath: 'matterContextApi',
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    getMatterContext: builder.query<MatterContext, GetMatterContextParams>({
      query: ({ matterId, medicalNoteId, insuranceNoteId }) => {
        const params = new URLSearchParams();
        if (medicalNoteId) params.append('medical_note_id', medicalNoteId);
        if (insuranceNoteId)
          params.append('insurance_note_id', insuranceNoteId);

        return {
          url: `api/matters/${matterId}/context/${params.toString() ? `?${params.toString()}` : ''}`,
        };
      },
    }),
  }),
});

export const { useGetMatterContextQuery } = matterContextApi;

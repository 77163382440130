// EditorContext.tsx
import { loadCKEditorCloud } from '@ckeditor/ckeditor5-react';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  CHAT_COMMANDS,
  CHAT_EVENTS,
  ChatPlugin,
  ChatSelectionEventData,
  ChatStateEventData,
} from './chat-plugin';

const {
  CKEditor: { Editor: _Editor },
} = await loadCKEditorCloud({
  version: '44.3.0',
  premium: true,
  translations: ['en'],
});

type Editor = InstanceType<typeof _Editor>;
// Define the context shape
interface EditorContextType {
  // Editor instance
  editor: Editor | null;
  setEditor: (editor: Editor | null) => void;

  // State
  isEnabled: boolean;
  isSelectionActive: boolean;
  selectedHtml: string;
  isReady: boolean;

  // Actions
  enableChat: () => boolean;
  disableChat: () => boolean;
  captureSelection: () => boolean;
  clearSelection: () => boolean;
  insertHtml: (html: string) => boolean;

  // Utility
  getChatPlugin: () => ChatPlugin | null;
}

const EditorContext = createContext<EditorContextType | null>(null);

export const EditorProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // Editor reference
  const [editor, setEditorInstance] = useState<Editor | null>(null);

  // Editor state
  const [isEnabled, setIsEnabled] = useState(true);
  const [isSelectionActive, setIsSelectionActive] = useState(false);
  const [selectedHtml, setSelectedHtml] = useState('');
  const [isReady, setIsReady] = useState(false);

  // Use memoized function to prevent recreation on each render
  const getChatPlugin = useCallback((): ChatPlugin | null => {
    if (!editor) return null;
    return editor.plugins.has(ChatPlugin)
      ? editor.plugins.get(ChatPlugin)
      : null;
  }, [editor]);

  // Set editor with validation - use useCallback to memoize this function
  const setEditor = useCallback(
    (newEditor: Editor | null) => {
      if (newEditor === editor) return;

      // Set new editor
      setEditorInstance(newEditor);

      // Initial state
      if (newEditor) {
        const plugin = newEditor.plugins.has(ChatPlugin)
          ? newEditor.plugins.get(ChatPlugin)
          : null;

        if (plugin) {
          setIsEnabled(!plugin.isDisabled);
          setIsSelectionActive(plugin.isSelectionActive);
          setSelectedHtml(plugin.selectedHtml);
          setIsReady(true);
        }
      } else {
        setIsReady(false);
      }
    },
    [editor],
  );

  // Set up event listeners when editor changes
  useEffect(() => {
    if (!editor) return undefined;

    const plugin = getChatPlugin();
    if (!plugin) return undefined;

    // Selection events with proper types
    const handleSelectionCaptured = (
      _: unknown,
      data: ChatSelectionEventData,
    ) => {
      setIsSelectionActive(true);
      setSelectedHtml(data.html);
    };

    const handleSelectionCleared = (
      _: unknown,
      data: ChatSelectionEventData,
    ) => {
      setIsSelectionActive(false);
      setSelectedHtml('');
    };

    const handleContentChanged = (_: unknown, data: ChatSelectionEventData) => {
      setSelectedHtml(data.html);
    };

    // Plugin state events with proper types
    const handleChatEnabled = (_: unknown, data: ChatStateEventData) => {
      setIsEnabled(true);
    };

    const handleChatDisabled = (_: unknown, data: ChatStateEventData) => {
      setIsEnabled(false);
    };

    // Add listeners
    plugin.on(CHAT_EVENTS.SELECTION_CAPTURED, handleSelectionCaptured);
    plugin.on(CHAT_EVENTS.SELECTION_CLEARED, handleSelectionCleared);
    plugin.on(CHAT_EVENTS.CONTENT_CHANGED, handleContentChanged);
    plugin.on(CHAT_EVENTS.ENABLED, handleChatEnabled);
    plugin.on(CHAT_EVENTS.DISABLED, handleChatDisabled);

    // Cleanup function - return undefined explicitly to satisfy linter
    return () => {
      plugin.off(CHAT_EVENTS.SELECTION_CAPTURED, handleSelectionCaptured);
      plugin.off(CHAT_EVENTS.SELECTION_CLEARED, handleSelectionCleared);
      plugin.off(CHAT_EVENTS.CONTENT_CHANGED, handleContentChanged);
      plugin.off(CHAT_EVENTS.ENABLED, handleChatEnabled);
      plugin.off(CHAT_EVENTS.DISABLED, handleChatDisabled);
      return undefined; // Explicitly return undefined to satisfy linter
    };
  }, [editor, getChatPlugin]);

  // Define actions that interact with the editor - use useMemo to prevent recreation
  const actions = useMemo(
    () => ({
      enableChat: (): boolean => {
        if (!editor) return false;
        editor.execute(CHAT_COMMANDS.ENABLE_CHAT);
        return true;
      },

      disableChat: (): boolean => {
        if (!editor) return false;
        editor.execute(CHAT_COMMANDS.DISABLE_CHAT);
        return true;
      },

      captureSelection: (): boolean => {
        if (!editor) return false;
        return !!editor.execute(CHAT_COMMANDS.CAPTURE_SELECTION);
      },

      clearSelection: (): boolean => {
        if (!editor) return false;
        editor.execute(CHAT_COMMANDS.CLEAR_SELECTION);
        return true;
      },

      insertHtml: (html: string): boolean => {
        if (!editor || !html) return false;
        return !!editor.execute(CHAT_COMMANDS.INSERT_HTML, html);
      },
    }),
    [editor],
  );

  // Use useMemo to prevent creating a new context value on each render
  const value = useMemo(
    () => ({
      // Editor instance
      editor,
      setEditor,

      // State
      isEnabled,
      isSelectionActive,
      selectedHtml,
      isReady,

      // Actions
      ...actions,

      // Utility
      getChatPlugin,
    }),
    [
      editor,
      setEditor,
      isEnabled,
      isSelectionActive,
      selectedHtml,
      isReady,
      actions,
      getChatPlugin,
    ],
  );

  return (
    <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
  );
};

// Custom hook to use the editor context
export const useEditor = () => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditor must be used within an EditorProvider');
  }
  return context;
};

import { Box, Divider, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface ReadOnlyNoteSectionProps {
  title: string;
  children: ReactNode;
}

const ReadOnlyNoteSection = ({ title, children }: ReadOnlyNoteSectionProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        border: 1,
        borderRadius: 2,
        borderColor: 'divider',
        p: 3,
      }}
    >
      <div>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'area-normal, sans-serif',
            mb: 2,
            fontWeight: 700,
            fontSize: '16px',
            letterSpacing: '0%',
            color: 'rgba(0, 0, 0, 0.75)',
          }}
        >
          {title}
        </Typography>
        <Divider />
      </div>
      <div>{children}</div>
    </Box>
  );
};

export default ReadOnlyNoteSection;

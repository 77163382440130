import {
  AlertCircle,
  ChevronDown,
  HelpCircle,
  InfoIcon,
  X,
} from 'lucide-react';
import React, { useState } from 'react';

import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

import {
  ProofreadingRuleResult,
  ProofreadingRuleStatus,
} from '../../../services/types/proofreading-types';
import { SeverityBadge } from '../common/SeverityBadge';
import { FindingDetail } from './FindingDetail';
import { RecommendationList } from './RecommendationList';

interface RuleResultCardProps {
  ruleResult: ProofreadingRuleResult;
  expandedByDefault?: boolean;
  compact?: boolean;
}

export const RuleResultCard: React.FC<RuleResultCardProps> = ({
  ruleResult,
  expandedByDefault = false,
  compact = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(expandedByDefault);
  const [showRecommendations, setShowRecommendations] = useState(false);

  const { rule, result } = ruleResult;
  const { status, reason, specific_issues, recommendations } = result;

  const hasContent =
    reason ||
    (specific_issues && specific_issues.length > 0) ||
    (recommendations && recommendations.length > 0);

  const toggleExpand = () => {
    if (hasContent) {
      setIsExpanded(!isExpanded);
    }
  };

  const toggleRecommendations = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowRecommendations(!showRecommendations);
  };

  // Create a more informative title that includes the issue count
  const issueCount = specific_issues?.length || 0;
  const issueText = issueCount > 0 ? `(${issueCount})` : '';

  return (
    <Card
      className={cn(
        'border-gray-200 overflow-hidden hover:border-gray-300 transition-colors',
        compact ? 'shadow-none' : 'shadow-sm',
      )}
    >
      <button
        type="button"
        className={cn(
          'w-full text-left flex items-center justify-between transition-colors',
          compact ? 'px-3 py-2' : 'px-4 py-3',
          isExpanded ? 'bg-gray-50' : 'hover:bg-gray-50',
          hasContent && 'border-b border-gray-200',
        )}
        onClick={toggleExpand}
        disabled={!hasContent}
        aria-expanded={isExpanded}
        aria-controls={`content-${rule.id}`}
      >
        <div className="flex-1 min-w-0 flex items-center">
          <div className="mr-2.5">
            <SeverityBadge status={status} compact />
          </div>
          <div>
            <h3
              className={cn(
                'font-medium truncate flex items-center',
                compact ? 'text-sm' : 'text-base',
              )}
            >
              {rule.title}
              {issueCount > 0 && (
                <span className="ml-1.5 text-xs text-gray-500">
                  {issueText}
                </span>
              )}
              <Popover>
                <PopoverTrigger asChild onClick={(e) => e.stopPropagation()}>
                  <span className="ml-1.5 inline-flex">
                    <InfoIcon className="h-3.5 w-3.5 text-gray-400 hover:text-gray-500" />
                  </span>
                </PopoverTrigger>
                <PopoverContent
                  className="w-72 p-0 max-h-96 overflow-auto"
                  onPointerDownOutside={(e) => e.preventDefault()}
                >
                  <div className="p-3 pb-2">
                    <div className="flex justify-between items-start">
                      <h4 className="font-medium text-sm text-gray-900">
                        Rule Details
                      </h4>
                      <div className="flex gap-2 items-center">
                        <div
                          className={cn(
                            'text-xs font-medium rounded-full px-1.5 py-0.5',
                            rule.is_global
                              ? 'bg-blue-50 text-blue-700'
                              : 'bg-purple-50 text-purple-700',
                          )}
                        >
                          {rule.is_global ? 'Global' : 'Firm-Specific'}
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          className="h-auto w-auto p-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            const popoverElement = e.currentTarget.closest(
                              '[data-radix-popper-content-wrapper]',
                            );
                            if (popoverElement) {
                              popoverElement.dispatchEvent(
                                new KeyboardEvent('keydown', { key: 'Escape' }),
                              );
                            }
                          }}
                        >
                          <X className="h-3.5 w-3.5 text-gray-500" />
                          <span className="sr-only">Close</span>
                        </Button>
                      </div>
                    </div>
                    <p className="text-xs text-gray-500 mt-0.5">
                      Created: {new Date(rule.created_at).toLocaleDateString()}
                    </p>
                    <Separator className="my-2" />
                    <h5 className="text-xs uppercase font-medium text-gray-700 mb-0.5">
                      Description
                    </h5>
                    <p className="text-sm text-gray-800 whitespace-pre-wrap mb-2">
                      {rule.description}
                    </p>
                  </div>

                  <div className="bg-gray-50 p-3 pt-2 rounded-b-md">
                    <h5 className="text-xs uppercase font-medium text-gray-700 mb-0.5">
                      AI Prompt
                    </h5>
                    <p className="text-xs text-gray-700 whitespace-pre-wrap leading-relaxed">
                      {rule.prompt}
                    </p>
                  </div>
                </PopoverContent>
              </Popover>
            </h3>

            <div className="flex items-center gap-2 mt-0.5">
              <div
                className={cn(
                  'text-[10px] uppercase tracking-wide text-gray-400',
                  compact && 'hidden sm:block',
                )}
              >
                {rule.rule_type}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center ml-2">
          {hasContent && (
            <ChevronDown
              className={cn(
                'h-4 w-4 text-gray-400 transition-transform',
                isExpanded && 'transform rotate-180',
              )}
              aria-hidden="true"
            />
          )}
        </div>
      </button>

      {isExpanded && hasContent && (
        <CardContent
          className={cn('space-y-4', compact ? 'p-3' : 'p-4')}
          id={`content-${rule.id}`}
        >
          <div
            className={cn(
              'text-sm font-medium text-gray-700',
              compact && 'text-xs',
            )}
          >
            {reason}
          </div>

          {specific_issues && specific_issues.length > 0 && (
            <div className="space-y-3">
              <h4
                className={cn(
                  'font-medium text-gray-700',
                  compact ? 'text-xs' : 'text-sm',
                )}
              >
                Specific Issues
              </h4>
              <div className="space-y-3">
                {specific_issues.map((finding, index) => (
                  <FindingDetail
                    key={index}
                    finding={finding}
                    compact={compact}
                  />
                ))}
              </div>
            </div>
          )}

          {recommendations && recommendations.length > 0 && (
            <>
              <Separator className="my-3" />
              <button
                type="button"
                className="flex items-center gap-1.5 text-gray-600 hover:text-gray-900 transition-colors"
                onClick={toggleRecommendations}
              >
                <h4
                  className={cn('font-medium', compact ? 'text-xs' : 'text-sm')}
                >
                  Recommendations
                </h4>
                <ChevronDown
                  className={cn(
                    'h-3.5 w-3.5 transition-transform',
                    showRecommendations && 'transform rotate-180',
                  )}
                />
              </button>
              {showRecommendations && (
                <RecommendationList
                  recommendations={recommendations}
                  compact={compact}
                />
              )}
            </>
          )}
        </CardContent>
      )}
    </Card>
  );
};

import { Check, ChevronDown } from 'lucide-react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Dialog,
  DialogTitle,
  RightDialogContent,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn, filterStaffUsers } from '@/lib/utils';
import {
  useCreateTaskMutation,
  useGetMattersQuery,
  useGetTaskGroupsQuery,
  useGetTaskStatusesQuery,
  useGetTaskTypesQuery,
} from '@/services/api';
import { useGetStaffUsersQuery } from '@/services/api/userService';
import {
  TaskGroup,
  TaskStatus,
  TaskType,
} from '@/services/types/client-intake-types';
import {
  CanonicalTaskGroup,
  CanonicalTaskStatus,
  CanonicalTaskType,
} from '@/services/types/task-types';
import { User } from '@/services/types/user-types';

import { TaskAssignee } from './TaskAssignee';
import { TaskDueDate } from './TaskDueDate';

export const Combobox = <T extends { id: string; name: string }>({
  label,
  options,
  value,
  onChange,
  placeholder,
}: {
  label?: string;
  options: T[];
  value: T | null;
  onChange: (value: T | null) => void;
  placeholder: string;
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      {label && <Label className="font-bold">{label}</Label>}
      <PopoverTrigger>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
          onClick={(e) => {
            e.preventDefault();
            setOpen(!open);
          }}
        >
          {value
            ? options.find((type) => type.id === value.id)?.name
            : placeholder}
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] bg-white p-0">
        <Command>
          <CommandInput placeholder={`Search ${placeholder.toLowerCase()}`} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.id}
                  value={option.name}
                  onSelect={() => {
                    onChange(option);
                    setOpen(false);
                  }}
                  className="hover:bg-gray-100"
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      value === option ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  {option.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

interface CreateTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateTaskModal: React.FC<CreateTaskModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { matter_id } = useParams();
  const navigate = useNavigate();
  const [createTask] = useCreateTaskMutation();
  const [name, setName] = React.useState('');
  const [startDate, setStartDate] = React.useState<Date>();
  const [dueDate, setDueDate] = React.useState<Date>();
  const [selectedMatterId, setSelectedMatterId] = React.useState<string | null>(
    matter_id || null,
  );

  // Get task statuses, types, and groups from API
  const { data: taskStatuses = [] } = useGetTaskStatusesQuery();
  const { data: taskTypes = [] } = useGetTaskTypesQuery();
  const { data: taskGroups = [] } = useGetTaskGroupsQuery();

  // States for selected entities
  const [selectedStatus, setSelectedStatus] = React.useState<TaskStatus | null>(
    null,
  );
  const [selectedType, setSelectedType] = React.useState<TaskType | null>(null);
  const [selectedGroup, setSelectedGroup] = React.useState<TaskGroup | null>(
    null,
  );

  const { data: matters } = useGetMattersQuery();
  const [assignee, setAssignee] = React.useState<User | null>(null);
  const { data: staffUsers, isLoading: isStaffUsersLoading } =
    useGetStaffUsersQuery();
  const filteredStaffUsers = React.useMemo(() => {
    return filterStaffUsers(staffUsers || []);
  }, [staffUsers]);

  // Update default status, type, and group when data is loaded
  React.useEffect(() => {
    if (taskStatuses.length > 0 && !selectedStatus) {
      // Default to Pending status
      const pendingStatus = taskStatuses.find(
        (status) => status.canonical_status === CanonicalTaskStatus.PENDING,
      );
      setSelectedStatus(pendingStatus || taskStatuses[0]);
    }
  }, [taskStatuses, selectedStatus]);

  React.useEffect(() => {
    if (taskTypes.length > 0 && !selectedType) {
      // Default to Other type
      const otherType = taskTypes.find(
        (type) => type.canonical_type === 'Other',
      );
      setSelectedType(otherType || taskTypes[0]);
    }
  }, [taskTypes, selectedType]);

  React.useEffect(() => {
    if (taskGroups.length > 0 && !selectedGroup) {
      // Default to Miscellaneous group
      const miscGroup = taskGroups.find(
        (group) => group.canonical_group === CanonicalTaskGroup.MISCELLANEOUS,
      );
      setSelectedGroup(miscGroup || taskGroups[0]);
    }
  }, [taskGroups, selectedGroup]);

  const resetForm = () => {
    setName('');
    setStartDate(undefined);
    setDueDate(undefined);
    setSelectedStatus(null);
    setSelectedMatterId(matter_id || null);
    setSelectedType(null);
    setSelectedGroup(null);
    setAssignee(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !selectedMatterId ||
      !name ||
      !selectedType ||
      !selectedStatus ||
      !selectedGroup
    ) {
      return;
    }

    const task = await createTask({
      matterId: selectedMatterId,
      task: {
        name,
        due_date: dueDate?.toISOString(),
        status_id: selectedStatus.id,
        type_id: selectedType.id,
        group_id: selectedGroup.id,
        assignee_id: assignee?.id,
        start_date: startDate?.toISOString(),
      },
    });

    resetForm();
    onClose();
    navigate(
      matter_id
        ? `/matters/${matter_id}/tasks/${task.data?.id}`
        : `/tasks/${task.data?.id}`,
    );
  };

  const submitDisabled =
    !selectedMatterId ||
    !name ||
    !selectedType ||
    !selectedStatus ||
    !selectedGroup;

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        resetForm();
        onClose();
      }}
      modal={false}
    >
      <RightDialogContent className="flex flex-col gap-4 overflow-auto bg-white sm:max-w-[600px]">
        <DialogTitle className="text-xl font-bold">Create New Task</DialogTitle>
        <form onSubmit={handleSubmit} className="space-y-4 pt-4">
          <div className="flex flex-col gap-1 space-y-2">
            <Combobox
              label="Matter"
              placeholder="Select matter"
              options={
                matters?.map((matter) => ({
                  id: matter.id,
                  name: matter.name,
                })) || []
              }
              value={
                selectedMatterId
                  ? { id: selectedMatterId, name: selectedMatterId }
                  : null
              }
              onChange={(value) => setSelectedMatterId(value?.id || '')}
            />
          </div>

          <div className="flex flex-col gap-1 space-y-2">
            <Combobox
              label="Task Type"
              placeholder="Select task type"
              options={taskTypes}
              value={selectedType}
              onChange={(value) => {
                if (value) {
                  setSelectedType(value);
                  if (value.canonical_type !== CanonicalTaskType.OTHER) {
                    setName(value.name || '');
                  }

                  // Find an appropriate group based on the selected type's group field
                  // which is an ID referencing a group
                  const associatedGroup = taskGroups.find(
                    (group) => group.id === value.group,
                  );
                  if (associatedGroup) {
                    setSelectedGroup(associatedGroup);
                  }
                }
              }}
            />
          </div>

          {selectedType?.canonical_type === CanonicalTaskType.OTHER && (
            <div className="flex flex-col gap-1 space-y-2">
              <Combobox
                label="Task Group"
                placeholder="Select task group"
                options={taskGroups}
                value={selectedGroup}
                onChange={(value) => {
                  if (value) {
                    setSelectedGroup(value);
                  }
                }}
              />
            </div>
          )}

          <div className="flex flex-col gap-1 space-y-2">
            <Label htmlFor="name" className="font-bold">
              <span className="mb-2">Task Name</span>
            </Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter task name"
              required
              className="w-full"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-1 space-y-2">
              <Label className="font-bold">Start Date</Label>
              <TaskDueDate
                className="w-full"
                date={startDate?.toISOString()}
                onDateChange={setStartDate}
              />
            </div>
            <div className="flex flex-col gap-1 space-y-2">
              <Label className="font-bold">Target Date</Label>
              <TaskDueDate
                className="w-full"
                date={dueDate?.toISOString()}
                onDateChange={setDueDate}
              />
            </div>
            <div className="flex flex-col gap-1 space-y-2">
              <Label className="font-bold">Status</Label>
              <div>
                <Combobox
                  label=""
                  placeholder="Select status"
                  options={taskStatuses}
                  value={selectedStatus}
                  onChange={(value) => {
                    if (value) {
                      setSelectedStatus(value);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col items-start gap-3">
              <Label className="font-bold">Assignee</Label>
              <TaskAssignee
                assignee={assignee}
                onAssigneeChange={(newAssignee) => {
                  setAssignee(
                    filteredStaffUsers?.find(
                      (user) => user.id === newAssignee,
                    ) || null,
                  );
                }}
                staffUsers={filteredStaffUsers || []}
                isLoading={isStaffUsersLoading}
              />
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
              className="cursor-pointer bg-white font-bold text-black"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={submitDisabled}
              className={cn(
                'bg-primary cursor-pointer font-bold text-white',
                submitDisabled && 'cursor-not-allowed opacity-50',
              )}
            >
              Create Task
            </Button>
          </div>
        </form>
      </RightDialogContent>
    </Dialog>
  );
};

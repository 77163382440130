import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import {
  CreateDemand,
  CreateDemandConfiguration,
  CreateDemandEditorThread,
  Demand,
  DemandCitation,
  DemandConfiguration,
  DemandConfigurationList,
  DemandEditorFile,
  DemandEditorMessage,
  DemandEditorThread,
  DemandRevision,
  DemandRevisionFull,
  DemandTemplate,
  PutRevision,
  UpdateDemandConfiguration,
  UpdateDemandEditorThread,
  UpdateDemandTemplate,
} from '../types/demand-types';
import { baseQueryWithErrorHandling } from './baseQuery';

export const demandEditorMessageAdapter =
  createEntityAdapter<DemandEditorMessage>({});

export const demandsApiClient = createApi({
  reducerPath: 'demandsApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: [
    'Demands',
    'DemandRevisions',
    'DemandEditorThreads',
    'DemandEditorMessages',
    'DemandEditorFiles',
    'DemandConfigurations',
    'DemandCitation',
    'DemandTemplate',
  ] as const,
  endpoints: (builder) => ({
    // Get/Create a demand for a matter (PUT endpoint)
    putDemand: builder.mutation<
      Demand,
      { matterId: string; data: CreateDemand }
    >({
      query: ({ matterId, data }) => ({
        url: `api/matters/${matterId}/demands/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { matterId }) => [
        { type: 'Demands', id: matterId },
      ],
    }),

    getDemandStatus: builder.query<Demand, { matterId: string }>({
      query: ({ matterId }) => `api/matters/${matterId}/demands/status/`,
      providesTags: (result, error, { matterId }) => [
        { type: 'Demands', id: matterId },
      ],
    }),

    generateDemand: builder.mutation<Demand, { matterId: string }>({
      query: ({ matterId }) => ({
        url: `api/matters/${matterId}/demands/generate/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { matterId }) => [
        { type: 'Demands', id: matterId },
      ],
    }),

    // Get all revisions for a demand
    listRevisions: builder.query<
      DemandRevision[],
      { matterId: string; demandId: string }
    >({
      query: ({ matterId, demandId }) =>
        `api/matters/${matterId}/demands/${demandId}/revisions/`,
      providesTags: (result, error, { matterId, demandId }) => [
        { type: 'DemandRevisions', id: `${matterId}:${demandId}` },
      ],
    }),

    // Get a specific revision
    getRevision: builder.query<
      DemandRevisionFull,
      { matterId: string; demandId: string; revisionId: string }
    >({
      query: ({ matterId, demandId, revisionId }) =>
        `api/matters/${matterId}/demands/${demandId}/revisions/${revisionId}/`,
      providesTags: (result, error, { matterId, demandId, revisionId }) => [
        {
          type: 'DemandRevisions',
          id: `${matterId}:${demandId}:${revisionId}`,
        },
      ],
    }),

    // Update a revision
    updateRevision: builder.mutation<
      DemandRevisionFull,
      {
        matterId: string;
        demandId: string;
        revisionId: string;
        data: PutRevision;
      }
    >({
      query: ({ matterId, demandId, revisionId, data }) => ({
        url: `api/matters/${matterId}/demands/${demandId}/revisions/${revisionId}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { matterId, demandId }) => [
        { type: 'DemandRevisions', id: `${matterId}:${demandId}` },
      ],
    }),

    // Thread endpoints
    listEditorThreads: builder.query<
      DemandEditorThread[],
      { matterId: string; demandId: string }
    >({
      query: ({ matterId, demandId }) =>
        `api/matters/${matterId}/demands/${demandId}/editor/threads/`,
      providesTags: (result, error, { matterId, demandId }) => [
        { type: 'DemandEditorThreads', id: `${matterId}:${demandId}` },
      ],
    }),

    createEditorThread: builder.mutation<
      DemandEditorThread,
      { matterId: string; demandId: string; data: CreateDemandEditorThread }
    >({
      query: ({ matterId, demandId, data }) => ({
        url: `api/matters/${matterId}/demands/${demandId}/editor/threads/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { matterId, demandId }) => [
        { type: 'DemandEditorThreads', id: `${matterId}:${demandId}` },
      ],
    }),

    updateEditorThread: builder.mutation<
      DemandEditorThread,
      {
        matterId: string;
        demandId: string;
        threadId: string;
        data: UpdateDemandEditorThread;
      }
    >({
      query: ({ matterId, demandId, threadId, data }) => ({
        url: `api/matters/${matterId}/demands/${demandId}/editor/threads/${threadId}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { matterId, demandId }) => [
        { type: 'DemandEditorThreads', id: `${matterId}:${demandId}` },
      ],
    }),

    deleteEditorThread: builder.mutation<
      void,
      { matterId: string; demandId: string; threadId: string }
    >({
      query: ({ matterId, demandId, threadId }) => ({
        url: `api/matters/${matterId}/demands/${demandId}/editor/threads/${threadId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { matterId, demandId }) => [
        { type: 'DemandEditorThreads', id: `${matterId}:${demandId}` },
      ],
    }),

    // Message endpoints
    listEditorMessages: builder.query<
      EntityState<DemandEditorMessage, string>,
      { matterId: string; demandId: string; threadId: string }
    >({
      query: ({ matterId, demandId, threadId }) =>
        `api/matters/${matterId}/demands/${demandId}/editor/threads/${threadId}/messages/`,
      providesTags: (result, error, { matterId, demandId, threadId }) => [
        {
          type: 'DemandEditorMessages',
          id: `${matterId}:${demandId}:${threadId}`,
        },
      ],
      transformResponse: (response: DemandEditorMessage[]) =>
        demandEditorMessageAdapter.setAll(
          demandEditorMessageAdapter.getInitialState(),
          response,
        ),
    }),

    // File endpoints
    getEditorFile: builder.query<
      DemandEditorFile,
      { matterId: string; demandId: string; threadId: string; fileId: string }
    >({
      query: ({ matterId, demandId, threadId, fileId }) =>
        `api/matters/${matterId}/demands/${demandId}/editor/threads/${threadId}/files/${fileId}/`,
      providesTags: (
        result,
        error,
        { matterId, demandId, threadId, fileId },
      ) => [
        {
          type: 'DemandEditorFiles',
          id: `${matterId}:${demandId}:${threadId}:${fileId}`,
        },
      ],
    }),

    uploadEditorFile: builder.mutation<
      DemandEditorFile,
      { matterId: string; demandId: string; threadId: string; data: FormData }
    >({
      query: ({ matterId, demandId, threadId, data }) => ({
        url: `api/matters/${matterId}/demands/${demandId}/editor/threads/${threadId}/files/`,
        method: 'POST',
        body: data,
        // Don't serialize the FormData as JSON
        formData: true,
      }),
      invalidatesTags: (result, error, { matterId, demandId, threadId }) => [
        {
          type: 'DemandEditorFiles',
          id: `${matterId}:${demandId}:${threadId}`,
        },
      ],
    }),

    deleteEditorFile: builder.mutation<
      void,
      { matterId: string; demandId: string; threadId: string; fileId: string }
    >({
      query: ({ matterId, demandId, threadId, fileId }) => ({
        url: `api/matters/${matterId}/demands/${demandId}/editor/threads/${threadId}/files/${fileId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { matterId, demandId, threadId }) => [
        {
          type: 'DemandEditorFiles',
          id: `${matterId}:${demandId}:${threadId}`,
        },
      ],
    }),

    // Demand Configuration endpoints
    createDemandConfiguration: builder.mutation<
      DemandConfiguration,
      { data: CreateDemandConfiguration }
    >({
      query: ({ data }) => ({
        url: `api/demands/configurations/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error) => [{ type: 'DemandConfigurations' }],
    }),

    getDemandConfiguration: builder.query<
      DemandConfiguration,
      { configurationId: string }
    >({
      query: ({ configurationId }) =>
        `api/demands/configurations/${configurationId}/`,
      providesTags: (result, error, { configurationId }) => [
        { type: 'DemandConfigurations', id: configurationId },
      ],
    }),

    updateDemandConfiguration: builder.mutation<
      DemandConfiguration,
      {
        firmId: string;
        configurationId: string;
        data: UpdateDemandConfiguration;
      }
    >({
      query: ({ configurationId, data }) => ({
        url: `api/demands/configurations/${configurationId}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { configurationId }) => [
        { type: 'DemandConfigurations', id: configurationId },
      ],
    }),

    deleteDemandConfiguration: builder.mutation<
      void,
      { configurationId: string }
    >({
      query: ({ configurationId }) => ({
        url: `api/demands/configurations/${configurationId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { configurationId }) => [
        { type: 'DemandConfigurations', id: configurationId },
      ],
    }),

    listDemandConfigurations: builder.query<DemandConfigurationList[], void>({
      query: () => `api/demands/configurations/`,
      providesTags: (result, error) => [{ type: 'DemandConfigurations' }],
    }),

    getDemandCitation: builder.query<
      DemandCitation,
      { matterId: string; demandId: string; citationId: string }
    >({
      query: ({ matterId, demandId, citationId }) =>
        `api/matters/${matterId}/demands/${demandId}/citations/${citationId}/`,
      providesTags: (result, error, { matterId, demandId, citationId }) => [
        {
          type: 'DemandCitation',
          id: `${matterId}:${demandId}:${citationId}`,
        },
      ],
    }),

    getDemandTemplate: builder.query<DemandTemplate, { firmId: string }>({
      query: ({ firmId }) => `api/demands/template/${firmId}/`,
      providesTags: (result, error, { firmId }) => [
        { type: 'DemandTemplate', id: firmId },
      ],
    }),

    updateDemandTemplate: builder.mutation<
      DemandTemplate,
      { firmId: string; data: UpdateDemandTemplate }
    >({
      query: ({ firmId, data }) => ({
        url: `api/demands/template/${firmId}/`,
        method: 'PUT',
        body: data,
        formData: true,
      }),
      invalidatesTags: (result, error, { firmId }) => [
        { type: 'DemandTemplate', id: firmId },
      ],
    }),
  }),
});

export const {
  usePutDemandMutation,
  useGetDemandStatusQuery,
  useGenerateDemandMutation,
  useListRevisionsQuery,
  useGetRevisionQuery,
  useUpdateRevisionMutation,
  useListEditorThreadsQuery,
  useCreateEditorThreadMutation,
  useUpdateEditorThreadMutation,
  useDeleteEditorThreadMutation,
  useListEditorMessagesQuery,
  useGetEditorFileQuery,
  useUploadEditorFileMutation,
  useDeleteEditorFileMutation,
  useCreateDemandConfigurationMutation,
  useGetDemandConfigurationQuery,
  useUpdateDemandConfigurationMutation,
  useDeleteDemandConfigurationMutation,
  useListDemandConfigurationsQuery,
  useGetDemandCitationQuery,
  useGetDemandTemplateQuery,
  useUpdateDemandTemplateMutation,
} = demandsApiClient;

import { Box, Typography, SxProps, Theme } from '@mui/material';

interface ReadOnlyNoteFieldProps {
  label: string;
  value: string | number | null | undefined;
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
}

const ReadOnlyNoteField = ({
  label,
  value,
  sx,
  labelSx,
}: ReadOnlyNoteFieldProps) => {
  const isTodo = value === 'TODO';
  if (!value && !isTodo) return null;

  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        variant="subtitle2"
        sx={{ color: 'text.secondary', fontSize: '0.75rem', ...labelSx }}
      >
        {label}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 600,
          color: isTodo ? 'warning.main' : 'inherit',
          bgcolor: isTodo ? 'warning.lighter' : 'transparent',
          px: isTodo ? 1 : 0,
          py: isTodo ? 0.5 : 0,
          borderRadius: 1,
          display: 'inline-block',
          ...sx,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default ReadOnlyNoteField;

import React from 'react';
import { Phone } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { ScheduleDetailsProps } from './types';

export const PhoneDetails: React.FC<ScheduleDetailsProps> = ({ schedule }) => {
  return (
    <div className="bg-white rounded-lg p-3 shadow-sm">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
        <div className="flex items-center">
          <Phone className="h-4 w-4 mr-2 text-primary flex-shrink-0" />
          <h4 className="font-medium">Phone Details</h4>
        </div>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="font-medium text-sm ml-6 sm:ml-0 truncate max-w-[200px]">
                {schedule.phone_number || 'No phone specified'}
              </span>
            </TooltipTrigger>
            <TooltipContent>
              <p>Target phone number for this schedule</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
};

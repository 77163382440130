import { Box } from '@mui/material';
import { PriorLawsuitNote } from '../../../../services/types/matter-notes-types';
import ReadOnlyNoteSection from './ReadOnlyNoteSection';
import ReadOnlyNoteField from './ReadOnlyNoteField';
import { hasPopulatedFields } from '../../../../utils/noteUtils';

interface ReadOnlyPriorLawsuitNoteSectionProps {
  notes: PriorLawsuitNote[];
}

const ReadOnlyPriorLawsuitNoteSection = ({
  notes,
}: ReadOnlyPriorLawsuitNoteSectionProps) => {
  if (!notes?.length || !notes.some(hasPopulatedFields)) return null;

  return (
    <ReadOnlyNoteSection title="Prior Lawsuits">
      {notes.map((note, index) => (
        <Box
          key={note.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mb: index < notes.length - 1 ? 4 : 0,
          }}
        >
          <ReadOnlyNoteField
            label="ACCIDENT / CLAIM"
            value={note.description}
            labelSx={{ fontWeight: 700 }}
          />
          <ReadOnlyNoteField label="ATTORNEY" value={note.attorney} />
          <ReadOnlyNoteField label="YEAR" value={note.year} />
          <ReadOnlyNoteField label="SETTLEMENT" value={note.settlement} />
        </Box>
      ))}
    </ReadOnlyNoteSection>
  );
};

export default ReadOnlyPriorLawsuitNoteSection;

import { Check, Loader2, Pencil, PlusCircle, X } from 'lucide-react';
import React, { useState } from 'react';

import { Button } from '../../../ui/button';
import { Input } from '../../../ui/input';
import { ThreadListProps } from '../types';

export const ThreadList: React.FC<ThreadListProps> = ({
  threads,
  selectedThreadId,
  onSelectThread,
  onDeleteThread,
  onEditThread,
  newThreadTitle,
  setNewThreadTitle,
  onCreateThread,
  isCreatingThread,
}) => {
  const [editingThreadId, setEditingThreadId] = useState<string | null>(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [isSubmittingEdit, setIsSubmittingEdit] = useState(false);

  const startEditing = (
    thread: { id: string; title: string },
    e: React.MouseEvent,
  ) => {
    e.stopPropagation();
    setEditingThreadId(thread.id);
    setEditedTitle(thread.title);
  };

  const cancelEditing = () => {
    setEditingThreadId(null);
    setEditedTitle('');
  };

  const submitEdit = async (threadId: string) => {
    if (
      editedTitle.trim() &&
      editedTitle !== threads.find((t) => t.id === threadId)?.title
    ) {
      setIsSubmittingEdit(true);
      try {
        await onEditThread(threadId, editedTitle);
      } finally {
        setIsSubmittingEdit(false);
      }
    }
    cancelEditing();
  };

  const handleKeyDown = (e: React.KeyboardEvent, threadId: string) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitEdit(threadId);
    } else if (e.key === 'Escape') {
      cancelEditing();
    }
  };

  const handleCreateThread = async () => {
    if (isCreatingThread || !newThreadTitle.trim()) return;
    await onCreateThread();
  };

  return (
    <div className="flex flex-col h-full">
      <div className="p-4 border-b">
        <div className="space-y-3">
          <h3 className="font-semibold text-sm text-primary">Threads</h3>
          <div className="flex items-center space-x-2">
            <Input
              type="text"
              value={newThreadTitle}
              onChange={(e) => setNewThreadTitle(e.target.value)}
              placeholder="New thread title"
              className="h-9 text-sm bg-muted/30"
              disabled={isCreatingThread}
            />
            <Button
              type="button"
              onClick={handleCreateThread}
              size="icon"
              variant="ghost"
              className="h-9 w-9 rounded-full hover:bg-primary/10 hover:text-primary"
              disabled={!newThreadTitle.trim() || isCreatingThread}
            >
              {isCreatingThread ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <PlusCircle className="h-4 w-4" />
              )}
            </Button>
          </div>
        </div>
      </div>

      <div className="overflow-y-auto flex-1 py-2 px-2">
        {threads?.length === 0 ? (
          <div className="flex justify-center items-center h-24 text-muted-foreground text-sm italic">
            No threads yet
          </div>
        ) : (
          <div className="space-y-1">
            {threads?.map((thread) => (
              <div
                key={thread.id}
                className={`group rounded-md overflow-hidden ${
                  selectedThreadId === thread.id
                    ? 'bg-primary/10 ring-1 ring-primary/20'
                    : 'hover:bg-accent'
                }`}
              >
                <div className="flex items-center w-full">
                  {editingThreadId === thread.id ? (
                    <div className="flex flex-1 items-center px-2 py-1">
                      <Input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, thread.id)}
                        className="h-8 text-sm flex-1 bg-background"
                        autoFocus
                        disabled={isSubmittingEdit}
                      />
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        onClick={() => submitEdit(thread.id)}
                        className="h-7 w-7 ml-1 text-primary hover:bg-primary/10"
                        disabled={!editedTitle.trim() || isSubmittingEdit}
                      >
                        {isSubmittingEdit ? (
                          <Loader2 className="h-3.5 w-3.5 animate-spin" />
                        ) : (
                          <Check className="h-3.5 w-3.5" />
                        )}
                      </Button>
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        onClick={cancelEditing}
                        className="h-7 w-7 ml-1 text-muted-foreground hover:bg-accent"
                        disabled={isSubmittingEdit}
                      >
                        <X className="h-3.5 w-3.5" />
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Button
                        type="button"
                        variant={
                          selectedThreadId === thread.id ? 'ghost' : 'ghost'
                        }
                        onClick={() => onSelectThread(thread.id)}
                        className="flex-1 justify-start px-3 py-2 h-auto text-left text-sm truncate rounded-none hover:bg-transparent"
                        style={{
                          fontWeight:
                            selectedThreadId === thread.id ? 500 : 400,
                          color:
                            selectedThreadId === thread.id
                              ? 'var(--primary)'
                              : 'inherit',
                        }}
                      >
                        {thread.title}
                      </Button>
                      <div className="flex items-center mr-1">
                        <div className="flex items-center bg-transparent rounded-md hover:bg-accent/50 p-0.5 opacity-0 group-hover:opacity-100 transition-opacity">
                          <button
                            type="button"
                            onClick={(e) => startEditing(thread, e)}
                            className="h-5 w-5 flex items-center justify-center text-muted-foreground hover:text-primary"
                            aria-label={`Edit thread ${thread.title}`}
                          >
                            <Pencil className="h-3 w-3" />
                          </button>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              onDeleteThread(thread.id);
                            }}
                            className="h-5 w-5 flex items-center justify-center text-muted-foreground hover:text-destructive"
                            aria-label={`Delete thread ${thread.title}`}
                          >
                            <X className="h-3.5 w-3.5" />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

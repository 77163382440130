import { ChevronLeft } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Header } from '@/layout/Header';
import {
  useCreateOperatorTemplateMutation,
  useDeleteOperatorTemplateMutation,
  useGetOperatorTemplatesQuery,
  useUpdateOperatorTemplateMutation,
} from '@/services/api/operatorTemplateService';
import { CanonicalTaskType } from '@/services/types/task-types';
import { OperatorTemplate } from '../../services/types/operator-templates-types';
import { EditableTitle } from './components/EditableTitle';
import { TaskTypeSelector } from './components/TaskTypeSelector';
import { TemplateDebugPanel } from './components/TemplateDebugPanel';
import { OperatorTemplateEditor } from './components/OperatorTemplateEditor';
import { OperatorTemplateList } from './components/OperatorTemplateList';
import { MessagePreview } from './components/MessagePreview';
import {
  DEFAULT_CONTENT_JSON,
  DEFAULT_CONTENT_TEXT,
} from './config/editor-config';
import {
  TemplateValues,
  TemplateVariablesProvider,
} from './context/TemplateContext';
import { getSampleTemplateValues } from './hooks/useSampleTemplateValues';
import { useOperatorTemplateState } from './hooks/useOperatorTemplateState';
import { isJsonEqual } from '../../utils/comparison';

const EmptyEditorState = () => (
  <div className="flex flex-col items-center justify-center h-full p-8 text-center border-2 border-dashed rounded-lg bg-muted/5">
    <ChevronLeft className="w-12 h-12 mb-4 text-muted-foreground" />
    <h3 className="text-lg font-semibold mb-2">No Template Selected</h3>
    <p className="text-sm text-muted-foreground max-w-sm">
      Select a template from the list on the left to start editing, or create a
      new template using the buttons above.
    </p>
  </div>
);

export const OperatorTemplateManagerPage = () => {
  // Form state (separate from editor content)
  const [title, setTitle] = useState<string>('');
  const [selectedTaskType, setSelectedTaskType] = useState<CanonicalTaskType>(
    CanonicalTaskType.ALL,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [templateValues, setTemplateValues] = useState<TemplateValues>(() =>
    getSampleTemplateValues(CanonicalTaskType.ALL),
  );

  // Template state with our improved hook
  const {
    template: selectedTemplate,
    setTemplate: setSelectedTemplate,
    handleContentChange,
    getOriginalTemplate,
    getCurrentTemplateState,
    getRenderedTemplate,
    editorContent,
  } = useOperatorTemplateState({
    onTemplateChange: (template) => {
      if (template) {
        // Only update form fields when template changes
        setTitle(template.title || '');
        setSelectedTaskType(
          template.suggested_task_type || CanonicalTaskType.ALL,
        );
      } else {
        setTitle('');
        setSelectedTaskType(CanonicalTaskType.ALL);
      }
    },
  });

  // API mutations and queries
  const { data: templates = [], isLoading: isLoadingTemplates } =
    useGetOperatorTemplatesQuery();
  const [createTemplate] = useCreateOperatorTemplateMutation();
  const [updateTemplate] = useUpdateOperatorTemplateMutation();
  const [deleteTemplate] = useDeleteOperatorTemplateMutation();

  // Dirty check compares current form values with original template
  const isDirty = useCallback(() => {
    if (!selectedTemplate || !title.trim()) return false;

    const originalTemplate = getOriginalTemplate();
    if (!originalTemplate) return false;

    const currentTemplate = getCurrentTemplateState();
    if (!currentTemplate) return false;

    // Compare title and task type
    const titleChanged = title !== originalTemplate.title;
    const taskTypeChanged =
      selectedTaskType !== originalTemplate.suggested_task_type;

    // For JSON comparison, use our utility function
    const jsonChanged = !isJsonEqual(
      currentTemplate.prompt_json,
      originalTemplate.prompt_json,
    );

    // Debug logging - remove in production
    if (jsonChanged) {
      console.log('JSON changed:', {
        current: currentTemplate.prompt_json,
        original: originalTemplate.prompt_json,
      });
    }

    if (titleChanged) {
      console.log('Title changed:', {
        current: title,
        original: originalTemplate.title,
      });
    }

    if (taskTypeChanged) {
      console.log('Task type changed:', {
        current: selectedTaskType,
        original: originalTemplate.suggested_task_type,
      });
    }

    const hasChanges = titleChanged || taskTypeChanged || jsonChanged;
    return hasChanges;
  }, [
    selectedTemplate,
    title,
    selectedTaskType,
    getOriginalTemplate,
    getCurrentTemplateState,
    isJsonEqual,
  ]);

  const handleSaveTemplate = useCallback(async () => {
    if (!selectedTemplate || !title.trim()) return;

    setIsSaving(true);
    try {
      // Get the current editor content and combine with form fields
      const currentState = getCurrentTemplateState();

      if (!currentState) {
        toast.error('No template content to save');
        return;
      }

      const result = await updateTemplate({
        id: selectedTemplate.id,
        template: {
          title: title.trim(),
          suggested_task_type: selectedTaskType,
          prompt_text: currentState.prompt_text,
          prompt_json: currentState.prompt_json,
        },
      }).unwrap();

      // Update the entire template state after successful save
      setSelectedTemplate(result);
      toast.success('Template Updated');
    } catch (error) {
      toast.error('Failed to save template');
    } finally {
      setIsSaving(false);
    }
  }, [
    selectedTemplate,
    title,
    selectedTaskType,
    updateTemplate,
    setSelectedTemplate,
    getCurrentTemplateState,
  ]);

  const handleCreateNew = useCallback(async () => {
    try {
      const result = await createTemplate({
        title: 'New Template',
        suggested_task_type: CanonicalTaskType.ALL,
        prompt_text: DEFAULT_CONTENT_TEXT,
        prompt_json: DEFAULT_CONTENT_JSON,
      }).unwrap();
      setSelectedTemplate(result);
      toast.success('Template Created');
    } catch (err) {
      toast.error('Failed to create template');
    }
  }, [createTemplate, setSelectedTemplate]);

  const handleDuplicate = useCallback(async () => {
    if (!selectedTemplate) return;

    try {
      // Use the current state for duplication
      const currentState = getCurrentTemplateState();

      if (!currentState) {
        toast.error('Cannot duplicate empty template');
        return;
      }

      const result = await createTemplate({
        title: `Copy of ${currentState.title}`,
        suggested_task_type: currentState.suggested_task_type,
        prompt_text: currentState.prompt_text,
        prompt_json: currentState.prompt_json,
      }).unwrap();

      setSelectedTemplate(result);
      toast.success('Template Duplicated');
    } catch (err) {
      toast.error('Failed to duplicate template');
    }
  }, [
    selectedTemplate,
    createTemplate,
    setSelectedTemplate,
    getCurrentTemplateState,
  ]);

  const handleDeleteTemplate = useCallback(
    async (template: OperatorTemplate) => {
      try {
        await deleteTemplate(template.id);
        if (selectedTemplate?.id === template.id) {
          setSelectedTemplate(null);
        }
        toast.success('Template Deleted');
      } catch (err) {
        toast.error('Failed to delete template');
      }
    },
    [deleteTemplate, selectedTemplate, setSelectedTemplate],
  );

  // Effects
  useEffect(() => {
    setTemplateValues(getSampleTemplateValues(selectedTaskType));
  }, [selectedTaskType]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        e.key === 'Enter' &&
        isDirty() &&
        !isSaving
      ) {
        e.preventDefault();
        handleSaveTemplate();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isDirty, isSaving, handleSaveTemplate]);

  return (
    <div className="h-screen w-screen">
      <Header />
      <main className="p-4 flex-1 flex flex-col h-[calc(100vh-4rem)]">
        <div className="grid grid-cols-[300px_1fr] gap-8 flex-1 min-h-0">
          <div className="overflow-hidden flex flex-col">
            <h1 className="text-2xl font-bold mb-4">Operator Templates</h1>
            <div className="flex-1 min-h-0">
              <OperatorTemplateList
                templates={templates}
                isLoading={isLoadingTemplates}
                selectedTemplate={selectedTemplate}
                onSelectTemplate={setSelectedTemplate}
                onDeleteTemplate={handleDeleteTemplate}
                onCreateNew={handleCreateNew}
                onDuplicate={handleDuplicate}
              />
            </div>
          </div>

          <div className="overflow-y-auto">
            {!selectedTemplate ? (
              <EmptyEditorState />
            ) : (
              <div className="space-y-4">
                <div className="bg-gray-50/50 rounded-lg border divide-y">
                  <div className="p-4">
                    <EditableTitle title={title} onChange={setTitle} />
                  </div>
                  <div className="p-4 flex items-center justify-between gap-8">
                    <div className="w-[400px]">
                      <span className="block text-xs text-gray-500 font-semibold mb-1">
                        SUGGESTED TASK <span className="text-red-500">*</span>
                      </span>
                      <TaskTypeSelector
                        value={selectedTaskType}
                        onChange={(value) =>
                          setSelectedTaskType(value || CanonicalTaskType.ALL)
                        }
                        className="w-full"
                        label=""
                      />
                    </div>
                    <div className="h-9 flex items-center justify-end w-[200px]">
                      <div
                        className={`transition-all duration-200 ${
                          isDirty() && !isSaving
                            ? 'opacity-100 translate-y-0'
                            : 'opacity-0 translate-y-1 pointer-events-none'
                        }`}
                      >
                        <Button
                          variant="default"
                          onClick={handleSaveTemplate}
                          className="bg-primary text-white hover:bg-primary/90 font-semibold px-8 shrink-0"
                          title={`Save (${
                            navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'
                          }+Enter)`}
                        >
                          <div className="flex items-center">
                            <span>Save Changes</span>
                            <span className="ml-2 text-xs opacity-60">
                              {navigator.platform.includes('Mac')
                                ? '⌘'
                                : 'Ctrl'}
                              +↵
                            </span>
                          </div>
                        </Button>
                      </div>
                      <div
                        className={`absolute transition-all duration-200 ${
                          isSaving
                            ? 'opacity-100 scale-100'
                            : 'opacity-0 scale-75'
                        }`}
                      >
                        <div className="w-4 h-4 border-2 border-primary/30 border-t-primary rounded-full animate-spin" />
                      </div>
                    </div>
                  </div>
                  <TemplateVariablesProvider
                    values={templateValues}
                    setValues={setTemplateValues}
                  >
                    <div className="grid grid-cols-[70%_30%] divide-x">
                      <div>
                        <div className="p-4">
                          <span className="block text-xs text-gray-500 font-semibold uppercase mb-2">
                            SLACK MESSAGE{' '}
                            <span className="text-red-500">*</span>
                          </span>
                          <OperatorTemplateEditor
                            template={selectedTemplate}
                            onContentChange={handleContentChange}
                          />
                        </div>
                      </div>
                      <div className="p-4">
                        <TemplateDebugPanel taskType={selectedTaskType} />
                      </div>
                    </div>
                    <div className="p-4 border-t">
                      <MessagePreview
                        renderedText={editorContent.renderedText || ''}
                        isSaving={isSaving}
                      />
                    </div>
                  </TemplateVariablesProvider>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

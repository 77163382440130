export const isPhoneNumberValid = (phoneNumber: string) => {
  // Accepting flexible phone number formats like (303) 217-0796 or 303-217-0796
  const phonePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return phonePattern.test(phoneNumber);
};

// New function that returns specific validation errors for phone numbers
export const validatePhoneNumber = (
  phoneNumber: string,
): { isValid: boolean; errorMessage?: string } => {
  if (!phoneNumber || phoneNumber.trim() === '') {
    return { isValid: false, errorMessage: 'Phone number is required' };
  }

  // Remove all non-digit characters to count actual digits

  // Check if it matches the expected format
  const phonePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  if (!phonePattern.test(phoneNumber)) {
    return {
      isValid: false,
      errorMessage:
        'Please enter a valid phone number format (e.g. 303-217-0796 or (303) 217-0796)',
    };
  }

  return { isValid: true };
};

export const sanitizePhoneNumber = (phoneNumber: string) => {
  return `+1${phoneNumber.replace(/\D/g, '')}`;
};

export const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isEmailValid = (email: string) => {
  return EmailRegex.test(email);
};
export const normalizePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  // Remove +1 prefix if it exists
  return phoneNumber.replace('+1', '');
};

import { useEffect, useCallback } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';

import { BlandCallTemplate } from '../../../services/types/bland-templates.types';
import {
  templateEditorConfig,
  DEFAULT_CONTENT_JSON,
} from '../config/editor-config';
import { useTemplateVariables } from '../context/TemplateContext';
import { TemplateSettings } from './TemplateSettings';

interface TemplateEditorProps {
  template: BlandCallTemplate;
  onContentChange: (content: {
    text: string;
    html: string;
    json: string;
    renderedText: string;
    [key: string]: any;
  }) => void;
  onSettingsChange?: (settings: Partial<BlandCallTemplate>) => void;
}

export const TemplateEditor = ({
  template,
  onContentChange,
  onSettingsChange,
}: TemplateEditorProps) => {
  const { setUsedVariables, values } = useTemplateVariables();

  const updateContent = useCallback(
    (editorInstance: any) => {
      // Extract template variables from the editor content
      const variables = new Set<string>();
      editorInstance.state.doc.descendants((node: any) => {
        if (node.type.name === 'template' && node.attrs.id) {
          variables.add(node.attrs.id);
        }
      });

      // Update the context with the used variables
      setUsedVariables(variables);

      // Call the original onContentChange with the updated content
      const text = editorInstance.getText();
      const html = editorInstance.getHTML();
      const json = JSON.stringify(editorInstance.getJSON());
      const renderedText = editorInstance.getText({
        textSerializers: {
          template: ({ node }: any) => {
            return values[node.attrs.id];
          },
        },
      });

      onContentChange({
        text,
        html,
        json,
        renderedText,
      });
    },
    [onContentChange, setUsedVariables, values],
  );

  const editor = useEditor({
    ...templateEditorConfig,
    content: template.prompt_json
      ? JSON.parse(template.prompt_json)
      : DEFAULT_CONTENT_JSON,
    editable: true,
    onCreate: ({ editor: editorInstance }) => {
      // Initial content update without setTimeout
      updateContent(editorInstance);
    },
    onUpdate: ({ editor: editorInstance }) => {
      // Direct update without setTimeout
      updateContent(editorInstance);
    },
  });

  // Update editor content when template changes
  useEffect(() => {
    if (editor && template) {
      const currentContent = JSON.stringify(editor.getJSON());
      const newContent =
        template.prompt_json || JSON.stringify(DEFAULT_CONTENT_JSON);

      // Only update editor content if it's different from current content
      if (currentContent !== newContent) {
        editor.commands.setContent(JSON.parse(newContent));
        // Schedule the content update for the next tick
        setTimeout(() => updateContent(editor), 0);
      }
    }
  }, [template, editor, updateContent]);

  const handleSettingsChange = (settings: Partial<BlandCallTemplate>) => {
    // Call the separate onSettingsChange handler if provided
    if (onSettingsChange) {
      onSettingsChange(settings);
    }
  };

  return (
    <div>
      {editor && (
        <>
          <EditorContent editor={editor} className="prose max-w-none" />
          <div className="mt-4">
            <TemplateSettings
              template={template}
              onSettingsChange={handleSettingsChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithErrorHandling } from './baseQuery';
import {
  OperatorTemplate,
  CreateOperatorTemplate,
  UpdateOperatorTemplate,
} from '../types/operator-templates-types';

export const operatorTemplateApi = createApi({
  reducerPath: 'operatorTemplateApi',
  tagTypes: ['OperatorTemplate'],
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    // Get all templates
    getOperatorTemplates: builder.query<
      OperatorTemplate[],
      void | { taskType?: string; search?: string }
    >({
      query: (params) => {
        // Build query string from params if provided
        let queryString = '';
        if (params) {
          const queryParams = new URLSearchParams();
          if (params.taskType) {
            queryParams.append('task_type', params.taskType);
          }
          if (params.search) {
            queryParams.append('search', params.search);
          }
          queryString = queryParams.toString()
            ? `?${queryParams.toString()}`
            : '';
        }
        return `api/operator-templates/${queryString}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'OperatorTemplate' as const,
                id,
              })),
              { type: 'OperatorTemplate', id: 'LIST' },
            ]
          : [{ type: 'OperatorTemplate', id: 'LIST' }],
    }),

    // Get single template by ID
    getOperatorTemplateById: builder.query<OperatorTemplate, string>({
      query: (id) => `api/operator-templates/${id}/`,
      providesTags: (result, error, id) => [{ type: 'OperatorTemplate', id }],
    }),

    // Create new template
    createOperatorTemplate: builder.mutation<
      OperatorTemplate,
      CreateOperatorTemplate
    >({
      query: (body) => ({
        url: 'api/operator-templates/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'OperatorTemplate', id: 'LIST' }],
    }),

    // Update template
    updateOperatorTemplate: builder.mutation<
      OperatorTemplate,
      { id: string; template: UpdateOperatorTemplate }
    >({
      query: ({ id, template }) => ({
        url: `api/operator-templates/${id}/`,
        method: 'PATCH',
        body: template,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'OperatorTemplate', id },
        { type: 'OperatorTemplate', id: 'LIST' },
      ],
    }),

    // Delete template
    deleteOperatorTemplate: builder.mutation<void, string>({
      query: (id) => ({
        url: `api/operator-templates/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'OperatorTemplate', id: 'LIST' }],
    }),

    // Create empty template
    createEmptyOperatorTemplate: builder.mutation<
      OperatorTemplate,
      { title: string; taskType?: string }
    >({
      query: (body) => ({
        url: 'api/operator-templates/',
        method: 'POST',
        body: {
          title: body.title,
          suggested_task_type: body.taskType || null,
          prompt_text: null,
          prompt_json: null,
        },
      }),
      invalidatesTags: [{ type: 'OperatorTemplate', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetOperatorTemplatesQuery,
  useGetOperatorTemplateByIdQuery,
  useCreateOperatorTemplateMutation,
  useUpdateOperatorTemplateMutation,
  useDeleteOperatorTemplateMutation,
  useCreateEmptyOperatorTemplateMutation,
} = operatorTemplateApi;

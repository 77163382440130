// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router-dom';
import {
  useGetBlobsForCollectionQuery,
  useGetCollectionTreeQuery,
  useGetMatterDetailsQuery,
} from '../services/api';

const useMatterSelection = () => {
  const { matter_id } = useParams();

  const {
    data: selectedMatterData,
    error: selectedMatterError,
    isLoading: selectedMatterLoading,
    refetch: refetchMatterDetails,
  } = useGetMatterDetailsQuery(matter_id || '', {
    skip: !matter_id,
    pollingInterval: 1000 * 60 * 5, // 5 minutes
  });

  const { data: collectionTree } = useGetCollectionTreeQuery(matter_id || '', {
    skip: !matter_id,
  });
  const { data: blobs } = useGetBlobsForCollectionQuery(matter_id || '', {
    skip: !matter_id,
  });

  return {
    selectedMatterData,
    selectedMatterError,
    selectedMatterLoading,
    collectionTree,
    blobs,
    refetchMatterDetails,
  };
};

export default useMatterSelection;

import React from 'react';

import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

import { ProofreadingRuleStatus } from '../../../services/types/proofreading-types';

interface SeverityBadgeProps {
  status: ProofreadingRuleStatus;
  compact?: boolean;
}

export const SeverityBadge: React.FC<SeverityBadgeProps> = ({
  status,
  compact = false,
}) => {
  const statusConfig = {
    [ProofreadingRuleStatus.NO_ISSUES]: {
      label: 'Passed',
      variant: 'green',
    },
    [ProofreadingRuleStatus.MINOR_ISSUES_FOUND]: {
      label: 'Minor Issues',
      variant: 'yellow',
    },
    [ProofreadingRuleStatus.MODERATE_ISSUES_FOUND]: {
      label: 'Moderate Issues',
      variant: 'orange',
    },
    [ProofreadingRuleStatus.CRITICAL_ISSUES_FOUND]: {
      label: 'Critical Issues',
      variant: 'red',
    },
    [ProofreadingRuleStatus.UNABLE_TO_ASSESS]: {
      label: 'Unable to Assess',
      variant: 'gray',
    },
  };

  const config =
    statusConfig[status] ||
    statusConfig[ProofreadingRuleStatus.UNABLE_TO_ASSESS];

  const variantStyles = {
    green: 'bg-green-50 text-green-700 border-green-200',
    yellow: 'bg-yellow-50 text-yellow-700 border-yellow-200',
    orange: 'bg-orange-50 text-orange-700 border-orange-200',
    red: 'bg-red-50 text-red-700 border-red-200',
    gray: 'bg-gray-50 text-gray-700 border-gray-200',
  };

  if (compact) {
    const dotColor = {
      green: 'bg-green-500/75',
      yellow: 'bg-yellow-500/75',
      orange: 'bg-orange-500/75',
      red: 'bg-red-500/75',
      gray: 'bg-gray-500/75',
    }[config.variant];

    return (
      <div className="flex items-center">
        <div
          className={cn('w-2.5 h-2.5 rounded-full', dotColor)}
          title={config.label}
        />
      </div>
    );
  }

  return (
    <Badge
      variant="outline"
      className={cn(
        'text-xs font-medium border py-0.5',
        variantStyles[config.variant as keyof typeof variantStyles],
      )}
    >
      {config.label}
    </Badge>
  );
};

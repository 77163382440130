import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  useCreateEditorThreadMutation,
  useDeleteEditorThreadMutation,
  useUpdateEditorThreadMutation,
} from '../../../../services/api/demandService';
import { ChatContextType } from '../types';

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};

export const ChatProvider: React.FC<{
  children: React.ReactNode;
  matterId: string;
  demandId: string;
}> = ({ children, matterId, demandId }) => {
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null);
  const [newThreadTitle, setNewThreadTitle] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [createThread] = useCreateEditorThreadMutation();
  const [deleteThread] = useDeleteEditorThreadMutation();
  const [updateThread] = useUpdateEditorThreadMutation();

  const handleCreateThread = useCallback(async () => {
    if (!newThreadTitle.trim()) return;

    try {
      const response = await createThread({
        matterId,
        demandId,
        data: { title: newThreadTitle },
      }).unwrap();
      setNewThreadTitle('');
      // Auto-select the newly created thread
      if (response && response.id) {
        setSelectedThreadId(response.id);
      }
    } catch (error) {
      console.error('Failed to create thread:', error);
    }
  }, [
    createThread,
    demandId,
    matterId,
    newThreadTitle,
    setNewThreadTitle,
    setSelectedThreadId,
  ]);

  const handleDeleteThread = useCallback(
    async (threadId: string) => {
      try {
        await deleteThread({ matterId, demandId, threadId });
        if (selectedThreadId === threadId) {
          setSelectedThreadId(null);
        }
      } catch (error) {
        console.error('Failed to delete thread:', error);
      }
    },
    [deleteThread, demandId, matterId, selectedThreadId, setSelectedThreadId],
  );

  const handleEditThread = useCallback(
    async (threadId: string, newTitle: string) => {
      try {
        await updateThread({
          matterId,
          demandId,
          threadId,
          data: { title: newTitle },
        });
      } catch (error) {
        console.error('Failed to update thread:', error);
      }
    },
    [updateThread, demandId, matterId],
  );

  const contextValue = useMemo(
    () => ({
      selectedThreadId,
      setSelectedThreadId,
      newThreadTitle,
      setNewThreadTitle,
      sidebarOpen,
      setSidebarOpen,
      handleCreateThread,
      handleDeleteThread,
      handleEditThread,
    }),
    [
      selectedThreadId,
      setSelectedThreadId,
      newThreadTitle,
      setNewThreadTitle,
      sidebarOpen,
      setSidebarOpen,
      handleCreateThread,
      handleDeleteThread,
      handleEditThread,
    ],
  );

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};

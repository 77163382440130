import { format, parseISO } from 'date-fns';
import { cn } from '@/lib/utils';
import { DAYS_OF_WEEK } from './types';

// Format time with AM/PM with error handling
export const formatTime = (hour: number, minute: number) => {
  try {
    const date = new Date();
    date.setHours(hour || 0);
    date.setMinutes(minute || 0);
    return format(date, 'h:mm a'); // Format as 12-hour time with AM/PM
  } catch (error) {
    return `${hour || 0}:${(minute || 0).toString().padStart(2, '0')}`;
  }
};

// Format date with time
export const formatDateTime = (dateString: string) => {
  try {
    const date = parseISO(dateString);
    return format(date, 'MMM d, yyyy h:mm a');
  } catch (error) {
    return dateString;
  }
};

// Format days function with null checks
export const formatDays = (days: number[] = []) => {
  if (!days) return 'No days selected';
  if (days.length === 31) return 'Every day of the month';
  if (days.length > 20) return `${days.length} days of the month`;
  return days.length > 0
    ? `Day${days.length > 1 ? 's' : ''} ${days.join(', ')}`
    : 'No days selected';
};

// Format days of week function
export const formatDaysOfWeek = (dayIndices: number[] = []) => {
  if (!dayIndices || dayIndices.length === 0) return 'No days selected';
  if (dayIndices.length === 7) return 'Every day of the week';

  const dayNames = dayIndices.map((index) => {
    const day = DAYS_OF_WEEK.find(
      (d: { value: number; label: string }) => d.value === index,
    );
    return day ? day.label : `Day ${index}`;
  });

  if (dayNames.length <= 3) {
    return dayNames.join(', ');
  }

  return `${dayNames.length} days per week`;
};

// Format retry delay in a more human-readable way
export const formatRetryDelay = (seconds: number) => {
  if (seconds < 60) return `${seconds} seconds`;
  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (minutes === 0) return `${hours} hour${hours > 1 ? 's' : ''}`;
  return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
};

// Get status badge class
export const getStatusBadgeClass = (status: string, isPaused: boolean) => {
  let badgeClass = 'px-2 text-xs';

  if (isPaused) {
    badgeClass += ' bg-amber-50 text-amber-700 border-amber-200';
  } else if (status === 'active' || status === 'completed') {
    badgeClass += ' bg-green-50 text-green-700 border-green-200';
  } else if (status === 'failed') {
    badgeClass += ' bg-red-50 text-red-700 border-red-200';
  } else {
    badgeClass += ' bg-blue-50 text-blue-700 border-blue-200';
  }

  return badgeClass;
};

// Get retry status text
export const getRetryStatusText = (retryCount: number, maxRetries: number) => {
  if (retryCount === 0) {
    return 'Waiting for first attempt';
  }
  if (retryCount >= maxRetries) {
    return 'Max retries reached';
  }
  return `Retry ${retryCount} of ${maxRetries}`;
};

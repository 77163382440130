import * as React from 'react';
import { Button } from '@/components/ui/button';
import {
  PhoneCallAction,
  Task,
  TaskUpdateVisibility,
} from '@/services/types/client-intake-types';
import { useGetMatterContextQuery } from '@/services/api/matterContextService';
import { VoiceAICallModal } from '../VoiceAICallModal';
import { OperatorMessageModal } from '../OperatorMessageModal';
import { CallClientButton } from '../CallClientButton';

interface TaskActionsProps {
  task: Task;
  handleSubmit: (action: PhoneCallAction) => void;
  createTaskUpdate: (params: {
    matterId: string;
    taskId: string;
    update: {
      update: string;
      visibility: TaskUpdateVisibility;
    };
  }) => void;
  refetchTaskUpdates?: () => void;
}

export const TaskActions: React.FC<TaskActionsProps> = ({
  task,
  handleSubmit,
  createTaskUpdate,
  refetchTaskUpdates,
}) => {
  const [showVoiceAIModal, setShowVoiceAIModal] = React.useState(false);
  const [showOperatorMessageModal, setShowOperatorMessageModal] =
    React.useState(false);
  const { data: matterContext } = useGetMatterContextQuery({
    matterId: task.matter,
  });

  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-lg font-semibold">Actions</h3>
      <div className="flex flex-row gap-2">
        <Button
          className="font-semibold text-sm cursor-pointer "
          onClick={() => setShowVoiceAIModal(true)}
        >
          Voice AI Call
        </Button>
        <Button
          variant="outline"
          className="font-semibold text-sm  "
          onClick={() => setShowOperatorMessageModal(true)}
        >
          Send to External Ops
        </Button>
        {matterContext?.clientPhone && (
          <CallClientButton phoneNumber={matterContext.clientPhone} />
        )}
      </div>
      {showVoiceAIModal && (
        <VoiceAICallModal
          task={task}
          onClose={() => setShowVoiceAIModal(false)}
          handleSubmit={handleSubmit}
        />
      )}
      {showOperatorMessageModal && (
        <OperatorMessageModal
          task={task}
          onClose={() => {
            setShowOperatorMessageModal(false);
            // Refresh task updates when the modal is closed
            if (refetchTaskUpdates) {
              refetchTaskUpdates();
            }
          }}
          handleSubmit={() => {
            // We're not using this anymore since the backend is handling the update
          }}
        />
      )}
    </div>
  );
};

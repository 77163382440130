import { Highlighter, Loader2, PlusCircle, Send, X } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useSendMessage } from '../../../../hooks/useDemandEditorChat';
import {
  useDeleteEditorFileMutation,
  useUploadEditorFileMutation,
} from '../../../../services/api/demandService';
import { AutosizeTextarea } from '../../../ui/autosize-textarea';
import { Button } from '../../../ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../ui/tooltip';
import { useSafeEditor } from '../hooks/useSafeEditor';
import { ChatInputProps } from '../types';

export const ChatInput: React.FC<ChatInputProps> = ({
  matterId,
  demandId,
  threadId,
  onBeforeSend,
  containerHeight,
  disabled,
  disableSend,
}) => {
  const [message, setMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<
    { id: string; name: string }[]
  >([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { sendMessage, isLoading } = useSendMessage(
    matterId,
    demandId,
    threadId,
  );
  const [uploadEditorFile] = useUploadEditorFileMutation();
  const [deleteEditorFile] = useDeleteEditorFileMutation();

  const {
    isSelectionActive,
    selectedHtml,
    clearSelection,
    disableChat,
    enableChat,
  } = useSafeEditor();

  // Get a plain text preview of the selected HTML (up to 50 chars)
  const selectedTextPreview = React.useMemo(() => {
    if (!selectedHtml) return '';

    // Create a temporary div to parse the HTML and extract text
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = selectedHtml;
    const text = tempDiv.textContent || tempDiv.innerText || '';

    // Return a truncated version if it's too long
    return text.length > 100 ? `${text.substring(0, 97)}...` : text;
  }, [selectedHtml]);

  // Create a new thread when the user starts typing in the input field
  useEffect(() => {
    if (message.trim() && disableSend && onBeforeSend) {
      onBeforeSend();
    }
  }, [message, disableSend, onBeforeSend]);

  const handleFileUpload = async (files: FileList) => {
    if (!files.length) return;

    const file = files[0];
    if (!file.type.match(/^image\/(jpeg|png)$/)) {
      console.error('Invalid file type. Only JPG and PNG files are allowed.');
      return;
    }

    // If there's no thread yet, create one when uploading a file
    if (disableSend && onBeforeSend) {
      onBeforeSend();
    }

    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('filename', file.name);
      formData.append('file_type', 'image');

      const response = await uploadEditorFile({
        matterId,
        demandId,
        threadId,
        data: formData,
      }).unwrap();

      // Add the uploaded file to the list
      setUploadedFiles((prev) => [
        ...prev,
        { id: response.id, name: file.name },
      ]);
    } catch (error) {
      console.error('Failed to upload file:', error);
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  // Handle clipboard paste
  const handlePaste = async (e: React.ClipboardEvent) => {
    const clipboardItems = e.clipboardData.items;

    // Find the first image in the clipboard
    const imageItem = Array.from(clipboardItems).find((item) =>
      item.type.startsWith('image/'),
    );

    if (!imageItem) return;

    // If there's no thread yet, create one when pasting an image
    if (disableSend && onBeforeSend) {
      onBeforeSend();
    }

    // Convert clipboard image to a File object
    const file = imageItem.getAsFile();
    if (!file) return;

    // Create a more meaningful filename for pasted images
    const pastedFileName = `Pasted image ${new Date().toLocaleString()}.png`;
    const imageFile = new File([file], pastedFileName, { type: file.type });

    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', imageFile);
      formData.append('filename', imageFile.name);
      formData.append('file_type', 'image');

      const response = await uploadEditorFile({
        matterId,
        demandId,
        threadId,
        data: formData,
      }).unwrap();

      setUploadedFiles((prev) => [
        ...prev,
        { id: response.id, name: imageFile.name },
      ]);
    } catch (error) {
      console.error('Failed to upload pasted image:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      (!message.trim() && uploadedFiles.length === 0) ||
      isLoading ||
      disabled ||
      disableSend
    )
      return;

    // Call onBeforeSend if provided
    if (onBeforeSend) {
      onBeforeSend();
    }

    disableChat();

    const now = new Date().toISOString();
    const messageRequest = {
      message: {
        id: uuidv4(),
        created_at: now,
        modified_at: now,
        role: 'user' as const,
        parent_id: null,
        status: 'completed' as const,
        openai_response: null,
        content: [
          // Add text content if there's a message
          ...(message.trim()
            ? [
                {
                  id: uuidv4(),
                  type: 'text' as const,
                  text: message.trim(),
                  created_at: now,
                  modified_at: now,
                },
              ]
            : []),
          // Add image content for each uploaded file
          ...uploadedFiles.map((file) => ({
            id: uuidv4(),
            type: 'image' as const,
            image_id: file.id,
            created_at: now,
            modified_at: now,
          })),
        ],
      },
      context: selectedHtml || '',
    };

    const pendingMessage = message;
    try {
      setMessage('');

      await sendMessage(messageRequest);
      // Clear uploaded files after successful message
      setUploadedFiles([]);
      if (isSelectionActive) {
        clearSelection();
      }
    } catch (error) {
      console.error('Failed to send message:', error);
      setMessage(pendingMessage);
    } finally {
      enableChat();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (message.trim() && !isLoading && !disabled && !disableSend) {
        handleSubmit(e);
      }
    }
  };

  const removeUploadedFile = async (fileId: string) => {
    try {
      await deleteEditorFile({
        matterId,
        demandId,
        threadId,
        fileId,
      }).unwrap();

      // Only remove from state if delete was successful
      setUploadedFiles((prev) => prev.filter((file) => file.id !== fileId));
    } catch (error) {
      console.error('Failed to delete file:', error);
    }
  };

  const getPlaceholderText = () => {
    if (isLoading) {
      return 'Assistant is thinking...';
    }
    if (disabled) {
      return 'Start a thread to chat...';
    }
    if (disableSend) {
      return 'Type a message or paste an image...';
    }
    if (isSelectionActive) {
      return 'Ask about the selected text...';
    }
    return 'Type a message or paste an image...';
  };

  return (
    <div
      className={`flex flex-col bg-background border  ${
        isSelectionActive
          ? 'border-primary border-opacity-50'
          : 'border-border/10'
      } rounded-md shadow-sm ${disabled ? 'opacity-70' : ''}`}
    >
      <form onSubmit={handleSubmit}>
        {isSelectionActive && (
          <div className="px-4 py-2 bg-primary/5 border-b border-primary/10 text-sm">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2 text-primary">
                <Highlighter className="h-4 w-4 flex-shrink-0" />
                <span className="font-medium">Responding to selected text</span>
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={clearSelection}
                className="h-6 py-0 px-2 text-xs text-muted-foreground hover:text-destructive"
                disabled={disabled}
              >
                <X className="h-3 w-3 mr-1" />
                Clear
              </Button>
            </div>
            {selectedTextPreview && (
              <div className="pl-6 mt-1.5 text-xs line-clamp-2 bg-muted/30 p-2 rounded-sm border border-border/5">
                <span className="text-muted-foreground/70">
                  {selectedTextPreview}
                </span>
              </div>
            )}
          </div>
        )}

        {/* Uploaded files preview */}
        {uploadedFiles.length > 0 && (
          <div className="px-4 py-2 border-b border-border/10 bg-muted/30">
            <div className="flex flex-wrap gap-2">
              {uploadedFiles.map((file) => (
                <div
                  key={file.id}
                  className="flex items-center gap-2 bg-background px-2 py-1 rounded-md border text-sm"
                >
                  <span className="text-xs text-muted-foreground truncate max-w-[150px]">
                    {file.name}
                  </span>
                  <button
                    type="button"
                    onClick={() => removeUploadedFile(file.id)}
                    className="text-muted-foreground hover:text-destructive"
                    aria-label={`Remove ${file.name}`}
                    disabled={disabled}
                  >
                    <X className="h-3 w-3" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        <AutosizeTextarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          placeholder={getPlaceholderText()}
          className="flex-1 border-0 focus-visible:ring-0 bg-transparent text-base py-4 px-4 resize-none"
          minHeight={60}
          maxHeight={200}
          disabled={disabled}
        />
        <div className="flex justify-between items-center px-2 py-2 border-t border-border/10">
          <div className="flex items-center">
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) =>
                e.target.files && handleFileUpload(e.target.files)
              }
              className="hidden"
              accept="image/jpeg,image/png"
              disabled={disabled}
            />
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => fileInputRef.current?.click()}
                    disabled={isLoading || isUploading || disabled}
                    className="text-muted-foreground hover:text-primary"
                  >
                    {isUploading ? (
                      <Loader2 className="h-4 w-4 custom-spin" />
                    ) : (
                      <PlusCircle className="h-4 w-4" />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Upload an image (JPG or PNG)</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <Button
            type="submit"
            disabled={
              (!message.trim() && uploadedFiles.length === 0) ||
              isLoading ||
              isUploading ||
              disabled ||
              disableSend
            }
            variant="default"
            size="sm"
            className={`transition-colors flex-shrink-0 bg-primary hover:bg-primary/90 text-primary-foreground ${
              isLoading || isUploading || disabled || disableSend
                ? 'opacity-90'
                : ''
            }`}
            aria-label="Send message"
          >
            {isLoading ? (
              <Loader2 className="h-4 w-4 mr-1 custom-spin" />
            ) : (
              <Send className="h-4 w-4 mr-1" />
            )}
            {isLoading ? 'Thinking...' : 'Send'}
          </Button>
        </div>
      </form>
    </div>
  );
};

import React from 'react';

export const EndOfNotes: React.FC = () => {
  return (
    <div className="h-[70vh] relative mt-12" aria-hidden="true">
      {/* Top fade gradient */}
      <div className="absolute inset-x-0 top-0 h-40 bg-gradient-to-b from-white to-transparent" />

      {/* Content wrapper */}
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        {/* Up arrow indicator */}
        <div className="mb-6 animate-bounce [animation-duration:3s]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-slate-300"
          >
            <path d="m18 15-6-6-6 6" />
          </svg>
        </div>

        {/* Decorative logo */}
        <div className="relative w-full max-w-[240px] aspect-[31/24] mb-8 opacity-[0.09] mix-blend-multiply">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31 24"
            fill="currentColor"
            className="w-full h-full text-slate-700 animate-pulse [animation-duration:5s]"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.4232 5.41484C16.6753 4.3061 16.6692 4.31217 15.7284 5.25292L15.7284 5.25293L0.676797 20.1894C-0.24242 21.1086 -0.24237 21.1087 0.781835 21.8377L0.797857 21.8491C1.06602 22.04 1.3416 22.2189 1.62386 22.3853L8.56262 15.4466L8.77013 16.5808L2.49905 22.8518C2.84577 23.0179 3.20066 23.1666 3.56253 23.2974L8.56262 18.2973L8.77013 19.4315L4.5864 23.6153C6.19334 24.0345 7.90833 24.1176 9.63516 23.8326C11.0879 23.5897 12.4599 23.0634 13.7108 22.2943C19.8042 18.6108 21.055 10.7985 17.4232 5.41484Z"
            />
            <path d="M30.3223 6.39559C29.7574 5.82891 29.7574 5.66698 29.717 5.50507C29.2731 2.34776 26.5292 0 23.3413 0C21.6867 0 20.1534 0.607178 18.9428 1.74057L18.055 2.63109C17.1425 3.50242 17.3853 3.18751 17.9743 3.96689C21.7674 8.98622 21.6062 16.1105 17.4497 20.9274C22.2762 22.0709 27.8608 16.4747 27.8608 10.8077C27.8608 9.87674 27.7397 8.94574 27.4976 8.05522C27.4169 7.81235 27.4976 7.56947 27.6187 7.36708C27.7801 7.16469 27.9819 7.04326 28.2643 7.04326H30.0802C30.914 7.04326 30.9131 6.98814 30.3223 6.39559Z" />
          </svg>
        </div>

        {/* Lines */}
        <div className="flex flex-col items-center">
          {/* Top line pattern */}
          <div className="flex items-center gap-4 mb-6">
            <div className="w-16 h-[1px] bg-gradient-to-r from-transparent via-slate-300 to-transparent" />
            <div className="w-1.5 h-1.5 rounded-full bg-slate-300" />
            <div className="w-16 h-[1px] bg-gradient-to-r from-transparent via-slate-300 to-transparent" />
          </div>
        </div>
      </div>

      {/* Bottom fade gradient */}
      <div className="absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-white via-white/90 to-transparent" />
    </div>
  );
};

export default EndOfNotes;

import { isNil } from 'lodash';

const METADATA_FIELDS = ['id', 'created_at', 'modified_at', 'matter', 'is_pcp'];

/**
 * Checks if a value is considered populated (not null, undefined, empty string, or empty array)
 */
export const isValuePopulated = (value: any): boolean => {
  if (isNil(value)) return false;
  if (typeof value === 'boolean') return false;
  if (Array.isArray(value)) return value.length > 0;
  if (typeof value === 'string') return value.trim() !== '';
  if (typeof value === 'object') return Object.keys(value).length > 0;
  return true;
};

/**
 * Checks if an object has any populated fields, excluding metadata fields
 */
export const hasPopulatedFields = (obj: Record<string, any>): boolean => {
  if (!obj) return false;
  const values = Object.entries(obj).filter(
    ([key]) => !METADATA_FIELDS.includes(key),
  );
  return values.some(([_, value]) => isValuePopulated(value));
};

import { Loader2 } from 'lucide-react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getInitials } from '@/components/tasks/TaskAssignee';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn, taskIsPastDue, taskIsUrgent } from '@/lib/utils';
import { useGetTasksQuery } from '@/services/api/tasksService';
import { Task } from '@/services/types/client-intake-types';
import {
  CanonicalTaskStatus,
  TaskStatusColors,
} from '@/services/types/task-types';

const getTaskStatusColor = (task: Task): string => {
  if (taskIsUrgent(task)) {
    return 'bg-purple-400';
  }
  if (taskIsPastDue(task)) {
    return 'bg-amber-400';
  }
  return 'bg-transparent';
};

const sortTasks = (tasks: Task[]): Task[] => {
  return [...tasks].sort((a, b) => {
    // 1. Urgent tasks at the top (identified by negative order value)
    const aIsUrgent = a.order !== undefined && a.order < 0;
    const bIsUrgent = b.order !== undefined && b.order < 0;

    if (aIsUrgent && !bIsUrgent) return -1;
    if (!aIsUrgent && bIsUrgent) return 1;

    // 2. Past due tasks next
    const aIsPastDue = taskIsPastDue(a);
    const bIsPastDue = taskIsPastDue(b);

    if (aIsPastDue && !bIsPastDue) return -1;
    if (!aIsPastDue && bIsPastDue) return 1;

    // 3. Sort by status: Pending -> In Progress -> Blocked -> Completed -> Not Applicable
    const statusOrder = {
      [CanonicalTaskStatus.PENDING]: 1,
      [CanonicalTaskStatus.IN_PROGRESS]: 2,
      [CanonicalTaskStatus.BLOCKED]: 3,
      [CanonicalTaskStatus.COMPLETED]: 4,
      [CanonicalTaskStatus.NOT_APPLICABLE]: 5,
    };

    const aStatusOrder = statusOrder[a.status.canonical_status] || 99;
    const bStatusOrder = statusOrder[b.status.canonical_status] || 99;

    return aStatusOrder - bStatusOrder;
  });
};

export const TaskGroupings = () => {
  const { matter_id } = useParams();
  const { data: tasks, isLoading: isTasksLoading } = useGetTasksQuery({
    limit: 100,
    matter: matter_id,
  });

  const groupedTasks: Record<string, Task[]> | undefined =
    tasks?.results.reduce(
      (acc: Record<string, Task[]>, task: Task) => {
        const { group } = task;
        if (!acc[group.name]) {
          acc[group.name] = [];
        }
        acc[group.name].push(task);
        return acc;
      },
      {} as Record<string, Task[]>,
    );

  if (isTasksLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
        <span className="ml-2 text-lg">Loading tasks...</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 ">
      <Accordion type="multiple" className="w-full">
        {Object.entries(groupedTasks || {}).map(([group, ts]) => (
          <AccordionItem key={group} value={group}>
            <AccordionTrigger className="font-bold cursor-pointer py-3">
              <div className="flex items-center justify-between w-full">
                {group}{' '}
                <span
                  className={cn(
                    'ml-2 text-sm font-bold bg-amber-100 p-2 rounded-md w-[60px] text-center',
                    ts.filter(
                      (task) =>
                        task.status.canonical_status ===
                        CanonicalTaskStatus.COMPLETED,
                    ).length === ts.length
                      ? 'bg-green-100 text-green-800'
                      : 'bg-amber-100 text-amber-800',
                  )}
                >
                  {
                    ts.filter(
                      (task) =>
                        task.status.canonical_status ===
                        CanonicalTaskStatus.COMPLETED,
                    ).length
                  }{' '}
                  / {ts.length}
                </span>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="flex flex-col gap-2 items-start">
                {sortTasks(ts).map((task: Task) => (
                  <Link
                    key={task.id}
                    to={`/matters/${matter_id}/tasks/${task.id}`}
                    className="hover:bg-gray-100 w-full"
                  >
                    <div className="flex items-center justify-between border border-gray-500 py-2 px-4 rounded-md relative overflow-hidden">
                      <div
                        className={`absolute left-0 top-0 bottom-0 w-1.5 ${getTaskStatusColor(
                          task,
                        )}`}
                      />
                      <span className="ml-2">{task.name}</span>
                      <div className="flex items-center gap-4">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <span
                                className={`font-semibold p-2 w-10 text-center ${
                                  task.assignee
                                    ? 'rounded-full bg-gray-200'
                                    : ''
                                }`}
                              >
                                {task.assignee
                                  ? getInitials(
                                      task.assignee.first_name,
                                      task.assignee.last_name,
                                    )
                                  : '-'}
                              </span>
                            </TooltipTrigger>
                            {task.assignee && (
                              <TooltipContent>
                                <p>{task.assignee?.email}</p>
                              </TooltipContent>
                            )}
                          </Tooltip>
                        </TooltipProvider>
                        <span
                          className={`text-sm text-center w-[120px] p-2 font-bold rounded-md ${TaskStatusColors[task.status.canonical_status]}`}
                        >
                          {task.status.name}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

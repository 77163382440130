import { useMemo } from 'react';

import {
  ProofreadingJob,
  ProofreadingRuleResult,
  ProofreadingRuleStatus,
  ProofreadingRuleType,
} from '../../../services/types/proofreading-types';

export function useRuleResults(jobResult?: ProofreadingJob) {
  return useMemo(() => {
    if (!jobResult) {
      return {
        resultsByType: {} as Record<
          ProofreadingRuleType,
          ProofreadingRuleResult[]
        >,
        resultsByStatus: {} as Record<
          ProofreadingRuleStatus,
          ProofreadingRuleResult[]
        >,
        statistics: {
          byType: {} as Record<ProofreadingRuleType, number>,
          byStatus: {} as Record<ProofreadingRuleStatus, number>,
        },
        findingCount: 0,
        recommendationCount: 0,
      };
    }

    const { rule_results } = jobResult;

    // Group results by rule type
    const resultsByType = rule_results.reduce(
      (acc, result) => {
        const type = result.rule.rule_type;
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(result);
        return acc;
      },
      {} as Record<ProofreadingRuleType, ProofreadingRuleResult[]>,
    );

    // Group results by status
    const resultsByStatus = rule_results.reduce(
      (acc, result) => {
        const { status } = result.result;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push(result);
        return acc;
      },
      {} as Record<ProofreadingRuleStatus, ProofreadingRuleResult[]>,
    );

    // Count results by type
    const typeStats = Object.values(ProofreadingRuleType).reduce(
      (acc, type) => {
        acc[type] = resultsByType[type]?.length || 0;
        return acc;
      },
      {} as Record<ProofreadingRuleType, number>,
    );

    // Count results by status
    const statusStats = Object.values(ProofreadingRuleStatus).reduce(
      (acc, status) => {
        acc[status] = resultsByStatus[status]?.length || 0;
        return acc;
      },
      {} as Record<ProofreadingRuleStatus, number>,
    );

    // Count total findings and recommendations
    const findingCount = rule_results.reduce((count, result) => {
      return count + (result.result.specific_issues?.length || 0);
    }, 0);

    const recommendationCount = rule_results.reduce((count, result) => {
      return count + (result.result.recommendations?.length || 0);
    }, 0);

    return {
      resultsByType,
      resultsByStatus,
      statistics: {
        byType: typeStats,
        byStatus: statusStats,
      },
      findingCount,
      recommendationCount,
    };
  }, [jobResult]);
}

import React from 'react';
import { useDescribeBlandScheduleQuery } from '@/services/api/blandScheduleService';
import { ScheduleDetailsProps, ScheduleDescriptionResponse } from './types';
import { PhoneDetails } from './PhoneDetails';
import { CalendarDaysComponent } from './CalendarDays';
import { WeeklySchedule } from './WeeklySchedule';
import { UpcomingCalls } from './UpcomingCalls';
import { RecentCallHistory } from './RecentCallHistory';
import { AdvancedDetails } from './AdvancedDetails';
import { CallPrompt } from './CallPrompt';
import { ErrorMessage } from './ErrorMessage';
import { RetryConfiguration } from './RetryConfiguration';

export const ScheduleDetails = ({ schedule }: ScheduleDetailsProps) => {
  // Fetch detailed schedule information
  const scheduleDetailsQuery = useDescribeBlandScheduleQuery(
    schedule?.id || '',
    {
      skip: !schedule?.id,
    },
  );

  const scheduleDetails = scheduleDetailsQuery.data as
    | ScheduleDescriptionResponse
    | undefined;
  const isLoadingDetails = scheduleDetailsQuery.isLoading;

  if (!schedule) {
    return (
      <div className="p-4 border-t bg-slate-50 text-center text-gray-500">
        Schedule details unavailable
      </div>
    );
  }

  // Extract and sort days of month safely
  const daysOfMonth = schedule.calendar_day_of_month
    ? [...schedule.calendar_day_of_month].sort((a, b) => a - b)
    : [];

  // Extract days of week safely
  const daysOfWeek = schedule.calendar_day_of_week || [];

  // Determine if we're using days of month or days of week
  const usingDaysOfMonth = daysOfMonth.length > 0;
  const usingDaysOfWeek = daysOfWeek.length > 0;

  // Get prompt from bland_payload if available
  const prompt =
    schedule.bland_payload?.task || schedule.bland_payload?.prompt || null;

  // Get upcoming run times from schedule details
  const upcomingRunTimes =
    scheduleDetails?.temporal_schedule?.upcoming_run_times || [];

  // Get recent actions from schedule details
  const recentActions = scheduleDetails?.database_schedule?.calls || [];

  // Check if schedule is paused
  const isPaused = scheduleDetails?.temporal_schedule?.state?.paused || false;

  return (
    <div className="p-4 border-t bg-slate-50/50 space-y-3">
      {/* Priority Section: Phone and upcoming calls */}
      <div className="flex flex-col space-y-3">
        {/* Phone section - prioritized */}
        <PhoneDetails schedule={schedule} />

        {/* Upcoming Calls Section - prioritized */}
        <UpcomingCalls
          upcomingRunTimes={upcomingRunTimes}
          isPaused={isPaused}
          isLoading={isLoadingDetails}
          timezone={schedule.timezone || 'UTC'}
        />

        {/* Prompt section - prioritized */}
        <CallPrompt prompt={prompt} />
      </div>

      {/* Secondary information in a less prominent section */}
      {(usingDaysOfMonth || usingDaysOfWeek) && (
        <div className="mt-2 pt-2 border-t border-slate-200">
          <div className="grid grid-cols-1 gap-3">
            {/* Calendar days section - only show if using days of month */}
            {usingDaysOfMonth && (
              <CalendarDaysComponent
                schedule={schedule}
                daysOfMonth={daysOfMonth}
              />
            )}

            {/* Days of Week section - only show if using days of week */}
            {usingDaysOfWeek && (
              <WeeklySchedule schedule={schedule} daysOfWeek={daysOfWeek} />
            )}
          </div>
        </div>
      )}

      {/* Recent Call History */}
      {recentActions.length > 0 && (
        <RecentCallHistory
          recentCalls={recentActions}
          isLoading={isLoadingDetails}
        />
      )}

      {/* Advanced Schedule Details */}
      {scheduleDetails && <AdvancedDetails scheduleDetails={scheduleDetails} />}

      {/* Error message */}
      <ErrorMessage errorMessage={schedule.error_message} />

      {/* Retry information */}
      <RetryConfiguration schedule={schedule} />
    </div>
  );
};

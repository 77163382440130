import {
  ChevronRight,
  BuildingIcon,
  CalendarIcon,
  Unlink,
  Link2,
  LinkIcon,
  RefreshCw,
} from 'lucide-react';
import React from 'react';
import { useFrontMatterLinks } from '@/providers/FrontMatterLinksContext';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import {
  CommandList,
  CommandItem,
  CommandEmpty,
  CommandGroup,
} from '@/components/ui/command';
import { InfoBadge } from '@/components/ui/info-badge';
import { BaseMatter } from '@/services/types/client-intake-types';
import { useFrontContext } from '@/providers/frontContext';
import * as frontService from '@/services/frontService';
import { FinchIcon } from './FinchIcon';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { LinkingButton } from './LinkingButton';

// Reusable badge component to reduce duplication
const MatterBadge = ({
  icon,
  text,
  variant = 'outline',
  className = '',
}: {
  icon?: React.ReactNode;
  text: string;
  variant?: 'outline' | 'secondary';
  className?: string;
}) => (
  <InfoBadge
    variant={variant}
    icon={
      icon && (
        <span className="text-muted-foreground mr-1">
          {React.cloneElement(icon as React.ReactElement, {
            className: 'h-3.5 w-3.5',
          })}
        </span>
      )
    }
    text={text}
    className={`${variant === 'outline' ? 'border-gray-200' : 'bg-gray-100'} ${className}`}
  />
);

/**
 * Matter item component for search results
 */
const MatterItem = ({
  matter,
  isLinked,
}: {
  matter: BaseMatter;
  isLinked?: boolean;
}) => {
  const { selectMatter } = useFrontMatterLinks();

  return (
    <CommandItem
      onSelect={() => selectMatter(matter)}
      className={`group relative flex items-start rounded-md border p-2 ${
        isLinked
          ? 'border-primary/30 bg-primary/5'
          : 'border-transparent hover:border-gray-200 hover:bg-gray-100/50'
      }`}
    >
      <div className="min-w-0 flex-1">
        <div className="flex items-center gap-2">
          <h4 className="text-primary truncate text-xs font-medium">
            {matter.name}
          </h4>
          {isLinked && (
            <InfoBadge
              variant="outline"
              text="Linked"
              className="border-primary/30 bg-primary/5 text-primary text-[10px]"
            />
          )}
        </div>
        <div className="mt-1 flex flex-wrap gap-1.5">
          {matter.firm?.name && <MatterBadge text={matter.firm.name} />}
          {matter.date_of_loss && (
            <MatterBadge text={matter.date_of_loss} variant="secondary" />
          )}
        </div>
      </div>
      <ChevronRight className="text-muted-foreground absolute top-1/2 right-2 h-3.5 w-3.5 -translate-y-1/2 opacity-0 transition-opacity group-hover:opacity-100" />
    </CommandItem>
  );
};

/**
 * Simplified badges row component
 */
const MatterBasicsRow = () => {
  const {
    refetchMatters,
    isLoading,
    selectedMatter: matter,
  } = useFrontMatterLinks();

  const handleRefresh = () => {
    // Placeholder for refresh functionality
    console.log('Refreshing matter data:', matter?.id);
    refetchMatters();
  };

  return (
    <div className="scrollbar-hide flex items-center gap-2 overflow-x-auto border-t border-gray-200 px-3 py-2">
      <div className="flex-grow flex items-center gap-2">
        {matter?.firm?.name && (
          <MatterBadge
            icon={<BuildingIcon />}
            text={matter?.firm?.name || ''}
          />
        )}
        {matter?.date_of_loss && (
          <MatterBadge
            icon={<CalendarIcon />}
            text={matter?.date_of_loss || ''}
            variant="secondary"
          />
        )}
      </div>
      <button
        type="button"
        onClick={handleRefresh}
        className="text-muted-foreground cursor-pointer hover:text-primary flex-shrink-0 p-1 rounded-full hover:bg-gray-100 transition-colors"
        aria-label="Refresh matter data"
        disabled={isLoading}
      >
        <RefreshCw
          className={`h-3.5 w-3.5 ${isLoading ? 'animate-spin' : ''}`}
        />
      </button>
    </div>
  );
};

/**
 * Search results component for displaying filtered matters
 */
const SearchResults = () => {
  const { mattersLoading, filteredMatters, searchQuery } =
    useFrontMatterLinks();
  const frontContext = useFrontContext();
  const linkedMatterId = frontContext
    ? frontService.getMatterId(frontContext)
    : null;

  // Sort matters so linked matter appears first
  const sortedMatters = React.useMemo(() => {
    return [...filteredMatters].sort((a, b) => {
      if (a.id === linkedMatterId) return -1;
      if (b.id === linkedMatterId) return 1;
      return 0;
    });
  }, [filteredMatters, linkedMatterId]);

  if (mattersLoading) {
    return <Skeleton className="mx-auto my-2 h-4 w-32 rounded-md" />;
  }

  return (
    <div className="transition-all duration-300">
      <CommandList className="max-h-96 p-3">
        {sortedMatters.length > 0 ? (
          <CommandGroup className="space-y-1.5 p-0">
            {sortedMatters.map((matter) => (
              <MatterItem
                key={matter.id}
                matter={matter}
                isLinked={matter.id === linkedMatterId}
              />
            ))}
          </CommandGroup>
        ) : (
          <CommandEmpty className="py-3 text-center">
            <p className="text-muted-foreground text-xs">
              {searchQuery.trim()
                ? `No matters found matching "${searchQuery}"`
                : 'Search for a matter by name or firm'}
            </p>
          </CommandEmpty>
        )}
      </CommandList>
    </div>
  );
};

/**
 * Unified header component with search functionality
 */
const FrontMatterHeaderWithSearch = ({
  searchInputRef,
}: {
  searchInputRef: React.RefObject<HTMLInputElement>;
}) => {
  const {
    selectedMatter,
    isLoading,
    searchQuery,
    updateSearchQuery,
    setSelectedMatter,
    mattersLoading,
  } = useFrontMatterLinks();
  const frontContext = useFrontContext();
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const searchResultsRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!selectedMatter) {
      setTimeout(() => searchInputRef.current?.focus(), 0);
    }
  }, [selectedMatter, searchInputRef]);

  const handleDeselect = React.useCallback(() => {
    setSelectedMatter(null);
  }, [setSelectedMatter]);

  const handleBlur = React.useCallback((e: React.FocusEvent) => {
    // Check if the new focused element is within our search results
    if (searchResultsRef.current?.contains(e.relatedTarget as Node)) {
      return;
    }
    // Add a small delay to allow click events to complete
    setTimeout(() => {
      setIsInputFocused(false);
    }, 200);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="relative">
        <div className="flex flex-col">
          <div className="border-b border-gray-200">
            <div className="flex h-6 w-full flex-row items-center px-3 py-4">
              <button
                disabled={isLoading}
                onClick={() => selectedMatter && handleDeselect()}
                type="button"
                className="flex cursor-pointer items-center justify-center transition-all duration-200 ease-in-out"
              >
                <FinchIcon isActive={!!selectedMatter} />
              </button>

              <div className="flex-1 transition-all duration-200 ease-in-out">
                {selectedMatter ? (
                  <div className="flex items-start">
                    <button
                      onClick={handleDeselect}
                      type="button"
                      className="text-muted-foreground hover:text-primary truncate px-2 text-lg font-semibold"
                    >
                      {selectedMatter.name}
                    </button>
                    {frontService.getMatterId(frontContext) ===
                      selectedMatter.id && (
                      <InfoBadge
                        variant="outline"
                        text="Linked"
                        className="border-primary/30 bg-primary/5 text-primary mt-1 text-[10px]"
                      />
                    )}
                    {isLoading && (
                      <div className="bg-primary ml-1 h-2 w-2 animate-pulse rounded-full" />
                    )}
                  </div>
                ) : (
                  <input
                    ref={searchInputRef}
                    value={searchQuery}
                    onChange={(e) => updateSearchQuery(e.target.value)}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={handleBlur}
                    placeholder="Search matters..."
                    className="text-md h-6 w-full px-2 transition-all duration-200 ease-in-out focus:ring-0 focus:outline-none"
                  />
                )}
              </div>

              <LinkingButton />
            </div>
          </div>

          {!selectedMatter &&
            !mattersLoading &&
            (isInputFocused || searchQuery.trim() !== '') && (
              <div ref={searchResultsRef}>
                <SearchResults />
              </div>
            )}
        </div>
        <div>{selectedMatter && <MatterBasicsRow />}</div>
      </div>
    </div>
  );
};

export default FrontMatterHeaderWithSearch;

import { AlertCircle, ChevronDown, Edit } from 'lucide-react';
import React, { useState } from 'react';

import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

import { FindingEntry } from '../../../services/types/proofreading-types';

interface FindingDetailProps {
  finding: FindingEntry;
  compact?: boolean;
}

export const FindingDetail: React.FC<FindingDetailProps> = ({
  finding,
  compact = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="border-l-2 border-amber-400 pl-3 bg-amber-50/50 rounded-r-md">
      {/* Always visible rationale */}
      <div
        className="flex items-start py-2 cursor-pointer group"
        onClick={() => setIsExpanded(!isExpanded)}
        onKeyDown={(e) => e.key === 'Enter' && setIsExpanded(!isExpanded)}
        tabIndex={0}
        role="button"
        aria-expanded={isExpanded}
      >
        <div className="flex-1">
          <div
            className={cn(
              'text-gray-800 pr-2 font-medium',
              compact ? 'text-xs' : 'text-sm',
            )}
          >
            {finding.rationale}
          </div>
          <div
            className={cn(
              'text-gray-500 group-hover:text-gray-700 transition-colors flex items-center mt-1',
              compact ? 'text-[10px]' : 'text-xs',
            )}
          >
            {isExpanded ? 'Click to collapse' : 'Click to view details'}
            <ChevronDown
              className={cn(
                'h-3 w-3 ml-1 text-gray-400 transition-transform',
                isExpanded && 'transform rotate-180',
              )}
            />
          </div>
        </div>
      </div>

      {/* Expandable details */}
      {isExpanded && (
        <div className="pb-3 px-1">
          <Separator className="my-2 opacity-30" />

          <div className="grid grid-cols-1 gap-3">
            <div className="space-y-1.5">
              <div className="flex items-center">
                <div
                  className={cn(
                    'font-semibold text-gray-600',
                    compact ? 'text-[10px]' : 'text-xs',
                  )}
                >
                  Excerpt
                </div>
                <div
                  className={cn(
                    'text-gray-400 ml-1.5',
                    compact ? 'text-[9px]' : 'text-[10px]',
                  )}
                >
                  (Original text)
                </div>
              </div>
              <div
                className={cn(
                  'p-2 bg-white border border-gray-200 rounded font-mono',
                  compact ? 'text-xs' : 'text-sm',
                )}
              >
                {finding.excerpt}
              </div>
            </div>

            <div className="space-y-1.5">
              <div className="flex items-center">
                <div
                  className={cn(
                    'font-semibold text-gray-600',
                    compact ? 'text-[10px]' : 'text-xs',
                  )}
                >
                  Suggested Fix
                </div>
              </div>
              <div
                className={cn(
                  'p-2 bg-white border-l-2 border-green-500 border border-gray-200 rounded font-mono',
                  compact ? 'text-xs' : 'text-sm',
                )}
              >
                {finding.suggested_fix}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import { NodeViewProps } from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/react';
import { cn } from '@/lib/utils';
import { useTemplateVariables } from '../context/TemplateContext';

export const TemplateNodeView = ({ node }: NodeViewProps) => {
  const { values } = useTemplateVariables();
  const variableName = node.attrs.id as string;
  const value = values[variableName];
  const hasValue = !!value && value.trim() !== '';

  if (!hasValue) {
    return (
      <NodeViewWrapper
        as="span"
        className="rounded-xs break-words font-mono   inline-block leading-none align-bottom  px-0.5 border-red-600 border-b-2 bg-red-300"
      >
        [{variableName}]
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper
      as="span"
      className={cn(
        'rounded break-words inline-block align-bottom  bg-green-100',
      )}
    >
      <div className="flex flex-col items-left">
        <span className="text-[8px] text-gray-600 font-mono leading-none">
          {variableName}
        </span>
        <span>{value}</span>
      </div>
    </NodeViewWrapper>
  );
};

import { Check, ChevronDown } from 'lucide-react';
import * as React from 'react';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { CanonicalTaskType } from '@/services/types/task-types';

interface TaskTypeSelectorProps {
  value: CanonicalTaskType;
  onChange: (value: CanonicalTaskType) => void;
  label?: string;
  required?: boolean;
  className?: string;
  placeholder?: string;
}

export const TaskTypeSelector = ({
  value,
  onChange,
  label = 'Task Type',
  required = false,
  className,
  placeholder = 'Select a task type',
}: TaskTypeSelectorProps) => {
  const [open, setOpen] = React.useState(false);
  const options = Object.values(CanonicalTaskType).sort();

  return (
    <div className={cn(label ? 'space-y-2' : '')}>
      {label && (
        <Label className="text-sm font-medium">
          {label} {required && <span className="text-red-500">*</span>}
        </Label>
      )}
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn('w-full justify-between', className)}
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
            }}
          >
            <span className="truncate">{value || placeholder}</span>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-[--radix-popover-trigger-width] p-0 bg-white"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search task types..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((type) => (
                  <CommandItem
                    key={type}
                    value={type}
                    onSelect={() => {
                      onChange(type as CanonicalTaskType);
                      setOpen(false);
                    }}
                    className="hover:bg-gray-100"
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        value === type ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                    {type}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

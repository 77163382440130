import React from 'react';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';

interface ClientFormFieldProps {
  label: string;
  name: string;
  value: any;
  onChange: (value: any) => void;
  type?: string;
  placeholder?: string;
  multiline?: boolean;
  pattern?: RegExp;
  maxLength?: number;
  disabled?: boolean;
}

export const ClientFormField: React.FC<ClientFormFieldProps> = ({
  label,
  name,
  value,
  onChange,
  type = 'text',
  placeholder,
  multiline = false,
  pattern,
  maxLength,
  disabled = false,
}) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newValue = e.target.value;

    // Apply pattern filter if provided
    if (pattern) {
      newValue = newValue.replace(pattern, '');
    }

    // Apply max length if provided
    if (maxLength) {
      newValue = newValue.slice(0, maxLength);
    }

    onChange(newValue);
  };

  if (multiline) {
    return (
      <Textarea
        id={name}
        name={name}
        value={value || ''}
        onChange={handleChange}
        placeholder={placeholder}
        className="resize-none min-h-[100px]"
        disabled={disabled}
      />
    );
  }

  return (
    <Input
      id={name}
      name={name}
      type={type}
      value={value || ''}
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
      className="h-9"
    />
  );
};

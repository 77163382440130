import { Repeat, Calendar as CalendarIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { ScheduleFormValues } from './types';

interface SelectionModeToggleProps {
  onModeChange: (mode: 'dates' | 'daysOfWeek') => void;
}

export const SelectionModeToggle = ({
  onModeChange,
}: SelectionModeToggleProps) => {
  const form = useFormContext<ScheduleFormValues>();
  const currentSelectionMode = form.watch('selectionMode');

  return (
    <div className="flex items-center justify-center space-x-4 border rounded-lg p-3">
      <Button
        type="button"
        variant={currentSelectionMode === 'daysOfWeek' ? 'default' : 'outline'}
        onClick={() => onModeChange('daysOfWeek')}
        className="flex items-center gap-1.5"
      >
        <Repeat className="h-4 w-4" />
        <span>Weekly Schedule</span>
      </Button>
      <Button
        type="button"
        variant={currentSelectionMode === 'dates' ? 'default' : 'outline'}
        onClick={() => onModeChange('dates')}
        className="flex items-center gap-1.5"
      >
        <CalendarIcon className="h-4 w-4" />
        <span>Days of Month</span>
      </Button>
    </div>
  );
};

import { Bot, PanelLeft } from 'lucide-react';
import { useState } from 'react';
import { cn } from '../../lib/utils';
import { MatterThread } from '../../services/api/matterAssistant';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogOverlay } from '../ui/dialog';
import { ThreadContent } from './thread-content';
import { ThreadSidebar } from './thread-sidebar';

export const MatterAssistantContent = ({ matterId }: { matterId: string }) => {
  const [selectedThread, setSelectedThread] = useState<MatterThread | null>(
    null,
  );

  const [sidebarCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapsed = () => setIsCollapsed(!sidebarCollapsed);

  const handleThreadSelected = (thread: MatterThread | null) => {
    setSelectedThread(thread);
  };

  return (
    <div className="w-full h-full flex flex-row">
      <div
        className={cn('h-full w-full @container transition-all duration-300', {
          'w-0': sidebarCollapsed,
          'md:w-64': !sidebarCollapsed,
        })}
      >
        <ThreadSidebar
          matterId={matterId}
          onThreadSelected={handleThreadSelected}
          toggleCollapsed={toggleCollapsed}
        />
      </div>
      {sidebarCollapsed && (
        <div className="sticky top-0 right-0 w-0 h-0">
          <Button
            variant="ghost"
            size="icon"
            className="m-3"
            onClick={toggleCollapsed}
          >
            <PanelLeft className="h-3 w-3" />
          </Button>
        </div>
      )}

      <div
        className={cn('flex-1 p-4 w-full h-full hidden', {
          'w-full block': sidebarCollapsed,
          'sm:w-0 sm:hidden md:block md:w-full': !sidebarCollapsed,
        })}
      >
        {selectedThread ? (
          <ThreadContent matterId={matterId} thread={selectedThread} />
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            Select a thread to view messages
          </div>
        )}
      </div>
    </div>
  );
};

export const ActionButton = ({ matterId }: { matterId: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
    setIsAnimationComplete(false);
  };

  const handleAnimationEnd = () => {
    if (isOpen) {
      setIsAnimationComplete(true);
    }
  };

  return (
    <>
      <div className="">
        <Button
          variant="outline"
          color="primary"
          className="rounded-3xl h-12 w-12 p-0 flex items-center justify-center transition-all duration-200 hover:text-primary-foreground hover:scale-110 hover:shadow-md"
          onClick={handleOpen}
        >
          <span className="sr-only">Action Button</span>
          <Bot className="h-6 w-6" />
        </Button>
      </div>

      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          setIsOpen(open);
          if (!open) setIsAnimationComplete(false);
        }}
      >
        <DialogOverlay className="bg-transparent" />
        <DialogContent
          className={cn(
            'flex flex-row',
            'fixed bottom-4 right-4',
            'max-w-none rounded-3xl sm:rounded-3xl',
            'left-[auto] top-[auto] translate-x-[auto] translate-y-[auto]',
            'w-12 h-12',
            'bg-white',
            'fade-in-0 zoom-in-95',
            'p-0',
          )}
          style={{
            animation:
              'dialogExpand 150ms 0ms cubic-bezier(0, 0.5, 0.75, 1) forwards',
          }}
          onAnimationEnd={handleAnimationEnd}
        >
          {isAnimationComplete && (
            <MatterAssistantContent matterId={matterId} />
          )}
        </DialogContent>
      </Dialog>

      <style>{`
        @keyframes dialogExpand {
          0% {
            width: 3rem;
            height: 3rem;
            opacity: 0.8;
          }
          100% {
            width: calc(100% - 3rem);
            height: calc(100% - 3rem);
            opacity: 1;
          }
        }
      `}</style>
    </>
  );
};

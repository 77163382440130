import { Box } from '@mui/material';
import { DamageNote } from '../../../../services/types/matter-notes-types';
import ReadOnlyNoteSection from './ReadOnlyNoteSection';
import ReadOnlyNoteField from './ReadOnlyNoteField';
import { hasPopulatedFields } from '../../../../utils/noteUtils';

interface ReadOnlyDamageNoteSectionProps {
  notes: DamageNote[];
}

const ReadOnlyDamageNoteSection = ({
  notes,
}: ReadOnlyDamageNoteSectionProps) => {
  if (!notes?.length || !notes.some(hasPopulatedFields)) return null;

  return (
    <ReadOnlyNoteSection title="Damages">
      {notes.map((note, index) => (
        <Box
          key={note.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mb: index < notes.length - 1 ? 4 : 0,
          }}
        >
          <ReadOnlyNoteField label="AMOUNT" value={note.amount} />
          <ReadOnlyNoteField label="NOTES" value={note.description} />
        </Box>
      ))}
    </ReadOnlyNoteSection>
  );
};

export default ReadOnlyDamageNoteSection;

import { Loader2, MessageSquare, AlertCircle } from 'lucide-react';
import { useFrontContext } from '@/providers/frontContext';
import { CreateMatterForm } from './FrontCreateMatterForm';
import { FrontMatterPanel } from './FrontMatterPanel';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

const EmptyState = ({
  icon: Icon,
  title,
  description,
}: {
  icon: any;
  title: string;
  description: string;
}) => (
  <div className="bg-muted/5 flex h-[200px] flex-col items-center justify-center rounded-lg border-2 border-dashed p-8 text-center">
    <Icon className="text-muted-foreground mb-4 h-12 w-12" />
    <h3 className="mb-2 text-lg font-semibold">{title}</h3>
    <p className="text-muted-foreground max-w-sm text-sm">{description}</p>
  </div>
);

export const FrontSidebarContent = () => {
  const context = useFrontContext();

  if (!context)
    return (
      <div className="w-full p-4">
        <EmptyState
          icon={Loader2}
          title="Connecting..."
          description="Waiting to connect to the Front context."
        />
      </div>
    );

  switch (context.type) {
    case 'noConversation':
      return (
        <div className="w-full p-4">
          <EmptyState
            icon={MessageSquare}
            title="No Conversation Selected"
            description="Select a conversation to use this plugin."
          />
        </div>
      );
    case 'multiConversations':
      return (
        <div className="w-full p-4">
          <EmptyState
            icon={AlertCircle}
            title="Multiple Conversations"
            description="This plugin does not support multi-conversation mode."
          />
        </div>
      );
    case 'singleConversation':
      return (
        <div className="w-full">
          <Tabs defaultValue="matters" className="w-full">
            <TabsList className="flex w-full justify-between rounded-none border-b border-gray-200 bg-transparent p-1">
              <TabsTrigger
                value="matters"
                className="data-[state=active]:text-primary px-1 text-[11px] data-[state=active]:bg-transparent data-[state=active]:shadow-none"
              >
                Matters
              </TabsTrigger>
              <TabsTrigger
                value="create"
                className="data-[state=active]:text-primary px-1 text-[11px] data-[state=active]:bg-transparent data-[state=active]:shadow-none"
              >
                Create Matter
              </TabsTrigger>
            </TabsList>
            <TabsContent value="matters">
              <FrontMatterPanel />
            </TabsContent>
            <TabsContent value="create">
              <CreateMatterForm />
            </TabsContent>
          </Tabs>
        </div>
      );
    default:
      console.error(`Unsupported context type: ${context.type}`);
      break;
  }

  return <div>Loading...</div>;
};

import { Box, Grid } from '@mui/material';
import { EmergencyContactNote } from '../../../../services/types/matter-notes-types';
import ReadOnlyNoteSection from './ReadOnlyNoteSection';
import ReadOnlyNoteField from './ReadOnlyNoteField';
import { hasPopulatedFields } from '../../../../utils/noteUtils';

interface ReadOnlyEmergencyContactNoteSectionProps {
  note?: EmergencyContactNote;
}

const ReadOnlyEmergencyContactNoteSection = ({
  note,
}: ReadOnlyEmergencyContactNoteSectionProps) => {
  if (!note || !hasPopulatedFields(note)) return null;

  return (
    <Box>
      <ReadOnlyNoteSection title="Emergency Contact">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ReadOnlyNoteField label="Name" value={note.name} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReadOnlyNoteField label="Phone" value={note.phone} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReadOnlyNoteField label="Email" value={note.email} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReadOnlyNoteField label="Address" value={note.address} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReadOnlyNoteField
              label="Date of Birth"
              value={note.date_of_birth}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReadOnlyNoteField
              label="Last 4 of SSN"
              value={note.last_four_of_ssn}
            />
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyNoteField label="Description" value={note.description} />
          </Grid>
        </Grid>
      </ReadOnlyNoteSection>
    </Box>
  );
};

export default ReadOnlyEmergencyContactNoteSection;

import { MentionNodeAttrs } from '@tiptap/extension-mention';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { cn } from '@/lib/utils';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command';

interface MentionItem {
  id: string;
  label: string;
  value: string;
}

interface MentionListProps {
  items: MentionItem[];
  command: (item: Required<MentionNodeAttrs>) => void;
}

interface MentionListRef {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
}

export const MentionList = forwardRef<MentionListRef, MentionListProps>(
  (props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const commandRef = useRef<HTMLDivElement>(null);

    const selectItem = (value: string) => {
      const foundItem = props.items.find((item) => item.id === value);
      if (foundItem) {
        props.command({
          id: foundItem.id,
          label: foundItem.label,
        });
      }
    };
    useEffect(() => {
      setSelectedIndex(0);
    }, [props.items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          event.preventDefault();
          setSelectedIndex(
            (current) =>
              (current + props.items.length - 1) % props.items.length,
          );
          return true;
        }

        if (event.key === 'ArrowDown') {
          event.preventDefault();
          setSelectedIndex((current) => (current + 1) % props.items.length);
          return true;
        }

        if (event.key === 'Enter') {
          event.preventDefault();
          const selectedItem = props.items[selectedIndex];
          if (selectedItem) {
            selectItem(selectedItem.id);
          }
          return true;
        }

        return false;
      },
    }));

    return (
      <Command
        ref={commandRef}
        className="rounded-lg bg-white border shadow-md relative z-[100] overflow-hidden"
      >
        <CommandList className="max-h-[200px] overflow-y-auto py-2">
          <CommandGroup className="h-full">
            {props.items.length ? (
              props.items.map((item, index) => (
                <CommandItem
                  key={item.id}
                  value={item.id}
                  onSelect={() => selectItem(item.id)}
                  onMouseEnter={() => setSelectedIndex(index)}
                  className={cn(
                    'cursor-pointer transition-colors hover:bg-purple-100 hover:text-purple-900 font-mono text-xs p-1',
                    index === selectedIndex && 'bg-purple-100 text-purple-900',
                  )}
                >
                  <div className="flex flex-col gap-1">
                    <div className="font-medium">{item.label}</div>
                    <div
                      className={cn(
                        'text-xs',
                        index === selectedIndex
                          ? 'text-purple-700'
                          : 'text-muted-foreground',
                      )}
                    >
                      {item.value}
                    </div>
                  </div>
                </CommandItem>
              ))
            ) : (
              <div className="p-2 text-sm text-muted-foreground">
                No variables found
              </div>
            )}
          </CommandGroup>
        </CommandList>
      </Command>
    );
  },
);

MentionList.displayName = 'MentionList';

import { Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import { DetailedMatter } from '../../../services/types/client-intake-types';

export interface MatterInfoProps {
  matter: DetailedMatter;
}

export const MatterInfo: React.FC<MatterInfoProps> = ({ matter }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatDate = (dateString?: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');
  };

  const infoItems = [
    {
      label: 'Date of Birth',
      value: formatDate(matter.clients[0]?.date_of_birth),
    },
    { label: 'Date of Loss', value: formatDate(matter.date_of_loss) },
    {
      label: '',
      value:
        matter.city && matter.state ? `${matter.city}, ${matter.state}` : '',
    },
    { label: 'SOL', value: formatDate(matter.statute_of_limitations_date) },
  ].filter((item) => item.value);

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h1Secondary">{matter.name}</Typography>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
        {infoItems.map((item, index) => (
          <Stack key={index} direction="row" spacing={1} alignItems="center">
            {index > 0 && item.label && !isMobile && (
              <Typography variant="h4Secondary" color="info.light">
                •
              </Typography>
            )}
            <Typography variant="h4Secondary">
              {item.label ? `${item.label}: ${item.value}` : item.value}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

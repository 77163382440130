import React from 'react';

import { EmptyStateProps } from '../../types';

export const EmptyState: React.FC<EmptyStateProps> = () => (
  <div className="flex flex-col items-center justify-center py-8 text-muted-foreground">
    <div className="text-sm">No messages in this thread</div>
    <div className="text-xs mt-1">Type a message to start the conversation</div>
  </div>
);
